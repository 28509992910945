import React, { useEffect, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import MedsListView from './medsListView';
import { useMedPassApi } from '../../hooks/api/medpassApiHook'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS } from '../../config/config';

const PassMeds = (props) => {
   const [times, setTimes] = useState([])

   const medPassApi = useMedPassApi()
   const dispatch = useDispatch()
   const activeTab = useSelector(state => state.extras && state.extras.tabIndex)

   useEffect(() => {
      getTimingList()
   }, [])

   const getTimingList = () => {
      medPassApi.timingList(
         (message, resp) => {
            let temp = resp.length ? resp.map(item => {
               return {
                  name: item.name,
                  time: `${moment(item.fromTime, 'HH:mm:ss').format('HH:mm')}-${moment(item.toTime, 'HH:mm:ss').format('HH:mm')
                     }`
               }
            }) : []
            setTimes(temp)
         }, (message) => {
            //toast.error(message)
         })
   }

   const toggle = tab => {
      console.log('tab',tab)
      if (activeTab !== tab) {
         dispatch({ type: ACTIONS.MEDPASS.SET_TAB_INDEX, payload: tab })
         dispatch({ type: ACTIONS.MEDPASS.SET_PAGE, payload: 1 })
      }
   }

   const setTimeStr = (type) => {
      let temp = times.find(item => item.name === type)
      if (temp) {
         return `(${temp.time})`
      }
      else {
         return ''
      }
   }

   return (
      <div className="page-content" >
         <div className="container-fluid">
            {/* start page title */}
            <div className="row">
               <div className="col-12">
                  <div className="page-title-box">
                     <h4 className="mb-0 font-size-18">Pass Meds</h4></div>
               </div>
            </div>

            <div className="row f_1">
               <div className="col-lg-12">
                  <div className="card">
                     <div className="card-body p-0">
                        <Nav tabs>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '1' ? 'active' : ''} cursor-pointer text-center`}
                                 onClick={() => { toggle('1'); }}
                              >Morning <br />{setTimeStr('Morning')}</NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '2' ? 'active' : ''} cursor-pointer text-center`}
                                 onClick={() => { toggle('2'); }}
                              >Afternoon <br />{setTimeStr('Afternoon')}</NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '3' ? 'active' : ''} cursor-pointer text-center`}
                                 onClick={() => { toggle('3'); }}
                              >Evening <br />{setTimeStr('Evening')}</NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '4' ? 'active' : ''} cursor-pointer text-center`}
                                 onClick={() => { toggle('4'); }}
                              >Bed Time <br />{setTimeStr('Bed Time')}</NavLink>
                           </NavItem>
                           {/* <NavItem>
                              <NavLink
                                 className={`${activeTab === '5' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('5'); }}
                              >Treatment</NavLink>
                           </NavItem> */}
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '6' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('6'); }}
                              >As Needed(PRN)</NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '7' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('7'); }}
                              >Comfort Kit</NavLink>
                           </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                           <TabPane tabId="1">
                              {activeTab === '1' && <MedsListView time='Am' />}
                           </TabPane>
                           <TabPane tabId="2">
                              {activeTab === '2' && <MedsListView time='Pm' />}
                           </TabPane>
                           <TabPane tabId="3">
                              {activeTab === '3' && <MedsListView time='Evening' />}
                           </TabPane>
                           <TabPane tabId="4">
                              {activeTab === '4' && <MedsListView time='Bed' />}
                           </TabPane>
                           <TabPane tabId="5">
                              {activeTab === '5' && <MedsListView time='Treatment' />}
                           </TabPane>
                           <TabPane tabId="6">
                              {activeTab === '6' && <MedsListView time='Pnr' />}
                           </TabPane>
                           <TabPane tabId="7">
                              {activeTab === '7' && <MedsListView time='CmfKit' />}
                           </TabPane>
                        </TabContent>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default PassMeds