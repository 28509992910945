import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyReports = React.lazy(() =>
   import('./inventory')
);

const InventoryReportss = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyReports {...props} />
   </React.Suspense>
);

export default InventoryReportss