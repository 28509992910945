import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useRoleApi() {
   const callApi = useApiCall();

   const addEditRole = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.ADD_EDIT_ROLE, data, onSuccess, onError);
   }

   const assignPermissionsToRole = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.ASSIGN_PERMISSIONS_TO_ROLE, data, onSuccess, onError);
   }

   const deleteRole = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.DELETE_ROLE, data, onSuccess, onError);
   }

   const roleList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.ROLE_LIST, data, onSuccess, onError);
   }

   const rolePermissionsList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.ROLE_PERMISSION_LIST, data, onSuccess, onError);
   }

   const permissionsList = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.PERMISSIONS_LIST, null, onSuccess, onError);
   }

   const getDefaultRole = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.GET_DEFAULT_ROLE, null, onSuccess, onError);
   }

   const unassignRole = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.UNASSIGN_USER_ROLE, data, onSuccess, onError);
   }

   const getRoleDetails = (data, onSuccess, onError) => {
      console.log("Role API Data",data);
      callApi(SAGA_ACTIONS.ROLES.GET_ROLE_DETAILS, data, onSuccess, onError);
   }

   const permissionsListByRoleType = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ROLES.PERMISSIONS_LIST_BY_ROLE_TYPE, data, onSuccess, onError);
   }

   return {
      addEditRole,
      assignPermissionsToRole,
      deleteRole,
      roleList,
      permissionsList,
      getDefaultRole,
      unassignRole,
      rolePermissionsList,
      getRoleDetails,
      permissionsListByRoleType
   }
}