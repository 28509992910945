import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useMedPassApi() {
   const callApi = useApiCall();

   const getPatientList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.GET_LIST, data, onSuccess, onError);
   }

   const getMedPassDetails = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.GET_DETAILS, data, onSuccess, onError);
   }

   const submitMedPass = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.SUBMIT_MEDPASS, data, onSuccess, onError);
   }

   const dispenseList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.DISPENSE_LIST, data, onSuccess, onError);
   }

   const updateDispense = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.UPDATE_DISPENSED_MED, data, onSuccess, onError);
   }

   const timingList = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDPASS.GET_TIMINGS, null, onSuccess, onError);
   }

   return {    
      getPatientList,
      getMedPassDetails,
      submitMedPass,
      dispenseList,
      timingList,
      updateDispense
   }
}