import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useUserApi() {
   const callApi = useApiCall();

   const getProfile = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.ME, null, onSuccess, onError);
   }
   
   const login = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
   }

   const forgotPassword = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
   }

   const resetPassword = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
   }

   const changePassword = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.CHANGE_PASSWORD, data, onSuccess, onError);
   }

   const checkEmailToken = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.CHECK_TOKEN, data, onSuccess, onError);
   }

   const verifyResetPassToken = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.VALID_PASSWORD_TOKEN, data, onSuccess, onError);
   }

   const logout = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.LOGOUT, null, onSuccess, onError);
   }

   const register = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.REGISTER, data, onSuccess, onError);
   }

   const getUser = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.GET_USER, data, onSuccess, onError);
   }

   const getUserByRoleId = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.GET_USER_BY_ROLEID, data, onSuccess, onError);
   }

   const updateUser = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.UPDATE_USER, data, onSuccess, onError);
   }

   const updateUserMultipleFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.UPDATE_USER_MULTIPLE_FACILITY, data, onSuccess, onError);
   }

   const updateIncidentWitness = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.UPDATE_INCIDENT_WITNESS, data, onSuccess, onError);
   }

   const getUserMultipleFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.GET_USER_MULTIPLE_FACILITY, data, onSuccess, onError);
   }

   const getPermissions = (onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.USER_PERMISSIONS, null, onSuccess, onError);
   }

   const changeUserState = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.CHANGE_USER_STATE, data, onSuccess, onError);
   }

   const deleteUser = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.DELETE_USER, data, onSuccess, onError);
   }

   return {
      login,
      logout,
      changeUserState,
      getUser,
      getUserByRoleId,
      deleteUser,
      updateUser,
      getPermissions,
      register,
      getProfile,
      checkEmailToken,
      forgotPassword,
      resetPassword,
      changePassword,
      verifyResetPassToken,
      updateUserMultipleFacility,
      getUserMultipleFacility,
      updateIncidentWitness
      
   }
}