import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import './report.css';
import {toast} from 'react-toastify';
import {
  decryptId,
  toCustomTitleCase,
} from '../../components/form-elements/functions';
import moment from 'moment';
import 'moment-timezone';
import {useResidentApi} from '../../hooks/api/residentApiHook';
import ReactToPrint from 'react-to-print';
import {formatPhone} from '../../components/form-elements/functions';

const emptyArr = [...Array(4).keys()];

const Template = () => {
  const [resident, setResident] = useState([]);
  const [data, setData] = useState([]);
  const [incidentTypes, setIncidentTypes] = useState([]);
  const [typeSettingDone, setTypeSettingDone] = useState(false);

  const residentApi = useResidentApi();
  const history = useHistory();
  const {id: uid} = useParams();

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
  console.log(timeZone);

  useEffect(() => {
    fetchIncidentTypes();
    getList();
  }, []);

  useEffect(() => {
    if (data.incidentTypes && data.incidentTypes.length) {
      setIncidentTypes(
        incidentTypes.map(i => ({
          ...i,
          checked: !!(data.incidentTypes.map(k => k.id).indexOf(i.id) > -1),
        })),
      );
    }
  }, [incidentTypes.length, data.incidentTypes]);

  const getList = (search = '') => {
    if (isNaN(decryptId(uid))) {
      toast.error('Invalid link');
      setTimeout(() => {
        history.goBack();
      }, 1000);
      return;
    }

    residentApi.getIncident(
      {id: decryptId(uid)},
      (message, resp) => {
        var localDate = moment.utc(resp?.incidentDate + ' ' + resp?.incidentTime, "YYYY-MM-DD HH:mm").local().tz(timeZone).format("YYYY-MM-DD");
        var localTime = moment.utc(resp?.incidentTime, "HH:mm").local().tz(timeZone).format("HH:mm");
        resp.incidentDate = localDate
        resp.incidentTime = localTime
        console.log(resp?.incidentDate, resp?.incidentTime)
        setData(resp);
        ////------REPORT SUBMITTED BY
        if(resp.createdBy.role.type === 1){
          resp.createdBy.role['roles'] = 'Administrator'
        }else if(resp.createdBy.role.type === 2){
          resp.createdBy.role['roles'] = 'Admin'
        }else if(resp.createdBy.role.type === 3){
          resp.createdBy.role['roles'] = 'Manager'
        }else if(resp.createdBy.role.type === 4){
          resp.createdBy.role['roles'] = 'Caregiver'
        }
        else{
          resp.createdBy.role['roles'] = resp.createdBy.role.name;
        }
        ////------REPORT REVIEWED/APPROVED BY
        if(resp.approvedBy.role.type == 1){
          resp.approvedBy.role['roles'] = 'Administrator'
        }else if(resp.approvedBy.role.type == 2){
          resp.approvedBy.role['roles'] = 'Admin'
        }else if(resp.approvedBy.role.type == 3){
          resp.approvedBy.role['roles'] = 'Manager'
        }else if(resp.approvedBy.role.type == 4){
          resp.approvedBy.role['roles'] = 'Caregiver'
        }else{
          resp.approvedBy.role['roles'] = resp.approvedBy.role.name;
        }
      },
      message => {
        toast.error(message);
      },
    );
  };

  const fetchIncidentTypes = id => {
    residentApi.getIncidentTypes(
      {page: 1, limit: 99999},
      (message, resp) => {
        if (resp.data) {
          setIncidentTypes(
            resp.data.map(i => ({
              ...i,
              checked: false,
            })),
          );
        }
      },
      (message, resp) => {},
    );
  };

  const getSignatureFont = rowIndex => {
    const totalAvailableFonts = 3;
    return `signature${(rowIndex % totalAvailableFonts) + 1}`;
  };

  return (
    <div className="incident_table">
      <style>
        {`@media print {
  @page {
    width:800px;
  }
}
`}

{
   `@media print {
      div.divFooter {
        position: fixed;
        bottom: 0;
      }
    }`
}

{
  `@page {
    size: auto;
    margin: 0;
         }
  `
}
      </style>
      {console.log({data, incidentTypes})}
      <table cellSpacing={0} cellPadding={0} style={{width: '100%',margin:'auto' ,}}>
        <tbody>
          <tr>
            <td>
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{width: '100%',}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: '14px',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                      }}>
                      STATE OF CALIFORNIA - HEALTH AND HUMAN SERVICES AGENCY
                    </td>
                    <td
                      style={{
                        width: '45%',
                        fontSize: '12px',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'right',
                        textTransform: 'uppercase',
                      }}>
                      CALIFORNIA DEPARTMENT OF SOCIAL SERVICES
                      <br />
                      COMMUNITY CARE LICENSING DIVISION
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom: '5px'}}>
              <table cellSpacing={0} cellPadding={0}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '35%',
                        fontSize: '20px',
                        fontWeight: 600,
                        margin:'0',

                        padding: '0px 0',
                        borderBottom: '1px solid #000',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                      }}>
                      UNUSUAL INCIDENT/INJURY REPORT
                    </td>
                    <td
                      style={{
                        width: '55%',
                        borderLeft: '1px solid #000',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        fontSize: '11px',
                        margin:'0',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'right',
                        textTransform: 'uppercase',
                      }}>
                      <table cellSpacing={0} cellPadding={0}>
                        <tbody>
                          <tr>
                            <td
                              valign="top"
                              align="left"
                              style={{
                                fontSize: '16px',
                                fontWeight: 600,
                                color: '#000',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                              }}>
                              instructions:
                            </td>
                            <td
                              valign="top"
                              align="left"
                              style={{
                                fontSize: '12px',
                                color: '#000',
                                textAlign: 'left',
                                textTransform: 'uppercase',
                              }}>
                              <p style={{paddingTop: 0, marginTop: 0,marginBottom:'10px',}}>
                                NOTIFY LICENSING AGENCY, PLACEMENT AGENCY AND
                                RESPONSIBLE PERSONS, IF ANY, BY NEXT WORKING DAY
                              </p>
                              <p style={{paddingTop: 0, marginTop: 0,marginBottom:'0',}}>
                                SUBMIT WRITTEN REPORT WITHIN 7 DAYS OF
                                OCCURRENCE.
                              </p>
                              <p style={{marginBottom: 0}}>
                                RETAIN COPY OF REPORT IN CLIENT’S FILE.
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom: '5px'}}>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '50%',
                         fontSize: '12px',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>name of facility</p>
                      <p style={{padding: '0', margin: '0',}}>{data?.facility?.name || ''}</p>
                    </td>
                    <td
                      style={{
                         fontSize: '12px',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        borderLeft: '1px solid #000',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>facility file number</p>
                      <p style={{padding: '0', margin: '0',}}>{data?.facility?.liscenseNo || ''}</p>
                    </td>
                    <td
                      style={{
                         fontSize: '12px',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        borderLeft: '1px solid #000',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>telephone number</p>
                      <p style={{padding: '0', margin: '0',}}>
                        {data?.facility?.adminPhone
                          ? formatPhone(data.facility.adminPhone)
                          : ''}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '50%',
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>address</p>
                      <p style={{padding: '0', margin: '0',}}>
                        {data?.facility?.addrLine1
                          ? `${data?.facility?.streetAddr} ${data?.facility?.streetAddr ? ',' : ''} ${data?.facility?.addrLine1} ${data?.facility?.addrLine1 && data?.facility?.addrLine2  ? ',' : ''} ${data?.facility?.addrLine2}`
                          : 'N/A'}
                      </p>
                    </td>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        borderLeft: '1px solid #000',
                        padding: '5px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>city,state,zip</p>
                      <p style={{padding: '0', margin: '0',}}>
                        {data?.facility?.city || 'N/A'},{' '}
                        {data?.facility?.state?.state_name || 'N/A'},{' '}
                        {data?.facility?.zipCode || 'N/A'}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom: '5px'}}>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        width: '30%',
                         fontSize: '12px',
                        fontWeight: 600,
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      clients/residents involved
                    </td>
                    <td
                      style={{
                        width: '20%',
                         fontSize: '12px',
                        fontWeight: 600,
                        borderLeft: '1px solid #000',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      date occurred
                    </td>
                    <td
                      style={{
                        width: '12%',
                         fontSize: '12px',
                        fontWeight: 600,
                        borderLeft: '1px solid #000',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      age
                    </td>
                    <td
                      style={{
                        width: '8%',
                         fontSize: '12px',
                        fontWeight: 600,
                        borderLeft: '1px solid #000',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      sex
                    </td>
                    <td
                      style={{
                        width: '30%',
                         fontSize: '12px',
                        fontWeight: 600,
                        borderLeft: '1px solid #000',
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      date of admission
                    </td>
                  </tr>
                  {data.residents && data.residents.length ? (
                    data.residents.map((resident, index) => (
                      <tr key={index}>
                        <td
                          style={{
                             fontSize: '12px',
                            borderBottom: '1px solid #000',
                            padding: '0',
                            margin:'0',
                            color: '#000',
                            textAlign: 'left',
                            background: '#f1f4ff',
                          }}>
                          {resident.firstName} {resident.lastName}
                        </td>
                        <td
                          style={{
                             fontSize: '12px',
                            borderBottom: '1px solid #000',
                            borderLeft: '1px solid #000',
                            padding: '0',
                            margin:'0',
                            color: '#000',
                            textAlign: 'left',
                            background: '#f1f4ff',
                          }}>
                          {moment(data.incidentDate).format('MM-DD-YYYY')}
                        </td>
                        <td
                          style={{
                             fontSize: '12px',
                            borderBottom: '1px solid #000',
                            borderLeft: '1px solid #000',
                            padding: '0',
                            margin:'0',
                            color: '#000',
                            textAlign: 'left',
                            background: '#f1f4ff',
                          }}>
                          {resident?.birthDate
                            ? moment().diff(moment(resident.birthDate), 'years')
                            : 'N/A'}
                        </td>
                        <td
                          style={{
                             fontSize: '12px',
                            borderBottom: '1px solid #000',
                            borderLeft: '1px solid #000',
                            padding: '0',
                            margin:'0',
                            color: '#000',
                            textAlign: 'left',
                            background: '#f1f4ff',
                          }}>
                          {resident.gender
                            ? toCustomTitleCase(resident.gender)
                            : 'N/A'}
                        </td>
                        <td
                          style={{
                             fontSize: '12px',
                            borderBottom: '1px solid #000',
                            borderLeft: '1px solid #000',
                            padding: '0',
                            margin:'0',
                            color: '#000',
                            textAlign: 'left',
                            background: '#f1f4ff',
                          }}>
                          {resident?.movedInDate
                            ? moment(resident.movedInDate).format('MM-DD-YYYY')
                            : 'N/A'}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        style={{
                           fontSize: '12px',
                          borderBottom: '1px solid #000',
                          padding: '0',
                        margin:'0',
                          color: '#000',
                          textAlign: 'left',
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                           fontSize: '12px',
                          borderBottom: '1px solid #000',
                          borderLeft: '1px solid #000',
                          padding: '0',
                          margin:'0',
                          color: '#000',
                          textAlign: 'left',
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                           fontSize: '12px',
                          borderBottom: '1px solid #000',
                          borderLeft: '1px solid #000',
                          padding: '0',
                        margin:'0',
                          color: '#000',
                          textAlign: 'left',
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                           fontSize: '12px',
                          borderBottom: '1px solid #000',
                          borderLeft: '1px solid #000',
                          padding: '0',
                          margin:'0',
                          color: '#000',
                          textAlign: 'left',
                        }}>
                        &nbsp;
                      </td>
                      <td
                        style={{
                           fontSize: '12px',
                          borderBottom: '1px solid #000',
                          borderLeft: '1px solid #000',
                          padding: '0',
                          margin:'0',
                          color: '#000',
                          textAlign: 'left',
                        }}>
                        &nbsp;
                      </td>
                    </tr>
                  )}
                  {/* <tr>
                           <td style={{ width: '30%', fontSize: '17px', fontWeight: 600, borderTop: '1px solid #000', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>
                              clients/residents involved
                    </td>
                           <td style={{ width: '20%', fontSize: '17px', fontWeight: 600, borderLeft: '1px solid #000', borderTop: '1px solid #000', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>
                              date occurred
                    </td>
                           <td style={{ width: '12%', fontSize: '17px', fontWeight: 600, borderLeft: '1px solid #000', borderTop: '1px solid #000', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>
                              age
                    </td>
                           <td style={{ width: '8%', fontSize: '17px', fontWeight: 600, borderLeft: '1px solid #000', borderTop: '1px solid #000', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>
                              sex
                    </td>
                           <td style={{ width: '30%', fontSize: '17px', fontWeight: 600, borderLeft: '1px solid #000', borderTop: '1px solid #000', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'center', textTransform: 'uppercase' }}>
                              date of admission
                    </td>
                        </tr>
                           <tr>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                           </tr>
                           <tr>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                           </tr>
                           <tr>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                           </tr>
                           <tr>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                              <td style={{ fontSize: '12px', borderBottom: '1px solid #000', borderLeft: '1px solid #000', padding: '5px 0', color: '#000', textAlign: 'left' }}>
                                 &nbsp;
                    </td>
                           </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingBottom: '0px'}}>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      colSpan={5}
                      style={{
                        fontSize: '17px',
                        fontWeight: 600,
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                      }}>
                      type of incident
                    </td>
                    {/* <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                                <input type="checkbox" checked={iType.checked} readonly />
                                                <label>{ iType.label }</label>
                                             </p> */}
                  </tr>
                  <tr>
                    {incidentTypes && incidentTypes.length ? (
                      <>
                        <td>
                          {incidentTypes.map((iType, index) => (
                            <>
                              {index <  6 && (
                                <p
                                  key={index}
                                  style={{padding: '2px 0 0 0', margin: 0,display:'flex',}}>
                                  <input style={{
                       margin:'0 5px 0 0' , 
                      }}
                                    type="checkbox"
                                    checked={iType.checked}
                                    readonly
                                  />
                                  <label style={{
                        fontSize: '10px',
                       margin:'0',
                      }}>{iType.label}</label>
                                </p>
                              )}
                            </>
                          ))}
                        </td>
                        <td>
                          {incidentTypes.map((iType, index) => (
                            <>
                              {index >= 6 && index < 11 && (
                                <p
                                  key={index}
                                  style={{padding: '2px 0 0 0', margin: 0 ,display:'flex',}}>
                                  <input style={{
                       margin:'0 5px 0 0',
                      }}
                                    type="checkbox"
                                    checked={iType.checked}
                                    readonly
                                  />
                                  <label  style={{
                        fontSize: '10px',
                       margin:'0',
                      }}>{iType.label}</label>
                                </p>
                              )}
                            </>
                          ))}
                        </td>
                        <td>
                          {incidentTypes.map((iType, index) => (
                            <>
                              {index >= 11 && index < 16 && (
                                <p
                                  key={index}
                                  style={{padding: '2px 0 0 0', margin: 0,display:'flex',}}>
                                  <input style={{
                       margin:'0 5px 0 0',
                      }}
                                    type="checkbox"
                                    checked={iType.checked}
                                    readonly
                                  />
                                  <label style={{
                        fontSize: '10px',
                       margin:'0',
                      }}>{iType.label}</label>
                                </p>
                              )}
                            </>
                          ))}
                        </td>

                        <td>
                          {incidentTypes.map((iType, index) => (
                            <>
                              {index >= 16 && index < 22 && (
                                <p
                                  key={index}
                                  style={{padding: '2px 0 0 0', margin: 0,display:'flex',}}>
                                  <input style={{
                       margin:'0 5px 0 0',
                      }}
                                    type="checkbox"
                                    checked={iType.checked}
                                    readonly
                                  />
                                  <label style={{
                        fontSize: '10px',
                       margin:'0',
                      }}>{iType.label}</label>
                                </p>
                              )}
                            </>
                          ))}
                        </td>

                        <td>
                          {incidentTypes.map((iType, index) => (
                            <>
                              {index >= 22 && index < 30 && (
                                <p
                                  key={index}
                                  style={{padding: '2px 0 0 0', margin: 0,display:'flex',}}>
                                  <input style={{
                       margin:'0 5px 0 0',
                      }}
                                    type="checkbox"
                                    checked={iType.checked}
                                    readonly
                                  />
                                  <label style={{
                        fontSize: '10px',
                       margin:'0',
                      }}>{iType.label}</label>
                                </p>
                              )}
                            </>
                          ))}
                        </td>
                      </>
                    ) : null}
                    {/* <td>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Unauthorized absence</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Aggressive Act/Self</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Aggressive Act/Another Client</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Aggressive Act/Staff</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Aggressive Act/Family, Visitors</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Alleged Violation of Rights</label>
                                 </p>
                              </td>
                              <td>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <label>Alleged Client Abuse</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Sexual</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Physical</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Psychological</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Financial</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Neglect</label>
                                 </p>
                              </td>
                              <td>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Rape</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Pregnancy</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Suicide Attempt</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Other</label>
                                 </p>
                              </td>
                              <td>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Injury-Accident</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Injury-Unknown Origin</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Injury-From another Client</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Injury-From behavior episode</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Epidemic Outbreak</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Hospitalization</label>
                                 </p>
                              </td>
                              <td>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Medical Emergency</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Other Sexual Incident</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Theft</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Fire</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Property Damage</label>
                                 </p>
                                 <p style={{ padding: '5px 0 0 0', margin: 0 }}>
                                    <input type="checkbox" />
                                    <label>Other (explain)</label>
                                 </p>
                              </td> */}
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                         fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                     DESCRIBE EVENT OR INCIDENT (INCLUDE DATE, TIME, LOCATION,
                      PERPETRATOR, NATURE OF INCIDENT, ANY ANTECEDENTS LEADING
                      UP TO INCIDENT AND HOW CLIENTS WERE AFFECTED, INCLUDING
                      ANY INJURIES:
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.description || ''}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        paddingBottom: '0px',
                        paddingTop: '0',
                         fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      PERSON(S) WHO OBSERVED THE INCIDENT/INJURY:
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.observed || ''}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                         fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      EXPLAIN WHAT IMMEDIATE ACTION WAS TAKEN (INCLUDE PERSONS
                      CONTACTED):
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.immediateAction || ''}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingTop: '5px'}}>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr className="print-break">
                    <td
                      style={{
                        fontSize: '16px',
                        fontWeight: 600,
                        borderTop: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0',
                        margin:'0',
                        color: '#000',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                      }}>
                      over
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '13px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                      }}>
                      <span>medical treatment necessary?</span>
                      {data?.treatmentRequired ? (
                        <>
                          <span className="ml-4">
                            <input
                              type="checkbox"
                              checked={true}
                              readonly
                              className="mr-1"
                            />{' '}
                            Yes
                          </span>
                          <span className="ml-4">
                            <input
                              type="checkbox"
                              checked={false}
                              readonly
                              className="mr-1"
                            />{' '}
                            no
                          </span>
                        </>
                      ) : (
                        <>
                          <span className="ml-4">
                            <input
                              type="checkbox"
                              checked={false}
                              readonly
                              className="mr-1"
                            />{' '}
                            Yes
                          </span>
                          <span className="ml-4">
                            <input
                              type="checkbox"
                              checked={true}
                              readonly
                              className="mr-1"
                            />{' '}
                            no
                          </span>
                        </>
                      )}

                      <span className="ml-5">
                        {' '}
                        if yes, give nature of treatment:
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.treatmentNature || ''}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        margin:'0',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}> where administered:</p>
                      <p style={{padding: '0', margin: '0',}}>{data?.treatmentAdminLocation || ''}</p>
                    </td>
                    <td
                      style={{
                         fontSize: '12px',
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                        margin:'0',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>Administered By:</p>
                      <p style={{padding: '0', margin: '0',}}>{data?.treatmentAdminBy || ''}</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                        margin:'0',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>FOLLOW-UP TREATMENT, IF ANY: </p>
                      <p style={{padding: '0', margin: '0',}}>{data?.treatmentFollowUp || ''}</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      {/* <p style={{padding: '0', margin: '0',}}>&nbsp;</p> */}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>&nbsp;</p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>&nbsp;</p>
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0', margin: '0',}}>&nbsp;</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                        margin:'0',
                        fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      ACTION TAKEN OR PLANNED (BY WHOM AND ANTICIPATED RESULTS:
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.actionTaken || ''}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                        fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px',
                        margin: '0px',
                         fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      LICENSEE/SUPERVISOR COMMENTS:
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      {data?.comments || ''}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                         fontSize: '12px',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      &nbsp;
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <table cellSpacing={0} cellPadding={0} style={{width: '100%'}}>
                <tbody>
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        borderTop: '4px solid #000',
                        borderBottom: '1px solid #000',
                        paddingBottom: '0px',
                        paddingTop: '0px',
                         fontSize: '12px',
                        color: '#000',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        background: '#f1f4ff',
                      }}>
                      <p style={{padding: '0px 0', margin: 0}}>
                        NAME OF ATTENDING PHYSICIAN{' '}
                      </p>
                      <p style={{padding: '0px 0', margin: 0}}>
                        {data?.attendedBy || ''}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '30%',
                         fontSize: '13px',
                        textTransform: 'uppercase',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        margin:'0',
                      }}>
                      report submitted by:
                    </td>
                    <td
                      style={{
                        width: '50%',
                         fontSize: '11px',
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                        margin:'0',

                      }}>
                      <p
                        style={{
                          padding: '0px 0',
                          margin: 0,
                          textTransform: 'uppercase',
                        }}>
                        name and title
                      </p>
                      <p style={{padding: '0px 0', margin: 0}}>
                        {data?.createdBy?.firstName || ''}{' '}
                        {data?.createdBy?.lastName || ''}, {data?.createdBy?.role?.roles || ''}{' '} {/* {data?.createdBy?.roles || ''}*/}
                        {data?.createdBy?.firstName ? ' , ' : ''}{' '}
                        <strong className={getSignatureFont(1)}>
                          {data?.createdBy?.firstName || ''}{' '}
                          {data?.createdBy?.lastName || ''}
                        </strong>
                      </p>
                    </td>
                    <td
                      style={{
                         fontSize: '11px',
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        margin:'0',

                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      <p
                        style={{
                          padding: '0px 0',
                          margin: 0,
                          textTransform: 'uppercase',
                        }}>
                        date
                      </p>
                      <p style={{padding: '0px 0', margin: 0}}>
                        {data.createdDate
                          ? moment(data.createdDate).format('MM-DD-YYYY')
                          : 'N/A'}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '30%',
                         fontSize: '13px',
                        textTransform: 'uppercase',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        margin:'0',

                        color: '#000',
                        textAlign: 'left',
                      }}>
                      report reviewed/approved by:
                    </td>
                    <td
                      style={{
                        width: '50%',
                         fontSize: '11px',
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        margin:'0',

                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      <p
                        style={{
                          padding: '0px 0',
                          margin: 0,
                          textTransform: 'uppercase',
                        }}>
                        name and title
                      </p>
                      <p style={{padding: '0px 0', margin: 0}}>
                        {data?.approvedBy?.firstName || 'N/A'}{' '}
                        {data?.approvedBy?.lastName || ''}, {data?.approvedBy?.role?.roles || 'N/A'}{' '}
                        {data?.approvedBy?.firstName ? ' , ' : ''}{' '}
                        <strong className={getSignatureFont(2)}>
                          {data?.approvedBy?.firstName || ''}{' '}
                          {data?.approvedBy?.lastName || ''}
                        </strong>
                      </p>
                      {console.log('data123', data)}
                    </td>
                    <td
                      style={{
                         fontSize: '11px',
                        borderLeft: '1px solid #000',
                        borderBottom: '1px solid #000',
                        padding: '0px 0',
                        margin:'0',

                        color: '#000',
                        textAlign: 'left',
                        background: '#f1f4ff',
                      }}>
                      <p
                        style={{
                          padding: '0px 0',
                          margin: 0,
                          textTransform: 'uppercase',
                        }}>
                        date
                      </p>
                      <p style={{padding: '0px 0', margin: 0}}>
                        {data?.approvedDate
                          ? moment(data.approvedDate).format('MM-DD-YYYY')
                          : 'N/A'}
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{paddingTop: '0px', paddingBottom: '0px',margin:'0',}}>
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{
                  width: '100%',
                  paddingBottom: '0px',
                  margin:'0',

                  borderBottom: '1px solid #000',
                }}>
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        borderTop: '4px solid #000',
                        padding: '0px',
                        margin:'0',
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000',
                        margin: '0',
                        textAlign: 'left',
                        textTransform: 'uppercase',
                      }}>
                      Agencies/individuals notifies{' '}
                      <font
                        style={{
                          fontStyle: 'italic',
                          fontSize: '11px',
                          fontWeight: 400,
                          lineHeight:'14px'
                        }}>
                        (specify name and telephone number)
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '11px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '0px',
                        margin:'0',
                      }}>
                          <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.licensingName}/>
                      <span style={{width: '80px'}}>Licensing </span>
                      <font
                        style={{
                          width: 'calc(100% - 80px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '11px',
                          padding: '0px',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'
                        
                        }}>
                        &nbsp;{data?.licensingName}{' '}
                        {data?.licensingName ? ',' : ''} {data?.licensingPhone}
                      </font>
                    </td>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '12px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '5px 0',
                        margin:'0',
                        lineHeight: '11px',
                      }}>
                          <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.protectiveName}/>
                      <span style={{width: '80px'}}>
                        ADULT/CHILD PROTECTIVE SERVICES
                      </span>{' '}
                      <font
                        style={{
                          width: 'calc(100% - 228px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '10px',
                          padding: '0px',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'
                        }}>
                        &nbsp;{data?.protectiveName}{' '}
                        {data?.protectiveName ? ',' : ''}{' '}
                        {data?.protectivePhone}
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '11px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '5px 0',
                        margin:'0',
                      }}>
                          <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.ombudsmanName}/>
                      <span style={{width: '80px'}}>
                        LONG TERM CARE OMBUDSMAN{' '}
                      </span>
                      <font
                        style={{
                          width: 'calc(100% - 190px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '11px',
                          padding: '0px',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'
                        

                        }}>
                        &nbsp;{data?.ombudsmanName}{' '}
                        {data?.ombudsmanName ? ',' : ''} {data?.ombudsmanPhone}
                      </font>
                    </td>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '11px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '5px 0',
                        margin:'0',
                      }}>
                          <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.PGCName}/>
                      <span style={{width: '80px'}}>
                        {' '}
                        PARENT/GUARDIAN/CONSERVATOR{' '}
                      </span>
                      <font
                        style={{
                          width: 'calc(100% - 211px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '11px',
                          padding: '5px 0',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'
                        }}>
                        &nbsp;{data?.PGCName} {data?.PGCName ? ',' : ''}{' '}
                        {data?.PGCPhone}
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '11px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '05px 0',
                        margin:'0',
                      }}>
                         <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.lawEnforcementName}/> 
                      <span style={{width: '80px'}}>LAW ENFORCEMENT </span>
                      <font
                        style={{
                          width: 'calc(100% - 128px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '11px',
                          padding: '5px 0',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'

                        }}>
                        &nbsp;{data?.lawEnforcementName}{' '}
                        {data?.lawEnforcementName ? ',' : ''}{' '}
                        {data?.lawEnforcementPhone}
                      </font>
                    </td>
                    <td
                      style={{
                        width: '400px',
                        fontSize: '11px',
                        color: '#000',
                        textTransform: 'uppercase',
                        textAlign: 'left',
                        padding: '5px 0',
                        margin:'0',
                      }}>
                        <input type="checkbox" readonly  style={{marginRight: '5px'}} checked={data?.placementAgencyName}/> 

                      <span style={{width: '80px'}}> PLACEMENT AGENCY </span>
                      <font
                        style={{
                          width: 'calc(100% - 135px)',
                          display: 'inline-block',
                          borderBottom: '1px solid #000',
                          background: '#f1f4ff',
                          lineHeight: '11px',
                          padding: '5px 0',
                        margin:'0',
                        lineHeight:'14px',
                        whiteSpace: 'pre-wrap', 
                        overflowWrap: 'break-word'

                        }}>
                        &nbsp;{data?.placementAgencyName}{' '}
                        {data?.placementAgencyName ? ',' : ''}{' '}
                        {data?.placementAgencyPhone}
                      </font>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="divFooter show-footer ml-4">LIC 624 (4/99)</div>
    </div>
  );
};

class Temp extends React.Component {
  render() {
    return <Template />;
  }
}

class IncidentReport extends React.Component {
  render() {
    return (
      <>
        <div class="float-right my-3 mr-3">
          {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
            <button
              className="btn btn-dark print-btn"
              onClick={() => window.print()}>
              <i className="fa fa-print" data-toggle="tooltip" title="Print" />
            </button>
          ) : (
            <ReactToPrint
              documentTitle="incident-report"
              trigger={() => (
                <button className="btn btn-dark">
                  <i
                    className="fa fa-print"
                    data-toggle="tooltip"
                    title="Print"
                  />
                </button>
              )}
              content={() => this.componentRef}
            />
          )}
        </div>
        <br />
        <br />
        <br />
        <Temp ref={el => (this.componentRef = el)} />
      </>
    );
  }
}

export default IncidentReport;
