import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { APIS } from '../../../config/config'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'

const DocumentTab = (props) => {
   const [modal, setModal] = useState(false)
   const [data, setData] = useState({})
   const [alert, setAlert] = useState(null)

   const history = useHistory()

   useEffect(() => {
      setSearch()
   }, [])

   const downloadFile = (item) => {
      let fileDoc = item.file
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${fileDoc.id}`,//your url
         method: 'GET',
         responseType: 'blob',
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
            url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = fileDoc.originalName;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#resDocViewInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$("#resDocViewTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
            document.querySelectorAll('.no-res').forEach(e => e.remove());
            if (window.$('#resDocViewTable tr:visible').length == 0) {
               let table = document.getElementById("resDocViewTable")
               let row = table.insertRow(0)
               row.className = "no-res"
               let cell = row.insertCell(0);
               cell.colSpan = "3"
               cell.className = "no-td"
               cell.innerHTML = "No results found"
            }
         });
      });
   }

   return (
      <>
         {alert}
         <div className="mt-3 p-0 pb-3 details-wrap">
            <input className="form-control mb-3" id="resDocViewInput" type="text" placeholder="Search.." />

            <div className="table-responsive">
               <table className="table table-striped table-bordered w-100">
                  <thead>
                     <tr>
                        <th>File Name</th>
                        <th style={{minWidth: '100px'}}>Type</th>
                        <th>Entered Date</th>
                        <th>Due Date</th>
                        <th>Reminder</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  <tbody id="resDocViewTable">
                     {props.data && props.data.length ? props.data.map((item, i) =>
                        <tr key={i} onClick={() => { setModal(true); setData(item) }}>
                           <td>
                              <p className="text-truncate text-primary cursor-pointer" style={{ maxWidth: '150px' }} onClick={() => downloadFile(item)}>
                                 {item.file && item.file.originalName}
                              </p>
                           </td>
                           <td>{item.type || ''}</td>
                           <td>{item.enteredDate && moment(item.enteredDate).format('MM-DD-YYYY')}
                           </td>
                           <td>{item.dueDate ? moment(item.dueDate).format('MM-DD-YYYY') : 'Lifetime'}
                           </td>
                           <td>{item.reminder ? 'yes' : 'no'}</td>
                           <td><i className="fa fa-eye cursor-pointer" onClick={() => { setModal(true); setData(item) }} /></td>
                        </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                  </tbody>
               </table>
            </div>
            <ViewDoc
               isOpen={modal}
               close={() => setModal(false)}
               data={data}
            />
            <div className="d-flex justify-content-between mt-3">
               <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
            </div>
         </div>
      </>
   )
}

export default DocumentTab

const ViewDoc = (props) => {
   const [alert, setAlert] = useState(null)

   const downloadFile = () => {
      let fileDoc = props.data.file
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${fileDoc.id}`,//your url
         method: 'GET',
         responseType: 'blob',
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
            url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = fileDoc.originalName;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   return (
      <>
         {alert}
         <Modal centered isOpen={props.isOpen} size='md'>
            <ModalHeader toggle={props.close}>Document</ModalHeader>
            <ModalBody>
               <div className="row">
                  <div className="col-lg-12" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <button className="btn btn-info mr-2" type="button" onClick={downloadFile}>{props.data && props.data.file && props.data.file.originalName}</button><br />
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="form-group">
                        <label className="form-label">Description</label>
                        <p className="detail-text">{props.data.description || ''}</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Entered Date</label>
                        <p className="detail-text">{props.data.enteredDate && moment(props.data.enteredDate).format('MM-DD-YYYY')}</p>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Due Date</label>
                        <p className="detail-text">{props.data.dueDate && moment(props.data.dueDate).format('MM-DD-YYYY')}</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-lg-12" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Reminder</label>
                        <p className="detail-text">{props.data.reminder ? 'Yes' : 'No'}</p>
                     </div>
                  </div>
               </div>
            </ModalBody>
         </Modal>
      </>
   )
}