import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyApprovalMedication = React.lazy(() =>
   import('./approvalMedication')
);

const ApprovalMedication = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyApprovalMedication {...props} />
   </React.Suspense>
);

export default ApprovalMedication