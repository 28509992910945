import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import loaderReducer from './loaderReducer'
import contactReducer from './contactReducer';
import residentReducer from './residentReducer';
import extrasReducer from './extras.reducer';
import wizardReducer from './wizardReducer';

const rootReducer = combineReducers({
    user: userReducer,
    loader: loaderReducer,
    resident: residentReducer,
    contactReducer: contactReducer,
    extras: extrasReducer,
    wizard: wizardReducer
});

export default rootReducer;