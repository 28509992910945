import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addEditRole(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.ROLES.ADD_EDIT_ROLE, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* roleList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ROLES.ROLE_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* permissionList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ROLES.PERMISSIONS_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* rolePermissionList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ROLES.ROLE_PERMISSION_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* assignRolePermission(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.ROLES.ASSIGN_PERMISSIONS_TO_ROLE, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getDefaultRole(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ROLES.GET_DEFAULT_ROLE, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* unassgnUserRole(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.ROLES.UNASSIGN_USER_ROLE, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* getRoleDetails(action) {
   try {
      const data = action.payload;
      console.log("payloadpayloadpayloadpayloadpayloadpayloadpayload",data);
      const resp = yield call(CallApi.GET, APIS.ROLES.GET_ROLE_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteRole(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.ROLES.DELETE_ROLE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* permissionsListByRoleType(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ROLES.PERMISSIONS_LIST_BY_ROLE_TYPE, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}