import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import Pagination from 'react-js-pagination'
import { PER_PAGE } from '../../../config/config'
import { useResidentApi } from '../../../hooks/api/residentApiHook'

const ResIncidentTab = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [data, setData] = useState([])
   const [activePage, setPage] = useState(1)
   const [search, setSearch] = useState('')

   const residentApi = useResidentApi()
   const history = useHistory()

   useEffect(() => {
      getList(1)
   }, [])

   const getList = (page = 1, search = '') => {
      let params = { page, limit: PER_PAGE, search, resident: props.resId }

      residentApi.listIncident(params,
         (message, resp) => {
            setData(resp)
         }, (message) => { })
   }

   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (id) => {
      let obj = data.data.find(item => item.id === id)
      if (obj) {
         setDetails(obj)
         toggle()
      }
   }

   const handlePage = (page) => {
      if (activePage !== page) {
         getList(page, search)
         setPage(page)
      }
   }

   const cancelSearch = () => {
      getList(1)
      setSearch('')
      setPage(1)
   }

   const handleSearch = () => {
      if (search === '') {
         toast.warn('No search value given')
      }
      else {
         getList(1, search)
         setPage(1)
      }
   }

   return (
      <>
         <div className="mt-3 p-0 pb-3">
            <div className="d-flex mb-3 justify-content-end">
               <input className="form-control mr-2" value={search} onChange={e => setSearch(e.target.value)} placeholder='Search by type' />
               <button className="btn btn-outline-success mr-2" onClick={handleSearch}><i className="bx bx-search-alt" /></button>
               <button className="btn btn-outline-danger mr-1" onClick={cancelSearch}><i className="bx bx-x-circle" /></button>
            </div>

            <div className="table-responsive">
               <table className="table table-bordered">
                  <thead>
                     <tr>
                        <th style={{ minWidth: '100px' }}>Incident</th>
                        <th style={{ minWidth: '100px' }}>Date</th>
                        <th style={{ minWidth: '100px' }}>Time</th>
                        <th style={{ minWidth: '120px' }}>Type</th>
                        <th>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data && data.data && data.data.length ? data.data.map((item, i) =>
                        <tr key={i} onClick={() => editData(item.id)}>
                           <td>{item?.title || ""}</td>
                           <td>{moment(item.incidentDate).format('MM-DD-YYYY')}</td>
                           <td>{moment(item.incidentTime, 'HH:mm').format('hh:mm a')}</td>
                           <td>{item?.incidentTypes?.map((t, ind) => (
                              ind < 2 && <span className="badge badge-info mr-2" style={{ fontSize: '12px' }}>{`${t.label.substr(0, 15)}..`}</span>
                           )) || ''} { item.incidentTypes.length > 2 ? `+${item.incidentTypes.length - 2} more` : ''}</td>
                           <td>
                              <div>
                                 <i className="fa fa-eye mr-3 cursor-pointer" onClick={() => editData(item.id)} data-toggle="tooltip" title="Edit Incident" />
                              </div>
                           </td>
                        </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                  </tbody>
               </table>
            </div>

            <div className="row">
               <div className="col-lg-6">
                  {data.total > 0 && <p>Total results: {data.total}</p>}
               </div>
               <div className="col-lg-6 d-flex justify-content-end">
                  {data.totalPage > 1 ?
                     <Pagination
                        activePage={activePage}
                        itemsCountPerPage={PER_PAGE}
                        totalItemsCount={data.total ? data.total : 1}
                        pageRangeDisplayed={5}
                        onChange={handlePage}
                        innerClass='pagination'
                        itemClass='paginate_button page-item'
                        linkClass='page-link'
                        activeClass='active'
                        hideFirstLastPages={true}
                        prevPageText='Previous'
                        nextPageText='Next'
                     /> : null}
               </div>
            </div>
            <ViewIncident
               isOpen={modal}
               close={toggle}
               data={details}
            />
            <div className="d-flex justify-content-between mt-3">
               <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               {/*<button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>*/}
            </div>
         </div>
      </>
   )
}

export default ResIncidentTab

const ViewIncident = (props) => {
   return (
      <Modal centered isOpen={props.isOpen} size='md'>
         <ModalHeader toggle={props.close}>Incident Details</ModalHeader>
         <ModalBody>
            <div className="row">
               <div className="col-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Title</label>
                     <p className="detail-text">{props.data.title || ''}</p>
                  </div>
               </div>
            </div>
            <div className="row mt-2">
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Date</label>
                     <p className="detail-text">{props.data.incidentDate && moment(props.data.incidentDate).format('MM-DD-YYYY')}</p>
                  </div>
               </div>
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Time</label>
                     <p className="detail-text">{props.data.incidentTime && moment(props.data.incidentTime, 'HH:mm').format('hh:mm a')}</p>
                  </div>
               </div>
            </div>
            <div className="row mt-2">
               <div className="col-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Type</label>
                     <p className="detail-text">{props?.data?.incidentTypes?.map((t, ind) => (
                              ind < 2 && <span className="badge badge-info mr-2" style={{ fontSize: '12px' }}>{`${t.label.substr(0, 15)}..`}</span>
                           )) || ''} { props?.data?.incidentTypes?.length > 2 ? `+${props.data.incidentTypes.length - 2} more` : ''}</p>
                  </div>
               </div>
            </div>
            <div className="row mt-2">
               <div className="col-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Description</label>
                     <p className="detail-text">{props.data.description || ''}</p>
                  </div>
               </div>
            </div>
         </ModalBody>
      </Modal>
   )
}