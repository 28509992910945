import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { formatPhone } from '../../components/form-elements/functions'
import ContactForm from '../../components/contactForm'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import { useHistory } from 'react-router-dom'
import { MODULES } from '../../config/config'

const ResContactList = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [alert, setAlert] = useState(null)
   const [apiList, setApiList] = useState([])
   const [isWizard, setWizard] = useState(props.isWizard)

   const residentApi = useResidentApi()
   const history = useHistory()

   useEffect(() => {
      setSearch()
      if (props.resId) {
         getList()
      }
   }, [props.resId])

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#emContactInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$("#emContactTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
         });
      });
   }

   const getList = () => {
      residentApi.listResidentContact({ resident: props.resId, search: '' },
         (message, resp) => {
            setApiList((resp || []).map(cont => ({
               ...cont,
               firstName: cont.reusableContact ? cont.reusableContact.firstName : cont.firstName,
               lastName: cont.reusableContact ? cont.reusableContact.lastName : cont.lastName,
               phone: cont.reusableContact ? cont.reusableContact.phone : cont.phone,
               email: cont.reusableContact ? cont.reusableContact.email : cont.email,
               type: cont.reusableContact ? cont.reusableContact.type : cont.type,
            })))

         },
         (message) => {
            toast.error(message)
         })
   }

   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (id) => {
      toggle()
      let data = apiList.find(item => item.id === id)
      setDetails(data)
   }

   const showConfirm = (id) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger cancel-padding"
            title="Delete Contact?"
            onConfirm={() => deleteContactFunc(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure?
         </SweetAlert>
      )
   }

   const deleteContactFunc = (id) => {
      setAlert(null)
      residentApi.deleteResidentContact({ id },
         (message, resp) => {
            toast.success('Contact deleted successfully')
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const sumbitResContUpdate = (data) => {
      data['id'] = details.id
      residentApi.updateResidentContact(data,
         (message, resp) => {
            toast.success('Contact updated successfully')
            toggle()
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const submitResContCreate = (data) => {
      data['link'] = '?resident=' + props.resId
      console.log("before submit data", data);
      residentApi.addResidentContact(data,
         (message, resp) => {
            toast.success('Contact created successfully')
            toggle()
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const skiptonextstep = () => {
      history.push('/dashboard');
   }

   return (
      <>
         {alert}
         <div className="mt-3 p-3">
            <button type="button" className="btn btn-outline-dark accordian-add-btn" onClick={() => showModal(true)}> <i className="fas fa-plus" />&nbsp;Add New</button>

            <input className="form-control mb-3" id="emContactInput" type="text" placeholder="Search.." />

            <div className="table-responsive">
               <table className="table table-striped table-bordered w-100">
                  <thead>
                     <tr>
                        <th style={{ minWidth: '140px' }}>Name</th>
                        <th style={{ minWidth: '140px' }}>Phone</th>
                        <th style={{ minWidth: '140px' }}>Email</th>
                        <th style={{ minWidth: '120px' }}>Relation</th>
                        <th>Primary</th>
                        <th>Action</th>
                     </tr>
                  </thead>
                  {/* formatPhone(item.phone) */}
                  <tbody id="emContactTable">
                     {apiList.length ? apiList.map((item, i) =>
                        <tr key={i}>
                           <td>{item.firstName} {item.lastName}</td>
                           <td>{item.phone ? (/[,\-]/.test(item.phone) ? item.phone : formatPhone(item.phone)) : ''}</td> 
                           <td>{item?.email}</td>
                           <td>{item.relation}</td>
                           <td>{item.isPrimary ? 'Yes' : 'No'}</td>
                           <td>
                              <i onClick={() => editData(item.id)} className="fa fa-edit mr-3 cursor-pointer" />
                              <i onClick={() => showConfirm(item.id)} className="fa fa-trash cursor-pointer" />
                           </td>
                        </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                  </tbody>
               </table>
            </div>
            <div className="d-flex justify-content-between mt-3">
               {isWizard != true && (
                  <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               )}
               {isWizard === true && (
                  <button className="btn btn-primary w-lg btn-lg mt-0" onClick={() => {
                     skiptonextstep()
                     props.setforceExit(true)
                  }}>Exit Wizard </button>
               )}
               <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
            </div>
            <Modal centered isOpen={modal} size='md'>
               <ModalHeader toggle={toggle}>Contact</ModalHeader>
               <ModalBody>
                  <ContactForm
                     isView={false}
                     data={details}
                     module={MODULES.RESIDENT}
                     submitCreate={submitResContCreate}
                     submitUpdate={sumbitResContUpdate}
                  />
               </ModalBody>
            </Modal>
         </div>
      </>
   )
}

export default ResContactList