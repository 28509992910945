import React from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { useUserApi } from '../../hooks/api/userApiHook';
import { TextInput } from '../form-elements/textInput';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
   password: yup.string().required('Password is required').min(8, 'Password must be at least 8 characters'),
   confirm_password: yup.string().required('Confirm password is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match')
})

const ChangePasswordForm = (props) => {
   const userApi = useUserApi()
   const history = useHistory()
   const token = new URLSearchParams(useLocation().search).get("token")

   const { handleSubmit, errors, control, reset } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const onSubmit = data => {

      let params = {
         newPassword: data.password
      }

      userApi.changePassword(params,
         (message, resp) => {
            toast.success('Password changed successfully')
            reset()
         }, (message) => {
            toast.error(message)
         })
   }

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <div className="row f_1">
            <div className="col-lg-8">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-lg-12" data-select2-id={1}>
                           <div className="form-group">
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="password"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.password}
                                       label='New Password'
                                       placeholder='New password'
                                       type='password'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                        <div className="col-lg-12" data-select2-id={1}>
                           <div className="form-group">
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="confirm_password"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.confirm_password}
                                       label='Confirm Password'
                                       placeholder='Confirm password'
                                       type='password'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>
                     <button type="submit" className="btn btn-success waves-effect waves-light">Save</button>
                  </div>
               </div>
            </div>
         </div>

      </form>
   )
}

export default ChangePasswordForm