import React from 'react'
import PropTypes from 'prop-types'
import { useUserPermissionsSelector } from '../hooks/selectors/userSelector'

const RoleCheckHOC = (props) => {
   // console.log(props)
   const userPermissions = useUserPermissionsSelector()
   // console.log(userPermissions)
   const checkRole = () => {
      let hasPermissions = userPermissions.some(item => item.module === props.module && props.operation.includes(item.operation))
      if(!props.operation && !props.module){
         return true
      }
      else if (hasPermissions) {
         return true
      }
      else {
         return false
      }
   }

   return (
      <React.Fragment>
         {checkRole() ? props.children : null}
      </React.Fragment>
   )
}

export default RoleCheckHOC

RoleCheckHOC.propTypes = {
   module: PropTypes.string.isRequired,
   operation: PropTypes.array.isRequired,
   children: PropTypes.element.isRequired
};
