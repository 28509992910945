import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useAncmentApi() {
   const callApi = useApiCall();

   const listAncment = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.LIST_ANCMENTS, data, onSuccess, onError);
   }

   const addAncment = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.CREATE_ANCMENT, data, onSuccess, onError);
   }

   const updateAncment = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.UPDATE_ANCMENT, data, onSuccess, onError);
   }

   const deleteAncment = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.DELETE_ANCMENT, data, onSuccess, onError);
   }

   const getAncment = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.GET_ANCMENT, data, onSuccess, onError);
   }

   const getApprovalsRequest = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.GET_APPROVAL, data, onSuccess, onError);
   }
   const getIncidentApprovalsRequest = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.ANCMENT.GET_INCIDENT_APPROVAL, data, onSuccess, onError);
   }

   

   return {
      listAncment,
      addAncment,
      updateAncment,
      deleteAncment,
      getAncment,
      getApprovalsRequest,
      getIncidentApprovalsRequest
   }
}