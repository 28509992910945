import React, { useEffect } from 'react'
import * as yup from 'yup'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from './form-elements/textInput';
import { CheckboxInput } from './form-elements/checkboxInput';
import { DateInput } from './form-elements/datepicker';
import moment from 'moment';
import ReadCheckHOC from '../hoc/readCheckHoc';
import { SelectInput } from './form-elements/dropdown';
import { toast } from 'react-toastify';
import { changeDate } from './form-elements/functions';

const DURATION_TYPES = [
   { label: 'Day', value: 'day' },
   { label: 'Month', value: 'month' },
   { label: 'Year', value: 'year' }
]

const schema = yup.object().shape({
   type: yup.string().required('Type is required').max(90, 'Max 90 characters'),
   description: yup.string().required('Description is required').max(250, 'Max 250 characters'),
   reminder: yup.bool().oneOf[true, false],
   duration: yup.string().when('reminder', {
      is: (reminder) => reminder == true,
      then: yup.string().nullable().required('Duration is required').matches(/^[0-9]+$/i, 'Invalid duration').test('Zero', 'Minimum 1 is required', val => val != 0)
   }),
   duration_type: yup.object().when('reminder', {
      is: (reminder) => reminder == true,
      then: yup.object().shape({
         label: yup.string(),
         value: yup.string()
      }).nullable().required('Duration Type is required')
   }),
   enteredDate: yup.string()
      .nullable()
      .test('Length', 'Invalid entered date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid();
      }),
   dueDate: yup.string()
      .nullable()
      .test('Length', 'Invalid due date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length ? value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() : true;
      }),
})

const LiscenseForm = (props) => {

   const { handleSubmit, errors, control, reset, watch, setValue } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const enteredDates = watch('enteredDate')
   const dueDate = watch('dueDate')
   const isChecked = watch('reminder')

   useEffect(() => {
      if (props.data.id) {
         let obj = DURATION_TYPES.find(item => item.value == props.data.duration_type)
         let temp = { ...props.data }
         temp.enteredDate = changeDate(props.data.enteredDate)
         temp.dueDate = props.data.dueDate ? changeDate(props.data.dueDate) : ''
         temp.duration_type = temp.reminder && obj ? obj : ''
         temp.duration = temp.reminder && temp.duration ? temp.duration : ''
         reset(temp)
      }
   }, [props.data])

   const onSubmit = (data) => {
      data['enteredDate'] = moment(data.enteredDate, 'MM-DD-YYYY').format('YYYY-MM-DD')

      if (data.dueDate) {
         data['dueDate'] = moment(data.dueDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      }
      else {
         data.reminder = false
      }

      if (data.reminder) {
         data.duration = parseInt(data.duration)
         data.duration_type = data.duration_type.value
      }

      if (props.data.id) {
         props.submitUpdate(data)
      }
      else {
         props.submitCreate(data)
      }
   }

   return (
      <div className="row">
         <div className="col-lg-12">
            <form onSubmit={handleSubmit(onSubmit)}>
               <fieldset disabled={props.isView}>
                  <div className="row">
                     <div className="col-lg-12" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <Controller
                              defaultValue=''
                              control={control}
                              name="type"
                              render={({ ref, value, ...props }) => (
                                 <TextInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.type}
                                    label='Type'
                                    required
                                    type='text'
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-lg-12" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <Controller
                              defaultValue=''
                              control={control}
                              name="description"
                              render={({ ref, value, ...props }) => (
                                 <TextInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.description}
                                    label='Description'
                                    required
                                    maxLength={250}
                                    type='textarea'
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-lg-6" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <label className="form-label">Entered Date <span className="text-danger">*</span></label>
                           <Controller
                              defaultValue={moment(new Date).format('MM-DD-YYYY')}
                              control={control}
                              name="enteredDate"
                              render={({ ref, value, ...props }) => (
                                 <DateInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.enteredDate}
                                    placeholder='mm-dd-yyyy'
                                    {...props}
                                 />
                              )}
                           />
                        </div>
                     </div>
                     <div className="col-lg-6" data-select2-id={2}>
                        <div className="form-group" data-select2-id={15}>
                           <label className="form-label">Due Date</label>
                           <Controller
                              defaultValue=''
                              control={control}
                              name="dueDate"
                              render={({ ref, value, ...props }) => (
                                 <DateInput
                                    inputref={ref}
                                    value={value}
                                    error={errors.dueDate}
                                    disabled={!enteredDates}
                                    placeholder='mm-dd-yyyy'
                                    {...props}
                                 />
                              )}
                           />
                           {props.data.id && dueDate === '' ? <p className="float-right mt-1">Lifetime</p> : null}
                        </div>
                     </div>
                  </div>

                  {dueDate && <>
                     <div className="row">
                        <div className="col-12">
                           <div className="form-group">
                              <Controller
                                 defaultValue={false}
                                 control={control}
                                 name="reminder"
                                 render={({ ref, value, ...props }) => (
                                    <CheckboxInput
                                       inputref={ref}
                                       value={value}
                                       label='Reminder'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>

                     {isChecked && <div className="row">
                        <div className="col-lg-6 col-12">
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="duration"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.duration}
                                       label='Duration'
                                       type='text'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                        <div className="col-lg-6 col-12">
                           <label className="form-label">Duration Type</label>
                           <Controller
                              defaultValue=''
                              control={control}
                              name="duration_type"
                              render={({ ref, value, ...rest }) => (
                                 <SelectInput
                                    error={errors.duration_type}
                                    inputref={ref}
                                    value={value}
                                    options={DURATION_TYPES}
                                    {...rest}
                                 />
                              )}
                           />
                        </div>
                     </div>}
                  </>}

                  <ReadCheckHOC module={props.module} operation={props.data && props.data.id ? 'update' : 'create'} isView={props.isView}>
                     <button className="btn btn-primary waves-light float-right mt-3" type="submit">Submit</button>
                  </ReadCheckHOC>
               </fieldset>
            </form>
         </div>
      </div>
   )
}

export default LiscenseForm