import { call } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS } from '../config/config';

export function* createStaffMember(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.USER.CREATE_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listStaffUsers(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.LIST_USER, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listWitnessUsers(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.LIST_USER_WITNESS, data, true);
      // console.log(resp);
      if(resp.status >= 200 && resp.status < 300){        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
      // console.log("else-part-try")
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getListStaffUsers(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.LIST_USER_STAFF, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listMedApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MED_APPROVAL.LIST_MED_APPROVAL, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateMedApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MED_APPROVAL.UPDATE_MED_APPROVAL + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteMedApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.MED_APPROVAL.DELETE_MED_APPROVAL + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* icidentApprovalLists(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MED_APPROVAL.INCIDENT_APPROVALS, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateIncidentApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MED_APPROVAL.UPDATE_INCIDENT_APPROVAL + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getListAdministratorUsers(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.USER.LIST_USER_ADMINISTRATOR, data, true);
      if (resp.status >= 200 && resp.status < 300) {        
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}