import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { MODULES, STORE_ID } from '../../../config/config';
import moment from "moment";
import 'moment-timezone';
import { useMedicationApi } from '../../../hooks/api/medcationApiHook'
import { timeName } from '../../form-elements/functions'
import axios, { Axios } from 'axios';
import { useResidentApi } from '../../../hooks/api/residentApiHook'
import SweetAlert from 'react-bootstrap-sweetalert';
import Swal from 'sweetalert2';




const ResMedicationTab = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [data, setData] = useState([])
   const [search, setSearch] = useState('')
   const [companyId, setCompanyId] = useState();


   const medicationApi = useMedicationApi()
   const history = useHistory()
   const residentApi = useResidentApi()


   useEffect(() => {
      getList()
      getCompanyIds()
   }, [])

   const getList = (search = '') => {
      let params = { search, residentId: props.resId }

      medicationApi.listMedication(params,
         (message, resp) => {
            resp?.sort((a, b) => (a.name.toLowerCase().charCodeAt(0) - b.name.toLowerCase().charCodeAt(0)))
            let temp =
               resp.length &&
               resp.map((item) => {
                  var current = moment().format('YYYY-MM-DD HH:mm:ss');
                  var tomorrow = new Date(item.createdDate);
                  tomorrow.setDate(tomorrow.getDate() + 1);
                  // console.log(tomorrow,current)
                  return {
                     ...item,
                     createdDate: moment.utc(item.createdDate).local().format('YYYY-MM-DD HH:mm:ss'),
                     current: current,
                     tomorrow: moment.utc(tomorrow).local().format('YYYY-MM-DD HH:mm:ss')
                  };
               });
            console.log(temp)
            setData(temp)
         }, (message) => { })
   }



   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (id) => {
      let obj = data.find(item => item.id === id)
      if (obj) {
         setDetails(obj)
         toggle()
      }
   }

   const thirdPartyApiCall = (id) => {
      let obj = data.find(item => item.id === id)
      Swal.fire({
         title: ``,
         text: 'Are you sure?',
         icon: 'warning',
         confirmButtonText: 'Continue!',
         cancelButtonText: 'Cancel',
         closeOnConfirm: true,
         closeOnCancel: false,
         showCancelButton: true,
         confirmButtonColor: '#DD6B55',
         reverseButtons: true
      }).then(function (result) {
         // Redirect the user
         if (result.isConfirmed) {
            thirdPApiCalling(obj)
         }
         else if (result.isDenied) {
            // Swal.fire('Changes are not saved', '', 'info')
         }
      });

   }

   const thirdPApiCalling = (obj) => {
      console.log('object object', obj, STORE_ID)
      // let params={StoreID: `${companyId?.companyId}`,Patient:{FirstName:companyId?.firstName, LastName:companyId?.lastName,Sex: companyId?.gender, Patientstreet: companyId?.facility?.addrLine1}, Doctor:{DoctorLastName: obj?.physician,DoctorFirstNa:obj?.physician}, RxClaim: {DrugName:obj?.name, Qty:obj?.prn_qty.toString(), Refills: obj?.refillis.toString(), Instructions: obj?.spDirections}}
      let params = { StoreID: STORE_ID, DocSignature: null, MessageHashCode: null, PDFFile: "", Patient: { FirstName: companyId?.firstName, LastName: companyId?.lastName, Sex: companyId?.gender, Patientstreet: companyId?.facility?.addrLine1, MI: null, DOB: companyId?.birthDate, Patientcity: null, Patientstate: null, Patientzip: null, PatientPhone: companyId?.phone || null, SSN: companyId?.ssn || null }, Doctor: { DoctorLastName: obj?.physician, DoctorFirstName: obj?.physician, Doctorstreet: null, Doctorcity: null, Doctorstate: null, Doctorzip: null, DoctorDea: null, DoctorNpi: null, DoctorPhone: null }, RxClaim: { DrugName: obj?.name, Qty: obj?.prn_qty.toString(), Refills: obj?.refillis.toString(), Instructions: obj?.spDirections, DrugNDC: null, DateWritten: null, Daw: null, DaysSupply: null, Notes: obj?.spDirections, RequestedBy: null }, Insurance: { Bin: null, PCN: null, CardNumber: null, GroupNumber: null } }
      residentApi.thirdPartyPRNSubmit(params,
         (message, resp) => {
            toast.success('Medication submitted successfully')
            // history.push('/resident-list')
         }, (message) => {
            toast.error(message)
         })
   }

   const getCompanyIds = () => {
      const id = props.resId
      residentApi.residentDetails({ id },
         (message, resp) => {
            console.log('resp?.companyId', resp?.companyId)
            setCompanyId(resp)

         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such resident found')
               setTimeout(() => {
                  history.replace('/resident-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const cancelSearch = () => {
      getList()
      setSearch('')
   }

   const handleSearch = () => {
      if (search === '') {
         toast.warn('No search value given')
      }
      else {
         getList(search)
      }
   }

   return (
      <div className="mt-3 p-0 pb-3">
         <div className="d-flex mb-4 justify-content-end">
            <input className="form-control mr-2" value={search} onChange={e => setSearch(e.target.value)} placeholder='Search by name' />
            <button className="btn btn-outline-success mr-2" onClick={handleSearch}><i className="bx bx-search-alt" /></button>
            <button className="btn btn-outline-danger mr-1" onClick={cancelSearch}><i className="bx bx-x-circle" /></button>
         </div>

         <div className="card border shadow-none">
            <div className='card-header'><h5>Routine</h5></div>
            <div className='card-body p-0'>
               <div className="table-responsive">
                  <table className="table table-striped table-condensed table-bordered w-100">
                     <thead>
                        <tr>
                           <th style={{ minWidth: '120px' }}>Name</th>
                           <th>Rx Number</th>
                           <th>Is New Medication</th>
                           {/* <th>Strength</th> */}
                           {/* <th>Units</th> */}
                           <th style={{ minWidth: '150px' }}>Directions</th>
                           <th style={{ minWidth: '110px' }}>Frequency</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.length && data.filter(item => (item?.freq && item?.freq?.length != 0 && (item?.freq[0]?.time == 'Am' || item?.freq[0]?.time == 'Pm' || item?.freq[0]?.time == 'Evening' || item?.freq[0]?.time == 'Bed'))).length ? data.filter(item => (item?.freq && item?.freq?.length != 0 && (item?.freq[0]?.time == 'Am' || item?.freq[0]?.time == 'Pm' || item?.freq[0]?.time == 'Evening' || item?.freq[0]?.time == 'Bed'))).map((item, i) =>
                           <tr key={item.id} onClick={() => editData(item.id)}>
                              <td>{item.name}</td>
                              <td>{item.prescription}</td>
                              <td>{((moment(item.createdDate).format('YYYY-MM-DD') == moment(item.current).format('YYYY-MM-DD')) && (item.createdDate <= item.current) && (item.createdDate <= item.tomorrow)) ? 'Yes' : ''}</td>
                              {/* <td>{item.strength}</td> */}
                              {/* <td>{item.units}</td> */}
                              <td>{!item.spDirections.length ? 'N/A' : item.spDirections.length > 70 ? `${item.spDirections.substr(0, 70)}...` : item.spDirections}</td>
                              <td>{item.freq.length ? item.freq.filter(item => item.time != 'Pass')
                                 .map(item => timeName(item.time)).join(', ') : 'N/A'}</td>
                              <td>
                                 <i onClick={() => editData(item.id)} className="fa fa-eye mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" />
                              </td>
                           </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>



         <div className="card border shadow-none">
            <div className='card-header'><h5>PRN</h5></div>
            <div className='card-body p-0'>
               <div className="table-responsive">
                  <table className="table table-striped table-condensed table-bordered w-100">
                     <thead>
                        <tr>
                           <th style={{ minWidth: '120px' }}>Name</th>
                           <th>Rx Number</th>
                           <th>Is New Medication</th>
                           {/* <th>Strength</th> */}
                           {/* <th>Units</th> */}
                           <th style={{ minWidth: '150px' }}>Directions</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.length && data.filter(obj => (obj?.freq?.length && (obj?.freq?.length && obj?.freq[0]?.time === 'Pnr') || (obj?.freq?.length && obj?.freq[0]?.time == 'Pnr'))).length ? data.filter(ob => (ob?.freq?.length && ob?.freq[0]?.time === 'Pnr')).map((items, i) =>
                           <tr key={items.id} >
                              <td>{items.name}</td>
                              <td>{items.prescription}</td>
                              <td>{((moment(items.createdDate).format('YYYY-MM-DD') == moment(items.current).format('YYYY-MM-DD')) && (items.createdDate <= items.current) && (items.createdDate <= items.tomorrow)) ? 'Yes' : ''}</td>
                              {/* <td>{items.strength}</td> */}
                              {/* <td>{items.units}</td> */}
                              <td>{!items.spDirections.length ? 'N/A' : items.spDirections.length > 65 ? `${items.spDirections.substr(0, 65)}...` : items.spDirections}</td>
                              <td>
                                 <i onClick={() => editData(items.id)} className="fa fa-eye mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" />
                                 {/* <i onClick={() => thirdPartyApiCall(items?.id)} className="fa fa-paper-plane mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" /> */}
                              </td>
                           </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>

         {/* <div className="card border shadow-none">
            <div className='card-header'><h5>Treatment</h5></div>
            <div className='card-body p-0'>
               <div className="table-responsive">
                  <table className="table table-striped table-condensed table-bordered w-100">
                     <thead>
                        <tr>
                        <th style={{ minWidth: '120px' }}>Name</th>
                    <th>Rx Number</th>
                           <th>Strength</th>
                           // <th>Units</th>
                           <th style={{ minWidth: '150px' }}>Directions</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.length && data.filter(obj => obj?.freq[0]?.time === 'Treatment').length ? data.filter(ob => ob?.freq[0]?.time === 'Treatment').map((items, i) =>
                           <tr key={items.id} onClick={() => editData(items.id)}>
                           <td>{items.name}</td>
                          <td>{items.prescription}</td>
                              <td>{items.strength}</td>
                              // <td>{items.units}</td>
                              <td>{!items.spDirections.length ? 'N/A' : items.spDirections.length > 65 ? `${items.spDirections.substr(0, 65)}...` : items.spDirections}</td>
                              <td>
                                 <i onClick={() => editData(items.id)} className="fa fa-eye mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" />
                              </td>
                           </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                     </tbody>
                  </table>
               </div>
            </div>
         </div> */}


         <div className="card border shadow-none">
            <div className='card-header'><h5>Comfort Kit</h5></div>
            <div className='card-body p-0'>
               <div className="table-responsive">
                  <table className="table table-striped table-condensed table-bordered w-100">
                     <thead>
                        <tr>
                           <th style={{ minWidth: '120px' }}>Name</th>
                           <th>Rx Number</th>
                           <th>Is New Medication</th>
                           {/* <th>Strength</th> */}
                           {/* <th>Units</th> */}
                           <th style={{ minWidth: '150px' }}>Directions</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.length && data.filter(obj => obj?.freq[0]?.time === 'CmfKit').length ? data.filter(ob => ob?.freq[0]?.time === 'CmfKit').map((items, i) =>
                           <tr key={items.id} onClick={() => editData(items.id)}>
                              <td>{items.name}</td>
                              <td>{items.prescription}</td>
                              <td>{((moment(items.createdDate).format('YYYY-MM-DD') == moment(items.current).format('YYYY-MM-DD')) && (items.createdDate <= items.current) && (items.createdDate <= items.tomorrow)) ? 'Yes' : ''}</td>
                              {/* <td>{items.strength}</td> */}
                              {/* <td>{items.units}</td> */}
                              <td>{!items.spDirections.length ? 'N/A' : items.spDirections.length > 65 ? `${items.spDirections.substr(0, 65)}...` : items.spDirections}</td>
                              <td>
                                 <i onClick={() => editData(items.id)} className="fa fa-eye mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" />
                              </td>
                           </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                     </tbody>
                  </table>
                  {/* <div className="d-flex justify-content-between mt-3">
                     <button className="btn btn-primary waves-light" onClick={() => history.push('/resident-list')}>Back to list</button>
                     <button className="btn btn-primary waves-dark" onClick={props.changeStep}>Go to Next</button>
                  </div> */}
               </div>
            </div>
         </div>

         <div className="card border shadow-none">
            <div className='card-header'><h5>Without Frequency</h5></div>
            <div className='card-body p-0'>
               <div className="table-responsive">
                  <table className="table table-striped table-condensed table-bordered w-100">
                     <thead>
                        <tr>
                           <th style={{ minWidth: '120px' }}>Name</th>
                           <th>Rx Number</th>
                           <th>Is New Medication</th>
                           {/* <th>Strength</th> */}
                           {/* <th>Units</th> */}
                           <th style={{ minWidth: '150px' }}>Directions</th>
                           <th style={{ minWidth: '110px' }}>Frequency</th>
                           <th>Action</th>
                        </tr>
                     </thead>
                     <tbody>
                        {data.length && data.filter(item => item?.freq ? (item?.freq && item?.freq[0]?.time !== 'CmfKit'
                           && item?.freq[0]?.time !== 'Pnr' && item?.freq[0]?.time !== 'Treatment'
                           && item?.freq[0]?.time !== 'Am' && item?.freq[0]?.time !== 'Pm' && item?.freq[0]?.time !== 'Evening'
                           && item?.freq[0]?.time !== 'Bed') : []).length ? data.filter(item => item?.freq ? (item?.freq && item?.freq[0]?.time !== 'CmfKit' && item?.freq[0]?.time !== 'Pnr'
                              && item?.freq[0]?.time !== 'Treatment' && item?.freq[0]?.time !== 'Am' && item?.freq[0]?.time !== 'Pm' && item?.freq[0]?.time !== 'Evening'
                              && item?.freq[0]?.time !== 'Bed') : []).map((item, i) =>
                                 <tr key={item.id} onClick={() => editData(item.id)}>
                                    <td>{item.name}</td>
                                    <td>{item.prescription}</td>
                                    <td>{((moment(item.createdDate).format('YYYY-MM-DD') == moment(item.current).format('YYYY-MM-DD')) && (item.createdDate <= item.current) && (item.createdDate <= item.tomorrow)) ? 'Yes' : ''}</td>
                                    {/* <td>{item.strength}</td> */}
                                    {/* <td>{item.units}</td> */}
                                    <td>{!item.spDirections.length ? 'N/A' : item.spDirections.length > 70 ? `${item.spDirections.substr(0, 70)}...` : item.spDirections}</td>
                                    <td>{item.freq.length ? item.freq
                                       .map(item => timeName(item.time)) : 'N/A'}</td>
                                    <td>
                                       <i onClick={() => editData(item.id)} className="fa fa-eye mr-3 cursor-pointer" data-toggle="tooltip" title="View medication" />
                                    </td>
                                 </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                     </tbody>
                  </table>

               </div>
            </div>
         </div>

         <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
            <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
         </div>

         <ViewMeds
            isOpen={modal}
            data={details}
            close={toggle}
         />
      </div>
   )
}

export default ResMedicationTab

const getCalculatedDate = (utcdate) => {

   let getdate = utcdate.substring(0, 10)
   let getArr = getdate.split("-");
   var date = getArr[2];
   var month = getArr[1];
   var fullyear = getArr[0];

   let datestring = `${month}-${date}-${fullyear}`;

   return datestring;

}

const ViewMeds = (props) => {
   console.log(props.data)
   return (
      <Modal centered isOpen={props.isOpen} size='md'>
         <ModalHeader toggle={props.close}>Medication Details</ModalHeader>
         <ModalBody>
            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Name</label>
                     <p className="detail-text">{props.data.name || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Frequency</label>
                     <p className="detail-text">{props?.data?.freq && props?.data?.freq?.length ? props?.data?.freq?.map(item => timeName(item.time)).join(', ') : 'N/A'}</p>
                  </div>
               </div>
            </div>

            {props?.data?.freq && props?.data?.freq[0]?.time != 'Pnr' &&
               <div className="row mt-2">
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Strength</label>
                        <p className="detail-text">{props.data.strength || 'N/A'} {props.data.units || ''}</p>
                     </div>
                  </div>
                  {/* <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Quantity</label>
                        <p className="detail-text">{props.data.prn_qty || 'N/A'}</p>
                     </div>
                  </div> */}
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Quantity</label>
                        <p className="detail-text">{props.data.prn_qty || 'N/A'}</p>
                     </div>
                  </div>
                  <div className="col-12" data-select2-id={1}>
                     <div className="form-group" data-select2-id={15}>
                        <label className="form-label">Directions</label>
                        <p className="detail-text">{props.data.spDirections || 'N/A'}</p>
                     </div>
                  </div>
               </div>}

            {props?.data?.freq && props?.data?.freq[0]?.time === 'Pnr' && <div className="row mt-2">
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Strength</label>
                     <p className="detail-text">{props.data.strength || 'N/A'} {props.data.units || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Quantity</label>
                     <p className="detail-text">{props.data.prn_qty || 'N/A'}</p>
                  </div>
               </div>
               {/* <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Minimum hours between doses</label>
                     <p className="detail-text">{props.data.prn_gap || 'N/A'}</p>
                  </div>
               </div> */}
               <div className="col-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Directions</label>
                     <p className="detail-text">{props.data.spDirections || 'N/A'}</p>
                  </div>
               </div>
               {/* <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Units</label>
                     <p className="detail-text">{props.data.units || 'N/A'}</p>
                  </div>
               </div> */}
            </div>}



            {props?.data?.freq && props?.data?.freq[0]?.time === "Treatment" &&
               <>
                  <div className="row mt-2">
                     <div className="col-6">
                        <div className="form-group">
                           <label className="form-label">Duration</label>
                           <p className="detail-text">{props.data.treatmentDuration || 'N/A'}</p>
                        </div>
                     </div>
                     <div className="col-6" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <label className="form-label">Duration Type</label>
                           <p className="detail-text capitalize">{props.data.treatmentDurationType ? (props.data.treatmentDurationType == 1 ? props.data.treatmentDurationType : props.data.treatmentDurationType + 's') : 'N/A'}</p>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-4" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           <label className="form-label">Duration Date</label>
                           <p className="detail-text">{props.data.treatmentDuration ? moment(props.data.treatmentDuration).format('MM-DD-YYYY') : 'N/A'}</p>
                        </div>
                     </div>
                  </div>
               </>}

            {/* {props?.data?.freq && props?.data?.freq[0]?.time === 'Pnr' && <div className="row mt-2">
               <div className="col-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Directions</label>
                     <p className="detail-text">{props.data.spDirections || 'N/A'}</p>
                  </div>
               </div>
            </div>} */}

            <div className="row mt-2">
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Expiration Date</label>
                     <p className="detail-text">{props.data.expirationDate ? getCalculatedDate(props.data.expirationDate) : 'N/A'}</p>
                  </div>
               </div>
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Date Filled</label>
                     <p className="detail-text">{props.data.dateFilled ? getCalculatedDate(props.data.dateFilled) : 'N/A'}</p>
                  </div>
               </div>
            </div>

            <div className="row">
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Date Started</label>
                     <p className="detail-text">{props.data.dateStarted ? getCalculatedDate(props.data.dateStarted) : 'N/A'}</p>
                  </div>
               </div>
            </div>

            <div className="row mt-2">
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Prescribing Physician</label>
                     <p className="detail-text">{props.data.physician || 'N/A'}</p>
                  </div>
               </div>
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Prescription No.</label>
                     <p className="detail-text">{props.data.prescription || 'N/A'}</p>
                  </div>
               </div>
            </div>

            <div className="row mt-2">
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">No. of refills</label>
                     <p className="detail-text">{props.data.refillis || '0'}</p>
                  </div>
               </div>
               <div className="col-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Name of pharmacy</label>
                     <p className="detail-text">{props.data.pharmacy || 'N/A'}</p>
                  </div>
               </div>
            </div>

         </ModalBody>
      </Modal>
   )
}