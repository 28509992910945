import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyReusableContactList = React.lazy(() =>
   import('./reusableContactList')
);

const ReusableContactList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyReusableContactList {...props} />
   </React.Suspense>
);

export default ReusableContactList