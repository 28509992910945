import React, { useEffect, useState } from "react";
import { useResidentApi } from "../../hooks/api/residentApiHook";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { encryptId } from "../form-elements/functions";
import moment from "moment";
import { useUserSelector } from "../../hooks/selectors/userSelector";
import { NO_IMAGE, PER_PAGE } from "../../config/config";
import Pagination from "react-js-pagination";
import LazyImage from "../form-elements/lazyImage";
import ReportResIncidentModal from './reportResIncidentModal'

const ReportResModal = (props) => {
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [activePage, setPage] = useState(1);
  const [radioValue, setRadioValue] = useState(1);
  const [incidentModal, showIncidentModal] = useState(false);
  const [residentData, setResidentData] = useState({});

  const residentApi = useResidentApi();
  const user = useUserSelector();
  const history = useHistory();

  useEffect(() => {
    if (props.isOpen) {
      getList();
    }
  }, [props.isOpen]);

  const getList = (page = 1, isActive = 1) => {
    let params = {
      isReport: true,
      fullName: search,
      isActive,
      page,
      limit: PER_PAGE,
    };

    console.log('props.type', props.type)

    if(props.type=='history'){
    residentApi.residentListJoinInventory(
      params,
      (message, resp) => {
        setData(resp);
      },
      (message) => {}
    );
    }
    else{
      residentApi.residentList(
        params,
        (message, resp) => {
          setData(resp);
        },
        (message) => {}
      );
    }
  };

  const close = () => {
    props.close();
  };

  const goToLink = (e, item) => {
    e.preventDefault();
    e.stopPropagation();
    if (props.type === "incident") {
      showIncidentModal(true);
      setResidentData(item);
    } else {
      let route =
        props.type === "911"
          ? `/face-sheet/${encryptId(item.id)}`
          : (props.type === "medication"? `/medication/${encryptId(item.id)}`: (props.type === "history") ? `/history/${encryptId(item.id)}` : `/622-report/${encryptId(item.id)}` );
      window.open(route, "_blank").focus();
    }
  };

  const handlePage = (page) => {
    if (activePage !== page) {
      getList(page, radioValue);
      setPage(page);
    }
  };

  const doSearch = () => {
    setPage(1);
    getList(1, radioValue);
  };

  const handleRadio = (item) => {
    setRadioValue(item);
    getList(1, item);
    setPage(1);
  };

  return (
    <>
      <Modal isOpen={props.isOpen} centered size="lg">
        <ModalHeader toggle={close} style={{ textTransform: "capitalize" }}>
          {props.type === "911" ? "911 / Face Sheet" : (props?.type=='history') ? 'Medication History Report' : props.type + " Report"}{" "}
        </ModalHeader>
        <ModalBody className="fixed-height-modal">
          <div className="mb-3">
            <div className="row">
              <div className="col-lg-5">
                <div className='btn-group'>
                    <button className={'btn w-xs waves-effect ' + (radioValue == 2 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(2)}>All</button>
                    <button className={'btn w-xs waves-effect ' + (radioValue == 1 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(1)}>Active</button>
                    <button className={'btn w-xs waves-effect ' + (radioValue == 0 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(0)}>In-Active</button>
                </div>
              </div>
              {/*<div className="col-lg-2">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="formRadios"
                    id="formRadios1"
                    onChange={() => handleRadio(1)}
                    checked={radioValue === 1}
                  />
                  <label class="form-check-label" htmlFor="formRadios1">
                    Active
                  </label>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formRadios"
                    id="formRadios2"
                    onChange={() => handleRadio(0)}
                    checked={radioValue === 0}
                  />
                  <label className="form-check-label" htmlFor="formRadios2">
                    Inactive
                  </label>
                </div>
              </div>
              <div className="col-lg-1">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="formRadios"
                    id="formRadios3"
                    onChange={() => handleRadio(2)}
                    checked={radioValue === 2}
                  />
                  <label className="form-check-label" htmlFor="formRadios3">
                    All
                  </label>
                </div>
              </div>*/}
              <div className="col-lg-7">
                <div className="d-flex mt-xs-3">
                  <input
                    className="form-control"
                    id="resDocViewInput"
                    type="text"
                    placeholder="Search by name"
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        doSearch();
                      }
                    }}
                  />
                  <button className="btn btn-outline-success ml-2" onClick={doSearch}>
                    <i className="fa fa-search" />
                  </button>
                </div>
                
              </div> 
            </div>  
                    
          </div>

          

          <div className="table-responsive mt-4">
            <table className="table table-hover table-bordered w-100">
              <thead>
                <tr>
                  <th style={{ width: "50px" }}></th>
                  <th>Name</th>
                  <th style={{ minWidth: "130px" }}>Facility</th>
                  <th style={{ minWidth: "120px" }}>Date of Birth</th>
                  <th style={{ width: '5%'}} className="p-1">&nbsp;</th>
                </tr>
              </thead>
              <tbody id="resSearch">
                {data.data && data.data.length ? (
                  data.data.map((item, i) => (
                    <tr
                      key={i}
                      onClick={(e) => goToLink(e, item)}
                      className="cursor-pointer"
                    >
                      {/* <td>
                        <LazyImage
                          src={item?.photo?.fullUrl || ""}
                          height="50px"
                        />
                      </td> */}
                      <td className="p-1 align-middle">
                        <img src={item?.photo?.fullUrl || NO_IMAGE} height={"50px"} alt="" className="rounded avatar-sm"/>
                      </td>
                      <td className="align-middle">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="align-middle">{item.facility ? item.facility.name : "N/A"}</td>
                      <td className="align-middle">{moment(item.birthDate).format("MM-DD-YYYY")}</td>
                      <td className="p-1 text-center align-middle">
                        <a
                          href="#"
                          className="text-dark"
                          onClick={(e) => goToLink(e, item)}
                        >
                          <i className="fa fa-eye cursor-pointer" />
                        </a>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="no-td" colSpan="3">
                      No results found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="row mt-1">
            <div class="col-lg-6">
              {data.total > 0 && <p>Total results: {data.total}</p>}
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              {data.totalPage > 1 ? (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={PER_PAGE}
                  totalItemsCount={data.total ? data.total : 1}
                  pageRangeDisplayed={5}
                  onChange={(page) => handlePage(page)}
                  innerClass="pagination"
                  itemClass="paginate_button page-item"
                  linkClass="page-link"
                  activeClass="active"
                  hideFirstLastPages={true}
                  prevPageText="Previous"
                  nextPageText="Next"
                />
              ) : null}
            </div>
          </div>
        </ModalBody>
      </Modal>
      {props.type === "incident" && incidentModal && (
        <ReportResIncidentModal
          isOpen={true}
          close={() => showIncidentModal(false)}
          resident={residentData}
        />
      )}
    </>
  );
};

export default ReportResModal;
