import Dashboard from '../container/dashboard/dashboard'
import ForgotPassword from '../container/forgot-password'
import Login from '../container/login'
import Profile from '../container/profile/profile'
import ResetPassword from '../container/reset-password'
import ManageStaff from '../container/manage-staff/manageStaff'
import VerifyEmail from '../container/verify-email'
import StaffList from '../container/staff-list'
import ApprovalList from '../container/approval-list'
import { MODULES, OPERATIONS, USER_TYPES } from '../config/config'
import RoleList from '../container/role-list/roleList'
import AssignPermission from '../container/assign-role-permission/assignRolePermission'
import ResidentList from '../container/resident-list/residentList'
import ManageResident from '../container/manage-resident/manageResident'
import ViewResident from '../container/view-resident/viewResident'
import FacilityList from '../container/facility-list'
import ManageFacility from '../container/manage-facility'
import PassMeds from '../container/pass-meds/passMeds'
import DispenseMeds from '../container/dispense-meds/dispenseMeds'
import Administerprn from '../container/administer-prn'
import Reports from '../container/reports'
import EmergencySheet from '../container/reports/911-report'
import ManageAncment from '../container/manage-ancment'
import AncmentList from '../container/ancment-list'
import ReusableContactList from '../container/reusable-contacts'
import ManageContact from '../container/manage-reusable-contact'
import IncidentReport from '../container/reports/incidentReport'
import SixTwoTwo from '../container/reports/622-report'
import CustomerWizard from '../container/customer-wizard/customerWizard'
import Medication from '../container/reports/medication-report'
import IncidentApprovalList from '../container/incident-approval'
import HistoryList from '../container/history-list'
import InventoryReportss from '../container/inventoryReport.js'
import ApprovalMedication from '../container/approval-medication'
import MedicationReportCalendarView from '../container/reports/medication-report-calendar-view'
import Calendar from '../container/reports/calendar'

export const allRoutes = [
   {
      path: '/login',
      component: Login,
      isPrivate: false
   },
   {
      path: '/forgot-password',
      component: ForgotPassword,
      isPrivate: false
   },
   {
      path: '/reset-password',
      component: ResetPassword,
      isPrivate: false
   },
   {
      path: '/verify-email',
      component: VerifyEmail,
      isPrivate: false
   },
   {
      path: '/dashboard',
      component: Dashboard,
      isPrivate: true,
   },
   {
      path: '/profile',
      component: Profile,
      isPrivate: true,
   },
   {
      path: '/view-staff/:id',
      component: ManageStaff,
      isPrivate: true,
      module: MODULES.STAFF,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/create-staff',
      component: ManageStaff,
      isPrivate: true,
      module: MODULES.STAFF,
      operation: [OPERATIONS.CREATE]
   },
   {
      path: '/manage-staff/:id?',
      component: ManageStaff,
      isPrivate: true,
      module: MODULES.STAFF,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/staff-list',
      component: StaffList,
      isPrivate: true,
      module: MODULES.STAFF,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/role-list',
      component: RoleList,
      isPrivate: true,
      module: MODULES.ROLES,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/assign-permission/:id?/:type?',
      component: AssignPermission,
      isPrivate: true,
      module: MODULES.ROLES,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/assign-permission/:id?/:type?/:name?/:from?',
      component: AssignPermission,
      isPrivate: true,
      module: MODULES.ROLES,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/create-resident',
      component: ManageResident,
      isPrivate: true,
      module: MODULES.RESIDENT,
      operation: [OPERATIONS.CREATE]
   },
   {
      path: '/manage-resident/:id?',
      component: ManageResident,
      isPrivate: true,
      module: MODULES.RESIDENT,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/resident-list',
      component: ResidentList,
      isPrivate: true,
      module: MODULES.RESIDENT,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/view-resident/:id',
      component: ViewResident,
      isPrivate: true,
      module: MODULES.RESIDENT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/create-facility',
      component: ManageFacility,
      isPrivate: true,
      module: MODULES.FACILITY,
      operation: [OPERATIONS.CREATE]
   },
   {
      path: '/manage-facility/:id?',
      component: ManageFacility,
      isPrivate: true,
      module: MODULES.FACILITY,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/view-facility/:id',
      component: ManageFacility,
      isPrivate: true,
      module: MODULES.FACILITY,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/facility-list',
      component: FacilityList,
      isPrivate: true,
      module: MODULES.FACILITY,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/pass-meds',
      component: PassMeds,
      isPrivate: true,
      module: MODULES.PASS_MEDS,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/dispense-meds/:resId/:time',
      component: DispenseMeds,
      isPrivate: true,
      module: MODULES.PASS_MEDS,
      operation: [OPERATIONS.CREATE, OPERATIONS.READ, OPERATIONS.UPDATE]
   },
   {
      path: '/administer-prn/:resId/:time',
      component: Administerprn,
      isPrivate: true,
      module: MODULES.PASS_MEDS,
      operation: [OPERATIONS.CREATE, OPERATIONS.READ, OPERATIONS.UPDATE]
   },
   {
      path: '/reports',
      component: Reports,
      isPrivate: true,
      module: MODULES.REPORT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/inventory',
      component: InventoryReportss,
      isPrivate: true,
      module: MODULES.REPORT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/announcement-list',
      component: AncmentList,
      isPrivate: true,
      module: MODULES.ANNOUNCEMENTS,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/create-announcement',
      component: ManageAncment,
      isPrivate: true,
      module: MODULES.ANNOUNCEMENTS,
      operation: [OPERATIONS.CREATE]
   },
   {
      path: '/view-announcement/:id',
      component: ManageAncment,
      isPrivate: true,
      module: MODULES.ANNOUNCEMENTS,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/manage-announcement/:id?',
      component: ManageAncment,
      isPrivate: true,
      module: MODULES.ANNOUNCEMENTS,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/contacts-list',
      component: ReusableContactList,
      isPrivate: true,
      module: MODULES.CONTACT,
      operation: [OPERATIONS.LIST]
   },
   {
      path: '/create-contact',
      component: ManageContact,
      isPrivate: true,
      module: MODULES.CONTACT,
      operation: [OPERATIONS.CREATE]
   },
   {
      path: '/manage-contact/:id?',
      component: ManageContact,
      isPrivate: true,
      module: MODULES.CONTACT,
      operation: [OPERATIONS.UPDATE]
   },
   {
      path: '/view-contact/:id',
      component: ManageContact,
      isPrivate: true,
      module: MODULES.CONTACT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/622-report/:id',
      component: SixTwoTwo,
      isPrivate: true,
      hideLayout: true,
      module: MODULES.REPORT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/face-sheet/:id',
      component: EmergencySheet,
      isPrivate: true,
      module: MODULES.REPORT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/medication/:id',
      component: Calendar, //Medication,  //
      isPrivate: true,
      module: MODULES.REPORT,
      operation: [OPERATIONS.READ]
   },
   {
      path: '/incident-report/:id',
      component: IncidentReport,
      isPrivate: true,
      hideLayout: true
   },
   {
      path: '/customer-wizard',
      component: CustomerWizard,
      isPrivate: true,
      hideLayout: false
   },
   {
      path: '/customer-wizard/:id',
      component: CustomerWizard,
      isPrivate: true,
      hideLayout: false
   },
   {  path: '/approval-list',
      component: ApprovalList,
      isPrivate: true,
      // module: MODULES.STAFF,
      // operation: [OPERATIONS.LIST]
   },
   {  path: '/approval-list/:id',
      component: ApprovalList,
      isPrivate: true,
      // module: MODULES.STAFF,
      // operation: [OPERATIONS.LIST]
   },
   {  path: '/incident-approval',
      component: IncidentApprovalList,
      isPrivate: true,
      // module: MODULES.STAFF,
      // operation: [OPERATIONS.LIST]
   },
   {  path: '/history/:id',
      component: HistoryList,
      isPrivate: true,
      // module: MODULES.STAFF,
      // operation: [OPERATIONS.LIST]
   },
   {
      path: '/approval-medication',
      component: ApprovalMedication,
      isPrivate: true,
      // hideLayout: false
   },
]