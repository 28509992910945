import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* createAncment(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.ANCMENT.CREATE_ANCMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* ancmentList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ANCMENT.LIST_ANCMENTS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateAncment(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.ANCMENT.UPDATE_DELETE_ANCMENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteAncment(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.ANCMENT.UPDATE_DELETE_ANCMENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* ancmentDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ANCMENT.GET_ANCMENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* getApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ANCMENT.GET_APPROVAL, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getIncidentApproval(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.ANCMENT.GET_INCIDENT_APPROVAL, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}