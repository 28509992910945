import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import FacilityForm from '../../components/facility/facilityForm'
import StaffForm from '../../components/staff/staffForm';
import ManageResident from '../../container/manage-resident/manageResident';

import { decryptId, encryptId } from '../../components/form-elements/functions'
import CustomerWizardStep1 from '../../components/customerWizard/step1'
import CustomerWizardStep2 from '../../components/customerWizard/step2'
import CustomerWizardStep3 from '../../components/customerWizard/step3'
import CustomerWizardStep4 from '../../components/customerWizard/step4'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'
import { ACTIONS } from '../../config/config'
import { Prompt } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert'

const CustomerWizard = (props) => {
   const history = useHistory()
   const { id } = useParams()

   const [stepCounter, setStepCounter] = useState(1);
   const tabValues = ['facility', 'stuff', 'resident', 'editResident'];
   const [forceExit, setforceExit] = useState(true);

   const [tabKey, setTabKey] = useState(tabValues[0]);
   const [alert, setAlert] = useState(null)

   const childRef = React.useRef()
   const reesidntRef = React.useRef()
   const staffRef = React.useRef()

   const activeTab = useSelector(state => state.extras && state.extras.wizardTab)
   const [activeTabVal, setActiveTabVal] = useState(activeTab);
   const dispatch = useDispatch()

   useEffect(() => {

      console.log("statestatestatestate", props.location.state);

      var tabfrompopup = props.location.state?.step == undefined ? activeTab : props.location.state?.step;

      if (tabfrompopup == 1) {
         dispatch({ type: ACTIONS.WIZARD.SET_WIZARD_TAB, payload: '1' });
         dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: '1' });
      }


      if (id && isNaN(decryptId(id))) {
         toast.error('Invalid link')
         setTimeout(() => {
            history.replace('/facility-list')
         }, 500);
      }
      return () => { console.log('useuse'); }

   }, [])



   const toggle = tab => {
      if (activeTab !== tab) dispatch({ type: ACTIONS.WIZARD.SET_WIZARD_TAB, payload: tab });
   }

   const changeStep = (step) => {
      console.log("stepstepstepstep", step);
      console.log("resident form values", reesidntRef?.current?.getAlert());
      console.log("facility form values", childRef?.current?.getAlert());
      console.log("staff form values", staffRef?.current?.getAlert());

      let residentObj = reesidntRef?.current?.getAlert() === undefined ? {} : reesidntRef?.current?.getAlert();
      let staffObj = staffRef?.current?.getAlert() === undefined ? {} : staffRef?.current?.getAlert();
      let childObj = childRef?.current?.getAlert() === undefined ? {} : childRef?.current?.getAlert();

      if ((Object.keys(residentObj).length === 0) && (Object.keys(staffObj).length === 0) && (Object.keys(childObj).length > 0) && (step === '2') && (
         (childRef.current.getAlert().name != "")
         || (childRef.current.getAlert().addrLine1 != "")
         || (childRef.current.getAlert().addrLine2 != "")
         || (childRef.current.getAlert().streetAddr != "")
         || (childRef.current.getAlert().phone != "")
         || (childRef.current.getAlert().adminPhone != "")
         || (childRef.current.getAlert().zipCode != "")
         || (childRef.current.getAlert().administrator != "")
         || (childRef.current.getAlert().liscenseNo != "")
         || (childRef.current.getAlert().state != ""))
      ) {
         console.log("step 1 to step 2");
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost!
            </SweetAlert>
         )
      }

      // else if ((Object.keys(residentObj).length === 0) && (Object.keys(childObj).length === 0) &&  (Object.keys(staffObj).length > 0) && (step === '2') && (
      //    (staffRef.current.getAlert().firstName != "")
      //       || (staffRef.current.getAlert().lastName != "")
      //       || (staffRef.current.getAlert().username != "")
      //       || (staffRef.current.getAlert().phone != "")
      //       || (staffRef.current.getAlert().ssn != "")
      //       || (staffRef.current.getAlert().description != "")
      //       || (staffRef.current.getAlert().email != "")
      //       || (staffRef.current.getAlert().dssConfirmation != "")
      //       || (staffRef.current.getAlert().birthday != "")
      //       || (staffRef.current.getAlert().facility != ""))
      // ) {
      //    setAlert(
      //       <SweetAlert
      //          warning
      //          showCancel
      //          confirmBtnText="Continue!"
      //          confirmBtnBsStyle="danger"
      //          title="Warning"
      //          onConfirm={() => {
      //             toggle(step);
      //             setAlert(null);
      //          }}
      //          onCancel={() => setAlert(null)}
      //          focusCancelBtn
      //       >
      //          Are you sure you want to change the tab without saving? Unsaved changes will be lost!
      //       </SweetAlert>
      //    )
      // }
      else if ((Object.keys(residentObj).length === 0) && (Object.keys(staffObj).length === 0) && (Object.keys(childObj).length > 0) && (step === '3') && (
         (childRef.current.getAlert().name != "")
         || (childRef.current.getAlert().addrLine1 != "")
         || (childRef.current.getAlert().addrLine2 != "")
         || (childRef.current.getAlert().streetAddr != "")
         || (childRef.current.getAlert().phone != "")
         || (childRef.current.getAlert().adminPhone != "")
         || (childRef.current.getAlert().zipCode != "")
         || (childRef.current.getAlert().administrator != "")
         || (childRef.current.getAlert().liscenseNo != "")
         || (childRef.current.getAlert().state != ""))
      ) {
         console.log("step 1 to step 2");
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost!
            </SweetAlert>
         )
      }
      else if ((Object.keys(residentObj).length === 0) && (Object.keys(childObj).length === 0) && (Object.keys(staffObj).length > 0) && (step === '3') &&
         (
            (staffRef.current.getAlert().firstName != "")
            || (staffRef.current.getAlert().lastName != "")
            || (staffRef.current.getAlert().username != "")
            || (staffRef.current.getAlert().phone != "")
            || (staffRef.current.getAlert().ssn != "")
            || (staffRef.current.getAlert().description != "")
            || (staffRef.current.getAlert().email != "")
            || (staffRef.current.getAlert().dssConfirmation != "")
            || (staffRef.current.getAlert().birthday != "")
            || (staffRef.current.getAlert().facility != ""))) {
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost
            </SweetAlert>
         )
      }

      else if ((Object.keys(residentObj).length > 0) && (step === '2') && (

         (reesidntRef?.current?.getAlert().accountNo != "") ||

         (reesidntRef?.current?.getAlert().birthDate != null) ||

         //(reesidntRef?.current?.getAlert().carePlan != "") || 
         (reesidntRef?.current?.getAlert().deathDate != null) ||
         (reesidntRef?.current?.getAlert().depositAccepted != null)||
         (reesidntRef?.current?.getAlert().email != "") ||
         (reesidntRef?.current?.getAlert().facility != "") ||
         (reesidntRef?.current?.getAlert().firstName != "") ||
          (reesidntRef?.current?.getAlert().gender != "")||
         (reesidntRef?.current?.getAlert().groupNo != "") ||
         (reesidntRef?.current?.getAlert().insuranceProvider != "") ||
         (reesidntRef?.current?.getAlert().language != "") ||
         (reesidntRef?.current?.getAlert().lastName != "") ||
         (reesidntRef?.current?.getAlert().medical != "") ||
         (reesidntRef?.current?.getAlert().medicalCond != "") ||
         (reesidntRef?.current?.getAlert().medicare != "") ||
         (reesidntRef?.current?.getAlert().nickname != "") ||
         (reesidntRef?.current?.getAlert().phone != "") ||
         (reesidntRef?.current?.getAlert().physician != "") ||
         (reesidntRef?.current?.getAlert().race != "") ||
         (reesidntRef?.current?.getAlert().religion != "") ||
         (reesidntRef?.current?.getAlert().residing != "") ||
         (reesidntRef?.current?.getAlert().movedInDate != null) ||
         (reesidntRef?.current?.getAlert().movedOut != null) ||
         (reesidntRef?.current?.getAlert().ssn != "")
      )) {
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost
            </SweetAlert>
         )
      }
      else if ((Object.keys(residentObj).length > 0) && (step === '1') && (
         (reesidntRef?.current?.getAlert().accountNo != "") ||

         (reesidntRef?.current?.getAlert().birthDate != null) ||

         //(reesidntRef?.current?.getAlert().carePlan != "") || 
         (reesidntRef?.current?.getAlert().deathDate != null) ||
         (reesidntRef?.current?.getAlert().depositAccepted != null)||
         (reesidntRef?.current?.getAlert().email != "") ||
         (reesidntRef?.current?.getAlert().facility != "") ||
         (reesidntRef?.current?.getAlert().firstName != "") ||
          (reesidntRef?.current?.getAlert().gender != "")||
         (reesidntRef?.current?.getAlert().groupNo != "") ||
         (reesidntRef?.current?.getAlert().insuranceProvider != "") ||
         (reesidntRef?.current?.getAlert().language != "") ||
         (reesidntRef?.current?.getAlert().lastName != "") ||
         (reesidntRef?.current?.getAlert().medical != "") ||
         (reesidntRef?.current?.getAlert().medicalCond != "") ||
         (reesidntRef?.current?.getAlert().medicare != "") ||
         (reesidntRef?.current?.getAlert().nickname != "") ||
         (reesidntRef?.current?.getAlert().phone != "") ||
         (reesidntRef?.current?.getAlert().physician != "") ||
         (reesidntRef?.current?.getAlert().race != "") ||
         (reesidntRef?.current?.getAlert().religion != "") ||
         (reesidntRef?.current?.getAlert().residing != "") ||
         (reesidntRef?.current?.getAlert().movedInDate != null) ||
         (reesidntRef?.current?.getAlert().movedOut != null) ||
         (reesidntRef?.current?.getAlert().ssn != "")
      )) {
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost
            </SweetAlert>
         )
      }
      else if ((Object.keys(residentObj).length === 0) && (Object.keys(staffObj).length > 0) && (step === '1') &&
         (
            (staffRef.current.getAlert().firstName != "")
            || (staffRef.current.getAlert().lastName != "")
            || (staffRef.current.getAlert().username != "")
            || (staffRef.current.getAlert().phone != "")
            || (staffRef.current.getAlert().ssn != "")
            || (staffRef.current.getAlert().description != "")
            || (staffRef.current.getAlert().email != "")
            || (staffRef.current.getAlert().dssConfirmation != "")
            || (staffRef.current.getAlert().birthday != "")
            || (staffRef.current.getAlert().facility != ""))) {
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Warning"
               onConfirm={() => {
                  toggle(step);
                  setAlert(null);
               }}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure you want to change the tab without saving? Unsaved changes will be lost
            </SweetAlert>
         )
      }

      else {

         toggle(step);
      }

   }



   const getvalues = (val) => {
      console.log("valvalvalvalval", val);
   }


   const isView = props.location.pathname.includes('view-facility')

   return (
      <>
         {alert}
         <div className="page-content">

            <Prompt message={(location, action) => {
               console.log(location, action);
               return 'customer-wizard-end';
            }} when={forceExit} />



            <div className="container-fluid">
               {/* start page title */}


               <div className="row f_1">
                  <div className="col-lg-12">
                     <div className="card">
                        <div className="card-body">
                           <div class="twitter-bs-wizard">
                              <Nav tabs className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                 <NavItem>
                                    <NavLink className={`${activeTab == '1' ? 'active' : ''}`}
                                       onClick={() => { changeStep('1'); }}> <span className="step-number mr-2">01</span> Facility <i className="fa fa-info-circle" aria-hidden="true" title="Add New Facility"></i></NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink className={`${activeTab === '2' ? 'active' : ''}`}
                                       onClick={() => { changeStep('2'); }}> <span className="step-number mr-2">02</span> Staff <i className="fa fa-info-circle" aria-hidden="true" title="Add New Staff"></i></NavLink>
                                 </NavItem>
                                 <NavItem>
                                    <NavLink className={`${activeTab === '3' ? 'active' : ''}`}
                                       onClick={() => { changeStep('3'); }}> <span className="step-number mr-2">03</span> Resident <i className="fa fa-info-circle" aria-hidden="true" title="Add or Update Resident"></i></NavLink>
                                 </NavItem>
                                 {/* <NavItem>
                              <NavLink className={`${activeTab === '4' ? '' : ''} cursor-pointer`}
                                 onClick={() => { toggle('4'); }}>4. Edit Resident <i className="fa fa-info-circle" aria-hidden="true" title="Edit Resident"></i></NavLink>
                           </NavItem> */}
                              </Nav>
                           </div>
                           <TabContent activeTab={activeTab.toString()}>
                              <TabPane tabId="1" >
                                 <div id="bar" class="progress mt-4">
                                    <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" style={{ width: '33%' }}></div>
                                 </div>
                                 <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                       <h4 className="mb-0 font-size-18 mt-4">Add Facility</h4>
                                    </div>
                                 </div>

                                 {/* <CustomerWizardStep1 getvalues={getvalues} setforceExit={setforceExit} onChangeStep={() => { toggle('2') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} /> */}
                                 {activeTab === '1' && (
                                    <FacilityForm getvalues={getvalues} setforceExit={setforceExit} onChangeStep={() => { toggle('2') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} ref={childRef} />
                                 )}
                              </TabPane>
                              <TabPane tabId="2">
                                 <div id="bar" class="progress mt-4">
                                    <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" style={{ width: '66%' }}></div>
                                 </div>
                                 <div className="col-12">
                                    <div className="page-title-box d-flex align-items-center justify-content-between">
                                       <h4 className="mb-0 font-size-18 mt-4">Add Staff</h4>
                                    </div>
                                 </div>
                                 {activeTab === '2' && (
                                    <StaffForm getvalues={getvalues} setforceExit={setforceExit} onChangeStep={() => { toggle('3') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} ref={staffRef} />
                                    // <CustomerWizardStep2 setforceExit={setforceExit} onChangeStep={() => { toggle('3') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} />
                                 )}

                              </TabPane>
                              <TabPane tabId="3">
                                 <div id="bar" class="progress mt-4">
                                    <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" style={{ width: '100%' }}></div>
                                 </div>
                                 {activeTab === '3' && (
                                    <ManageResident getvalues={getvalues} setforceExit={setforceExit} onChangeStep={() => { toggle('4') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} ref={reesidntRef} />
                                    // <CustomerWizardStep3 setforceExit={setforceExit} onChangeStep={() => { toggle('4') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} />
                                 )}



                              </TabPane>
                              {/* <TabPane tabId="4">
                           {activeTab === '4' && (
                                 <CustomerWizardStep4 onChangeStep={() => { toggle('4') }} currentStep={stepCounter} isWizard={true} activeTab={activeTab} />
                              )}

                              
                           </TabPane> */}
                           </TabContent>

                        </div>
                     </div>
                  </div>
               </div>

               {/* {currentView()} */}

            </div>
         </div>
      </>
   )
}

export default CustomerWizard