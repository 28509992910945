import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyResetPassword = React.lazy(() =>
   import('./resetPassword')
);

const ResetPassword = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyResetPassword {...props} />
   </React.Suspense>
);

export default ResetPassword