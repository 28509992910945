import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { decryptId, encryptId } from '../../components/form-elements/functions'
import ResidentForm from '../../components/resident/manage-resident/residentForm'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useResidentNameSelector } from '../../hooks/selectors/residentSelector'
import ResContactList from './resContactList'
import ResDocumentList from './resDocumentList'
import ResLiscenseList from './resLiscenseList'
import ResMedicationList from './resMedicationList'
import { useDispatch, useSelector } from 'react-redux'
import { ACTIONS } from '../../config/config'
import ResIncidentList from './resIncidentList'
import { propTypes } from 'cleave.js/react'
import { useResidentApi } from '../../hooks/api/residentApiHook';
import { getAge } from '../../components/form-elements/functions';
import moment from "moment";


//const ManageResident = (props) => {
const ManageResident = forwardRef((props, ref) => {

   const refe = React.useRef()

   const history = useHistory()
   const name = useResidentNameSelector()

   let id = decryptId(useParams().id)
   if (useParams().id && isNaN(id)) {
      toast.error('Invalid link')
      history.replace('/resident-list')
   }

   const [getAge, setAge] = useState("");
   const [getFacilityId, setFacilityId] = useState("");
   const [getCompanyId, setCompanyId] = useState("");
   const [resId, setResId] = useState('');
   const [isWizard, setWizard] = useState(props.isWizard)
   const [birthdateValue,setBirthdateValue]=useState('');
   const [residentNames,setResidentNames]=useState('');

   const activeTab = useSelector(state => state.extras && state.extras.residentTab)
   const dispatch = useDispatch()
   const residentApi = useResidentApi()

   const toggle = tab => {
      console.log(tab,"SET_RESIDENT_TAB", ACTIONS.RESIDENT.SET_RESIDENT_TAB);
      if (activeTab !== tab) dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: tab });
   }

   const submitDetails = (id) => {
      //setResId(id)
      //dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: "2" })
      // if(propTypes.isWizard){

      // }
      //alert(isWizard);
      if (isWizard === true) {
         console.log('redirectdredirectdredirectdredirectdredirectd', `/customer-wizard/${encryptId(id)}`);
         history.push(`/customer-wizard/${encryptId(id)}`)
      }
      else {
         history.push(`/manage-resident/${encryptId(id)}`)
      }

   }



   const calculateAge = (id) => {
      if(id){
      residentApi.residentDetailsForDOB({ id },
         (message, resp) => {
            let data = { ...resp, ...resp?.medical }

            console.log("datadatadatadatadatadatadatadatadatadata111111111111",data);
            setBirthdateValue(data?.birthDate)
            setResidentNames(`${data?.firstName} ${data?.lastName}`)
            var today = new Date();
            var birthDate = new Date(resp?.birthDate);
            var age = today.getFullYear() - birthDate?.getFullYear();
            var m = today.getMonth() - birthDate?.getMonth();
            // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
            // {
            setAge(age)
            //}
            setFacilityId(data?.facility?.id); 
            setCompanyId(data?.companyId);

         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such resident found')
               setTimeout(() => {
                  history.replace('/resident-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
      }
   }

   useEffect(() => {
      if (id) {
         setResId(id)
         calculateAge(id)
      }
   }, [id])

   const changeStep = (tab) => {
      dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: tab })
   }

   useImperativeHandle(ref, () => ({

      getAlert() {
        return refe.current.getAlert()
      }
  
    }));

    const getAges = (dateString) => {
      const date = moment(dateString, 'YYYY-MM-DD')
      const years = moment().diff(date, 'years')
      return years
   }

   return (
      <div className="page-content" >
         <div className="container-fluid">
            {/* start page title */}
            <div className="row">
               <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                     <h4 className="mb-0 font-size-18">{id ? `Edit details for ${residentNames || ''} (${birthdateValue ? getAges(birthdateValue) : ''} years)` : 'Add Resident'}</h4>
                  </div>
               </div>
            </div>

            <div className="row f_1">
               <div className="col-lg-12">
                  <div className="card">
                     <div className="card-body custom-wizard-mobile">
                        <Nav tabs>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '1' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('1'); }}
                              >1. Personal Info <i class="fa fa-info-circle" aria-hidden="true" title="Personal Information"></i></NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 disabled={resId === ''}
                                 className={`${activeTab === '2' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('2'); }}
                              >2. Medication <i class="fa fa-info-circle" aria-hidden="true" title="Medication"></i></NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 disabled={resId === ''}
                                 className={`${activeTab === '3' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('3'); }}
                              >3. Contacts <i class="fa fa-info-circle" aria-hidden="true" title="Contacts"></i></NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 disabled={resId === ''}
                                 className={`${activeTab === '4' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('4'); }}
                              >4. Documents <i class="fa fa-info-circle" aria-hidden="true" title="Documents"></i></NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 disabled={resId === ''}
                                 className={`${activeTab === '5' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('5'); }}
                              >5. Events <i class="fa fa-info-circle" aria-hidden="true" title="Events"></i></NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 disabled={resId === ''}
                                 className={`${activeTab === '6' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('6'); }}
                              >6. Incidents <i class="fa fa-info-circle" aria-hidden="true" title="Incidents"></i></NavLink>
                           </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                           <TabPane tabId="1">
                           {activeTab === '1' &&  <ResidentForm ref={refe} changeStep={() => changeStep('2')} setforceExit={props.setforceExit} userId={id} onChangeStep={props.onChangeStep} handleNext={submitDetails} isWizard={props.isWizard} activeTab={activeTab} />}
                           </TabPane>
                           <TabPane tabId="2">
                              {activeTab === '2' && <ResMedicationList changeStep={() => changeStep('3')} setforceExit={props.setforceExit} isWizard={props.isWizard} resId={resId} facilityId = {getFacilityId} tabId="2"/>}
                           </TabPane>
                           <TabPane tabId="3">
                              {activeTab === '3' && <ResContactList resId={resId} setforceExit={props.setforceExit} isWizard={props.isWizard} changeStep={() => changeStep('4')}  />}
                           </TabPane>
                           <TabPane tabId="4">
                              {activeTab === '4' && <ResDocumentList resId={resId} setforceExit={props.setforceExit} isWizard={props.isWizard} changeStep={() => changeStep('5')}  />}
                           </TabPane>
                           <TabPane tabId="5">
                              {activeTab === '5' && <ResLiscenseList resId={resId} setforceExit={props.setforceExit} isWizard={props.isWizard} changeStep={() => changeStep('6')}  />}
                           </TabPane>
                           <TabPane tabId="6">
                              {activeTab === '6' && <ResIncidentList setforceExit={props.setforceExit} isWizard={props.isWizard} resId={resId} facilityId = {getFacilityId} companyId = {getCompanyId} />}
                           </TabPane>
                        </TabContent>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}
)

export default ManageResident