import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { MODULES, PER_PAGE } from "../../../config/config";
import debounce from "lodash.debounce";
import { toast } from 'react-toastify';
import { AsyncSearch } from "../../form-elements/asyncSearch";
import { toCustomTitleCase } from "../../form-elements/functions";
import ReadCheckHOC from "../../../hoc/readCheckHoc";
import { useStaffApi } from "../../../hooks/api/staffApiHook";
import { useRoleApi } from "../../../hooks/api/roleApiHook";
import { useUserApi } from "../../../hooks/api/userApiHook";
import { useUserSelector } from '../../../hooks/selectors/userSelector';
import AsyncSelect from "react-select/async";
import CreatableSelect from 'react-select/creatable';



const DUMMY_WITNESS = {
  firstName: "",
  lastName: "",
  email: "",
  selected: { label: "", value: "" },
};

const schema = yup.object().shape({
  fullName: yup.string(),
  users: yup.array().of(
    yup.object().shape({
      selected: yup.object().shape({
        label: yup.string(),
        value: yup.string().required("Witness is required"),
      }),
    })
  ),
});

const MedWitnessEditForm = (props) => {

  console.log('props values', props)

  const [getRole, setRole] = useState([])
  const [userList, setUser] = useState([]);
  const [userLength, setUsersLength] = useState(false)
  const [getOptionValue, setOptionValue] = useState([])
  const [witnessLists, setWitnessList] = useState([])
  const [search, setSearch] = useState('')
  const [adminFacility, setAdminFacility] = useState(null);



  const { handleSubmit, errors, control, reset, setValue, register, watch } =
    useForm({
      mode: "onBlur",
      resolver: yupResolver(schema),
      defaultValues: {
        users: [],
      },
    });
  const formValues = watch();
  const user = useUserSelector()

  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  // Use States
  //   const [someVar, setSomeVar] = useState();

  // Use Hooks
  const staffApi = useStaffApi();
  const roleApi = useRoleApi();
  const userApi = useUserApi();
  // Use Effects
  useEffect(() => {
    console.log("useruseruseruseruseruseruser",user)
    let userObj = {
      id:user.id
    }
    if(userObj){
      userApi.getUser(
        userObj,
        (message, resp) => {
          console.log("getUsergetUsergetUsergetUsergetUser",resp?.role?.slug);
         setRole(resp?.role?.slug)
          
        },(message) => {
          console.log("messagemessagemessagemessage",message);
           //toast.error(message)
        }, 5000)
    }
    getwitnessList()
  }, []);

  const getwitnessList = () => {
    let params = { page: 1, limit: 99999, fullName: '', facilityId: props.facilityId };
    staffApi.witnessList(
      params,
      (message, resp) => {
        console.log("witnessList", resp)
        let items = resp?.data.map((staff) => ({
          ...staff,
          label: staff.email,
          value: staff.id,
        }));
        setUser(resp?.data);
        const adminFaci = resp?.data?.filter((user) => user?.multipleFacility[0]?.facility?.adminUserId != null);
        console.log(adminFaci[0]?.multipleFacility[0]?.facility?.adminUserId, user?.id);
        if (user?.id === adminFaci[0]?.multipleFacility[0]?.facility?.adminUserId) {
          console.log("if-part-witness-user")
          setAdminFacility(user?.id)
        }
      },
      (message) => { },
      false
    );
  }

  useEffect(() => {
    handleSearch()
    let temp = [];
    let temp123 = { ...props?.data }
    let tempp=[...temp123?.approval]
    temp = tempp.filter(appr => appr.status == 'Pending')
    
    if(temp?.length && temp?.length!=0){
      setUsersLength(true)
    }
    else{
      setUsersLength(false)
    }
    if(temp && temp?.length && temp?.length!=0){
            for(let i=0;i<=temp?.length;i++){
               if((temp[i]?.witness)){
                  temp[i]['selected'] = {label: temp[i]?.witness?.firstName + ' ' + temp[i]?.witness?.lastName[0].toUpperCase() + ' ' + '(' + temp[i]?.witness?.email + ')', value: temp[i].witness?.id, id: temp[i].witness?.id}
               }
              }
              if(temp){
                let widnessArray=[]
                widnessArray.push({'users':temp})
                let valueSettForDropdown=widnessArray[0]
                if(valueSettForDropdown){                  
                reset({
                  ...valueSettForDropdown
                })
              }
             }
    }
  }, [reset]);



  const getUserIdsByRoleId = (data) =>{

  }

  // Component Fucntions
  const onSubmit = (data) => {
    console.log("edit med form",user);
    var dataObj = Object.keys(data).length;
    if (dataObj > 0) {
      console.log(dataObj);
      var params = {
        medicationId: props.data.id,
        witness: data.users.map((user) => user.selected.id) || [],
      };
      props.onSubmit(params);
    }
    else {
      console.log("useruseruseruseruseruseruser", user, userList, adminFacility);
      const faciadmin = userList.filter((user) => user?.multipleFacility[0]?.facility.adminUserId != null);
      console.log(faciadmin[0]?.multipleFacility[0]?.facility.adminUserId, user.id);
      //phase-6-userList
      var userArr = [];
      if (faciadmin.length > 0) {
        userArr.push(faciadmin[0].multipleFacility[0].facility.adminUserId)
        if (userArr.length > 0) {
          var params = {
            medicationId: props.data.id,
            witness: userArr,
          };
          props.onSubmit(params);
        }
      } else {
        toast.error("Please add facility admin");
      }
      // phase-6-userList
    }

    /*var dataObj = Object.keys(data).length;

    
     
    if(dataObj > 0){
      console.log('ifff part');
      let WitnessArrPart=data?.users?.map((user) => user.selected.id) || []
      const removeDouplicateId = WitnessArrPart.filter((item,
        index) => WitnessArrPart.indexOf(item) === index)

    console.log('removeDouplicateId', removeDouplicateId)

    var params = {
        medicationId: props.data.id,
        witness: removeDouplicateId || [],
      };
      props.onSubmit(params);
    }
    else{

      console.log("useruseruseruseruseruseruser",user);

      var witnessIds = [];
      let managerRoleObj = {
        companyId:user.company.id,
        slug:"Default-Caregiver",
        slug2:"Default-Caregiver"
      }
  
      if(managerRoleObj){
        roleApi.getRoleDetails(
          managerRoleObj,
          (message, resp) => {
            let roleUserObj = {
              roleId:resp.data[0].id,
              companyId:user.company.id
            }
            if(roleUserObj){
              userApi.getUserByRoleId(
                roleUserObj,
                (message, userresp) => {
                  
                  var userArr = [];
                  var arrLength = userresp.data.length;
                  if(userresp.data.length>0){

                    for(var u=0; u<userresp.data.length; u++){
                      userArr.push(userresp.data[u].id)
                    }

                    if(arrLength == userArr.length){
                      var  params = {
                        medicationId: props.data.id,
                        witness: userArr,
                      };
                      props.onSubmit(params);
                    }
                    
                  }
                
                  
                },(umessage) => {
                  console.log("umessageumessageumessageumessage",umessage);
                   //toast.error(message)
                }, 5000)
            }
          },(message) => {
            console.log("messagemessagemessagemessage",message);
             //toast.error(message)
          }, 5000)
      }

    //   const searchFunc = useCallback(debounce(val => {
    //     handleSearch(val);
    //  }, 1500), []);

     

     


      // if(managerRoleObj){
      //   userApi.getRoleDetails(
      //     managerRoleObj,
      //     (message, resp) => {
      //       console.log("respresprespresprespresp",resp);
      //       var  params = {
      //         medicationId: props.data.id,
      //         witness: [resp.data[0].id,user.createdById],
      //       };
      //       console.log("paramsparamsparamsparamsparams",params);
      //       props.onSubmit(params);
      //     },(message) => {
      //       console.log("messagemessagemessagemessage",message);
      //        //toast.error(message)
      //     }, 5000)
      // }
     
     
    } */
   
   // console.log("paramsparamsparamsparamsparams",params);
   
  };

//   const handleSearch = debounce((search = "", callback) => {
//     if (search.length) {
//       let params = { page: 1, limit: PER_PAGE, fullName: search, userId: user.id };
//       staffApi.staffList(
//         params,
//         (message, resp) => {
//           let items = resp.data.map((staff) => ({
//             ...staff,
//             label: staff.email,
//             value: staff.id,
//           }));
//           callback(
//             items.filter(
//               (i) =>
//                 formValues.users.map((u) => u.selected.id).indexOf(i.id) === -1
//             )
//           );
//         },
//         (message) => {},
//         false
//       );
//     }
//   }, 1500);

const handleSearch = debounce((search = "", callback) => {
    staffApi.staffList({ page: 1, limit: PER_PAGE, userId: user.id, fullName: search },
       (message, resp) => {
          let temp = resp.data.map(item => { return {...item, label: `${item.email}`, value: item.id } })
          setWitnessList(temp)
       }, (message) => {
          //toast.error(message)
       })
 }, 1500)

 const typeAndSearch = (val) => {
     console.log('valll', val)   
 }

 const promiseOptions = debounce((search, callback) => {
  console.log("witness-new-api", props.facilityId)
  let params = { page: 1, limit: 99999, fullName: search, facilityId: props.facilityId };
  staffApi.witnessList(
    params,
    (message, resp) => {
      console.log("witnessList", resp)
      let items = resp.data.map((staff) => ({
        ...staff,
        label: staff.firstName + ' ' + staff.lastName[0].toUpperCase() + ' ' + '(' + staff.email +')',
        value: staff.id,
      })).filter((staff) => staff.id != user.id);
      callback(
        items.filter(
          (i) =>
            formValues.users.map((u) => u.selected.id).indexOf(i.id) === -1
        )
      );
    },
    (message) => { },
    false
  );
}, 1000);

  // const promiseOptions = debounce((search, callback) => {
  //   let params = { page: 1, limit: 99999, fullName: search, userId: user.id};
  //     staffApi.staffList(
  //       params,
  //       (message, resp) => {
  //         let items = resp.data.map((staff) => ({
  //           ...staff,
  //           label: staff.email,
  //           value: staff.id,
  //         }));
  //         callback(
  //                       items.filter(
  //                         (i) =>
  //                           formValues.users.map((u) => u.selected.id).indexOf(i.id) === -1
  //                       )
  //                     );
  //                   },
  //       (message) => {},
  //       false
  //     );
  // }, 1000);

  const onTypeChange =(type) =>{
    console.log('typessss', type)
  }

  // const removee = (index) =>{
  //   console.log("index", index, data)
  //   var dataObj = Object.keys(data).length;
  //   // if (dataObj > 0) {
  //   //   users.map((user) => user.selected.id)
  //   // }else{

  //   // }
  //   // users.map((user) => user.selected.id)
  // }

  return (
    <>
      <div className="row">
        <div className="col-lg-12"></div>
      </div>
      <div className="row">
        {/* {console.log({ formValues, errors })} */}
        <div className="col-lg-12">
          <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={false}>
              <div className="container-fluid p-0 d-flex">
                <div className="container p-0 d-flex flex-column">
                  <h4 className="title">Medication Details</h4>
                  <span>
                    <b>Name:&nbsp;</b>
                    {props?.data?.name || "N/A"}
                  </span>
                  <span>
                    <b>Strength:&nbsp;</b>
                    {props?.data?.strength || "N/A"}
                  </span>
                  <span>
                    <b>Units:&nbsp;</b>
                    {props?.data?.units || "N/A"}
                  </span>
                  <span>
                    <b>Frequency:&nbsp;</b>
                    {props?.data?.freq?.length ? props?.data?.freq.map((f, i) => i === 0 ? `${toCustomTitleCase(f.time)}` : `, ${toCustomTitleCase(f.time)}`) : "N/A"}
                  </span>
                  <span>
                    <b>Directions:&nbsp;</b>
                    {props?.data?.spDirections?.length
                      ? `${props.data.spDirections.substr(0, 25)}...`
                      : "N/A"}
                  </span>
                </div>
                <div className="submit-btn-container">
                  <ReadCheckHOC
                    module={MODULES.RESIDENT}
                    operation={
                      props.data && props.data.id ? "update" : "create"
                    }
                    isView={props.isView}
                  >
                    <button className="btn btn-success" type="submit">
                      Update
                    </button>
                  </ReadCheckHOC>
                </div>
              </div>
              
              {getRole != 'Default-Caregiver' && (
                <>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <h4 className="sub-title">Specify Witness {user.roles}</h4>
                </div>
                {fields.map((item, index) => (


                  <div
                    className="col-lg-12 d-flex justify-content-between align-items-end"
                    data-select2-id={1}
                  >
                    <div
                      className="form-group flex-grow-1 mt-4 mb-0"
                      data-select2-id={15}
                    >
                    {/* {console.log(promiseOptions)} */}


                      <Controller
                        defaultValue={item?.selected}
                        control={control}
                        name={`users[${index}].selected`}
                        render={({ ref, value, name, ...props }) => (
                          <AsyncSearch
                            inputRef={ref}
                            // options={handleSearch}
                            options={promiseOptions}
                            defaultOptions={true}
                            value={value}
                            onChange={(e) => props.onChange(e)}
                            isClearable={false}
                            label="Search Witness"
                            placeholder="Type here to search Witness"
                            error={errors?.users?.[index]?.selected?.value}
                            {...props}
                          />
                        //   <AsyncSearch
                        //     inputRef={ref}
                        //     options={promiseOptions}
                        //     value={value}
                        //     defaultOptions={true}
                        //     onChange={(e) => props.onChange(e)}
                        //     isClearable={false}
                        //     label="Search Witness"
                        //     placeholder="Type here to search Witness"
                        //     error={errors?.users?.[index]?.selected?.value}
                        //     {...props}
                        //   />
                    //     <CreatableSelect
                    //     name="city"
                    //     ref={ref}
                    //     onChange={(e) => props.onChange(e)}
                    //     options={witnessLists}
                    //     defaultOptions={witnessLists}
                    //     cacheOptions
                    //     value={value}
                    //     isClearable={false}
                    //         label="Search Witness"
                    //         placeholder="Type here to search Witness"
                    //         error={errors?.users?.[index]?.selected?.value}
                    //  />
                        )}
                      />
                    </div>
                    {fields.length > 1 && (
                      <div className="actions ml-2">
                        <span className="mb-2">&nbsp;</span>
                        <a
                          href="#"
                          className="btn btn-danger"
                          onClick={() => remove(index)}
                        >
                          <i className="fa fa-trash"></i>
                        </a>
                      </div>
                    )}
                  </div>
                ))}
              </div>
             
              <div className="row mt-2 d-flex justify-content-center">
                <a
                  href="#"
                  className="btn btn-outline-info"
                  onClick={() => append(DUMMY_WITNESS)}
                >
                  <i className="fa fa-plus mr-2"></i> Add Witness </a>
              </div>
              </>
               )}
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
};

export default MedWitnessEditForm;
