import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { decryptId } from '../../components/form-elements/functions'
import { MODULES, OPERATIONS } from '../../config/config'
import { useRoleApi } from '../../hooks/api/roleApiHook'
import { useUserPermissionsSelector } from '../../hooks/selectors/userSelector'
import { useUserSelector } from '../../hooks/selectors/userSelector';

const AssignPermission = () => {
   const [roledata, setRoleData] = useState({})
   const [data, setData] = useState({})
   const [name, setName] = useState('')
   const [isDefault, setDefault] = useState(false)
   const [checkedIds, setCheckedIds] = useState([])

   const history = useHistory()
   const roleApi = useRoleApi()
   const userPermissions = useUserPermissionsSelector()
   const user = useUserSelector()

   const id = useParams().id
   const type = useParams().type
   const rname = useParams().name
   const from = useParams().from
   
   useEffect(() => {
      console.log(type)
      // if(type == 'roleList'){
      //    getList()
      // }else{
      //    setPermissions({})
      // }
      setName(rname)
      setPermissions()
      if (id && isNaN(decryptId(id))) {
         toast.error('Invalid link')
         setTimeout(() => {
            history.replace('/role-list')
         }, 500);
      }
   }, [])

   // useEffect(() => {
   //    setPermissions()
   // }, [])

   const getList = (additional = {}) => {
      let params = { isList: 1, ...additional}
      roleApi.roleList(params,
         (message, resp) => {
            console.log(resp.data[0])
            setName(resp.data[0].name)
            setRoleData(resp.data[0])
            setPermissions(resp.data[0])
         }, (message) => { })
   }

   const setPermissions = () => {
      // roleApi.permissionsList(
      //       (message, resp) => {
      //       setData(makeGroup(resp))
      console.log(roledata)
      roleApi.permissionsListByRoleType({ roleId: decryptId(id), roleType: decryptId(type) },
         (message, resp) => {
            console.log(resp)
            setData(makeGroup(resp.permissions))
            if(from == 'roleList'){
               if(decryptId(type) == 2){
                  setCheckedIds(resp.permissions.filter((item) => item.admin === 'yes').map(item => item.id))

               }else if(decryptId(type) == 3){
                  setCheckedIds(resp.permissions.filter((item) => item.manager === 'yes').map(item => item.id))

               }else{
                  setCheckedIds(resp.permissions.filter((item) => item.caregiver === 'yes').map((item) => item.id))
               }
            }
            roleApi.rolePermissionsList({ roleId: decryptId(id) },
               (message, resp) => {
                  if(from != 'roleList'){
                     setName(resp.name)
                  }
                  setDefault(resp.isDefault)
                  if(from != 'roleList'){
                     setCheckedIds(resp.permissions.map(item => item.id))
                  }
               }, (message) => {

               })
         }, (message) => {

         })
   }

   const makeGroup = (resp) => {
      let ob = {}
      ob = resp.reduce(function (r, a) {
         r[a.module] = r[a.module] || [];
         r[a.module].push(a);
         return r;
      }, Object.create(null));
      console.log("rolePer",ob)
      return ob  
   }

   const setCheck = (module, op, isCheck) => {
      let tempId = data[module].find(item => item.operation === OPERATIONS.LIST)
      let listId = tempId ? tempId.id : ''

      let tempArr = [...checkedIds]
      if (op.operation !== OPERATIONS.LIST) {
         if (checkedIds.includes(op.id)) {
            tempArr = tempArr.filter(item => item !== op.id)
         }
         else {
            tempArr = [...tempArr, op.id]
         }
         if (listId && !tempArr.includes(listId)) {
            tempArr = [...tempArr, listId]
         }
      }
      else {
         if (!tempArr.includes(op.id)) {
            tempArr.push(op.id)
         }
         else {
            let arr = data[module].map(item => item.id)
            tempArr = tempArr.filter(item => !arr.includes(item))
         }
      }

      if (op.operation === OPERATIONS.UPDATE && isCheck === true) {
         let tempId = data[module].find(item => item.operation === OPERATIONS.READ).id
         if (!tempArr.includes(tempId)) {
            tempArr = [...tempArr, tempId]
         }
      }
      setCheckedIds(tempArr)
   }

   const getAllModule = (module) => {
      let modArr = data[module].map(item => item.id)
      return modArr.every(elem => checkedIds.includes(elem))
   }

   const checkAll = (module, isCheck) => {
      console.log(module, isCheck)
      let tempArr = [...checkedIds]
      let modArr = data[module]
      modArr.forEach(element => {
         if (isCheck) {
            if (!tempArr.includes(element.id)) {
               tempArr.push(element.id)
            }
         }
         else {
            if (tempArr.includes(element.id)) {
               tempArr.splice(tempArr.indexOf(element.id), 1)
            }
         }
      });
      setCheckedIds(tempArr)
   }

   const checkPermission = (operation) => {
      return userPermissions.some(item => item.module === MODULES.ROLES && item.operation === operation)
   }

   const savePermissions = () => {
      if (!checkedIds.length) {
         toast.error('Please choose at least one permission')
         return
      }
      if (!name.length) {
         toast.error('Please give a role name')
         return
      }

      let params = {
         role: parseInt(decryptId(id)),
         roleName: name,
         isDefault: isDefault,
         permissions: checkedIds
      }
      console.log(params)
      roleApi.assignPermissionsToRole(params,
         (message, resp) => {
            toast.success('Assigned permissions successfully')
            history.push('/role-list')
         },
         (message) => {
            toast.error('Could not assign permissions')
         })
   }

   return (
      <div className="page-content">
         <div className="container-fluid">
            <div className="row">
               <div className="col-lg-12">
                  <div className="form-group">
                     <label style={{ fontSize: '16px' }}>Role Name</label>
                     <input type="text" className="form-control w-50" value={name} onChange={e => setName(e.target.value)} placeholder="Role name" />
                  </div>
               </div>
               {/* <div className="col-lg-12 mt-2">
                  <div className="my-checkbox mb-3">
                     <div className="form-group custom_checkbox mb-3">
                        <input type="checkbox" className="mr-1" checked={isDefault} onChange={e => setDefault(e.target.checked)} id="default" />
                        <label htmlFor="default" className="ml-0"><span className="op">Set as default role</span></label>
                     </div>
                  </div>
               </div> */}
            </div>

            {(checkPermission(OPERATIONS.UPDATE) || checkPermission(OPERATIONS.READ)) && <div className="my-checkbox mb-3">
               <h4 className="font-size-18 mt-5 mb-4">Assign permissions to role</h4>
            </div>}

            {(checkPermission(OPERATIONS.UPDATE) || checkPermission(OPERATIONS.READ)) ?
               <div>
                  {Object.keys(data).length ?
                     Object.keys(data).map((module, i) =>
                        <div key={i} className="my-checkbox mb-3">
                           {module === 'contact' && (
                              <h4 className="underline">Reusable {module.replace('-', ' ')}</h4>
                           )}
                           {module != 'contact' && (
                              <h4 className="underline">{module.replace('-', ' ')}</h4>
                           )}
                           
                           {(data[module].length == 5 || module == 'pass-meds' || module == 'report' || module == 'approve-meds') && <div className="form-group custom_checkbox mb-3">
                              <input type="checkbox" onChange={(e) => checkAll(module, e.target.checked)} id={module} checked={getAllModule(module)} />
                              <label htmlFor={module}><span className="op">Full Control</span></label>
                           </div>}
                           <div className="row">
                              {data[module].length ? data[module].map((op, j) =>
                                 <div className={`${j <= 3 || j >= 4 ? 'col-sm-6' : ''}`} key={op.id}>
                                    <>
                                       <div className="form-group custom_checkbox">
                                          <input type="checkbox" id={op.id} value={op.id} checked={checkedIds.includes(op.id)} onChange={(e) => setCheck(module, op, e.target.checked)} />
                                          <label htmlFor={op.id}><span className="op">{op.operation}</span></label>
                                       </div>
                                    </>
                                 </div>
                              ) : null}
                           </div>
                        </div>
                     ) : <h6>No Permissions</h6>}
               </div> : null}

            {checkPermission(OPERATIONS.UPDATE) ?
               <button className="btn btn-primary waves-light my-4 px-4" onClick={savePermissions}>Save</button>
               : null}
         </div>
      </div>
   )
}

export default AssignPermission