import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyManageContact = React.lazy(() =>
   import('./manageContact')
);

const ManageContact = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyManageContact {...props} />
   </React.Suspense>
);

export default ManageContact