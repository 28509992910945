import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useEffect } from 'react/cjs/react.development'
import { ROLE_VALUE } from '../config/config'
import AuthLayout from '../hoc/authLayout'
import { useUserApi } from '../hooks/api/userApiHook'
import { useUserAuthSelector, useUserPermissionsSelector, useUserSelector } from '../hooks/selectors/userSelector'

const PrivateRoute = ({ component: Component, ...rest }) => {
   let userPermissions = useUserPermissionsSelector()
   let isAuthenticated = useUserAuthSelector()
   let user = useUserSelector()

   const userApi = useUserApi();
   const [getRole, setRole] = useState()

   // Use Effects

   return (
      <Route {...rest} render={props => {
         if (!isAuthenticated) {
            let next = rest && rest.location ? window.btoa(rest.location.pathname + rest.location.search + rest.location.hash) : ''
            let redirectPath = next.length ? `/login?next=${next}` : `/login`
            return <Redirect to={redirectPath} />
         }

         let hasPermissions = userPermissions.some(item => item.module === rest.module && rest.operation.includes(item.operation))

         // check if route is restricted by role

         // if (rest.module && rest.operation && !hasPermissions) {
         //    toast.error("You don't have permission")
         //    return <Redirect to='/dashboard' />
         // }

         // Approval list page is only for staffs
         //if ( rest.path && rest.path.includes('approval-list') && user.roles !== 'staff' ) {
         // if ( rest.path && rest.path.includes('approval-list') && user.roles === 'admin' ) {   
         //    console.log("Rollllllllllllllllllllllllllllll after routing",user.roles);
         //    toast.error("You don't have permission")
         //    return <Redirect to='/dashboard' />
         // }

         if (rest.hideLayout) {
            return (
               <Component {...props} />
            )
         }

         else {
            return (
               <AuthLayout>
                  <Component {...props} />
               </AuthLayout>
            )
         }
      }}
      />
   )
}

export default PrivateRoute