import React from 'react'
import ProfileForm from '../../components/profile/profileForm'
import ChangePasswordForm from '../../components/profile/changePassword'

const Profile = () => {
   return (
      <div className="page-content">
         <div className="container-fluid">
            {/* start page title */}
            <div className="row">
               <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                     <h4 className="mb-0 font-size-18">My Profile</h4>
                  </div>
               </div>
            </div>

            <ProfileForm />
            <div className="page-title-box d-flex align-items-center justify-content-between pb-0 mb-3 mt-2">
               <h4 className="mb-0 mt-3 font-size-18">Change Password</h4>
            </div>
            <ChangePasswordForm />
         </div>
      </div>
   )
}

export default Profile