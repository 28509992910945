import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useMedicationApi() {
   const callApi = useApiCall();

   const addMedication = (data, onSuccess, onError) => {
      console.log("MEDICATIONMEDICATIONdatadatadatadatadata", data)
      callApi(SAGA_ACTIONS.MEDICATION.ADD_MEDICATION, data, onSuccess, onError);
   }

   const getMedication = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.GET_MEDICATION, data, onSuccess, onError);
   }

   const updateMedication = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.UPDATE_MEDICATION, data, onSuccess, onError);
   }

   const deleteMedication = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.DELETE_MEDICATION, data, onSuccess, onError);
   }

   const listMedication = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST, data, onSuccess, onError);
   }

   const typeListMedication = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.TYPE_MEDICATION_LIST, data, onSuccess, onError);
   }

   const listMedicationForAllInventory = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST_FOR_ALL_INVENTORY, data, onSuccess, onError);
   }

   const listMedicationForAll = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.MEDICATION_LIST_FOR_ALL, data, onSuccess, onError);
   }

   const updatesMedications = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.UPDATES_MEDICATIONS, data, onSuccess, onError);
   }

   const updateRxStatus = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.UPDATE_RX_STATUS, data, onSuccess, onError);
   }

   const getRxMedPassDetails = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.GET_RX_DETAILS, data, onSuccess, onError);
   }

   const approveMedicationRx = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.MEDICATION.APPROVE_MEDICATION_RX, data, onSuccess, onError);
   }

   return {
      addMedication,
      deleteMedication,
      updateMedication,
      getMedication,
      listMedication,
      typeListMedication,
      updatesMedications,
      listMedicationForAll,
      listMedicationForAllInventory,
      updateRxStatus,
      getRxMedPassDetails,
      approveMedicationRx
   }
}