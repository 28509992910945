import { call } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS } from '../config/config';

export function* addResuableContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.REUSABLE_CONTACT.CREATE_REUSABLE_CONTACT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* resuableContactList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.REUSABLE_CONTACT.LIST_REUSABLE_CONTACT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteResuableContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.REUSABLE_CONTACT.UPDATE_DELETE_REUSABLE_CONTACT + data.id + '/' + data.withForce, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateResuableContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.REUSABLE_CONTACT.UPDATE_DELETE_REUSABLE_CONTACT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getResuableContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.REUSABLE_CONTACT.GET_REUSABLE_CONTACT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listContactTypes(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.REUSABLE_CONTACT.LIST_CONTACT_TYPES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}