import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

const ImageModal = (props) => {

   return (
      <Modal centered isOpen={props.isOpen}>
         <ModalHeader>Preview Image</ModalHeader>
         <ModalBody className="d-flex justify-content-center">
            {props.url ? <img src={props.url} style={{ maxWidth: '100%', maxHeight: '200px' }}/> : null}
         </ModalBody>
         <ModalFooter className="justify-content-between">
            <button className="btn btn-danger w-25" onClick={props.close}>Discard</button>
            <button className="btn btn-primary w-25" onClick={props.onSave}>Save</button>
         </ModalFooter>
      </Modal>
   )
}

export default ImageModal