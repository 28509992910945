import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyFacilityList = React.lazy(() =>
   import('./facilityList')
);

const FacilityList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyFacilityList {...props} />
   </React.Suspense>
);

export default FacilityList