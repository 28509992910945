import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyForgotPassword = React.lazy(() =>
   import('./forgotPassword')
);

const ForgotPassword = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyForgotPassword {...props} />
   </React.Suspense>
);

export default ForgotPassword