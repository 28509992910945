import { ACTIONS } from '../../config/config';

const initialState = {
   contacts: [],
   liscences: [],
   documents: []
};

const contactReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.CONTACTS.LIST_CONTACTS:
         return {
            ...state,
            contacts: action.payload.length ? action.payload : []
         }
      case ACTIONS.LISCENCE.LIST_LISCENCE:
         return {
            ...state,
            liscences: action.payload.length ? action.payload : []
         }
      case ACTIONS.DOCUMENTS.LIST_DOCUMENTS:
         return {
            ...state,
            documents: action.payload.length ? action.payload : []
         }
      default:
         return state
   }
}

export default contactReducer