import React, { useEffect, Suspense } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import StaffForm from '../../components/staff/staffForm'
import SuspenseLoader from '../../common/suspenseLoader'
import { decryptId, encryptId } from '../../components/form-elements/functions'
import { toast } from 'react-toastify'
import RoleCheckHOC from '../../hoc/roleCheckHoc'
import { MODULES, OPERATIONS } from '../../config/config'

const EmergencyList = React.lazy(() => import('./emergencyList'))
const LiscenseList = React.lazy(() => import('./liscenseList'))
const DocumentList = React.lazy(() => import('./documentList'))

const ManageStaff = (props) => {
   const history = useHistory()
   const isView = props.location.pathname.includes('view-staff')
   const { id } = useParams()

   useEffect(() => {
      if (id && isNaN(decryptId(id))) {
         toast.error('Invalid link')
         setTimeout(() => {
            history.replace('/staff-list')
         }, 500);
      }
   }, [])

   return (
      <div className="page-content">
         <div className="container-fluid">
            {/* start page title */}
            <div className="row">
               <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                     <h4 className="mb-0 font-size-18">{isView ?
                        'View' : id ? 'Edit' : 'Add'} Staff</h4>
                     {isView ? <Link className="btn btn-primary waves-light mb-3" to={`/manage-staff/${id}`}>Edit Staff</Link> : null}
                  </div>
               </div>
            </div>

            <StaffForm userId={decryptId(id)} isView={isView} />

            {id ? <div className="row f_1">
               <div className="col-lg-8">
                  <div className="card">
                     <div className="card-body">
                        <div className="row">
                           <div className="col-lg-12" data-select2-id={12}>
                              <div id="accordion">
                                 <RoleCheckHOC module={MODULES.STAFF_CONTACT} operation={[OPERATIONS.LIST]}>
                                    <Suspense fallback={<SuspenseLoader />}>
                                       <EmergencyList staffId={decryptId(id)} isView={isView} />
                                    </Suspense>
                                 </RoleCheckHOC>
                                 <RoleCheckHOC module={MODULES.STAFF_LISCENSE} operation={[OPERATIONS.LIST]}>
                                    <Suspense fallback={<SuspenseLoader />}>
                                       <LiscenseList staffId={decryptId(id)} isView={isView} />
                                    </Suspense>
                                 </RoleCheckHOC>
                                 <RoleCheckHOC module={MODULES.STAFF_DOCUMENT} operation={[OPERATIONS.LIST]}>
                                    <Suspense fallback={<SuspenseLoader />}>
                                       <DocumentList staffId={decryptId(id)} isView={isView} />
                                    </Suspense>
                                 </RoleCheckHOC>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> : null}
         </div>
      </div>
   )
}

export default ManageStaff