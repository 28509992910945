import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { decryptId, timeName } from '../../components/form-elements/functions'
import PassMedForm from '../../components/pass-meds/passMedForm'

const DispenseMeds = () => {
   const { resId, time } = useParams()
   const history = useHistory()

   useEffect(() => {
      if (isNaN(decryptId(resId))){
         toast.error('Invalid Link')
         setTimeout(() => {
            history.goBack()
         }, 1000);
      }
   }, [])

   return (
      <div className="page-content">
         <div className="container-fluid">
            {/* start page title */}
            
            <div className="row">
               <div className="col-12">
                  <div className="page-title-box d-flex align-items-center justify-content-between pb-2">
                     <h4 className="mb-0 font-size-18">Dispense Meds</h4>
                     <h4 className="mb-0 font-size-18">{timeName(time)}</h4>
                  </div>
               </div>
            </div>
            <div className="row f_1">
               <div className="col-lg-12">
                  <div className="card shadow-none">
                     <div className="card-body p-0">
                        <PassMedForm
                           resId={decryptId(resId)}
                           type={time}
                        />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DispenseMeds