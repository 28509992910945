import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment';
import { useRoleApi } from '../../hooks/api/roleApiHook';
import RoleCheckHOC from '../../hoc/roleCheckHoc';
import { MODULES, OPERATIONS } from '../../config/config';
import SweetAlert from 'react-bootstrap-sweetalert';
import { encryptId } from '../../components/form-elements/functions';
import { useStaffApi } from '../../hooks/api/staffApiHook';
import { useUserApi } from '../../hooks/api/userApiHook';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import debounce from 'lodash.debounce';
import { useUserPermissionsSelector } from '../../hooks/selectors/userSelector';
import { useUserSelector } from '../../hooks/selectors/userSelector';

const columns = [{
   dataField: 'id',
   text: '',
   hidden: true
}, {
   dataField: 'name',
   text: 'Role Name',
   filter: textFilter(),
   headerStyle: () => { return { minWidth: '140px' } },
   sort: true,
   formatter: (cell, row) => <div className="align-between flex-wrap">
      <span>{cell}</span> {row.isDefault ? <span className="text-primary mt-2">(default)</span> : null}
   </div>
}, {
   dataField: 'createdAt',
   text: 'Created On',
   filter: textFilter(),
   headerStyle: () => { return { minWidth: '140px' } },
   sort: true,
   formatter: (cell, row) => moment(cell).format('MM-DD-YYYY')
}]

const RoleList = () => {
   const [data, setData] = useState([])
   const [editData, setEditData] = useState({})
   const [viewModal, setViewModal] = useState(false)
   const [modal, setModal] = useState(false);
   const [alert, setAlert] = useState(null);
   const [variables, setVariables] = useState({})
   const [cols, setCols] = useState([])
   const [total, setTotal] = useState(0)

   const history = useHistory()
   const staffApi = useStaffApi()
   const roleApi = useRoleApi()
   const userPermissions = useUserPermissionsSelector()

   useEffect(() => {
      getList()
      columnSet()
   }, [])

   useEffect(() => {
      columnSet()
   }, [variables])

   const toggle = () => {
      if (modal) {
         setModal(false)
         setEditData({})
      }
      else {
         setModal(true)
      }
   }

   const getList = (additional = {}) => {
      let params = { isList: 1, ...additional }

      roleApi.roleList(params,
         (message, resp) => {
            setTotal(resp.total)
            setData(resp.data)
         }, (message) => { })
   }

   const confirmRole = (role) => {
      let found = data.find(item => item.isDefault === true)
      if (found && editData.id !== found.id && role.isDefault === true) {
         setAlert(
            <SweetAlert
               warning
               showCancel
               confirmBtnText="Continue!"
               confirmBtnBsStyle="danger"
               title="Save and set as default?"
               onConfirm={() => saveRole(role)}
               onCancel={() => setAlert(null)}
               focusCancelBtn
            >
               Are you sure?
         </SweetAlert>
         )
      }
      else {
         saveRole(role)
      }
   }

   const saveRole = (role) => {
      setAlert(null)
      let params = {
         name: role.name,
         isDefault: role.isDefault,
         roleType: role.roleType
      }
      if (editData.id) {
         params['id'] = editData.id
      }
      roleApi.addEditRole(params,
         (message, resp) => {
            toggle()
            // getList()
            // setData({})
            // setTimeout(() => {
               history.push(`/assign-permission/${encryptId(resp.id)}/${encryptId(resp.type)}/${resp.name}/roleList`)
            // }, 500);
            toast.success(`Role ${editData.id ? 'updated' : 'added'} successfully`)
         },
         (message) => {
            toast.error(message)
         })
   }

   const deleteRoleCheck = (id, name) => {
      let params = { page: 1, limit: 30, name }

      staffApi.staffList(params,
         (message, resp) => {
            if (resp.data.length) {
               let users = resp.data.length && resp.data.map(item => item.firstName + ' ' + item.lastName)
               setAlert(
                  <SweetAlert
                     warning
                     confirmBtnText="Close"
                     confirmBtnBsStyle="info"
                     title="Assigned Users"
                     onConfirm={() => setAlert(null)}
                     onCancel={() => setAlert(null)}
                     focusCancelBtn={false}
                  >
                     Unassign role from the following users:
                     <ul className="my-4 pl-3 text-left" style={{ listStyleType: 'none' }}>{users.map((item, i) => <li key={i}>{i + 1}. {item}</li>)}</ul>
                  </SweetAlert>
               )
            }
            else {
               setAlert(
                  <SweetAlert
                     warning
                     showCancel
                     confirmBtnText="Continue!"
                     confirmBtnBsStyle="danger"
                     title="Delete Role?"
                     onConfirm={() => confirmDeleteRole(id)}
                     onCancel={() => setAlert(null)}
                     focusCancelBtn
                  >
                     Are you sure?
            </SweetAlert>
               )
            }
         }, (message) => { })
   }

   const confirmDeleteRole = (id) => {
      setAlert(null)
      roleApi.deleteRole({ id },
         (message, resp) => {
            toast.success('Role deleted successfully')
            getList(variables)
         }, (message) => {
            toast.error(message)
         })
   }

   const openViewModal = (id) => {
      roleApi.rolePermissionsList({ roleId: id },
         (message, resp) => {
            let ob = {}
            ob = resp.permissions.reduce(function (r, a) {
               r[a.module] = r[a.module] || [];
               r[a.module].push(a);
               return r;
            }, Object.create(null));
            setEditData({ groups: ob, name: resp.name })
            setViewModal(true)
         }, (message) => {

         })
   }

   const columnSet = () => {
      setCols([...columns, {
         dataField: 'action',
         isDummyField: true,
         style: { minWidth: '130px' },
         text: 'Action',
         formatter: (cell, row) => (
            <div>
               <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.READ]}>
                  <i className="fa fa-eye mr-3" onClick={(e) => { e.stopPropagation(); openViewModal(row.id) }} data-toggle="tooltip" title="View Role" />
               </RoleCheckHOC>
               <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.UPDATE]}>
                  <i className="fa fa-edit mr-3" onClick={(e) => { e.stopPropagation(); history.push(`/assign-permission/${encryptId(row.id)}/${encryptId(row.type)}`) }} data-toggle="tooltip" title="Edit Role" />
               </RoleCheckHOC>
               {/* <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.UPDATE]}>
                  <i className="fa fa-edit mr-3" onClick={(e) => { e.stopPropagation(); history.push(`/assign-permission/${encryptId(row.id)}`) }} data-toggle="tooltip" title="Edit Role" />
               </RoleCheckHOC> */}
               <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.DELETE]}>
                  <i className="fa fa-trash" data-toggle="tooltip" title="Delete Role" onClick={(e) => { e.stopPropagation(); deleteRoleCheck(row.id, row.name) }} />
               </RoleCheckHOC>
            </div>
         )
      }])
   }

   const handleTableChange = debounce((type, { filters, sortField, sortOrder, }) => {
      let searchObj = {}
      for (const dataField in filters) {
         searchObj[dataField] = filters[dataField].filterVal
      }
      if (sortField && sortOrder) {
         searchObj['sort_type'] = sortOrder.toUpperCase()
         searchObj['sort'] = sortField
      }
      setVariables(searchObj)
      setData([])
      setTimeout(() => {
         getList(searchObj)
      }, 1500);
   }, 1500)

   const checkPermission = (operation) => {
      return userPermissions.some(item => item.module === MODULES.ROLES && item.operation === operation)
   }

   const rowEvents = {
      onClick: (e, row, rowIndex) => {
         if (checkPermission(OPERATIONS.READ)) {
            openViewModal(row.id)
         }
      }
   }

   return (
      <>
         {alert}
         <div className="page-content">
            <div className="container-fluid">
               {/* start page title */}
               <div className="row">
                  <div className="col-12">
                     <div className="page-title-box d-flex align-items-center justify-content-between">
                        <h4 className="mb-0 font-size-18">Role List</h4>
                        <RoleCheckHOC module={MODULES.ROLES} operation={[OPERATIONS.CREATE]}>
                           <div className="page-title-right">
                              <button type="button" className="btn btn-primary waves-light" onClick={toggle}><i className="bx bx-plus" /> &nbsp;Add Role </button>
                           </div>
                        </RoleCheckHOC>
                     </div>
                  </div>
               </div>
               {/* end page title */}

               <div className="row manage_res">
                  <div className="col-12">
                     <div className="card">
                        <div className="card-body">
                           <div className="table-responsive simple-data-table">
                              {cols.length ?
                                 <BootstrapTable
                                    keyField='id'
                                    hover
                                    rowEvents={rowEvents}
                                    remote={{ filter: true, sort: true }}
                                    filter={filterFactory()}
                                    onTableChange={handleTableChange}
                                    data={data}
                                    columns={cols}
                                    noDataIndication={() => !data.total || data && data.total === 0 ? <p>No results found</p> : <p>Loading...</p>}
                                 /> : null}
                           </div>
                           <div className="row">
                              <div className="col-lg-6">
                                 <p>Total results: {total}</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <AddEditModal
               isOpen={modal}
               editData={editData}
               close={toggle}
               save={confirmRole}
            />
            <ViewModal
               isOpen={viewModal}
               editData={editData}
               close={() => { setViewModal(); setEditData({}) }}
            />
         </div>
      </>
   )
}

export default RoleList

const AddEditModal = (props) => {
   const [edit, setEdit] = useState(false)
   const [data, setData] = useState({ name: '', roleType: 0, isDefault: false })
   const [isActive, setActive] = useState()
   const [detailsValue, setDetailsValue] = useState();

   const userApi = useUserApi()
   const user = useUserSelector()

   useEffect(() => {
      getUserDetails()
      
      if (props.editData.name) {
         setData({ name: props.editData.name, roleType:  props.editData.roleType, isDefault: props.editData.isDefault })
         setEdit(true)
      }
      else {
         setEdit(false)
         setData({ name: '', roleType: 0, isDefault: false })
      }
   }, [props.editData])


   const getUserDetails = () => {
      userApi.getUser({ id: user.id },
         (message, resp) => {
            console.log(resp)
            setDetailsValue(resp)
         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such staff found')
            }
            else {
               // toast.error(message)
            }
         })
   }

   const save = () => {
      if (!data.name) {
         toast.error('Please type role name before proceeding')
      }
      else if (data && data.name.length > 20) {
         toast.error('Max 20 characters allowed')
      }
      else if (data && data.roleType == 0) {
         toast.error('Please select a role type before proceeding')
      }
      else {
         props.save(data)
      }
      console.log("roledata",data)
   }

   const handleChange = (e) => {
      setData({ ...data, name: e.target.value })
   }

   const setCheck = () => {
      setData({ ...data, isDefault: !data.isDefault })
   }

   const handleRadio = (item) => {
      console.log(item)
      setActive(item)
      setData({ ...data, roleType: item })
   }

   return (
      <div>
         <Modal isOpen={props.isOpen} centered>
            <ModalHeader toggle={props.close}>{edit ? 'Edit' : 'Add'} Role</ModalHeader>
            <ModalBody>
               <div className="form-group">
                  <label>Role Name</label>
                  <input type="text" className="form-control" onChange={handleChange} value={data.name} maxLength={20} />
               </div>
               <div className="row manage_res">
               {((detailsValue?.roles == 'user') || (detailsValue?.roles == 'staff' && detailsValue?.role?.type == 2)) && <div className='col-4'>
                  <div class="form-check">
                     <input class="form-check-input" type="radio" name="data." id="formRadios1" onChange={() => handleRadio(2)} checked={isActive === 2} />
                     <label class="form-check-label" htmlFor="formRadios1">
                        Admin
                     </label>
                  </div>
               </div>}
               {((detailsValue?.roles == 'user') || (detailsValue?.roles == 'staff' && (detailsValue?.role?.type == 2 || detailsValue?.role?.type == 3))) &&  <div className="col-4">
                     <div className="form-check">
                        <input className="form-check-input" type="radio" name="formRadios" id="formRadios2" onChange={() => handleRadio(3)} checked={isActive === 3} />
                        <label className="form-check-label" htmlFor="formRadios2">
                           Manager
                        </label>
                     </div>
               </div>}
               <div className="col-4">
                  <div className="form-check">
                     <input className="form-check-input" type="radio" name="formRadios" id="formRadios3" onChange={() => handleRadio(4)} checked={isActive === 4} />
                     <label className="form-check-label" htmlFor="formRadios3">
                        Caregiver
                     </label>
                  </div>
               </div>
            </div>
            {/* phase-6 */}
               {/* <div className="form-group">
                  <input type="checkbox" className="mr-1" checked={data.isDefault} onChange={setCheck} /> <span>Set as default role</span>
               </div> */}
               
            </ModalBody>
            <ModalFooter>
               <button className="btn btn-primary waves-light" onClick={save}>Save</button>
            </ModalFooter>
         </Modal>
      </div>
   );
}

const ViewModal = (props) => {
   const data = props.editData && props.editData.groups ? props.editData.groups : {}
   return (
      <Modal isOpen={props.isOpen} centered>
         <ModalHeader toggle={props.close}>Permissions for {props.editData.name || ''}</ModalHeader>
         <ModalBody>
            {Object.keys(data).length ?
               Object.keys(data).map((module, i) =>
                  <div key={i} className="mb-4">
                     {module === 'contact' && (
                        <h5 style={{ textTransform: 'capitalize' }}>Reusable {module.replace('-', ' ')}</h5>
                        )}
                     {module != 'contact' && (
                        <h5 style={{ textTransform: 'capitalize' }}>{module.replace('-', ' ')}</h5>
                     )}
                     {/* <h5 style={{ textTransform: 'capitalize' }}>{module.replace('-', ' ')}</h5> */}
                     {data[module].length ? data[module].map((op, j) => op.operation 
                     ).join(', ') : null}
                  </div>
               ) : <h6>No Permissions</h6>}
         </ModalBody>
      </Modal >
   )
}