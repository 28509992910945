import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './store';
import { STORAGE, APIS, ACTIONS } from './config/config';
import axios from 'axios'
import * as serviceWorker from './serviceWorker';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import "react-datepicker/dist/react-datepicker.css";

function render() {
   ReactDOM.render(
      <Provider store={store}>
         <>
            <ToastContainer autoClose={5000} pauseOnHover={false} />
            <App />
         </>
      </Provider>,
      document.getElementById('root')
   );
}

const token = localStorage.getItem(STORAGE);

if (token) {
   try {
      axios({
         method: 'GET',
         url: APIS.USER.ME,
         headers: {
            Authorization: `Bearer ${token}`,
         },
      })
         .then(success => {
            store.dispatch({
               type: ACTIONS.USER.LOGIN,
               payload: {
                  token,
               },
            });
            store.dispatch({
               type: ACTIONS.USER.ME,
               payload: success.data,
            });
            axios({
               method: 'GET',
               url: APIS.USER.USER_PERMISSIONS,
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            }).then(success => {
               store.dispatch({
                  type: ACTIONS.USER.USER_PERMISSION,
                  payload: success.data,
               });
               render()
            }).catch(err => {
               render()
            })
         })
         .catch(error => {
            render();
         });
   } catch (e) {
      render();
   }
} else {
   render();
}

serviceWorker.unregister();
