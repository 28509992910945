import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';

const columns = [{
   dataField: 'id',
   text: '',
   hidden: true
}, {
   dataField: 'type',
   text: 'Type',
   sort: true
}, {
   dataField: 'enteredDate',
   text: 'Entered Date',
   sort: true,
   style: {minWidth: '120px'},
   formatter: (cell, row) => row.enteredDate && moment(row.enteredDate).format('MM-DD-YYYY')
}, {
   dataField: 'dueDate',
   text: 'Due Date/\nLifetime',
   sort: true,
   style: {minWidth: '120px'},
   formatter: (cell, row) => row.dueDate ? moment(row.dueDate).format('MM-DD-YYYY') : 'Lifetime'
}, {
   dataField: 'reminder',
   text: 'Reminder',
   sort: true,
   formatter: (cell, row) => row.reminder ? 'yes' : 'no'
}];

const LiscenseTab = (props) => {
   const [modal, setModal] = useState(false)
   const [modalData, setModalData] = useState([])
   const [cols, setCols] = useState([])

   const history = useHistory()

   useEffect(() => {
      setSearch()
      columnSet()
   }, [])

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#resLiscViewInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$(".resLiscViewTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
            document.querySelectorAll('.no-res').forEach(e => e.remove());
            if (window.$('.resLiscViewTable tr:visible').length == 0) {
               let table = document.getElementsByClassName("resLiscViewTable")[0]
               let row = table.insertRow(0)
               row.className = "no-res"
               let cell = row.insertCell(0);
               cell.colSpan = "3"
               cell.className = "no-td"
               cell.innerHTML = "No results found"
            }
         });
      });
   }

   const columnSet = () => {
      setCols([...columns,
      {
         dataField: 'actions',
         isDummyField: true,
         text: 'Action',
         formatter: (cell, row) => (<i className="fa fa-eye cursor-pointer" onClick={() => { setModal(true); setModalData(row) }} />)
      }
      ])
   }

   const rowEvents = {
      onClick: (e, row, rowIndex) => {
         setModal(true); setModalData(row)
      }
    };

   return (
      <div className="mt-3 p-0 pb-3 details-wrap">
         <input className="form-control mb-3" id="resLiscViewInput" type="text" placeholder="Search.." />

         <div className="table-responsive simple-data-table">
            {cols.length ?
               <BootstrapTable
                  keyField='id'
                  data={props.data}
                  columns={cols}
                  bodyClasses="resLiscViewTable"
                  rowEvents={rowEvents}
                  noDataIndication={() => <p>No results found</p>}
               /> : null}
         </div>
         <ViewLiscense
            isOpen={modal}
            close={() => setModal(false)}
            data={modalData}
         />
         <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
            <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
         </div>
      </div>
   )
}

export default LiscenseTab

const ViewLiscense = (props) => {
   return (
      <Modal centered isOpen={props.isOpen} size='md'>
         <ModalHeader toggle={props.close}>License/Events</ModalHeader>
         <ModalBody>
            <div className="row">
               <div className="col-lg-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Type</label>
                     <p className="detail-text">{props.data.type || ''}</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-12">
                  <div className="form-group">
                     <label className="form-label">Description</label>
                     <p className="detail-text">{props.data.description || ''}</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Entered Date</label>
                     <p className="detail-text">{props.data.enteredDate && moment(props.data.enteredDate).format('MM-DD-YYYY')}</p>
                  </div>
               </div>
               <div className="col-6">
                  <div className="form-group">
                     <label className="form-label">Due Date</label>
                     <p className="detail-text">{props.data.dueDate ? moment(props.data.dueDate).format('MM-DD-YYYY') : 'Lifetime'}</p>
                  </div>
               </div>
            </div>
            <div className="row">
               <div className="col-lg-12" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Reminder</label>
                     <p className="detail-text">{props.data.reminder ? 'Yes' : 'No'}</p>
                  </div>
               </div>
            </div>
            {props.data.reminder && <div className="row">
               <div className="col-lg-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Duration</label>
                     <p className="detail-text">{props.data.duration || ''}</p>
                  </div>
               </div>
               <div className="col-lg-6" data-select2-id={1}>
                  <div className="form-group" data-select2-id={15}>
                     <label className="form-label">Duration Type</label>
                     <p className="detail-text">{props.data.duration_type || ''}</p>
                  </div>
               </div>
            </div>}
         </ModalBody>
      </Modal>
   )
}