import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyVerifyEmail = React.lazy(() =>
   import('./verifyEmail')
);

const VerifyEmail = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyVerifyEmail {...props} />
   </React.Suspense>
);

export default VerifyEmail