import React from 'react'
import { useHistory } from 'react-router-dom'

const MedicalTab = (props) => {
   const history = useHistory()

   const { details } = props
   
   return (
      <div className="mt-3 p-0 pb-3 details-wrap">
         <div className="row">
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Insurance Provider</label>
                  <p className="detail-text">{details && details.insuranceProvider ? details.insuranceProvider : 'N/A'}</p>
               </div>
            </div>
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Account Number</label>
                  <p className="detail-text">{details && details.accountNo ? details.accountNo : 'N/A'}</p>
               </div>
            </div>
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Group Number</label>
                  <p className="detail-text">{details && details.groupNo ? details.groupNo : 'N/A'}</p>
               </div>
            </div>
         </div>

         <div className="row">
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Medicare</label>
                  <p className="detail-text">{details && details.medicare ? details.medicare : 'N/A'}</p>
               </div>
            </div>
            
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Medical</label>
                  <p className="detail-text">{details && details.medical ? details.medical : 'N/A'}</p>
               </div>
            </div>
            <div className="col-lg-4 col-6">
               <div className="form-group">
                  <label className="form-label">Physician</label>
                  <p className="detail-text">{details && details.physician ? details.physician : 'N/A'}</p>
               </div>
            </div>
         </div>

         <div className="row">
            <div className="col-lg-12 col-12">
               <div className="form-group">
                  <label className="form-label">Medical Conditions</label>
                  {details && details.medicalCond ? <div dangerouslySetInnerHTML={{ __html: details.medicalCond }} style={{ overflow: 'auto' }} /> : <p className="detail-text">N/A</p>}
               </div>
            </div>
         </div>

         <div className="row">
            <div className="col-lg-12 col-12">
               <div className="form-group">
                  <label className="form-label">Care Plan</label>
                  {details && details.medicalCond ? <div dangerouslySetInnerHTML={{ __html: details.carePlan }} style={{ overflow: 'auto' }} /> : <p className="detail-text">N/A</p>}
               </div>
            </div>
         </div>
         <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
            <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
         </div>
      </div>
   )
}

export default MedicalTab