import React from "react";

export const RadioGroup = ({ inputref, value, error, options, ...props }) => {
   return (
      <>
         <label className="control-label d-lg-block">{props.label}</label>
         <div>{options.map((opt, i) =>
            <div className="custom-control custom-radio custom-control-inline mb-3" key={i}>
               <input
                  type="radio"
                  checked={value == opt.value}
                  value={opt.value}
                  id={props.name + i}
                  className="custom-control-input"
                  onChange={(evt) => {
                     props.onChange(evt.target.value)
                  }}
                  {...props}
               />
               <label className="custom-control-label" htmlFor={props.name+i}>{opt.label}</label>
            </div>)} </div>
         {error?.message ? <div className="invalid-feedback">{error?.message}</div> : null}
      </>
   )
}