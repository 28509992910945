import Hashids from 'hashids'
import moment from 'moment'
import { SITE_URL } from '../../config/config'
const hashids = new Hashids('ThIsAS3cRet*Key#S3Nior', 25)

export const formatPhone = (phone) => {
   let str = phone ? `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}` : ''
   return str
}

export const formatSSN = (ssn) => {
   let str = ssn ? `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}` : ''
   return str
}

export const encryptId = (id) => {
   return hashids.encode(id)
}

export const decryptId = (str) => {
   return hashids.decode(str)[0]
}

export const makePhotoLink = (str) => {
   str = str.replace(/\\/g, '/')
   return SITE_URL + '/' + str
}

export const changeDate = (str) => {
   return str ? moment(str, 'YYYY-MM-DD').format('MM-DD-YYYY') : ''
}

export const toTitleCase = (str) => {
   if (str) {
     return str.toString().replace(/\w\S*/g, (txt) => {
       return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
     });
   }
   return str;
 };

export const toCustomTitleCase = (str) => {
   if (str) {
     const regex = /([a-z0-9])([A-Z])+/g;
     let newStr = str
       .replace(regex, '$1 $2')
       .split('_')
       .join(' ')
       .split('-')
       .join(' ');
     return toTitleCase(newStr);
   }
   return str;
 };

export const timeName = (time) => {
   switch (time) {
      case 'Am':
         return 'Morning'
      case 'Pm':
         return 'Afternoon'
      case 'Bed':
         return 'Bed Time'
      case 'Evening':
         return 'Evening'
      case 'CmfKit':
         return 'Comfort Kit'
      case 'Pnr':
         return 'As Needed'
      case 'Treatment':
         return 'Treatment'
      case 'Pass':
         return 'N/A'
      default:
         return time
   }
}

export const getAge = (dateString) =>
{
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
}