import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* getPatientList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDPASS.GET_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDPASS.GET_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* submitMedPass(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.MEDPASS.SUBMIT_MEDPASS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* dispenseList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDPASS.DISPENSE_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getTimings(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDPASS.GET_TIMINGS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateDispensedMed(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MEDPASS.UPDATE_DISPENSED_MED + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}