import React, { Component } from 'react'

class UnAuthLayout extends Component {
   componentDidMount() {
      document.body.classList.add('auth-body-bg')
      /* window.$('#auth-review-carousel').owlCarousel(
         { items: 1, loop: !1, margin: 16, nav: !1, dots: !0 }
      ) */
   }

   componentWillUnmount() {
      document.body.classList.remove('auth-body-bg')
   }

   render() {
      return (
         <div>
            <div className="container-fluid p-0">
               <div className="row no-gutters">
                  <div className="col-xl-9">
                     <div className="auth-full-bg pt-lg-5 p-4">
                        <div className="w-100 login-text">
                           <div />
                           <div className="d-flex h-100 flex-column">
                              <div className="p-4 my-auto">
                                 <div className="row justify-content-center">
                                    <div className="col-lg-7">
                                       <div className="text-center">
                                          <a href="https://seniorws.com/rcfe-admin/" target="_blank" className="what-is-link">What is RCFE Admin?</a>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-3">
                     <div className="auth-full-page-content">
                        <div className="w-100">
                           <div className="d-flex flex-column h-100 custom-right-section">
                              {this.props.children}
                              <div className="mt-4 mt-md-4 text-center">
                                 <p className="mb-0">© {new Date().getFullYear()} Senior Wellness Solutions</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      )
   }
}

export default UnAuthLayout