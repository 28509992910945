import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './report.css'
import { toast } from 'react-toastify'
import { decryptId } from '../../components/form-elements/functions'
//import moment from 'moment'
import moment from 'moment-timezone'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import ReactToPrint from 'react-to-print'

const emptyArr = [...Array(15).keys()];
const emptyDestruct = [...Array(5).keys()];

const Template = () => {

   const [resident, setResident] = useState([])
   const [data, setData] = useState([])
   const [tempdata, setTempData] = useState([])
   const [destruct, setDestruct] = useState([])

   const residentApi = useResidentApi()
   const history = useHistory()
   const { id: uid } = useParams()

   useEffect(() => {
      getList()
   }, [])

   const getList = (search = '') => {
      if (isNaN(decryptId(uid))) {
         toast.error('Invalid link')
         setTimeout(() => {
            history.goBack()
         }, 1000);
         return
      }
      residentApi.sixTwoTwo({ id: decryptId(uid) },
         (message, resp) => {
            // console.log(resp.temporary)
            setResident(resp.resident)
            setData(resp.medication)
            setTempData(resp.temporary)
            // setDestruct(resp.destruct)
            let temp = [...resp.destruct];
            setDestruct(temp)
            for(let i=0;i<=temp?.length;i++){
               if((temp[i].signingRepresentative===temp[i+1].signingRepresentative)){
                  temp[i]['signRep'] = true;
                  temp[i+1]['signRep'] = true;
               }
               else{
                  // temp[0]['signRep']=false
                  temp[i+1]['signRep']=false
               }
               if(temp){
                  setTimeout(() => {
                     setDestruct(temp)
                  }, 1000);
               }
            }
         },
         (message) => {
            toast.error(message)
         })
   }

   const calculateMonth = (month) => {
      var b = "";
     
      switch (month) {
         case '01':
            b = "January";
            break;
         case '02':
            b = "February";
            break;
         case '03':
            b = "March";
            break;
         case '04':
            b = "April";
            break;
         case '05':
               b = "May";
            break;
         case '06':
            b = "June";
            break;
         case '07':
            b = "July";
            break;
         case '08':
            b = "August";
            break;
         case '09':
            b = "September";
            break;
         case '10':
            b = "October";
            break;
         case '11':
            b = "November";
            break;
         case '12':
            b = "December";
            break;
      }

      return b;


   }

   const getDateCalculated = (utcdate) => {



      let getdate = utcdate.substring(0, 10)
      let getArr = getdate.split("-");
      // console.log("utcdateutcdateutcdateutcdate", getdate)
      var date = getArr[2];
      var month = getArr[1];
      var fullyear = getArr[0];

      // console.log("monthmonthmonth", month);
      // console.log("string monthsssssss", calculateMonth(month));

      let d = new Date(fullyear, month, date);
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      let mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      
      let datestring = `${calculateMonth(month)} ${date},${fullyear}`;

      return datestring;

   }


   const getSignatureFont = (rowIndex = 1) => {
      const totalAvailableFonts = 3;
      return `signature${(rowIndex % totalAvailableFonts) + 1}`;
   }

   const getSignatureFont1 = (rowIndex) => {
      const totalAvailableFonts = 2;
      return `signature${(rowIndex % totalAvailableFonts) + 1}`;
   }

   return (
      <div className="report">
         <style>

            {
            `@page {
               size: auto;
               margin: 0;
                     }
            `
            }
         </style>
         <div style={{ width: '1124px' }} ng-repeat="form in meds" className="ng-scope">
            <div style={{ width: '1124px' }}>
               <p className="s1" style={{ paddingTop: '2pt', paddingLeft: '7pt', textIndent: '0pt', textAlign: 'left' }}>
                  <span style={{ float: 'left' }}>STATE OF CALIFORNIA - HEALTH AND HUMAN SERVICES AGENCY</span>
                  <span style={{ float: 'right' }}>DEPARTMENT OF SOCIAL SERVICES</span></p>
               <p><br /></p>
               <p className="s1" style={{ textIndent: '0pt', textAlign: 'right' }}>COMMUNITY CARE LICENSING</p>
               <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            </div>
            <h1 style={{ paddingLeft: '7pt', textIndent: '0pt', textAlign: 'left' }}>CENTRALLY STORED MEDICATION AND DESTRUCTION RECORD</h1>
            <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.9375pt' }} cellSpacing={0}>
               <tbody><tr style={{ height: '24pt' }}>
                  <td style={{ width: '612pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={8} rowSpan={2}>
                     <p className="s2" style={{ paddingLeft: '1pt', textIndent: '0pt', textAlign: 'left' }}>I. CENTRALLY STORED MEDICATION</p>
                     <p style={{ textIndent: '0pt', textAlign: 'left' }}><br /></p>
                     <p className="s2" style={{ paddingLeft: '1pt', textIndent: '0pt', textAlign: 'left' }}>INSTRUCTIONS: <i>Centrally stored medications shall be kept in a safe and locked place that is not accessible to any person(s)</i></p>
                     <p className="s3" style={{ paddingLeft: '79pt', textIndent: '0pt', textAlign: 'left' }}>except authorized individuals. Medication records on each client/resident shall be maintained for at least one year.</p>
                  </td>
                  <td style={{ width: '132pt' }} className="lastTCell ng-binding" colSpan={2}>
                     <p className="s4" style={{ paddingLeft: '2pt', textIndent: '0pt', textAlign: 'left' }}>FACILITY NAME</p>{resident.facility && resident.facility.name}
                  </td>
               </tr>
                  <tr style={{ height: '25pt' }}>
                     <td style={{ width: '132pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt' }} colSpan={2} className="ng-binding">
                        <p className="s4" style={{ paddingLeft: '2pt', textIndent: '0pt', textAlign: 'left' }}>FACILITY NUMBER</p>{resident.facility && resident.facility.liscenseNo ? resident.facility.liscenseNo : <span>&nbsp;</span>}
                     </td>
                  </tr>
                  <tr style={{ height: '24pt' }}>
                     <td style={{ width: '360pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={4} className="ng-binding">
                        <p className="s4" style={{ paddingLeft: '1pt', textIndent: '0pt', textAlign: 'left' }}>NAME (LAST FIRST MIDDLE)</p>{resident.firstName} {resident.lastName}
                     </td>
                     <td style={{ width: '96pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2} className="ng-binding">
                        <p className="s4" style={{ paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>ADMISSION DATE</p>{resident.movedInDate ? moment(resident.movedInDate).format('MM-DD-YYYY') : ''}
                     </td>
                     <td style={{ width: '156pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }} colSpan={2} className="ng-binding">
                        <p className="s4" style={{ paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>ATTENDING PHYSICIAN</p>{resident.medical && resident.medical.physician ? resident.medical.physician : <span>&nbsp;</span>}
                     </td>
                     <td style={{ width: '132pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '2pt' }} colSpan={2} className="ng-binding">
                        <p className="s4" style={{ paddingLeft: '4pt', textIndent: '0pt', textAlign: 'left' }}>ADMINISTRATOR</p>{resident.facility && resident.facility.administrator ? resident.facility.administrator : <span>&nbsp;</span>}
                     </td>
                  </tr>
                  <tr style={{ height: '23pt' }}>
                     <td style={{ width: '126pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingTop: '5pt', paddingLeft: '20pt', textIndent: '0pt', textAlign: 'left' }}>MEDICATION&nbsp;NAME</p>
                     </td>
                     <td style={{ width: '66pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '10pt', paddingRight: '5pt', textIndent: '-3pt', textAlign: 'left' }}>STRENGTH/ QUANTITY</p>
                     </td>
                     <td style={{ width: '108pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '8pt', paddingRight: '7pt', textIndent: '11pt', textAlign: 'left' }}>INSTRUCTIONS CONTROL/CUSTODY</p>
                     </td>
                     <td style={{ width: '60pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '18pt', paddingRight: '1pt', textIndent: '-15pt', textAlign: 'left' }}>EXPIRATION DATE</p>
                     </td>
                     <td style={{ width: '48pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '13pt', paddingRight: '13pt', textIndent: '3pt', textAlign: 'left' }}>DATE FILLED</p>
                     </td>
                     <td style={{ width: '48pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '8pt', paddingRight: '8pt', textIndent: '9pt', textAlign: 'left' }}>DATE STARTED</p>
                     </td>
                     <td style={{ width: '84pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '17pt', paddingRight: '10pt', textIndent: '-6pt', textAlign: 'left' }}>PRESCRIBING PHYSICIAN</p>
                     </td>
                     <td style={{ width: '72pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '16pt', paddingRight: '1pt', textIndent: '-14pt', textAlign: 'left' }}>PRESCRIPTION NUMBER</p>
                     </td>
                     <td style={{ width: '36pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '2pt', textIndent: '2pt', textAlign: 'left' }}>NO. OF REFILLS</p>
                     </td>
                     <td style={{ width: '96pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt' }}>
                        <p className="s2" style={{ paddingLeft: '22pt', paddingRight: '21pt', textIndent: '5pt', textAlign: 'left' }}>NAME OF PHARMACY</p>
                     </td>
                  </tr>

                  {data.length ? data.map((item, key) =>
                     <tr style={{ height: '24pt' }} ng-repeat="data in form.pageOne" className="ng-scope" key={item.id}>
                        <td style={{ width: '126pt' }} className="firstTCell ng-binding">{item.name}</td>
                        <td style={{ width: '66pt' }} className="tCell ng-binding">{item?.strength || '--'} {item?.units} / {item.rx_prn_qty <0 ? 0 : item.rx_prn_qty}</td>
                        <td style={{ width: '108pt' }} className="tCell ng-binding">{item.spDirections}</td>
                        {/* <td style={{ width: '60pt', fontSize: 'small' }} className="tCell ng-binding">{item.expirationDate ? moment(item.expirationDate).format('MMM DD, YYYY') : ''}</td> */}
                        <td style={{ width: '60pt', fontSize: 'small' }} className="tCell ng-binding">{item.expirationDate ? getDateCalculated(item.expirationDate) : ''}</td>

                        <td style={{ width: '48pt', fontSize: 'small' }} className="tCell ng-binding">{item.dateFilled ? getDateCalculated(item.dateFilled) : ''}</td>
                        <td style={{ width: '48pt', fontSize: 'small' }} className="tCell ng-binding">{item.dateStarted ? getDateCalculated(item.dateStarted) : ''}</td>
                        <td style={{ width: '84pt' }} className="tCell ng-binding">{item.physician ? item.physician : ''}</td>
                        <td style={{ width: '72pt' }} className="tCell ng-binding">{item.prescription ? item.prescription : ''}</td>
                        <td style={{ width: '36pt' }} className="tCell ng-binding">{item.refillis ? item.refillis : (item.refillis == 0 ? 0 : '')}</td>
                        <td style={{ width: '96pt' }} className="lastTCell ng-binding">{item.pharmacy ? item.pharmacy : ''}</td>
                     </tr>) : null}
                  
                  {tempdata.length ? tempdata.map((item, key) =>
                     <tr style={{ height: '24pt' }} ng-repeat="data in form.pageOne" className="ng-scope" key={item.id}>
                        <td style={{ width: '126pt' }} className="firstTCell ng-binding">{item.name}</td>
                        <td style={{ width: '66pt' }} className="tCell ng-binding">{item?.strength ? item?.strength : (item?.rx_json ? JSON.parse(item?.rx_json)?.DrugStrength : '')} / {item.prn_qty <0 ? 0 : item.prn_qty}</td>
                        <td style={{ width: '108pt' }} className="tCell ng-binding">{item?.spDirections}</td>
                        {/* <td style={{ width: '60pt', fontSize: 'small' }} className="tCell ng-binding">{item.expirationDate ? moment(item.expirationDate).format('MMM DD, YYYY') : ''}</td> */}
                        <td style={{ width: '60pt', fontSize: 'small' }} className="tCell ng-binding">{item?.expirationDate ? getDateCalculated(item?.expirationDate) : ''}</td>

                        <td style={{ width: '48pt', fontSize: 'small' }} className="tCell ng-binding">{item?.dateFilled ? getDateCalculated(item?.dateFilled) : ''}</td>
                        <td style={{ width: '48pt', fontSize: 'small' }} className="tCell ng-binding">{item?.dateStarted ? getDateCalculated(item?.dateStarted) : ''}</td>
                        <td style={{ width: '84pt' }} className="tCell ng-binding">{item?.physician ? item.physician : ''}</td>
                        <td style={{ width: '72pt' }} className="tCell ng-binding">{item?.prescription ? item?.prescription : ''}</td>
                        <td style={{ width: '36pt' }} className="tCell ng-binding">{item?.refillis ? item?.refillis : (item?.refillis == 0 ? 0 : '')}</td>
                        <td style={{ width: '96pt' }} className="lastTCell ng-binding">Senior Wellness Solutions</td>
                     </tr>) : null}

                  {emptyArr.map((blank, i) => <tr style={{ height: '24pt' }} ng-repeat="data in form.pageOne" className="ng-scope" key={i}>
                     <td style={{ width: '126pt' }} className="firstTCell ng-binding" />
                     <td style={{ width: '66pt' }} className="tCell ng-binding" />
                     <td style={{ width: '108pt' }} className="tCell ng-binding" />
                     <td style={{ width: '60pt' }} className="tCell ng-binding" />
                     <td style={{ width: '48pt' }} className="tCell ng-binding" />
                     <td style={{ width: '48pt' }} className="tCell ng-binding" />
                     <td style={{ width: '84pt' }} className="tCell ng-binding" />
                     <td style={{ width: '72pt' }} className="tCell ng-binding" />
                     <td style={{ width: '36pt' }} className="tCell ng-binding" />
                     <td style={{ width: '96pt' }} className="lastTCell ng-binding" />
                  </tr>)}


               </tbody>
            </table>
            <p className="s1 pageBreak" style={{ paddingTop: '2pt', paddingLeft: '7pt', textIndent: '0pt', textAlign: 'left' }}>LIC 622 (3/99) (CONFIDENTIAL)</p>
            {/* <p style={{ paddingBottom: '1pt', paddingLeft: '7pt', textIndent: '0pt', lineHeight: '1pt', textAlign: 'left' }}><span> */}
               {/*<img width={1011} height={0} alt="image" src="images/lic622.png" />*/}
            {/* </span></p> */}
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.9375pt' }} cellSpacing={0}>
               <tbody><tr style={{ height: '22pt' }}>
                  <td style={{ width: '126pt' }} className="firstTCell">
                     <p className="s2" style={{ paddingTop: '5pt', paddingLeft: '20pt', textIndent: '0pt', textAlign: 'left' }}>MEDICATION NAME</p>
                  </td>
                  <td style={{ width: '66pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '10pt', paddingRight: '5pt', textIndent: '-3pt', textAlign: 'left' }}>STRENGTH/ QUANTITY</p>
                  </td>
                  <td style={{ width: '108pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '8pt', paddingRight: '7pt', textIndent: '11pt', textAlign: 'left' }}>INSTRUCTIONS CONTROL/CUSTODY</p>
                  </td>
                  <td style={{ width: '60pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '18pt', paddingRight: '1pt', textIndent: '-15pt', textAlign: 'left' }}>EXPIRATION DATE</p>
                  </td>
                  <td style={{ width: '48pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '8pt', paddingRight: '7pt', textIndent: '3pt', textAlign: 'left' }}>DATE FILLED</p>
                  </td>
                  <td style={{ width: '48pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '3pt', paddingRight: '2pt', textIndent: '9pt', textAlign: 'left' }}>DATE STARTED</p>
                  </td>
                  <td style={{ width: '84pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '17pt', paddingRight: '10pt', textIndent: '-6pt', textAlign: 'left' }}>PRESCRIBING PHYSICIAN</p>
                  </td>
                  <td style={{ width: '72pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '16pt', paddingRight: '1pt', textIndent: '-14pt', textAlign: 'left' }}>PRESCRIPTION NUMBER</p>
                  </td>
                  <td style={{ width: '36pt' }} className="tCell">
                     <p className="s2" style={{ paddingLeft: '2pt', textIndent: '2pt', textAlign: 'left' }}>NO. OF REFILLS</p>
                  </td>
                  <td style={{ width: '125pt' }} className="lastTCell">
                     <p className="s2" style={{ paddingLeft: '22pt', paddingRight: '21pt', textIndent: '5pt', textAlign: 'left' }}>NAME OF PHARMACY</p>
                  </td>
               </tr>
                  {/* ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}<tr style={{ height: '24pt' }} ng-repeat="med in form.pageTwo" className="ng-scope">
                     <td style={{ width: '126pt' }} className="firstTCell">
                     </td><td style={{ width: '66pt' }} className="tCell">
                     </td><td style={{ width: '108pt' }} className="tCell">
                     </td><td style={{ width: '60pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '48pt' }} className="tCell">
                     </td><td style={{ width: '84pt' }} className="tCell">
                     </td><td style={{ width: '72pt' }} className="tCell">
                     </td><td style={{ width: '36pt' }} className="tCell">
                     </td><td className="lastTCell">
                     </td></tr>{/* end ngRepeat: med in form.pageTwo */}
                  <tr style={{ height: '2pt' }}><td colSpan={10} style={{ borderTopWidth: '2px', borderTopStyle: 'solid', height: '2pt' }}><div style={{ height: '1pt', width: '1000px' }} /></td></tr>
               </tbody></table>
            <div style={{ width: '1044px' }}>
               <h2 style={{ paddingTop: '1pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'justify' }}>II. MEDICATION DESTRUCTION RECORD</h2>
               <h2 style={{ paddingTop: '2pt', paddingLeft: '8pt', textIndent: '0pt', textAlign: 'justify' }}>INSTRUCTIONS: <i>Prescription drugs not taken with the client/resident upon termination of services or otherwise disposed of shall be destroyed in the facility by the Administrator or Designated                 Representative and witnessed by one other adult who is not a client/resident.  All facilities except Residential Care Facilities for the Elderly (RCFEs) shall retain destruction records for at least one year. RCFEs shall retain records for at least three years.</i></h2>
            </div>
            <table style={{ borderCollapse: 'collapse', marginLeft: '5.9375pt' }} cellSpacing={0}>
               <tbody><tr style={{ height: '23pt' }}>
                  <td style={{ width: '144pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingTop: '7pt', paddingLeft: '29pt', textIndent: '0pt', textAlign: 'left' }}>MEDICATION NAME</p>
                  </td>
                  <td style={{ width: '60pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingLeft: '7pt', paddingRight: '3pt', textIndent: '-3pt', textAlign: 'left' }}>STRENGTH/ QUANTITY</p>
                  </td>
                  <td style={{ width: '72pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingTop: '0pt', paddingLeft: '14pt', textIndent: '0pt', textAlign: 'left', paddingRight: '14pt' }}>DATE FILLED</p>
                  </td>
                  <td style={{ width: '72pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingLeft: '16pt', paddingRight: '1pt', textIndent: '-14pt', textAlign: 'left' }}>PRESCRIPTION NUMBER</p>
                  </td>
                  <td style={{ width: '48pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingLeft: '13pt', paddingRight: '5pt', textIndent: '-8pt', textAlign: 'left' }}>DISPOSAL DATE</p>
                  </td>
                  <td style={{ width: '120pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s2" style={{ paddingTop: '8pt', paddingLeft: '12pt', textIndent: '0pt', textAlign: 'left' }}>NAME OF PHARMACY</p>
                  </td>
                  <td style={{ width: '138pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt' }}>
                     <p className="s5" style={{ paddingTop: '3pt', paddingLeft: '16pt', paddingRight: '9pt', textAlign: 'left' }}>SIGNATURE OF ADMINISTRATOR OR DESIGNATED REPRESENTATIVE</p>{/* textIndent: '-6pt', */}
                  </td>
                  <td style={{ width: '153pt', borderTopStyle: 'solid', borderTopWidth: '2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt' }}> {/* width: '90pt' */}
                     <p className="s5" style={{ paddingTop: '3pt', paddingLeft: '12pt', paddingRight: '2pt', textAlign: 'left' }}>SIGNATURE OF WITNESS ADULT NON-CLIENT</p>{/*  textIndent: '-8pt', */}
                  </td>
               </tr>
                  {/* {console.log('destruct.length', destruct.length)} */}
                  {destruct.length ? destruct.map((item, j) =>

                     <tr style={{ height: '24pt' }} ng-repeat="med in form.destructionForm" className="ng-scope" key={item.id}>
                        <td style={{ width: '144pt' }} className="firstTCell">{item.name}
                        </td><td style={{ width: '60pt' }} className="tCell">{item.strength} {item.units} / {item.prn_qty <0 ? 0 : item.prn_qty}
                        </td><td style={{ width: '72pt' }} className="tCell">{item.dateFilled && getDateCalculated(item.dateFilled)}
                        </td><td style={{ width: '72pt' }} className="tCell">{item.prescription}
                        </td>
                        <td style={{ width: '48pt', fontSize: 'small' }} className="tCell">{item.deletedAt ? moment(item.deletedAt).format('MMM DD, YYYY') : item.expirationDate ? moment(item.expirationDate).format('MMM DD, YYYY') : ''}
                        </td>
                        {/* <td style={{ width: '48pt', fontSize: 'small' }} className="tCell">{item.deletedAt ? getDateCalculated(item.deletedAt) : item.expirationDate ? getDateCalculated(item.expirationDate) : ''}
                        </td> */}
                        <td style={{ width: '120pt' }} className="tCell">{item.pharmacy}
                        </td><td style={{ width: '138pt' }} className="tCell">{item.authorizedRepresentative ? <p className={getSignatureFont(j)}>{item.authorizedRepresentative}</p> : ''}
                        {/* {console.log('item.signingRepresentative',item?.signRep)} */}
                        </td><td style={{ width: '90pt' }} className="lastTCell">{item.signingRepresentative ? <p className={(!item?.signRep || item?.signRep=='undefined') ? getSignatureFont1(j + 2) : 'signature1'}>{item.signingRepresentative}</p> : ''}
                        </td>
                     </tr>) : null}

                  {emptyDestruct.map((item, l) => <tr style={{ height: '24pt' }} ng-repeat="med in form.destructionForm" className="ng-scope" key={l}>
                        <td style={{ width: '144pt' }} className="firstTCell">
                        </td><td style={{ width: '60pt' }} className="tCell">
                        </td><td style={{ width: '72pt' }} className="tCell">
                        </td><td style={{ width: '72pt' }} className="tCell">
                        </td><td style={{ width: '48pt' }} className="tCell">
                        </td><td style={{ width: '120pt' }} className="tCell">
                        </td><td style={{ width: '138pt' }} className="tCell">
                        </td><td style={{ width: '90pt' }} className="lastTCell">
                        </td>
                     </tr>)
                  }

               </tbody></table>
         </div>
      </div>

   )
}


class Temp extends React.Component {
   render() {
      return (
         <Template />
      )
   }
}

class SixTwoTwo extends React.Component {
   render() {
      return (
         <>
            <div class="float-right my-3 mr-3">
               {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ?
                  <button className="btn btn-dark mobile-print-btn print-btn" onClick={() => window.print()}><i className="fa fa-print" data-toggle="tooltip" title="Print" /></button>
                  :
                  <ReactToPrint
                     documentTitle="622-report"
                     trigger={() => <button className="btn btn-dark mobile-print-btn"><i className="fa fa-print" data-toggle="tooltip" title="Print" /></button>}
                     content={() => this.componentRef}
                  />}
            </div>
            <br /><br /><br />
            <Temp ref={(el) => (this.componentRef = el)} />
         </>
      );
   }
}

export default SixTwoTwo