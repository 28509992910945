import React from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const EditorInput = ({ value, error, inputRef, disabled, ...props }) => {
   return (
      <>
         <CKEditor
            id={props.name}
            ref={inputRef}
            editor={ClassicEditor}
            data={value}
            config={{
               removePlugins: ['EasyImage', 'MediaEmbed']
            }}
            onChange={(event, editor) => {
               const data = editor.getData();
               props.onChange(data)
            }}
            onBlur={(event, editor) => {
               const data = editor.getData();
               props.onBlur(data)
            }}
            disabled={disabled || false}
         />
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}