import { API_BASE_URL, SITE_BASE_URL, SITE_STORE_ID } from "./site_urls";

export const STORAGE = 'eh-ui-auth-key';

export const ROLE_VALUE = 'roles';

export const SITE_URL = SITE_BASE_URL

export const STORE_ID = SITE_STORE_ID

export const BASE_URL = API_BASE_URL

export const APIS = {
   USER: {
      LOGIN: `${BASE_URL}/auth/login`,
      ME: `${BASE_URL}/auth`,
      LOGOUT: `${BASE_URL}/auth/logout`,
      REGISTER: `${BASE_URL}/auth/signup`,
      FORGOT_PASSWORD: `${BASE_URL}/auth/forgot-password`,
      RESET_PASSWORD: `${BASE_URL}/auth/reset-password`,
      UPDATE_PROFILE: `${BASE_URL}/auth/update-profile`,
      CHECK_EMAIL_TOKEN: `${BASE_URL}/auth/verify`,
      CHANGE_PASSWORD: `${BASE_URL}/auth/change-password`,
      CREATE_USER: `${BASE_URL}/users/create-user`,
      LIST_USER: `${BASE_URL}/users/user-list`,
      VALID_PASSWORD_TOKEN: `${BASE_URL}/auth/validate-password-token`,
      UPDATE_DELETE_USER: `${BASE_URL}/users/`,
      UPDATE_DELETE_USER_MULTIPLE_FACILITY: `${BASE_URL}/users/update-facility/`,
      UPDATE_INCIDENT_WITNESS: `${BASE_URL}/residents/update-resident-incident-witness/`,
      GET_USER_MULTIPLE_FACILITY: `${BASE_URL}/users/get-multiple-facility`,
      GET_USER: `${BASE_URL}/users/user-details`,
      GET_USER_BY_ROLEID: `${BASE_URL}/users/user-list-by-roleid`,
      USER_PERMISSIONS: `${BASE_URL}/users/user-permissions`,
      CHANGE_USER_STATE: `${BASE_URL}/users/user-activate`,
      LIST_USER_STAFF: `${BASE_URL}/users/user-listss`,
      LIST_USER_ADMINISTRATOR: `${BASE_URL}/users/administrator-list`,
      LIST_USER_WITNESS: `${BASE_URL}/users/witness-user-list`,
   },
   COMMON: {
      DASHBOARD: `${BASE_URL}/admin/dashboard`,
      UPLOAD_FILE: `${BASE_URL}/common/upload-file`,
      UPLOAD_FILES: `${BASE_URL}/common/upload-files`,
      DONWLOAD_FILE: `${BASE_URL}/common/download-file`,
      DELETE_FILES: `${BASE_URL}/common/delete-files`,
      GET_RELATIONS: `${BASE_URL}/relation/relation-list`,
      STATE_LIST: `${BASE_URL}/state-city/state-list`,
      CITY_LIST: `${BASE_URL}/state-city/city-list`,
      RESIDENT_ALERTS_LIST: `${BASE_URL}/users/resident-alerts`,
      STAFF_ALERTS_LIST: `${BASE_URL}/users/staff-alerts`,
   },
   ROLES: {
      ROLE_LIST: `${BASE_URL}/permission/roles-list`,
      ROLE_PERMISSION_LIST: `${BASE_URL}/permission/role-permission-list`,
      PERMISSIONS_LIST: `${BASE_URL}/permission/permission-list`,
      PERMISSIONS_LIST_BY_ROLE_TYPE: `${BASE_URL}/permission/role-permission-list-by-role-type`,
      ADD_EDIT_ROLE: `${BASE_URL}/permission/add-edit-role`,
      ASSIGN_PERMISSIONS_TO_ROLE: `${BASE_URL}/permission/assign-role-permission`,
      GET_DEFAULT_ROLE: `${BASE_URL}/permission/default-role`,
      DELETE_ROLE: `${BASE_URL}/permission/delete-role-permission/`,
      UNASSIGN_USER_ROLE: `${BASE_URL}/permission/unassign-user-role`,
      GET_ROLE_DETAILS: `${BASE_URL}/permission/get-role-details`
   },
   CONTACT: {
      ADD_CONTACT: `${BASE_URL}/contacts/create-contact`,
      UPDATE_DELETE_CONTACT: `${BASE_URL}/contacts/`,
   },
   LISCENCE: {
      ADD_LISCENCE: `${BASE_URL}/liscense/create-liscense`,
      UPDATE_DELETE_LISCENCE: `${BASE_URL}/liscense/`,
   },
   DOCUMENT: {
      UPLOAD_DOCUMENT: `${BASE_URL}/document/upload-document`,
      ADD_DOCUMENT: `${BASE_URL}/document/create-document`,
      UPDATE_DELETE_DOCUMENT: `${BASE_URL}/document/`,
   },
   RESIDENT: {
      SIX_TWO_TWO: `${BASE_URL}/residents/six-two-two`,
      NINE_ONE_ONE: `${BASE_URL}/residents/nine-one-one`,
      MAR: `${BASE_URL}/residents/mar-dispense-list`,
      MEDICATION_HISTORY: `${BASE_URL}/residents/medication-history`,
      RESIDENT_LIST: `${BASE_URL}/residents/resident-list`,
      RESIDENT_LIST_WITHOUT_FACILITY: `${BASE_URL}/residents/resident-list-without-facility`,
      RESIDENT_LIST_JOIN_INVENTORY: `${BASE_URL}/residents/resident-list-join-inventory`,
      RESIDENT_LIST_BY_FACILITY: `${BASE_URL}/residents/resident-list-by-facility`,
      WITNESS_LIST_BY_INCIDENT: `${BASE_URL}/residents/witness-details-by-incident-id`,
      UPDATE_DELETE_RESIDENT: `${BASE_URL}/residents/`,
      ADD_RESIDENT: `${BASE_URL}/residents/create-resident`,
      GET_RESIDENT: `${BASE_URL}/residents/resident-details`,
      GET_RESIDENT_DOB: `${BASE_URL}/residents/resident-details`,
      ACTIVATE_RESIDENT: `${BASE_URL}/residents/activate-resident`,
      EM_CONTACT: {
         LIST_EM_CONTACT: `${BASE_URL}/residents/resident-contacts-list`,
         CREATE_EM_CONTACT: `${BASE_URL}/residents/create-resident-contact`,
         DELETE_EM_CONTACT: `${BASE_URL}/residents/delete-resident-contact/`,
         UPDATE_EM_CONTACT: `${BASE_URL}/residents/update-resident-contact/`,
      },
      THIRD_PARTY_PRN_SUBMIT: `${BASE_URL}/residents/third-party-api-for-prn`,
      DOCS: {
         LIST_DOCS: `${BASE_URL}/residents/resident-document-list`,
         UPLOAD_DOCS: `${BASE_URL}/residents/upload-resident-document`,
         CREATE_DOCS: `${BASE_URL}/residents/create-resident-document`,
         DELETE_DOCS: `${BASE_URL}/residents/delete-resident-document/`,
         UPDATE_DOCS: `${BASE_URL}/residents/update-resident-document/`
      },
      LISCENCE: {
         CREATE_LISCENSE: `${BASE_URL}/residents/create-resident-liscense`,
         UPDATE_LISCENSE: `${BASE_URL}/residents/update-resident-liscense/`,
         LIST_LISCENSE: `${BASE_URL}/residents/resident-liscense-list`,
         DELETE_LISCNSE: `${BASE_URL}/residents/delete-resident-liscense/`
      },
      INCIDENT: {
         CREATE_INCIDENT: `${BASE_URL}/residents/create-resident-incident`,
         UPDATE_INCIDENT: `${BASE_URL}/residents/update-resident-incident/`,
         LIST_INCIDENT: `${BASE_URL}/residents/incident-list`,
         GET_INCIDENT: `${BASE_URL}/residents/resident-incident-details`,
         GET_INCIDENT_TYPES: `${BASE_URL}/residents/res-incident-type-list`,
      }
   },
   FACILITY: {
      FACILITY_LIST: `${BASE_URL}/facility/facility-list`,
      ADD_FACILITY: `${BASE_URL}/facility/create-facility`,
      UPDATE_DELETE_FACILITY: `${BASE_URL}/facility/`,
      ADMIN_FACILITY_LIST: `${BASE_URL}/facility/admin-facility-list`,
      GET_FACILITY: `${BASE_URL}/facility/facility-details`,
   },
   MEDICATION: {
      ADD_MEDICATION: `${BASE_URL}/medication/create-medication`,
      LIST_MEDICATION: `${BASE_URL}/medication/medication-list-for-admin`,
      TYPE_MEDICATION_LIST: `${BASE_URL}/medication/type-medication-list-for-admin`,
      UPDATE_DELETE_MEDICATION: `${BASE_URL}/medication/`,
      GET_MEDICATION: `${BASE_URL}/medication/medication-details`,
      LIST_MEDICATION_FOR_ALL: `${BASE_URL}/medication/medication-list-for-admin`,
      LIST_MEDICATION_FOR_THIRD_PARTY_ALL: `${BASE_URL}/medication/medication-list-third-parties`,
      LIST_MEDICATION_FOR_ALL_INVENTORY: `${BASE_URL}/medication/inventory-all-medicine-list`,
      UPDATE_RX_STATUS: `${BASE_URL}/medication/update-medication-rx/`,
      GET_RX_DETAILS: `${BASE_URL}/medication/temporary-medication-list-for-admin`,
      APPROVE_MEDICATION_RX: `${BASE_URL}/medication/approve-medication-rx`,
   },
   MEDPASS: {
      GET_LIST: `${BASE_URL}/medication/medpass-list`,
      GET_DETAILS: `${BASE_URL}/medication/medpass-details`,
      SUBMIT_MEDPASS: `${BASE_URL}/medication/create-dispense`,
      DISPENSE_LIST: `${BASE_URL}/medication/dispense-list`,
      UPDATE_DISPENSE: `${BASE_URL}/medication/update-dispense`,
      GET_TIMINGS: `${BASE_URL}/med-timing/med-timing-list`,
      UPDATE_DISPENSED_MED: `${BASE_URL}/medication/update-dispense/`
   },
   MED_APPROVAL: {
      LIST_MED_APPROVAL: `${BASE_URL}/medication/approval-list`,
      UPDATE_MED_APPROVAL: `${BASE_URL}/medication/update-approval/`,
      DELETE_MED_APPROVAL: `${BASE_URL}/medication/delete-approval/`,
      INCIDENT_APPROVALS: `${BASE_URL}/residents/resident-incident-approval-list`,
      UPDATE_INCIDENT_APPROVAL: `${BASE_URL}/residents/update-incident-approval/`,
   },
   ANCMENT: {
      UPDATE_DELETE_ANCMENT: `${BASE_URL}/announcements/`,
      GET_ANCMENT: `${BASE_URL}/announcements/announcements-details`,
      CREATE_ANCMENT: `${BASE_URL}/announcements/create-announcements`,
      LIST_ANCMENTS: `${BASE_URL}/announcements/announcements-list`,
      GET_APPROVAL: `${BASE_URL}/medication/discontinue-pending-approvals`,
      GET_INCIDENT_APPROVAL: `${BASE_URL}/residents/count-incident-approval`
   },
   REUSABLE_CONTACT: {
      CREATE_REUSABLE_CONTACT: `${BASE_URL}/reusable-contact/create-reusable-contact`,
      UPDATE_DELETE_REUSABLE_CONTACT: `${BASE_URL}/reusable-contact/`,
      LIST_REUSABLE_CONTACT: `${BASE_URL}/reusable-contact/list-reusable-contact`,
      LIST_CONTACT_TYPES: `${BASE_URL}/contact-types/list-contact-types`,
      GET_REUSABLE_CONTACT: `${BASE_URL}/reusable-contact/get-reusable-contact`,
   }
}

export const ACTIONS = {
   USER: {
      LOGIN: 'ACTION/USER/LOGIN',
      ME: 'ACTION/USER/ME',
      LOGOUT: 'ACTION/USER/LOGOUT',
      USER_PERMISSION: 'ACTION/USER/USER_PERMISSION'
   },
   LOADER: {
      SHOW_LOADER: 'ACTION/LOADER/SHOW_LOADER',
      HIDE_LOADER: 'ACTION/LOADER/HIDE_LOADER'
   },
   CONTACTS: {
      LIST_CONTACTS: 'ACTION/CONTACTS/LIST_CONTACTS'
   },
   LISCENCE: {
      LIST_LISCENCE: 'ACTION/LISCENCE/LIST_LISCENCE'
   },
   DOCUMENTS: {
      LIST_DOCUMENTS: 'ACTION/DOCUMENTS/LIST_DOCUMENTS'
   },
   RESIDENT: {
      SET_NAME: 'ACTION/RESIDENT/SET_NAME',
      SET_RESIDENT_TAB: 'ACTION/RESIDENT/SET_RESIDENT_TAB'
   },
   WIZARD: {
      SET_NAME: 'ACTION/WIZARD/SET_NAME',
      SET_WIZARD_TAB: 'ACTION/WIZARD/SET_WIZARD_TAB'
   },
   MEDPASS: {
      SET_TAB_INDEX: 'ACTION/MEDPASS/SET_TAB_INDEX',
      SET_PAGE: 'ACTION/MEDPASS/SET_PAGE',
   }
}

export const SAGA_ACTIONS = {
   USER: {
      LOGIN: 'SAGA_ACTION/USER/LOGIN',
      ME: 'SAGA_ACTION/USER/ME',
      LOGOUT: 'SAGA_ACTION/USER/LOGOUT',
      REGISTER: 'SAGA_ACTION/USER/REGISTER/REGISTER_USER',
      FORGOT_PASSWORD: 'SAGA_ACTION/USER/FORGOT_PASSWORD',
      RESET_PASSWORD: 'SAGA_ACTION/USER/RESET_PASSWORD',
      UPDATE_PROFILE: 'SAGA_ACTION/USER/UPDATE_PROFILE',
      CHANGE_PASSWORD: 'SAGA_ACTION/USER/CHANGE_PASSWORD',
      CHECK_TOKEN: 'SAGA_ACTION/USER/CHECK_TOKEN',
      CREATE_USER: 'SAGA_ACTION/USER/CREATE_USER',
      LIST_USER: 'SAGA_ACTION/USER/LIST_USER',
      VALID_PASSWORD_TOKEN: 'SAGA_ACTION/USER/VALID_PASSWORD_TOKEN',
      UPDATE_USER: 'SAGA_ACTION/USER/UPDATE_USER',
      UPDATE_USER_MULTIPLE_FACILITY: 'SAGA_ACTION/USER/UPDATE_USER_MULTIPLE_FACILITY',
      UPDATE_INCIDENT_WITNESS: 'SAGA_ACTION/USER/UPDATE_INCIDENT_WITNESS',
      GET_USER_MULTIPLE_FACILITY: 'SAGA_ACTION/USER/GET_USER_MULTIPLE_FACILITY',
      GET_USER: 'SAGA_ACTION/USER/GET_USER',
      GET_USER_BY_ROLEID: 'SAGA_ACTION/USER/GET_USER_BY_ROLEID',
      USER_PERMISSIONS: 'SAGA_ACTION/USER/USER_PERMISSIONS',
      CHANGE_USER_STATE: 'SAGA_ACTION/USER/CHANGE_USER_STATE',
      DELETE_USER: 'SAGA_ACTION/USER/DELETE_USER',
      DELETE_USER: 'SAGA_ACTION/USER/DELETE_USER',
      LIST_USER_STAFF: 'SAGA_ACTION/USER/LIST_USER_STAFF',
      LIST_USER_ADMINISTRATOR: 'SAGA_ACTION/USER/LIST_USER_ADMINISTRATOR',
      LIST_USER_WITNESS: 'SAGA_ACTION/USER/LIST_USER_WITNESS'
   },
   COMMON: {
      UPLOAD_FILE: 'SAGA_ACTION/COMMON/UPLOAD_FILE',
      UPLOAD_FILES: 'SAGA_ACTION/COMMON/UPLOAD_FILES',
      DELETE_FILES: 'SAGA_ACTION/COMMON/DELETE_FILES',
      GET_RELATIONS: 'SAGA_ACTION/COMMON/GET_RELATIONS',
      STATE_LIST: 'SAGA_ACTION/COMMON/STATE_LIST',
      CITY_LIST: 'SAGA_ACTION/COMMON/CITY_LIST',
      RESIDENT_ALERTS_LIST: 'SAGA_ACTION/COMMON/RESIDENT_ALERTS_LIST',
      STAFF_ALERTS_LIST: 'SAGA_ACTION/COMMON/STAFF_ALERTS_LIST',
      DASHBOARD: 'SAGA_ACTION/COMMON/DASHBOARD'
   },
   ROLES: {
      ROLE_PERMISSION_LIST: 'SAGA_ACTION/ROLES/ROLE_PERMISSION_LIST',
      ROLE_LIST: 'SAGA_ACTION/ROLES/ROLE_LIST',
      PERMISSIONS_LIST: 'SAGA_ACTION/ROLES/PERMISSIONS_LIST',
      PERMISSIONS_LIST_BY_ROLE_TYPE: 'SAGA_ACTION/ROLES/PERMISSIONS_LIST_BY_ROLE_TYPE',
      ADD_EDIT_ROLE: 'SAGA_ACTION/ROLES/CREATE_ROLE',
      DELETE_ROLE: 'SAGA_ACTION/ROLES/DELETE_ROLE',
      ASSIGN_PERMISSIONS_TO_ROLE: 'SAGA_ACTION/ROLES/ASSIGN_PERMISSIONS_TO_ROLE',
      GET_DEFAULT_ROLE: 'SAGA_ACTION/ROLES/GET_DEFAULT_ROLE',
      UNASSIGN_USER_ROLE: 'SAGA_ACTION/ROLES/UNASSIGN_USER_ROLE',
      GET_ROLE_DETAILS: 'SAGA_ACTION/ROLES/GET_ROLE_DETAILS'
   },
   CONTACT: {
      ADD_CONTACT: 'SAGA_ACTION/CONTACT/ADD_CONTACT',
      UPDATE_CONTACT: 'SAGA_ACTION/CONTACT/UPDATE_CONTACT',
      DELETE_CONTACT: 'SAGA_ACTION/CONTACT/DELETE_CONTACT',
   },
   LISCENCE: {
      ADD_LISCENCE: 'SAGA_ACTION/LISCENCE/ADD_LISCENCE',
      UPDATE_LISCENCE: 'SAGA_ACTION/LISCENCE/UPDATE_LISCENCE',
      DELETE_LISCENCE: 'SAGA_ACTION/LISCENCE/DELETE_LISCENCE',
   },
   DOCUMENT: {
      ADD_DOCUMENT: 'SAGA_ACTION/DOCUMENT/ADD_DOCUMENT',
      UPDATE_DOCUMENT: 'SAGA_ACTION/DOCUMENT/UPDATE_DOCUMENT',
      DELETE_DOCUMENT: 'SAGA_ACTION/DOCUMENT/DELETE_DOCUMENT',
      UPLOAD_DOCUMENT: 'SAGA_ACTION/DOCUMENT/UPLOAD_DOCUMENT'
   },
   RESIDENT: {
      SIX_TWO_TWO: 'SAGA_ACTION/RESIDENT/SIX_TWO_TWO',
      NINE_ONE_ONE: 'SAGA_ACTION/RESIDENT/NINE_ONE_ONE',
      MAR: 'SAGA_ACTION/RESIDENT/MAR',
      MEDICATION_HISTORY: 'SAGA_ACTION/RESIDENT/MEDICATION_HISTORY',
      ADD_RESIDENT: 'SAGA_ACTION/RESIDENT/ADD_RESIDENT',
      UPDATE_RESIDENT: 'SAGA_ACTION/RESIDENT/UPDATE_RESIDENT',
      RESIDENT_LIST: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST',
      RESIDENT_LIST_WITHOUT_FACILITY: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST_WITHOUT_FACILITY',
      RESIDENT_LIST_BY_FACILITY: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST_BY_FACILITY',
      WITNESS_LIST_BY_INCIDENT: 'SAGA_ACTION/RESIDENT/WITNESS_LIST_BY_INCIDENT',
      DELETE_RESIDENT: 'SAGA_ACTION/RESIDENT/DELETE_RESIDENT',
      GET_RESIDENT: 'SAGA_ACTION/RESIDENT/GET_RESIDENT',
      GET_RESIDENT_DOB: 'SAGA_ACTION/RESIDENT/GET_RESIDENT_DOB',
      ACTIVATE_RESIDENT: 'SAGA_ACTION/RESIDENT/ACTIVATE_RESIDENT',
      RESIDENT_LIST_JOIN_INVENTORY: 'SAGA_ACTION/RESIDENT/RESIDENT_LIST_JOIN_INVENTORY',
      EM_CONTACT: {
         LIST_EM_CONTACT: 'SAGA_ACTION/RESIDENT/EM_CONTACT/LIST_EM_CONTACT',
         CREATE_EM_CONTACT: 'SAGA_ACTION/RESIDENT/EM_CONTACT/CREATE_EM_CONTACT',
         DELETE_EM_CONTACT: 'SAGA_ACTION/RESIDENT/EM_CONTACT/DELETE_EM_CONTACT',
         UPDATE_EM_CONTACT: 'SAGA_ACTION/RESIDENT/EM_CONTACT/UPDATE_EM_CONTACT',
      },
      THIRD_PARTY_PRN_SUBMIT: 'SAGA_ACTION/RESIDENT/THIRD_PARTY_PRN_SUBMIT',
      DOCS: {
         CREATE_DOCS: 'SAGA_ACTION/RESIDENT/DOCS/CREATE_DOCS',
         UPDATE_DOCS: 'SAGA_ACTION/RESIDENT/DOCS/UPDATE_DOCS',
         LIST_DOCS: 'SAGA_ACTION/RESIDENT/DOCS/LIST_DOCS',
         UPLOAD_DOCS: 'SAGA_ACTION/RESIDENT/DOCS/UPLOAD_DOCS',
         DELETE_DOCS: 'SAGA_ACTION/RESIDENT/DOCS/DELETE_DOCS'
      },
      LISCENCE: {
         CREATE_LISCENSE: 'SAGA_ACTION/RESIDENT/LISCENCE/CREATE_LISCENSE',
         UPDATE_LISCENSE: 'SAGA_ACTION/RESIDENT/LISCENCE/UPDATE_LISCENSE',
         LIST_LISCENSE: 'SAGA_ACTION/RESIDENT/LISCENCE/LIST_LISCENSE',
         DELETE_LISCNSE: 'SAGA_ACTION/RESIDENT/LISCENCE/DELETE_LISCNSE'
      },
      INCIDENT: {
         CREATE_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/CREATE_INCIDENT',
         UPDATE_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/UPDATE_INCIDENT',
         LIST_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/LIST_INCIDENT',
         GET_INCIDENT: 'SAGA_ACTION/RESIDENT/INCIDENT/GET_INCIDENT',
         GET_INCIDENT_TYPES: 'SAGA_ACTION/RESIDENT/INCIDENT/GET_INCIDENT_TYPES',
      }
   },
   FACILITY: {
      FACILITY_LIST: 'SAGA_ACTION/FACILITY/FACILITY_LIST',
      ADD_FACILITY: 'SAGA_ACTION/FACILITY/ADD_FACILITY',
      UPDATE_FACILITY: 'SAGA_ACTION/FACILITY/UPDATE_FACILITY',
      ADMIN_FACILITY_LIST: 'SAGA_ACTION/FACILITY/ADMIN_FACILITY_LIST',
      DELETE_FACILITY: 'SAGA_ACTION/FACILITY/DELETE_FACILITY',
      GET_FACILITY: 'SAGA_ACTION/FACILITY/GET_FACILITY'
   },
   MEDICATION: {
      MEDICATION_LIST: 'SAGA_ACTION/MEDICATION/MEDICATION_LIST',
      TYPE_MEDICATION_LIST: 'SAGA_ACTION/MEDICATION/TYPE_MEDICATION_LIST',
      MEDICATION_LIST_FOR_ALL_INVENTORY: 'SAGA_ACTION/MEDICATION/MEDICATION_LIST_FOR_ALL_INVENTORY',
      ADD_MEDICATION: 'SAGA_ACTION/MEDICATION/ADD_MEDICATION',
      UPDATE_MEDICATION: 'SAGA_ACTION/MEDICATION/UPDATE_MEDICATION',
      DELETE_MEDICATION: 'SAGA_ACTION/MEDICATION/DELETE_MEDICATION',
      GET_MEDICATION: 'SAGA_ACTION/MEDICATION/GET_MEDICATION',
      UPDATES_MEDICATIONS: 'SAGA_ACTION/MEDICATION/UPDATES_MEDICATIONS',
      MEDICATION_LIST_FOR_ALL: 'SAGA_ACTION/MEDICATION/MEDICATION_LIST_FOR_ALL',
      UPDATE_RX_STATUS: 'SAGA_ACTION/MEDICATION/UPDATE_RX_STATUS',
      GET_RX_DETAILS: 'SAGA_ACTION/MEDPASS/GET_RX_DETAILS',
      APPROVE_MEDICATION_RX: 'SAGA_ACTION/MEDICATION/APPROVE_MEDICATION_RX',
   },
   MEDPASS: {
      GET_LIST: 'SAGA_ACTION/MEDPASS/GET_LIST',
      GET_DETAILS: 'SAGA_ACTION/MEDPASS/GET_DETAILS',
      SUBMIT_MEDPASS: 'SAGA_ACTION/MEDPASS/SUBMIT_MEDPASS',
      DISPENSE_LIST: 'SAGA_ACTION/MEDPASS/DISPENSE_LIST',
      UPDATE_DISPENSE: 'SAGA_ACTION/MEDPASS/UPDATE_DISPENSE',
      GET_TIMINGS: 'SAGA_ACTION/MEDPASS/GET_TIMINGS',
      UPDATE_DISPENSED_MED: 'SAGA_ACTION/MEDPASS/UPDATE_DISPENSED_MED'
   },
   MED_APPROVAL: {
      MED_APPROVAL_LIST: 'SAGA_ACTION/MED_APPROVAL/MED_APPROVAL_LIST',
      UPDATE_MED_APPROVAL: 'SAGA_ACTION/MED_APPROVAL/UPDATE_MED_APPROVAL',
      DELETE_MED_APPROVAL: 'SAGA_ACTION/MED_APPROVAL/DELETE_MED_APPROVAL',
      INCIDENT_APPROVAL_LIST: 'SAGA_ACTION/MED_APPROVAL/INCIDENT_APPROVAL_LIST',
      UPDATE_INCIDENT_APPROVAL: 'SAGA_ACTION/MED_APPROVAL/UPDATE_INCIDENT_APPROVAL',
   },
   ANCMENT: {
      UPDATE_ANCMENT: 'SAGA_ACTION/ANCMENT/UPDATE_ANCMENT',
      DELETE_ANCMENT: 'SAGA_ACTION/FACILITY/DELETE_ANCMENT',
      GET_ANCMENT: 'SAGA_ACTION/ANCMENT/GET_ANCMENT',
      CREATE_ANCMENT: 'SAGA_ACTION/ANCMENT/CREATE_ANCMENT',
      LIST_ANCMENTS: 'SAGA_ACTION/ANCMENT/LIST_ANCMENTS',
      GET_APPROVAL: 'SAGA_ACTION/ANCMENT/GET_APPROVAL',
      GET_INCIDENT_APPROVAL: 'SAGA_ACTION/ANCMENT/GET_INCIDENT_APPROVAL'
   },
   REUSABLE_CONTACT: {
      CREATE_REUSABLE_CONTACT: 'SAGA_ACTION/REUSABLE_CONTACT/CREATE_REUSABLE_CONTACT',
      UPDATE_REUSABLE_CONTACT: 'SAGA_ACTION/REUSABLE_CONTACT/UPDATE_REUSABLE_CONTACT',
      LIST_REUSABLE_CONTACT: 'SAGA_ACTION/REUSABLE_CONTACT/LIST_REUSABLE_CONTACT',
      DELETE_REUSABLE_CONTACT: 'SAGA_ACTION/REUSABLE_CONTACT/DELETE_REUSABLE_CONTACT',
      GET_REUSABLE_CONTACT: 'SAGA_ACTION/REUSABLE_CONTACT/GET_REUSABLE_CONTACT',
      LIST_CONTACT_TYPES: 'SAGA_ACTION/REUSABLE_CONTACT/LIST_CONTACT_TYPE',
   }
}

export const PER_PAGE = 10
export const PER_PAGE_OTHERS = 12

export const USER_TYPES = {
   ADMIN: 'admin',
   USER: 'user',
   STAFF: 'staff'
}

export const MODULES = {
   ROLES: 'role',
   STAFF: 'staff',
   PERMISSION: 'permission',
   RESIDENT: 'resident',
   STAFF_CONTACT: 'staff-contacts',
   STAFF_DOCUMENT: 'staff-documents',
   STAFF_LISCENSE: 'staff-licenses',
   STAFF_APPROVALS: 'staff-approvals',
   FACILITY: 'facility',
   PASS_MEDS: 'pass-meds',
   ANNOUNCEMENTS: 'announcements',
   CONTACT: 'contact',
   REPORT: 'report',
   APPROVE_MEDS: 'medication-approval',
   WIZARD:'customer-wizard'
}

export const OPERATIONS = {
   CREATE: 'create',
   READ: 'read',
   UPDATE: 'update',
   DELETE: 'delete',
   LIST: 'list',
   //ASSIGN: 'assign'
}

export const DURATION_TYPES = [
   { label: 'Day', value: 'day' },
   { label: 'Month', value: 'month' },
   { label: 'Year', value: 'year' }
]

export const FILE_TYPES = {
  DNR_POLST: 'dnrPolst'
}

export const NO_IMAGE = "/assets/images/no-image.jpg"