import { ACTIONS } from '../../config/config';

const initialState = {
   name: ''
};

const residentReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.RESIDENT.SET_NAME:
         return {
            ...state,
            name: action.payload
         }
         default:
            return state
   }
}

export default residentReducer