import React from 'react'
import { useSelector } from 'react-redux'
import { Modal, ModalBody } from 'reactstrap';

const Loader = (props) => {
    const isLoading = useSelector(state => state.loader.isLoading)
    return (
        <div>
            {isLoading ? <Modal isOpen={true} className="loader-content">
                <ModalBody className="p-0">
                    <div className="loader"></div>
                </ModalBody>
            </Modal> : null}
        </div>

    )
}

export default Loader