import React from "react";
import InputMask from 'react-input-mask';

export const MaskInput = ({ inputref, value, error, ...rest }) => {
   return (
      <>
         <label htmlFor={rest.name} className="form-label">{rest.label} {rest.required && <span className="text-danger">*</span>}</label>
         <InputMask
            className="form-control"
            value={value}
            id={rest.name}
            name={rest.name}
            mask={rest.mask}
            maskPlaceholder={rest.maskPlaceholder}
            //maskChar = {rest.maskPlaceholder}
            autoComplete={"no-auto-complete"}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
            // type={'number'}
         >
            {(inputProps) => <input {...inputProps} placeholder={rest.maskPlaceholder} ref={inputref}  type={'tel'}/>}
         </InputMask>
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}