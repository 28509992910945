import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextInput } from './form-elements/textInput';
import { CheckboxInput } from './form-elements/checkboxInput';
import { useContactApi } from '../hooks/api/contactApiHook';
import { DateInput } from './form-elements/datepicker';
import moment from 'moment';
import { APIS, MODULES } from '../config/config';
import axios from 'axios'
import { useResidentApi } from '../hooks/api/residentApiHook';
import ReadCheckHOC from '../hoc/readCheckHoc';
import { SelectInput } from './form-elements/dropdown';
import SweetAlert from 'react-bootstrap-sweetalert';
import { changeDate } from './form-elements/functions';

const DURATION_TYPES = [
   { label: 'Day', value: 'day' },
   { label: 'Month', value: 'month' },
   { label: 'Year', value: 'year' }
]

const schema = yup.object().shape({
   description: yup.string().required('Description is required').max(250, 'Max 250 characters'),
   type: yup.string().required('Type is required').max(200, 'Max 200 characters'),
   reminder: yup.bool().oneOf[true, false],
   duration: yup.string().when('reminder', {
      is: (reminder) => reminder === true,
      then: yup.string().nullable().required('Duration is required').matches(/^[0-9]+$/i, 'Invalid duration').test('Zero', 'Minimum 1 is required', val => val != 0)
   }),
   duration_type: yup.object().when('reminder', {
      is: (reminder) => reminder === true,
      then: yup.object().shape({
         label: yup.string(),
         value: yup.string()
      }).nullable().required('Duration type is required')
   }),
   enteredDate: yup.string()
      .nullable()
      .test('Length', 'Invalid entered date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid();
      }),
   dueDate: yup.string()
      .nullable()
      .test('Length', 'Invalid due date', function (value) {
         value = value && value.replace(/_/g, '')
         return value && value.length ? value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() : true;
      })
})

const DocumentForm = (props) => {
   const [fileDoc, setFileDoc] = useState({})
   const [alert, setAlert] = useState(null)

   const { handleSubmit, errors, control, reset, watch } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const contactApi = useContactApi()
   const residentApi = useResidentApi()
   const isChecked = watch('reminder')

   const enteredDate = watch('enteredDate')
   const dueDate = watch('dueDate')

   useEffect(() => {
      if (props.data.id) {
         let obj = DURATION_TYPES.find(item => item.value == props.data.duration_type)
         let temp = { ...props.data }
         temp.enteredDate = changeDate(props.data.enteredDate)
         temp.dueDate = props.data.dueDate ? changeDate(props.data.dueDate) : ''
         temp.duration_type = temp.reminder && obj ? obj : ''
         temp.duration = temp.reminder && temp.duration ? temp.duration : ''
         setFileDoc(temp.file)
         reset(temp)
      }
   }, [props.data])

   const onSubmit = (data) => {
      if (!fileDoc.id) {
         toast.error('No document file uploaded')
         return
      }
      if (data.reminder) {
         data.duration = parseInt(data.duration)
         data.duration_type = data.duration_type.value
      }
      if (data.dueDate) {
         data.dueDate = moment(data.dueDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      }
      else {
         data.dueDate = null
         data.reminder = false
      }

      data.enteredDate = moment(data.enteredDate, 'MM-DD-YYYY').format('YYYY-MM-DD')
      data.fileId = fileDoc.id

      if (props.data.id) {
         props.submitUpdate(data)
      }
      else {
         props.submitCreate(data)
      }
   }

   const uploadDocFile = (e) => {
      if (e.target.files[0]) {
         let formData = new FormData()
         formData.append('doc', e.target.files[0])
         if (props.module === MODULES.STAFF_DOCUMENT) {
            staffUpload(formData)
         }
         else if (props.module === MODULES.RESIDENT) {
            residentDocUpload(formData)
         }
      }
   }

   const residentDocUpload = (data) => {
      let idParam = '?uploadType=resDocFile&userId=' + props.uid

      residentApi.uploadResidentDoc({ data, link: idParam },
         (message, resp) => {
            toast.success('Please save to confirm changes')
            setFileDoc(resp)
         }, (message) => {
            toast.error(message)
         })
   }

   const staffUpload = (data) => {
      let idParam = '?userId=' + props.uid

      contactApi.uploadDocument({ data, link: idParam },
         (message, resp) => {
            toast.success('Please save to confirm changes')
            setFileDoc(resp)
         }, (message) => {
            toast.error(message)
         })
   }

   const downloadFile = () => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${fileDoc.id}`,//your url
         method: 'GET',
         responseType: 'blob',
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
         url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = fileDoc.originalName;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   return (
      <>
         {alert}
         <div className="row">
            <div className="col-lg-12">
               <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                     <div className="col-lg-12" data-select2-id={1}>
                        <div className="form-group" data-select2-id={15}>
                           {!fileDoc.id ?
                              <>
                                 <label htmlFor="file" className="d-block">Document File <span className="text-danger">*</span></label>
                                 <div className="custom-file">
                                    <input type='file' id="file" className="custom-file-input" onChange={uploadDocFile} accept=".png, .jpg, .jpeg, .pdf, .gif, .tiff, .doc, .docx, .xls, .xlsx" disabled={props.isView} />
                                    <label className="custom-file-label" htmlFor="file">Choose file</label>
                                 </div>
                              </>
                              :
                              <>
                                 <button className="btn btn-info mr-2" type="button" onClick={downloadFile}>{fileDoc.originalName}</button><br />
                                 {/* {fileDoc.id && !props.data.id && <button onClick={removeFile} className="btn btn-link text-danger">Remove file</button> */}
                              </>
                           }
                        </div>
                     </div>
                  </div>
                  <fieldset disabled={props.isView}>
                     <div className="row">
                        <div className="col-lg-12" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="type"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.type}
                                       label='Type'
                                       required
                                       type='text'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-12" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <Controller
                                 defaultValue=''
                                 control={control}
                                 name="description"
                                 render={({ ref, value, ...props }) => (
                                    <TextInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.description}
                                       label='Description'
                                       required
                                       maxLength={250}
                                       type='textarea'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-lg-6" data-select2-id={1}>
                           <div className="form-group" data-select2-id={15}>
                              <label className="form-label">Entered Date <span className="text-danger">*</span></label>
                              <Controller
                                 defaultValue={null}
                                 control={control}
                                 name="enteredDate"
                                 render={({ ref, value, ...props }) => (
                                    <DateInput
                                       inputref={ref}
                                       value={value}
                                       error={errors.enteredDate}
                                       placeholder='mm-dd-yyyy'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                        <div className="col-lg-6" data-select2-id={2}>
                           <div className="form-group" data-select2-id={15}>
                              <label className="form-label">Due Date</label>
                              <Controller
                                 defaultValue={null}
                                 control={control}
                                 name="dueDate"
                                 render={({ ref, value, ...props }) => (
                                    <DateInput
                                       inputref={ref}
                                       value={value}
                                       minDate={enteredDate}
                                       showYearDropdown={true}
                                       years={5}
                                       error={errors.dueDate}
                                       disabled={!enteredDate}
                                       placeholder='mm-dd-yyyy'
                                       {...props}
                                    />
                                 )}
                              />
                           </div>
                        </div>
                     </div>

                     {dueDate && <>
                        <div className="form-group">
                           <Controller
                              defaultValue={false}
                              control={control}
                              name="reminder"
                              render={({ ref, value, ...props }) => (
                                 <CheckboxInput
                                    inputref={ref}
                                    value={value}
                                    label='Reminder'
                                    {...props}
                                 />
                              )}
                           />
                        </div>

                        {isChecked &&
                           <div className="row">
                              <div className="col-lg-6 col-12">
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="duration"
                                       render={({ ref, value, ...props }) => (
                                          <TextInput
                                             inputref={ref}
                                             value={value}
                                             error={errors.duration}
                                             label='Duration'
                                             type='text'
                                             {...props}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6 col-12">
                                 <label className="form-label">Duration Type</label>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="duration_type"
                                    render={({ ref, value, ...rest }) => (
                                       <SelectInput
                                          error={errors.duration_type}
                                          inputref={ref}
                                          value={value}
                                          options={DURATION_TYPES}
                                          {...rest}
                                       />
                                    )}
                                 />
                              </div>
                           </div>}
                     </>}
                     <ReadCheckHOC module={props.module} operation={props.data && props.data.id ? 'update' : 'create'} isView={props.isView}>
                        <button className="btn btn-primary waves-light float-right mt-3" type="submit">Submit</button>
                     </ReadCheckHOC>
                  </fieldset>
               </form>
            </div>
         </div>
      </>
   )
}

export default DocumentForm