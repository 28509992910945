import React, { useEffect, useState, useCallback, Fragment } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextInput } from "./form-elements/textInput";
import { MaskInput } from "./form-elements/maskInput";
import { CheckboxInput } from "./form-elements/checkboxInput";
import { useCommonApi } from "../hooks/api/commonApiHook";
import { SelectInput } from "./form-elements/dropdown";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import ReadCheckHOC from "../hoc/readCheckHoc";
import { useReusableContactApi } from "../hooks/api/reusableContactApiHook";
import { MODULES, PER_PAGE } from "../config/config";
import debounce from "lodash.debounce";
import { toast } from 'react-toastify';

const DUMMY_CONTACT = {
  firstName: "",
  lastName: "",
  city: "",
  email: "",
  phone: "",
  type: {
    label: "",
    value: "",
  },
  isExist: false,
};

const schema = yup.object().shape({
  fullName: yup.string(),
  firstName: yup
    .string()
    .required("First name is required")
    .matches(/^[a-zA-Z]+$/, "Invalid first name")
    .max(20, "Max 20 characters"),
  lastName: yup
    .string(),
  email: yup.string(),
  phone: yup
    .string(),
  // lastName: yup
  //   .string()
  //   .required("Last name is required")
  //   .matches(/^[a-zA-Z ]+$/, "Invalid last name")
  //   .max(40, "Max 40 characters"),
  // email: yup.string().required('Email is required').email('Invalid email'),
  // phone: yup
  //   .string()
  //   .required("Phone is required")
  //   .transform((val) => val.replace(/[^\d]/g, ""))
  //   .test("len", "Invalid phone number", (val) => val.length === 10),
  relation: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Relation is required"),
  type: yup
    .object()
    .shape({
      label: yup.string(),
      value: yup.string(),
    })
    .nullable()
    .required("Type is required"),
  description: yup.string().nullable().max(250, "Max 250 characters"),
});

const ContactForm = (props) => {
  const refe = React.useRef();
  const [relatives, setRelatives] = useState([]);
  const [types, setTypes] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [isFieldsDisabled, setFieldsDisabled] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const reusableContactApi = useReusableContactApi();
  const commonApi = useCommonApi();

  const { handleSubmit, errors, control, reset, setValue, register, watch } =
    useForm({
      mode: "onBlur",
      resolver: yupResolver(schema),
    });
  const formValues = watch();

  useEffect(() => {
    getRelations();
    getTypes();
  }, []);

  useEffect(() => {
    if (props.isView) {
      setFieldsDisabled(true);
    }
  }, [props.isView]);

  const getRelations = () => {
    commonApi.getRelations(
      { search: "" },
      (message, resp) => {
        let temp = resp.length
          ? resp.map((item) => {
              return { label: item.name, value: item.name };
            })
          : [];
        setRelatives(temp);
      },
      (message) => {
        //toast.error(message)
      }
    );
  };

  const getTypes = () => {
    reusableContactApi.getTypeList(
      { search: "" },
      (message, resp) => {
        let temp = resp.length
          ? resp.map((item) => {
              return { label: item.type, value: item.id };
            })
          : [];
        setTypes(temp);
      },
      (message) => {
        //toast.error(message)
      }
    );
  };

  useEffect(() => {
    if (props.data.id) {
      let temp = { ...props.data };
      if (props.data.reusableContact) {
        temp.type = props.data.reusableContact.type
          ? {
              label: props.data.reusableContact.type.type,
              value: props.data.reusableContact.type.id,
            }
          : "";
      } else {
        temp.type = temp.type
          ? { label: temp.type.type, value: temp.type.id }
          : "";
      }
      if (props.data.reusableContact) {
        temp.firstName = props.data.reusableContact.firstName;
        temp.lastName = props.data.reusableContact.lastName;
        temp.email = props.data.reusableContact.email;
        console.log('props.data.reusableContact.lastName', props.data.reusableContact.email)
        temp.phone = props.data.reusableContact.phone;
        setSelectedContact({
          ...props.data.reusableContact,
          label: `${props.data.reusableContact.firstName} ${props.data.reusableContact.lastName} (${props.data.reusableContact.email})`,
          value: props.data.reusableContact.id,
        });
        setFieldsDisabled(true);
      }
      reset(temp);
      setShowForm(true);
    }
  }, [props.data]);

  useEffect(() => {
    if (props.data.id) {
      let obj = relatives.find((item) => item.label === props.data.relation);
      let val = { label: props.data.relation, value: props.data.relation };
      if (!obj) {
        setRelatives([...relatives, val]);
      }
      setValue("relation", val);
    }
  }, [relatives]);

  const onSubmit = (data) => {
    console.log(props.data)
    data["relation"] = data.relation.label;
    data["typeId"] = data.type.value;
    if (data["isExist"] === "true") {
      data["reusableContactId"] = selectedContact.id;
    }
    else{
      data["reusableContactId"] = props?.data?.reusableContact?.id;
    }

    delete data["type"];
    delete data["isExist"];

    if (props.data.id) {
      // props.submitUpdate(data);
      console.log("update data update data",data)
      let ph = data.phone.replace(/[^\d]/g, '');
      console.log(ph,ph.length)
      if (ph != "") {
        if (ph.length == 10) {
          props.submitUpdate(data);
        } else {
          toast.error("Entered phone number length should be 10")
        }
      }else{
        props.submitUpdate(data);
      }
    } else {
      console.log("add data add data",data)
      // props.submitCreate(data);
      let ph = data.phone.replace(/[^\d]/g, '');
      // console.log(ph,ph.length)
      if (ph != "") {
        if (ph.length == 10) {
          props.submitCreate(data);
        } else {
          toast.error("Entered phone number length should be 10")
        }
      }else{
        props.submitCreate(data);
      }
    }
  };

  const handleSearch = (inputValue = "") => {
    setLoading(true);
    reusableContactApi.reusableContactList(
      // { page: 1, limit: PER_PAGE, fullName: inputValue },
      { page: 1, limit: 99999, fullName: inputValue },
      (message, resp) => {
        setContactList(
          resp.data.map((contact) => ({
            ...contact,
            label: `${contact.firstName} ${contact.lastName} (${contact.email})`,
            value: contact.id,
          }))
        );
        setSearch(inputValue);
        setLoading(false);
        setOpen(true);
      },
      (message) => {
        //toast.error(message)
      }
    );
  };

  const searchFunc = useCallback(
    debounce((val) => {
      handleSearch(val);
    }, 1500),
    []
  );

  const typeAndSearch = (val) => {
    setSearch(val);
    if (val.length >= 3) {
      searchFunc(val);
    }
  };

  const handleSelect = (val, isExist) => {
    let newContact = null;
    if (isExist === "true") {
      newContact = {
        ...formValues,
        ...val,
        isExist,
        type: {
          label: val.type && val.type.type ? val.type.type : "",
          value: val.type && val.type.id ? val.type.id : "",
        },
      };
       setFieldsDisabled(true);
    } else {
      let firstName = "";
      let lastName = "";
      // let email = "";
      let splitted = val.split(" ");
      firstName = splitted[0];
      if (splitted.length > 1) {
        lastName = splitted[1];
      }
      newContact = {
        ...DUMMY_CONTACT,
        ...formValues,
        isExist,
        firstName,
        lastName,
        // email,
        label: val,
        value: val,
      };
      setFieldsDisabled(false);
    }
    reset(newContact);
    setSelectedContact(newContact);
    setShowForm(true);
  };

  const promiseOptions = debounce((search, callback) => {
    reusableContactApi.reusableContactList(
      { page: 1, limit: PER_PAGE, fullName: search },
      (message, resp) => {
        callback(
          resp.data.map((contact) => ({
            ...contact,
            label: contact.email ? `${contact.firstName} ${contact.lastName} (${contact.email})` : `${contact.firstName} ${contact.lastName}`,
            value: contact.id,
          }))
        );
        // setSearch(inputValue);
        // setLoading(false);
        // setOpen(true);
      },
      (message) => {
        //toast.error(message)
      },
      false
    );
  }, 1000);

  return (
    <div className="row">
      {/* {console.log({props, formValues, errors})} */}
      <div className="col-lg-12">
        <form onSubmit={handleSubmit(onSubmit)}>
          {props.module === MODULES.RESIDENT && (
            <div className="row" data-select2-id={1}>
              <div className="col-lg-12" data-select2-id={15}>
                {/* <label className="form-label">Choose or create a contact</label> */}
                <label className="form-label">
                  Choose a contact below or type the first name to create a new
                  contact
                </label>
                {/* <CreatableSelect
                  name="city"
                  ref={refe}
                  onBlur={() => setOpen(false)}
                  menuIsOpen={isOpen}
                  inputValue={search}
                  onInputChange={typeAndSearch}
                  onChange={(val) => handleSelect(val, "true")}
                  options={contactList}
                  onCreateOption={(val) => handleSelect(val, "false")}
                  createOptionPosition="first"
                  cacheOptions
                  value={selectedContact}
                  blurInputOnSelect
                  isLoading={loading}
                  allowCreateWhileLoading={false}
                  placeholder={"Type a name"}
                  // isDisabled={!selectedState || !selectedState.value}
                /> */}
                <AsyncCreatableSelect
                  cacheOptions={true}
                  defaultOptions={true}
                  value={selectedContact}
                  allowCreateWhileLoading={true}
                  loadOptions={promiseOptions}
                  onChange={(val) => handleSelect(val, "true")}
                  onCreateOption={(val) => handleSelect(val, "false")}
                  // formatCreateLabel={(value: string) =>
                  //   `Use ${value}`
                  // }
                />
                <small className="text-muted font-weight-bold float-right mt-1">
                  (Input at least 3 characters)
                </small>
                {errors?.fullname && (
                  <div className="text-danger">
                    <small>{errors?.fullName?.message}</small>
                  </div>
                )}
              </div>
            </div>
          )}
          {showForm && (
            <Fragment>
              <fieldset disabled={isFieldsDisabled}>
                <div className="row">
                  <div className="col-lg-6" data-select2-id={1}>
                    <div className="form-group" data-select2-id={15}>
                      <Controller
                        defaultValue=""
                        control={control}
                        name="firstName"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            value={value}
                            error={errors.firstName}
                            label="First Name"
                            required
                            placeholder="First name"
                            type="text"
                            maxLength={20}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" data-select2-id={2}>
                    <div className="form-group" data-select2-id={15}>
                      <Controller
                        defaultValue=""
                        control={control}
                        name="lastName"
                        render={({ ref, value, ...props }) => (
                          <TextInput
                            inputref={ref}
                            type="text"
                            value={value}
                            error={errors.lastName}
                            label="Last Name"
                            maxLength={40}
                            placeholder="Last name"
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12" data-select2-id={3}>
                        <div className="form-group" data-select2-id={15}>
                           <Controller
                              defaultValue=''
                              control={control}
                              name="email"
                              render={({ ref, value, ...rest }) => (
                                 <TextInput
                                    inputref={ref}
                                    type='text'
                                    placeholder="Email"
                                    value={value}
                                    error={errors.email}
                                    label='Email'
                                    {...rest}
                                 />
                              )}
                           />
                        </div>
                     </div>
                </div>
                <div className="row">
                  <div className="col-lg-12" data-select2-id={1}>
                    <div className="form-group" data-select2-id={15}>
                      <Controller
                        defaultValue=""
                        control={control}
                        name="phone"
                        render={({ ref, value, ...props }) => (
                          <MaskInput
                            mask="(999) 999-9999"
                            maskPlaceholder="Phone"
                            inputref={ref}
                            value={value}
                            error={errors.phone}
                            label="Phone"
                            type={'number'}
                            {...props}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12" data-select2-id={2}>
                    <div className="form-group" data-select2-id={15}>
                      <label className="form-label">
                        Type <span className="text-danger">*</span>
                      </label>
                      <Controller
                        defaultValue=""
                        control={control}
                        name="type"
                        render={({ ref, value, ...rest }) => (
                          <SelectInput
                            error={errors.type}
                            inputref={ref}
                            value={value}
                            options={types}
                            {...rest}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <div className="row">
                <div className="col-lg-12" data-select2-id={2}>
                  <div className="form-group" data-select2-id={15}>
                    <label className="form-label">
                      Relation <span className="text-danger">*</span>
                    </label>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="relation"
                      render={({ ref, value, ...rest }) => (
                        <SelectInput
                          error={errors.relation}
                          inputref={ref}
                          value={value}
                          options={relatives}
                          disabled={props.isView}
                          {...rest}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12" data-select2-id={2}>
                  <div className="form-group" data-select2-id={15}>
                    <Controller
                      defaultValue=""
                      control={control}
                      name="description"
                      render={({ ref, value, ...rest }) => (
                        <TextInput
                          inputref={ref}
                          type="textarea"
                          value={value}
                          maxLength={250}
                          error={errors.description}
                          label="Description"
                          readOnly={props.isView}
                          {...rest}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <Controller
                  defaultValue={false}
                  control={control}
                  name="isPrimary"
                  render={({ ref, value, ...props }) => (
                    <CheckboxInput
                      inputref={ref}
                      value={value}
                      label="Is Primary?"
                      disabled={props.isView}
                      {...props}
                    />
                  )}
                />
              </div>

              <input
                defaultValue={selectedContact?.isExist}
                type="hidden"
                id="contact-is-exist"
                name={`isExist`}
                ref={register()}
              />

              <ReadCheckHOC
                module={props.module}
                operation={props.data && props.data.id ? "update" : "create"}
                isView={props.isView}
              >
                <button
                  className="btn btn-primary waves-light float-right mt-2"
                  type="submit"
                >
                  Submit
                </button>
              </ReadCheckHOC>
            </Fragment>
          )}
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
