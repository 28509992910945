import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useStaffApi() {
   const callApi = useApiCall();

   const createStaff = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.USER.CREATE_USER, data, onSuccess, onError);
   }

   const staffList = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.USER.LIST_USER, data, onSuccess, onError, useLoader);
   }

   const witnessList = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.USER.LIST_USER_WITNESS, data, onSuccess, onError, useLoader);
   }

   const staffLists = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.USER.LIST_USER_STAFF, data, onSuccess, onError, useLoader);
   }

   const medApprovalList = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.MED_APPROVAL.MED_APPROVAL_LIST, data, onSuccess, onError, useLoader);
   }

   const updateMedApproval = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.MED_APPROVAL.UPDATE_MED_APPROVAL, data, onSuccess, onError, useLoader);
   }

   const deleteMedApproval = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.MED_APPROVAL.DELETE_MED_APPROVAL, data, onSuccess, onError, useLoader);
   }

   const incidentApprovalList = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.MED_APPROVAL.INCIDENT_APPROVAL_LIST, data, onSuccess, onError, useLoader);
   }
   const updateIncidentApproval = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.MED_APPROVAL.UPDATE_INCIDENT_APPROVAL, data, onSuccess, onError, useLoader);
   }

   const administratorList = (data, onSuccess, onError, useLoader = true) => {
      callApi(SAGA_ACTIONS.USER.LIST_USER_ADMINISTRATOR, data, onSuccess, onError, useLoader);
   }


   return {
      createStaff,
      staffList,
      witnessList,
      medApprovalList,
      updateMedApproval,
      deleteMedApproval,
      incidentApprovalList,
      updateIncidentApproval,
      staffLists,
      administratorList
   }
}
