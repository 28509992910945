import { ACTIONS } from '../../config/config';

const initialState = {
   tabIndex: '1',
   tabPage: 1,
   residentTab: '1',
   wizardTab:'1'
};

const extrasReducer = (state = initialState, action) => {
   switch (action.type) {
      case ACTIONS.MEDPASS.SET_TAB_INDEX:
         return {
            ...state,
            tabIndex: action.payload
         }
      case ACTIONS.MEDPASS.SET_PAGE:
         return {
            ...state,
            tabPage: action.payload
         }
      case ACTIONS.RESIDENT.SET_RESIDENT_TAB:
         return {
            ...state,
            residentTab: action.payload
         }
      case ACTIONS.WIZARD.SET_WIZARD_TAB:
         return {
            ...state,
            wizardTab: action.payload
         }
      default:
         return state
   }
}

export default extrasReducer