import React from 'react'
import PropTypes from 'prop-types'
import { useUserPermissionsSelector } from '../hooks/selectors/userSelector'
import { MODULES, OPERATIONS } from '../config/config'

const ReadCheckHOC = (props) => {
   const userPermissions = useUserPermissionsSelector()
   const read = userPermissions.find(item => item.module === props.module && item.operation === OPERATIONS.READ)
   const update = userPermissions.find(item => item.module === props.module && item.operation === OPERATIONS.UPDATE)
   const create = userPermissions.find(item => item.module === props.module && item.operation === OPERATIONS.CREATE)

   const checkRole = () => {
      if (props.isView === true) {
         return false
      }
      if (create && props.operation === 'create') {
         return true
      }
      else if (update && props.operation === 'update') {
         return true
      }
      else if (!props.module === MODULES.RESIDENT && ((read && !update) || (read || !create))) {
         return false
      }
      else {
         return true
      }
   }

   return (
      <React.Fragment>
         {checkRole() ? props.children : null}
      </React.Fragment>
   )
}

export default ReadCheckHOC

ReadCheckHOC.propTypes = {
   module: PropTypes.string.isRequired,
   operation: PropTypes.string.isRequired,
   children: PropTypes.element.isRequired
};
