import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks
} from "date-fns";
import moment from 'moment';

import 'moment-timezone';
import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  decryptId,
  formatPhone,
  formatSSN,
} from '../../components/form-elements/functions';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import { useMedicationApi } from "../../hooks/api/medcationApiHook";
import ReactToPrint from 'react-to-print';
import { NO_IMAGE } from '../../config/config';
import DatePicker from 'react-datepicker';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import 'rsuite/dist/styles/rsuite-default.css';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';
import Position from "rsuite/esm/Overlay/Position";

const Template = props => {
  const [resident, setResident] = useState({});
  const [medication, setMedication] = useState([]);
  const [discontd, setDiscontd] = useState([]);
  const [monthHeading, setMonthHeading] = useState({});
  const [startDays, setStartDays] = useState([]);
  const [startMDates, setStartMDates] = useState([]);
  const [getAge, setAge] = useState();
  const [stickyClass, setStickyClass] = useState('relative');
  const [printresinfoClass, setPrintResInfoClass] = useState('');
  // const [scrollPosition, setScrollPosition] = useState({ scrollTop: 0, scrollLeft: 0 });
  // const scrollDemoRef = useRef(null);

  const { id } = useParams();
  const history = useHistory();
  const residentApi = useResidentApi();
  const medicationApi = useMedicationApi();


  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  // console.log(timeZone);

  useEffect(() => {
    const days = [];
    const dates = [];
    const dateFormat = "EEE";
    const dateFormat2 = "dd";
    var date = new Date();
    // var monthLabel = moment(date).format('YYYY,MM');
    var monthHeading = moment(date).format('MMM yyyy');
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var firstDay = new Date(date.getFullYear(), date.getMonth(), date.setDate(1));
    console.log(date, firstDay)
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let from = moment(moment(firstDay).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
    let to = moment(moment(lastDay).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    // console.log(from, to)

    let Difference_In_Time = lastDay.getTime() - firstDay.getTime();

    // Calculating the no. of days between two dates
    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    for (let i = 0; i < Difference_In_Days; i++) {
      days.push({ 'indexKey': i, 'weekday': format(addDays(firstDay, i), dateFormat) })
      dates.push({ 'indexKey': i, 'monthdate': moment(addDays(firstDay, i)).format('YYYY-MM-DD') })
      // console.log(days)
      // console.log("dates", dates)
    }
    setMonthHeading({ 'year': moment(date).format('YYYY'), 'month': moment(date).format('MM'), 'heading': monthHeading })
    setStartDays(days)
    setStartMDates(dates)
    getInfo(from, to);
    console.log(medication)

    window.addEventListener('scroll', stickNavbar);

    return () => {
      window.removeEventListener('scroll', stickNavbar);
    };
  }, []);

  // handle scroll event
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      //windowHeight > 192 ? setStickyClass('fixed top-0 left-0 z-50') : setStickyClass('relative');
      windowHeight > 192 ? setStickyClass('print-row') : setStickyClass('relative');
    }
  };

  const getPrevMonth = (monthHeading) => {
    // console.log(monthHeading)
    const days = [];
    const dates = [];
    const dateFormat = "EEE";
    const dateFormat2 = "dd";
    // const prevmonth = new Date("May-2024")
    var prevdate = monthHeading.year + ',' + monthHeading.month + ',' + '01';//'01-' + '01-2024'//monthHeading;
    const prevmonth = new Date(prevdate).getMonth();
    // console.log(prevmonth)
    var date = new Date(monthHeading.year + ',' + prevmonth + ',' + '01');
    // console.log(date)
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    // var monthLabel = moment(date).format('MM-YYYY');
    var monthHeading = moment(firstDay).format('MMM yyyy'); //format(firstDay, dateHFormat);
    // console.log("PrevMonth", date, firstDay)
    var lastDay = new Date(date.getFullYear(), firstDay.getMonth() + 1, 0);
    let from = moment(moment(firstDay).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
    let to = moment(moment(lastDay).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    // console.log(from, to)
    let Difference_In_Time = lastDay.getTime() - firstDay.getTime();

    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    for (let i = 0; i < Difference_In_Days; i++) {
      days.push({ 'indexKey': i, 'weekday': format(addDays(firstDay, i), dateFormat) })
      // dates.push({ 'indexKey': i, 'monthdate': format(addDays(firstDay, i), dateFormat2) })
      dates.push({ 'indexKey': i, 'monthdate': moment(addDays(firstDay, i)).format('YYYY-MM-DD') })
      // days.push(format(addDays(firstDay, i), dateFormat))
      // dates.push(format(addDays(firstDay, i), dateFormat2))
      // console.log(days)
      // console.log("dates", dates)
    }
    setMonthHeading({ 'year': moment(date).format('YYYY'), 'month': moment(date).format('MM'), 'heading': monthHeading })
    setStartDays(days)
    setStartMDates(dates)
    getInfo(from, to);
  };

  const getNextMonth = (monthHeading) => {
    // console.log(monthHeading)
    const days = [];
    const dates = [];
    const dateFormat = "EEE";
    const dateFormat2 = "dd";
    var date = new Date(monthHeading.year + ',' + monthHeading.month + ',' + '01');
    // console.log(date)
    var firstDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
    var monthHeading = moment(firstDay).format('MMM yyyy');
    // console.log("NextMonth", firstDay)
    var lastDay = new Date(date.getFullYear(), firstDay.getMonth() + 1, 0);
    let from = moment(moment(firstDay).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
    let to = moment(moment(lastDay).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    // console.log(from, to)
    let Difference_In_Time = lastDay.getTime() - firstDay.getTime();

    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24)) + 1;
    for (let i = 0; i < Difference_In_Days; i++) {
      days.push({ 'indexKey': i, 'weekday': format(addDays(firstDay, i), dateFormat) })
      dates.push({ 'indexKey': i, 'monthdate': moment(addDays(firstDay, i)).format('YYYY-MM-DD') })
      // days.push(format(addDays(firstDay, i), dateFormat))
      // dates.push(format(addDays(firstDay, i), dateFormat2))
      // console.log(days)
      // console.log("dates", dates)
    }
    setMonthHeading({ 'year': moment(firstDay).format('YYYY'), 'month': moment(firstDay).format('MM'), 'heading': monthHeading })
    setStartDays(days)
    setStartMDates(dates)
    getInfo(from, to);
  };

  const calculateAge = dob => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    setAge(age);
  };

  const getInfo = (from, to) => {
    if (isNaN(decryptId(id))) {
      toast.error('Invalid Link');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    } else {
      residentApi.mar(
        {
          residentId: decryptId(id),
          from: from,
          to: to,
        },
        (message, resp) => {
          console.log(resp);
          calculateAge(resp.resident.birthDate);
          setResident(resp.resident);

          let temp =
            resp.data.length &&
            resp.data.map((item) => {
              return {
                ...item,
              };
            });
          setMedication(temp);
          // setDiscontd(disMed);
        },
        message => {
          toast.error(message);
        },
      );
    }
  };

  const getAges = dateString => {
    const date = moment(dateString, 'YYYY-MM-DD');
    const years = moment().diff(date, 'years');
    return years;
  };

  const onClickPrint = (value) => {
    console.log(value);
    setPrintResInfoClass(value)
  }

  // const handleScroll = () => {
  //   if (scrollDemoRef.current) {
  //     const { scrollTop, scrollLeft } = scrollDemoRef.current;
  //     setScrollPosition(scrollTop, scrollLeft);
  //     console.log(scrollTop)
      
  //   }
  // };

  return (
    <div className="row f_1 emergency_report">
      <style>
        {`
        @page {
          size: 594mm 420mm ;
          // size: 420mm 594mm landscape !important;
          // size: 594mm 841mm; //841mm 1189mm;
          margin-top: 0cm;
          margin-bottom: 0cm;
          margin-left: 0.25cm;
          margin-right: 0.5cm;
         }
        
        `}
        {/* // auto; */}
      </style>
      <div className="col-lg-2 bg-light print-resident">
        <div className="card shadow-none bg-light">
          <div className="card-body text-center">
            <img
              src={resident.photo ? resident.photo.fullUrl : NO_IMAGE}
              alt=""
              className="rounded-circle avatar-xl"
            />

            <h4 className="text-dark my-3">{resident.firstName} {resident.lastName}</h4>
            <p>
              <strong>SSN: </strong>
              {resident.ssn ? formatSSN(resident?.ssn?.replace(/[^\d]/g, '')) : 'N/A'}
            </p>
            <p>
              <strong>Date of Birth: </strong>
              {moment(resident.birthDate).format('MMM DD, YYYY')}
            </p>
            <p>
              <strong>Age: </strong>
              {resident?.birthDate
                ? getAges(resident?.birthDate)
                : ''}{' '}
              Years
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-10 px-xs-0 mt-xs-3 print-medicine sticky">

        <div className="card">
          <div className="card-body">
            {/* <div className="table-responsive"> */}

            <div className="col-lg-12 p-0">
              <div className="row">
                <div className="em-sections print-report-heading">
                <h5>Medication Report </h5>
                <div className="pl-20 pr-20">
                  <div className="row mb-3 print-row">
                    <div className="col-lg-2 col-md-4" style={{width: '9%'}}>
                      <img
                        src={resident.photo ? resident.photo.fullUrl : NO_IMAGE}
                        alt=""
                         className="rounded-circle avatar-xl"
                      />
                    </div>
                    <div className="col-lg-10 col-md-4">
                      <h2>
                        {/* <strong>Resident Name:</strong> */}
                        {resident.firstName} {resident.lastName}
                      </h2>
                      <p>
                        <strong>SSN:</strong>
                        {resident.ssn ? formatSSN(resident?.ssn?.replace(/[^\d]/g, '')) : 'N/A'}
                      </p>
                      <p>
                        <strong>DOB:</strong>
                        {moment(resident.birthDate).format('MMM DD, YYYY')}
                      </p>
                      <p>
                        <strong>Age:</strong>
                        {/* {getAge} Years */}
                        {resident?.birthDate
                          ? getAges(resident?.birthDate)
                          : ''}{' '}
                          years
                      </p>
                    </div>
                    
                  </div>
                </div>
                </div>
                {/* <div className="col-lg-12 print-report-heading">
                  <div className="d-flex align-items-start justify-content-start">
                    <h4 className="mb-0 font-size-18">Medication Report</h4>
                  </div>
                  <div className="d-flex align-items-start justify-content-start">
                  <h4>{resident.firstName} {resident.lastName}</h4>
                  </div>
                  <div className="d-flex align-items-start justify-content-start">
                    <p>
                      <strong>SSN: </strong>
                      {resident.ssn ? formatSSN(resident?.ssn?.replace(/[^\d]/g, '')) : 'N/A'}
                    </p>
                  </div>
                  <div className="d-flex align-items-start justify-content-start">
                    <p>
                      <strong>Date of Birth: </strong>
                      {moment(resident.birthDate).format('MMM DD, YYYY')}
                    </p>
                  </div>
                  <div className="d-flex align-items-start justify-content-start">

                    <p>
                      <strong>Age: </strong>
                      {resident?.birthDate
                        ? getAges(resident?.birthDate)
                        : ''}{' '}
                      Years
                    </p>
                  </div>
                </div> */}
                <div className="col-lg-11">
                  <div className="align-items-center justify-content-center">
                    {/* <h4 style={{ textAlign: 'center', fontSize: 'xx-large', color: '#a6a6a6' }} className="print-res-info mb-3">{resident.firstName} {resident.lastName} &raquo; {monthHeading.heading}</h4> */}
                    <h4 style={{ textAlign: 'center', fontSize: 'xx-large', color: '#a6a6a6' }} className="report mb-3">{monthHeading.heading}</h4>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className="d-flex align-items-end justify-content-end">
                    <button data-toggle="tooltip" title="Previous Month"
                      className="btn btn-primary print-btn mr-1"
                      onClick={() => getPrevMonth(monthHeading)}>
                      <i class="fa fa-caret-left" aria-hidden="true"></i>
                    </button>
                    <button data-toggle="tooltip" title="Next Month"
                      data-tooltip-id="my-tooltip" data-tooltip-content="Hello world!"
                      className="btn btn-primary print-btn mr-1"
                      onClick={() => getNextMonth(monthHeading)}
                    >

                      <i class="fa fa-caret-right" data-toggle="tooltip" title="Next Month" saria-hidden="true"></i>
                    </button>

                    <button
                      className="btn btn-primary print-btn"
                      onClick={() => window.print()}>
                      Print
                    </button>

                    {/* {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
                        <button
                          className="btn btn-primary print-btn"
                          onClick={() => window.print()}>
                          Print
                        </button>
                      ) : (
                        <ReactToPrint
                          documentTitle="medication"
                          trigger={() => (
                            <button className="btn btn-primary">Print</button>
                          )}
                          content={() => this.componentRef}
                        />
                      )} */}
                  </div>
                  {/* <div className="align-items-end justify-content-end"> */}

                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="table-responsive table-container m-0">
              <table className="table table-sticky table-bordered w-100 mt-2">

                <thead style={{position: 'sticky', top: '0'}}>

                  <tr key='name'>
                    <th class="text-center align-middle " style={{ minWidth: '200px',position: 'sticky'}} >Name</th>
                    {startMDates.length ? startMDates.map((item, key) =>

                    <th className='text-center align-middle' key={item.indexKey} style={{ minWidth: '54px' }}>{moment(item.monthdate).format('DD')}</th>
                    ) : null}
                  </tr>
                </thead>
                <tbody>
                  <tr key='data'>
                    <th style={{ minWidth: '200px' }}></th>
                    {startDays.length ? startDays.map((item, key) =>
                      <th className="text-center align-middle" key={item.indexKey} style={{ minWidth: '50px' }}>{item.weekday}</th>
                    ) : null}
                  </tr>

                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Am'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Am')?.length ? ob.freq.filter(obj => obj?.time === 'Am') : '') : (ob?.freq[0]?.time == 'Am'))).length ?
                      <tr><th className="bg-light align-middle">Morning</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}

                  {medication.length && medication.filter(obj => (obj?.freq?.length && (obj?.freq.filter(fre => fre?.time == 'Am')))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Am')?.length ? ob.freq.filter(obj => obj?.time === 'Am') : '') : (ob?.freq[0]?.time == 'Am')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date, smd) => (

                            items?.freq[items.freq.findIndex(obj => obj?.time === 'Am')]?.dispense?.length ? <td className="align-middle  text-center"> {
                              items?.freq[items.freq.findIndex(obj => obj?.time === 'Am')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                                (dtest, index, array) =>
                                  index ===
                                  array.findIndex(
                                    (findTest) =>
                                      findTest?.date === dtest?.date
                                  )
                              ).map((fdate) =>
                              (
                                fdate.roles == 'Taken' ? 'G' : 'R'
                              )
                              )} </td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) :
                    ('')
                  }
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Pm'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Pm')?.length ? ob.freq.filter(obj => obj?.time === 'Pm') : '') : (ob?.freq[0]?.time == 'Pm'))).length ?
                      <tr><th className="bg-light align-middle">Afternoon</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}
                  {/* {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time === 'Pm'))?.length ?  */}
                  {/* <tr><th className="bg-light align-middle">{medication.filter(obj => (obj?.freq.filter(fre => fre?.time === 'Pm')))?.length}Afternoon</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> */}
                  {/* : '' )  } */}

                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Pm'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Pm')?.length ? ob.freq.filter(obj => obj?.time === 'Pm') : '') : (ob?.freq[0]?.time == 'Pm')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date, smd) => (
                            items?.freq[items.freq.findIndex(obj => obj?.time === 'Pm')]?.dispense?.length ? <td className="align-middle  text-center"> {items?.freq[items.freq.findIndex(obj => obj?.time === 'Pm')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                              (dtest, index, array) =>
                                index ===
                                array.findIndex(
                                  (findTest) =>
                                    findTest?.date === dtest?.date
                                )
                            ).map((fdate) =>
                            (
                              fdate.roles == 'Taken' ? 'G' : 'R'
                            )
                            )} </td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) : (''
                    // <tr>
                    //   <td className="no-td" colSpan="3">
                    //     No results found
                    //   </td>
                    // </tr>
                  )}

                  {/* {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Evening'))).length ? <tr><th className="bg-light align-middle">Evening</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : ''} */}
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Evening'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Evening')?.length ? ob.freq.filter(obj => obj?.time === 'Evening') : '') : (ob?.freq[0]?.time == 'Evening'))).length ?
                      <tr><th className="bg-light align-middle">Evening</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time === 'Evening'))).length > 0 ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Evening')?.length ? ob.freq.filter(obj => obj?.time === 'Evening') : '') : (ob?.freq[0]?.time === 'Evening')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date) => (
                            items?.freq[items.freq.findIndex(obj => obj?.time === 'Evening')]?.dispense?.length ? <td className="align-middle text-center"> {items?.freq[items.freq.findIndex(obj => obj?.time === 'Evening')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                              (dtest, index, array) =>
                                index ===
                                array.findIndex(
                                  (findTest) =>
                                    findTest?.date === dtest?.date
                                )
                            ).map((fdate) =>
                            (
                              fdate.roles == 'Taken' ? 'G' : 'R'
                            )
                            )} </td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) : ('')}

                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Bed'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Bed')?.length ? ob.freq.filter(obj => obj?.time === 'Bed') : '') : (ob?.freq[0]?.time == 'Bed'))).length ?
                      <tr><th className="bg-light align-middle">Bed</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Bed'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Bed')?.length ? ob.freq.filter(obj => obj?.time === 'Bed') : '') : (ob?.freq[0]?.time == 'Bed')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date, smd) => (
                            items?.freq[items.freq.findIndex(obj => obj?.time === 'Bed')]?.dispense?.length ? <td className="align-middle text-center"> {items?.freq[items.freq.findIndex(obj => obj?.time === 'Bed')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                              (dtest, index, array) =>
                                index ===
                                array.findIndex(
                                  (findTest) =>
                                    findTest?.date === dtest?.date
                                )
                            ).map((fdate) =>
                            (
                              fdate.roles == 'Taken' ? 'G' : 'R'
                            )
                            )} </td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) : ('')}

                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Pnr'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Pnr')?.length ? ob.freq.filter(obj => obj?.time === 'Pnr') : '') : (ob?.freq[0]?.time == 'Pnr'))).length ?
                      <tr><th className="bg-light align-middle">PRN</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'Pnr'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'Pnr')?.length ? ob.freq.filter(obj => obj?.time === 'Pnr') : '') : (ob?.freq[0]?.time == 'Pnr')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date, smd) => (
                            // items?.freq[0]?.dispense?.length ? <td className="align-middle"> {items?.freq[0]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))
                            items?.freq[items.freq.findIndex(obj => obj?.time === 'Pnr')]?.dispense?.length ? <td className="align-middle text-center"> {items?.freq[items.freq.findIndex(obj => obj?.time === 'Pnr')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                              (dtest, index, array) =>
                                index ===
                                array.findIndex(
                                  (findTest) =>
                                    findTest?.date === dtest?.date
                                )
                            ).map((fdate) =>
                            (
                              fdate.roles == 'Taken' ? 'G' : 'R'
                            )
                            )}
                             {/* <br/><span class="font-size-10"> </span>*/}
                            {items?.freq[items.freq.findIndex(obj => obj?.time === 'Pnr')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.length > 1 ? ':'+ items?.freq[items.freq.findIndex(obj => obj?.time === 'Pnr')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.length : ''}</td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) : ('')}

                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'CmfKit'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'CmfKit')?.length ? ob.freq.filter(obj => obj?.time === 'CmfKit') : '') : (ob?.freq[0]?.time == 'CmfKit'))).length ?
                      <tr><th className="bg-light align-middle">Comfort Kit</th><th colSpan={35} className="bg-light">&nbsp;</th></tr> : '') : ''}
                  {medication.length && medication.filter(obj => (obj?.freq.filter(fre => fre?.time == 'CmfKit'))).length ? (
                    medication
                      .filter(ob => (ob?.freq?.length > 1 ? (ob.freq.filter(obj => obj?.time === 'CmfKit')?.length ? ob.freq.filter(obj => obj?.time === 'CmfKit') : '') : (ob?.freq[0]?.time == 'CmfKit')))
                      .map((items, i) => (
                        <tr key={i}>
                          <td className="align-middle">{items?.name}</td>
                          {startMDates.length ? startMDates.map((date, smd) => (
                            items?.freq[items.freq.findIndex(obj => obj?.time === 'CmfKit')]?.dispense?.length ? <td className="align-middle text-center"> {items?.freq[items.freq.findIndex(obj => obj?.time === 'CmfKit')]?.dispense.filter((disdate, dd) => (date?.monthdate == disdate?.date))?.filter(
                              (dtest, index, array) =>
                                index ===
                                array.findIndex(
                                  (findTest) =>
                                    findTest?.date === dtest?.date
                                )
                            ).map((fdate) =>
                            (
                              fdate.roles == 'Taken' ? 'G' : 'R'
                            )
                            )} </td> : <td></td>


                          )
                          ) : null}
                        </tr>
                      ))
                  ) : ('')}

                </tbody>

              </table>
            </div>
            <p className="print-badge"><span className="badge badge-success">G</span> Given <span className="badge badge-danger">R</span> Refused</p>
            {/* </div> */}


          </div>
        </div>
      </div>
    </div>
  );
};


// const PrevNextMonthTemplate
class Temp extends React.Component {
  render() {
    return <Template />;
  }
}


class Calendar extends React.Component {
  render() {
    return (
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Medication Report</h4>
                <div className="d-flex align-items-end justify-content-end mr-1">

                  {/* print-heading-btn */}
                  {/* {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
                    <button
                      className="btn btn-primary print-btn mr-4"
                      onClick={() => window.print()}>
                      Print
                    </button>
                  ) : (
                    <ReactToPrint
                      documentTitle="medication"
                      trigger={() => (
                        <button className="btn btn-primary mr-4">Print</button>
                      )}
                      content={() => this.componentRef}
                    />
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <Temp ref={el => (this.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default Calendar;
/**
 * Header:
 * icon for switching to the previous month,
 * formatted date showing current month and year,
 * another icon for switching to next month
 * icons should also handle onClick events to change a month
 */
