import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import LiscenseForm from '../../components/liscenseForm'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import moment from 'moment'
import { MODULES } from '../../config/config'
import BootstrapTable from 'react-bootstrap-table-next';
import { debounce } from 'lodash'

const columns = [{
   dataField: 'id',
   text: '',
   hidden: true
}, {
   dataField: 'type',
   text: 'Type',
   sort: true,
   style: { minWidth: '120px' },
}, {
   dataField: 'enteredDate',
   text: 'Entered Date',
   sort: true,
   style: { minWidth: '150px' },
   formatter: (cell, row) => row.enteredDate && moment(row.enteredDate).format('MM-DD-YYYY')
}, {
   dataField: 'dueDate',
   text: 'Due Date/\nLifetime',
   sort: true,
   style: { minWidth: '120px' },
   formatter: (cell, row) => row.dueDate ? moment(row.dueDate).format('MM-DD-YYYY') : 'Lifetime'
}, {
   dataField: 'reminder',
   text: 'Reminder',
   sort: true,
   style: { minWidth: '120px' },
   formatter: (cell, row) => row.reminder ? 'yes' : 'no'
}];


const ResLiscenseList = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [alert, setAlert] = useState(null)
   const [apiList, setApiList] = useState([])
   const [cols, setCols] = useState([])
   const [total, setTotal] = useState(0)
   const [isWizard, setWizard] = useState(props.isWizard)

   const residentApi = useResidentApi()
   const history = useHistory()

   useEffect(() => {
      setSearch()
      if (props.resId) {
         getList()
      }
   }, [props.resId])

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#liscResInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$(".liscResTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
            document.querySelectorAll('.no-res').forEach(e => e.remove());
            if (window.$('.liscResTable tr:visible').length == 0) {
               let table = document.getElementsByClassName("liscResTable")[0]
               let row = table.insertRow(0)
               row.className = "no-res"
               let cell = row.insertCell(0);
               cell.colSpan = "3"
               cell.className = "no-td"
               cell.innerHTML = "No results found"
            }
         });
      });
   }

   const columnSet = () => {
      setCols([...columns,
      {
         dataField: 'actions',
         isDummyField: true,
         text: 'Action',
         style: { minWidth: '100px' },
         formatter: (cell, row) => (
            <>
               <i onClick={() => editData(row)} className="fa fa-edit mr-3 cursor-pointer" />
               <i onClick={() => showConfirm(row.id)} className="fa fa-trash cursor-pointer" />
            </>
         )
      }
      ])
   }

   const getList = () => {
      residentApi.listResidentLisc({ resident: props.resId, search: '' },
         (message, resp) => {
            setApiList(resp.data)
            setTotal(resp.total)
            columnSet()
         },
         (message) => {
            toast.error(message)
         })
   }

   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (data) => {
      setDetails(data)
      showModal(true)
   }

   const showConfirm = (id) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger cancel-padding"
            title="Delete License/Event?"
            onConfirm={() => deleteContactFunc(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure?
      </SweetAlert>
      )
   }

   const deleteContactFunc = (id) => {
      setAlert(null)
      residentApi.deleteResidentLisc({ id },
         (message, resp) => {
            toast.success('License/Event deleted successfully')
            setApiList([])
            setTimeout(() => {
               getList()
            }, 1500);
         }, (message) => {
            toast.error(message)
         })
   }

   const sumbitResLiscUpdate = (data) => {
      data['id'] = details.id
      residentApi.updateResidentLisc(data,
         (message, resp) => {
            toast.success('License/Event updated successfully')
            toggle()
            setApiList([])
            setTimeout(() => {
               getList()
            }, 1500);
         }, (message) => {
            toast.error(message)
         })
   }

   const submitResLiscCreate = (data) => {
      data['link'] = '?resident=' + props.resId
      residentApi.addResidentLisc(data,
         (message, resp) => {
            toast.success('License/Event created successfully')
            toggle()
            setTotal(total + 1)
            setApiList([])
            setTimeout(() => {
               getList()
            }, 1500);
         }, (message) => {
            toast.error(message)
         })
   }

   const skiptonextstep = () => {
      history.push('/dashboard');
   }

   return (
      <>
         {alert}
         <div className="mt-3 p-3">
            <button type="button" className="btn btn-outline-dark accordian-add-btn" onClick={() => showModal(true)}> <i className="fas fa-plus" />&nbsp;Add New</button>
            <input className="form-control mb-3" id="liscResInput" type="text" placeholder="Search.." />
            <div className="table-responsive simple-data-table">
               {cols.length ?
                  <BootstrapTable
                     keyField='id'
                     data={apiList}
                     bodyClasses="liscResTable"
                     columns={cols}
                     noDataIndication={() => total === 0 ? <p>No results found</p> : <p>Loading...</p>}
                  /> : null}
            </div>
            <div className="d-flex justify-content-between mt-3">
               
               {isWizard != true && (
               <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               )}
               {isWizard === true && (
                  <button className="btn btn-primary w-lg btn-lg mt-0" onClick={()=>{skiptonextstep()
                     props.setforceExit(true)
                     }}>Exit Wizard </button>
               )}
               <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
            </div>
            <Modal centered isOpen={modal} size='md'>
               <ModalHeader toggle={toggle}>Events</ModalHeader>
               <ModalBody>
                  <LiscenseForm
                     isView={false}
                     data={details}
                     module={MODULES.RESIDENT}
                     submitCreate={submitResLiscCreate}
                     submitUpdate={sumbitResLiscUpdate}
                  />
               </ModalBody>
            </Modal>
         </div>
      </>
   )
}

export default ResLiscenseList