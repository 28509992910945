import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addFacility(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.FACILITY.ADD_FACILITY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* facilityList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.FACILITY.FACILITY_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteFacility(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.FACILITY.UPDATE_DELETE_FACILITY + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateFacility(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.FACILITY.UPDATE_DELETE_FACILITY + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getFacility(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.FACILITY.GET_FACILITY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* adminFacilityList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.FACILITY.ADMIN_FACILITY_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

