import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* deleteFiles(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.COMMON.DELETE_FILES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* uploadFiles(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.COMMON.UPLOAD_FILES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* uploadFile(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, `${APIS.COMMON.UPLOAD_FILE}?format=${data.format}`, data.data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getRelations(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.GET_RELATIONS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getStates(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.STATE_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getCities(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.CITY_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* staffAlertsList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.STAFF_ALERTS_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* resAlertsList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.RESIDENT_ALERTS_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* dashboard(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.COMMON.DASHBOARD, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}