import React, { useEffect, useState } from 'react'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import DocumentForm from '../../components/documentForm'
import { useContactApi } from '../../hooks/api/contactApiHook'
import { useUserApi } from '../../hooks/api/userApiHook'
import moment from 'moment'
import { APIS, MODULES } from '../../config/config'
import axios from 'axios'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import { useHistory } from 'react-router-dom'

const ResDocumentList = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [alert, setAlert] = useState(null)
   const [apiList, setApiList] = useState([])
   const [isWizard, setWizard] = useState(props.isWizard)

   const residentApi = useResidentApi()
   const history = useHistory()

   useEffect(() => {
      setSearch()
      if (props.resId) {
         getList()
      }
   }, [props.resId])

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#resDocInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$("#resDocTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
            document.querySelectorAll('.no-res').forEach(e => e.remove());
            if (window.$('#resDocTable tr:visible').length == 0) {
               let table = document.getElementById("resDocTable")
               let row = table.insertRow(0)
               row.className = "no-res"
               let cell = row.insertCell(0);
               cell.colSpan = "3"
               cell.className = "no-td"
               cell.innerHTML = "No results found"
            }
         });
      });
   }

   const getList = () => {
      residentApi.listResidentDoc({ resident: props.resId, search: '' },
         (message, resp) => {
            setApiList(resp)
         },
         (message) => {
            toast.error(message)
         })
   }

   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (id) => {
      toggle()
      let data = apiList.find(item => item.id === id)
      setDetails(data)
   }

   const showConfirm = (id) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger cancel-padding"
            title="Delete Document?"
            onConfirm={() => deleteDocFunc(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure?
         </SweetAlert>
      )
   }

   const deleteDocFunc = (id) => {
      setAlert(null)
      residentApi.deleteResidentDoc({ id },
         (message, resp) => {
            toast.success('Document deleted successfully')
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const downloadFile = (item) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${item.file.id}`,//your url
         method: 'GET',
         responseType: 'blob', // important
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
            url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = item.file.originalName;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   const sumbitResDocUpdate = (data) => {
      data['id'] = details.id
      residentApi.updateResidentDoc(data,
         (message, resp) => {
            toast.success('Document updated successfully')
            toggle()
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const submitResDocCreate = (data) => {
      data['link'] = '?resident=' + props.resId
      residentApi.addResidentDoc(data,
         (message, resp) => {
            toast.success('Document created successfully')
            toggle()
            getList()
         }, (message) => {
            toast.error(message)
         })
   }

   const skiptonextstep = () => {
      history.push('/dashboard');
   }

   return (
      <>
         {alert}
         <div className="mt-3 p-3">
            <button type="button" className="btn btn-outline-dark accordian-add-btn" onClick={() => showModal(true)}> <i className="fas fa-plus" />&nbsp;Add New</button>

            <input className="form-control mb-3" id="resDocInput" type="text" placeholder="Search.." />

            <div className="table-responsive">
               <table className="table table-striped table-bordered w-100">
                  <thead>
                     <tr>
                        <th>File Name</th>
                        <th style={{ minWidth: '100px' }}>Type</th>
                        <th style={{ minWidth: '120px' }}>Entered Date</th>
                        <th style={{ minWidth: '120px' }}>Due Date</th>
                        <th>Reminder</th>
                        <th style={{ minWidth: '100px' }}>Action</th>
                     </tr>
                  </thead>
                  <tbody id="resDocTable">
                     {apiList.length ? apiList.map((item, i) =>
                        <tr key={i}>
                           <td>
                              <p className="text-truncate text-primary cursor-pointer" style={{ maxWidth: '150px' }} onClick={() => downloadFile(item)}>
                                 {item.file && item.file.originalName}
                              </p>
                           </td>
                           <td>{item.type || 'N/A'}</td>
                           <td>
                              {item.enteredDate ? moment(item.enteredDate).format('MM-DD-YYYY') : 'N/A'}
                           </td>
                           <td>
                              {item.dueDate ? moment(item.dueDate).format('MM-DD-YYYY') : 'N/A'}
                           </td>
                           <td>{item.reminder ? 'yes' : 'no'}</td>
                           <td>
                              <i onClick={() => editData(item.id)} className="fa fa-edit mr-3 cursor-pointer" data-toggle="tooltip" title="Edit Document" />
                              <i onClick={() => showConfirm(item.id)} className="fa fa-trash cursor-pointer" data-toggle="tooltip" title="Delete Document" />
                           </td>
                        </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                  </tbody>
               </table>
            </div>
            <div className="d-flex justify-content-between mt-3">
               {isWizard != true && (
                  <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               )}

               {isWizard === true && (
                  <button className="btn btn-primary w-lg btn-lg mt-0" onClick={() => {
                     skiptonextstep()
                     props.setforceExit(true)
                  }}>Exit Wizard </button>
               )}
               <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
            </div>
            <Modal centered isOpen={modal} size='md'>
               <ModalHeader toggle={toggle}>Document</ModalHeader>
               <ModalBody>
                  <DocumentForm
                     isView={false}
                     data={details}
                     uid={props.resId}
                     module={MODULES.RESIDENT}
                     submitCreate={submitResDocCreate}
                     submitUpdate={sumbitResDocUpdate}
                  />
               </ModalBody>
            </Modal>
         </div >
      </>
   )
}

export default ResDocumentList