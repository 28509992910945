import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.CONTACT.ADD_CONTACT + data.staff, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.CONTACT.UPDATE_DELETE_CONTACT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.CONTACT.UPDATE_DELETE_CONTACT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//liscences

export function* addLiscence(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.LISCENCE.ADD_LISCENCE + data.staff, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateLiscence(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.LISCENCE.UPDATE_DELETE_LISCENCE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteLiscence(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.LISCENCE.UPDATE_DELETE_LISCENCE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//document

export function* uploadDocument(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.DOCUMENT.UPLOAD_DOCUMENT + data.link, data.data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* addDocument(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.DOCUMENT.ADD_DOCUMENT + data.staff, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateDocument(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.DOCUMENT.UPDATE_DELETE_DOCUMENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteDocument(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.DOCUMENT.UPDATE_DELETE_DOCUMENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}