import { useSelector } from "react-redux";
import { OPERATIONS } from "../../config/config";

export function useUserSelector() {
   const user = useSelector(state => (state.user ? state.user.user : null))
   return user
}

export function useUserAuthSelector() {
   const isAuth = useSelector(state => (state.user ? state.user.isAuthenticated : null))
   return isAuth
}

export function useUserTypeSelector() {
   const type = useSelector(state => (state.user && state.user.user ? state.user.user.roles : null))
   return type
}

export function useUserPermissionsSelector() {
   const permissions = useSelector(state => (state.user && state.user ? state.user.permissions : null))
   return permissions
}
