import React, { useEffect, useState } from "react";
import { useResidentApi } from "../../hooks/api/residentApiHook";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { encryptId } from "../form-elements/functions";
import moment from "moment";
import 'moment-timezone';
import { useUserSelector } from "../../hooks/selectors/userSelector";
import { PER_PAGE } from "../../config/config";
import Pagination from "react-js-pagination";
import LazyImage from "../form-elements/lazyImage";

const ReportResIncidentModal = (props) => {
  const [data, setData] = useState({});
  const [search, setSearch] = useState("");
  const [activePage, setPage] = useState(1);

  const residentApi = useResidentApi();

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
  console.log(timeZone);

  useEffect(() => {
    if (props.isOpen) {
      getList();
    }
  }, [props.isOpen]);

  const getList = (page = 1) => {
    let params = {
      page,
      limit: PER_PAGE,
      search,
      resident: props.resident.id
    };

    residentApi.listIncident(
      params,
      (message, resp) => {
        let resInc = resp.data.length ? resp?.data?.map((inc) => {
          var localDate = moment.utc(inc?.incidentDate + ' ' + inc?.incidentTime, "YYYY-MM-DD HH:mm").local().tz(timeZone).format("YYYY-MM-DD");
          var localTime = moment.utc(inc?.incidentTime, "HH:mm").local().tz(timeZone).format("HH:mm");
          inc.incidentDate = localDate
          inc.incidentTime = localTime
          console.log(inc.incidentDate, inc.incidentTime)
          return inc;
        }) : [];
        setData(resp);
      },
      (message) => {}
    );
  };

  const close = () => {
    props.close();
  };

  const goToLink = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(`/incident-report/${encryptId(id)}`, "_blank").focus();
  };

  const handlePage = (page) => {
    if (activePage !== page) {
      getList(page);
      setPage(page);
    }
  };

  const doSearch = () => {
    setPage(1);
    getList(1);
  };

  return (
    <Modal isOpen={props.isOpen} centered size="lg">
      <ModalHeader toggle={close} style={{ textTransform: "capitalize" }}>
        Incidents of {props.resident?.firstName  || ''} {props.resident?.lastName || ''}
      </ModalHeader>
      <ModalBody className="fixed-height-modal">
        <div className="d-flex">
          <input
            className="form-control"
            type="text"
            placeholder="Search by incident"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                doSearch();
              }
            }}
          />
          <button className="btn btn-outline-success ml-2" onClick={doSearch}>
            <i className="fa fa-search" />
          </button>
        </div>

        <div className="table-responsive mt-4">
          <table className="table table-hover table-bordered w-100">
            <thead>
              <tr>
                <th style={{ minWidth: "130px" }}>Title</th>
                <th style={{ minWidth: "130px" }}>Date</th>
                <th style={{ minWidth: "120px" }}>Time</th>
                <th style={{ minWidth: "120px" }}>Status</th>
                <th style={{ minWidth: "130px" }}>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.data && data.data.length ? (
                data.data.map((item, i) => (
                  <tr
                    key={i}
                    onClick={e => goToLink(e, item.id)}
                    className="cursor-pointer"
                  >
                    <td>{item.title || "N/A"}</td>
                    <td>{moment(item.incidentDate).format("MM-DD-YYYY")}</td>
                    <td>{moment(item.incidentTime, 'HH:mm:ss').format('hh:mm a')}</td>
                    <td>{item?.status || "N/A"}</td>
                    <td>{item?.incidentTypes?.map((t, ind) => (
                      ind < 2 && <span className="badge badge-info mr-2" style={{ fontSize: '12px' }}>{`${t.label.substr(0, 15)}..`}</span>
                    )) || ''} { item.incidentTypes.length > 2 ? `+${item.incidentTypes.length - 2} more` : ''}</td>
                    <td>
                      <a href="#" className="text-dark" onClick={e => goToLink(e,item.id)}>
                        <i className="fa fa-eye cursor-pointer" />
                      </a>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="no-td" colSpan="3">
                    No results found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="row mt-1">
          <div class="col-lg-6">
            {data.total > 0 && <p>Total results: {data.total}</p>}
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            {data.totalPage > 1 ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={PER_PAGE}
                totalItemsCount={data.total ? data.total : 1}
                pageRangeDisplayed={5}
                onChange={(page) => handlePage(page)}
                innerClass="pagination"
                itemClass="paginate_button page-item"
                linkClass="page-link"
                activeClass="active"
                hideFirstLastPages={true}
                prevPageText="Previous"
                nextPageText="Next"
              />
            ) : null}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ReportResIncidentModal;
