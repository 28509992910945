import React from 'react'
import Select from 'react-select'

export const SelectInput = ({ inputref, value, disabled, error, options, placeholder, onChange, isMulti, ...props }) => {
   return (
      <>
         <Select
            styles={{
               menu: provided => ({ ...provided, zIndex: 9999 })
            }}
            className="se-stat"
            ref={inputref}
            options={options}
            value={value}
            id={props.name}
            placeholder={placeholder}
            onChange={val => onChange(val)}
            onBlur={val => props.onBlur(val)}
            isDisabled={disabled}
            isMulti={isMulti}
         />
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}