import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { formatPhone } from '../../form-elements/functions'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const ContactTab = (props) => {

   const [modal, setModal] = useState(false)
   const [data, setData] = useState({})

   const history = useHistory()

   useEffect(() => {
      setSearch()
   }, [])

   const setSearch = () => {
      window.$(document).ready(function () {
         window.$("#resContViewInput").on("keyup", function () {
            var value = window.$(this).val().toLowerCase();
            window.$("#resContViewTable tr").filter(function () {
               window.$(this).toggle(window.$(this).text().toLowerCase().indexOf(value) > -1)
            });
            document.querySelectorAll('.no-res').forEach(e => e.remove());
            if (window.$('#resContViewTable tr:visible').length == 0) {
               let table = document.getElementById("resContViewTable")
               let row = table.insertRow(0)
               row.className = "no-res"
               let cell = row.insertCell(0);
               cell.colSpan = "3"
               cell.className = "no-td"
               cell.innerHTML = "No results found"
            }
         });
      });
   }

   return (
      <div className="mt-3 p-0 pb-3 details-wrap">
         <input className="form-control mb-3" id="resContViewInput" type="text" placeholder="Search.." />

         <div className="table-responsive">
            <table className="table table-striped table-bordered w-100">
               <thead>
                  <tr>
                     <th style={{ minWidth: '140px' }}>Name</th>
                     <th style={{ minWidth: '140px' }}>Phone</th>
                     <th style={{ minWidth: '120px' }}>Relation</th>
                     <th>Primary</th>
                  </tr>
               </thead>
               <tbody id="resContViewTable">
                  {props.data && props.data.length ? props.data.map((item, i) =>
                     <tr key={i} onClick={() => { setModal(true); setData(item) }}>
                        <td>{item.firstName} {item.lastName}</td>
                        <td>{item.phone ? (/[,\-]/.test(item.phone) ? item.phone : formatPhone(item.phone)) : ''}</td>
                        <td>{item.relation}</td>
                        <td>{item.isPrimary ? 'Yes' : 'No'}</td>
                     </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
               </tbody>
            </table>
         </div>

         <ViewContact
            isOpen={modal}
            close={() => setModal(false)}
            data={data}
         />

         <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
            <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
         </div>
      </div>
   )
}

export default ContactTab

const ViewContact = (props) => {
   const [alert, setAlert] = useState(null)



   return (
      <>
         {alert}
         <Modal centered isOpen={props.isOpen} size='md'>
            <ModalHeader toggle={props.close}>Contacts</ModalHeader>
            <ModalBody>
            <div className="row">
                  <div className="col-12">
                     <div className="form-group">
                        <label className="form-label">Name</label>
                        <p className="detail-text">{props.data.firstName || ''} {props.data.lastName || ''}</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-12">
                     <div className="form-group">
                        <label className="form-label">Phone</label>
                        <p className="detail-text">{props.data.phone && formatPhone(props.data.phone) || ''}</p>
                     </div>
                  </div>
               </div>
               <div className="row">
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Relation</label>
                        <p className="detail-text">{props.data.relation || ''}</p>
                     </div>
                  </div>
                  <div className="col-6">
                     <div className="form-group">
                        <label className="form-label">Is primary</label>
                        <p className="detail-text">{props.data.isPrimary ? 'Yes' : 'No'}</p>
                     </div>
                  </div>
               </div>
               
            </ModalBody>
         </Modal>
      </>
   )
}