import React, { useCallback, useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import { ACTIONS, MODULES, OPERATIONS, PER_PAGE, PER_PAGE_OTHERS } from '../../config/config';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import debounce from 'lodash.debounce'
import Pagination from 'react-js-pagination';
import { encryptId, formatPhone } from '../../components/form-elements/functions';
import moment from 'moment';
import RoleCheckHOC from '../../hoc/roleCheckHoc';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useUserPermissionsSelector, useUserSelector } from '../../hooks/selectors/userSelector';
import LazyImage from '../../components/form-elements/lazyImage';

const getAge = (dateString) => {
   const date = moment(dateString, 'YYYY-MM-DD')
   const years = moment().diff(date, 'years')
   return years
}
 


const ResidentList = (props) => {
   const [users, setUsers] = useState([])
   const [data, setData] = useState({})
   const [adminUser, setAdminUserData] = useState({})
   const [activePage, setPage] = useState(1)
   const [variables, setVariables] = useState({})
   const [isActive, setActive] = useState(1)
   const [cols, setCols] = useState([])
   const [alert, setAlert] = useState(null)

   const residentApi = useResidentApi()
   const history = useHistory()
   const dispatch = useDispatch()
   const user = useUserSelector()
   const userPermissions = useUserPermissionsSelector()

   const columns = [{
      dataField: 'id',
      text: '',
      hidden: true
   }, {
      dataField: 'resident.photo.fullUrl',
      // classes : 'rounded avatar-sm',
      text: '',
      classes: 'p-1',
      formatter: (cell, row) => <LazyImage src={row.photo ? row.photo.fullUrl : ''} height='50px' className="rounded avatar-sm" containerClassName="avatar" />
   }, {
      dataField: 'newMed',
      isDummyField: true,
      classes: ' text-center align-middle',
      headerStyle: () => { return { width: '20px', textAlign: 'right' } },
      text: 'New Med',
      style: () => { return { padding: '5px', textAlign: 'right' } },
      formatter: (cell, row) => (
         <div>
         {row.tempMedCount >0 ? <i className="mdi mdi-pill text-danger font-size-24" data-toggle="tooltip" title="Resident Medication Approvals" onClick={(e) => { e.stopPropagation(); goToApprovalList(row.id, 'medication_approval_list') }} /> : ''}
         </div>)
      },
   {
      dataField: 'firstName',
      // onClear: 'clear',
      text: 'First Name',
      filter: textFilter(),
      sort: true,
      headerStyle: () => { return { minWidth: '120px' } },
   }, {
      dataField: 'lastName',
      text: 'Last Name',
      filter: textFilter(),
      sort: true,
      headerStyle: () => { return { minWidth: '120px' } },
   }, {
      dataField: 'facility.name',
      text: 'Facility',
      filter: textFilter(),
      sort: true,
      headerStyle: () => { return { minWidth: '140px' } },
   }, {
      dataField: 'birthDate',
      text: 'DOB / Age',
      filter: textFilter({placeholder: 'Enter DOB'}),
      sort: true,
      headerStyle: () => { return { minWidth: '130px' } },
      formatter: (cell, row) => `${moment(cell).format('MM-DD-YYYY')} / ${getAge(row.birthDate)}`
   }, {
      dataField: 'movedInDate',
      text: 'Move In Date',
      filter: textFilter(),
      headerStyle: () => { return { minWidth: '130px' } },
      sort: true,
      formatter: (cell, row) => moment(row.movedInDate).format('MM-DD-YYYY')
   }, {
      dataField: 'contact.name',
      text: 'Primary Contact',
      filter: textFilter(),
      sort: true,
      headerStyle: () => { return { minWidth: '120px' } },
      formatExtraData: 'contactName',
      formatter: (cell, row) => row.contact.length ? row.contact[0].firstName + ' ' + row.contact[0].lastName : 'N/A'
   }, {
      dataField: 'contact.phone',
      filter: textFilter(),
      sort: true,
      headerStyle: () => { return { minWidth: '130px' } },
      text: 'Primary Contact Phone',
      formatter: (cell, row) => row.contact.length ? (/[,\-]/.test(row.contact[0].phone) ? row.contact[0].phone : formatPhone(row.contact[0].phone)) : 'N/A'
   }, {
      dataField: 'medical.residing',
      text: 'Care Type Name',
      filter: textFilter(),
      headerStyle: () => { return { minWidth: '140px' } },
      sort: true,
      formatter: (cell, row) => cell ? `${cell === 'homehealth' ? 'Home Health' : 'Hospice'}: ${row.medical.hospice || '-'}` : 'N/A'
   }
   ];

   useEffect(() => {
      console.log("user",user)
     if(props && props.location && props.location.state && props.location.state=='without-facility'){
      setActive(3)
      getList(1, { isActive: 3 })
     }
     else{
      getList(1, { isActive: 1 })
     }
      columnSet();

      // console.log("useruseruseruseruseruseruser", user)
      // let userObj = {
      //   id: user.id
      // }
      // if (userObj) {
      //   userApi.getUser(
      //     userObj,
      //     (message, resp) => {
      //       console.log("getUsergetUsergetUsergetUsergetUser", resp);
      //     }, (message) => {
      //       console.log("messagemessagemessagemessage", message);
      //       //toast.error(message)
      //     }, 5000)
      // }

   }, [])

   

   useEffect(() => {
      columnSet()
   }, [activePage, variables])

   const goToResident = (id = '', manage = false) => {
      dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: '1' })
      if (id && !manage) {
         history.push(`/view-resident/${encryptId(id)}`)
      }
      else if (id && manage) {
         history.push(`/manage-resident/${encryptId(id)}`)
      }
      else {
         history.push('/create-resident')
      }
   }

   const goToApprovalList = (id = '', type) => {
      // history.push(`/approval-list/${encryptId(id)}`)
      history.push({ pathname: `/approval-list/${encryptId(id)}`, state: type });
   }
   const showAlert = (id) => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Delete resident?"
            onConfirm={() => confirmDeleteResident(id)}
            onCancel={() => setAlert(null)}
            focusCancelBtn={false}
         >
            Are you sure? If a resident passed away or moved out we suggest marking them inactive, not delete. Inactive keeps their records per state requirements. To mark inactive, go in to the residents page and scroll to the bottom and choose inactive and save.
      </SweetAlert>
      )
   }

   const confirmDeleteResident = (id) => {
      setAlert(null)
      residentApi.deleteResident({ id },
         (message, resp) => {
            toast.success('Resident deleted successfully')
            getList(1, { ...variables, isActive })
         }, (message) => {
            toast.error(message)
         })
   }

   const columnSet = () => {
      let temp = [...columns]
      let ob = {
         dataField: 'isActive',
         text: 'Active',
         sort: true,
         style: { minWidth: '100px' },
         formatter: (cell, row) => row.isActive ? 'Yes' : 'No'
      }

      temp.push(ob)
      temp.push(
         {
            dataField: 'actions',
            isDummyField: true,
            headerStyle: () => { return { width: '20px', textAlign: 'right' } },
            text: 'Action',
            style: () => { return { padding: '5px', textAlign: 'right' } },
            formatter: (cell, row) => (
               <div>
                  <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.READ]}>
                     <i className="fa fa-eye mr-2" onClick={(e) => { e.stopPropagation(); goToResident(row.id) }} data-toggle="tooltip" title="View Resident" />
                  </RoleCheckHOC>
                  <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.UPDATE]}>
                     <i className="fa fa-edit mr-0" onClick={(e) => { e.stopPropagation(); goToResident(row.id, true) }} data-toggle="tooltip" title="Edit Resident" />
                  </RoleCheckHOC>
                  {/* {row.tempMedCount >0 ? <i className="fa fa-medkit res-medkit ml-1 mr-1" onClick={(e) => { e.stopPropagation(); goToApprovalList(row.id, 'medication_approval_list') }} data-toggle="tooltip" title="Resident Medication Approvals" />  : '' } */}
                  {/* <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.DELETE]}>
                     <i className="fa fa-trash" data-toggle="tooltip" title="Delete Resident" onClick={(e) => { e.stopPropagation(); showAlert(row.id) }} />
                  </RoleCheckHOC> */}
               </div>
            )
         }
      )
      setCols(temp)
   }

   const getList = (page, additional = {}) => {
      // console.log('additional', additional,user)
      // let params = { page, limit: PER_PAGE, ...additional }
      let params = { page, limit: PER_PAGE_OTHERS, ...additional }

      if(additional?.isActive!=3){
      residentApi.residentList(params,
         (message, resp) => {
            // console.log("resprespresprespresp",resp.data);
            let temp =
               resp.data.length ?
               resp.data.map((item) => {
                  return {
                     ...item,
                     tempMedCount: item?.tempMed?.length
                  }
               }) : []
            setUsers(temp)
            setData({ total: resp.total, totalPage: resp.totalPage })
            setAdminUserData(resp.adminUser)
         }, (message) => { })
      }
      else if(additional?.isActive==3){
         residentApi.residentListWithoutFacility(params,
            (message, resp) => {
               // console.log("resprespresprespresp",resp.data);
               let temp =
               resp.data.length ?
               resp.data.map((item) => {
                  return {
                     ...item,
                     tempMedCount: item?.tempMed?.length
                  }
               }) : []
               setUsers(temp)
               setData({ total: resp.total, totalPage: resp.totalPage })
            }, (message) => { }) 
      }
   }

   const handleTableChange = debounce((type, { filters, sortField, sortOrder, }) => {
      let searchObj = { isActive }
      for (const dataField in filters) {
         searchObj[dataField.replace('medical.residing', 'hospice').replace('facility.name', 'facilityName').replace('contact.name', 'contactName').replace('contact.phone', 'contactPhone')] = filters[dataField].filterVal
      }
      if (sortField && sortOrder) {
         searchObj['sort_type'] = sortOrder.toUpperCase()
         searchObj['sort'] = sortField.replace('medical.residing', 'hospice').replace('facility.name', 'facilityName').replace('contact.name', 'contactName').replace('contact.phone', 'contactPhone')
      }
      setPage(1)
      setVariables(searchObj)
      setUsers([])
      setTimeout(() => {
         getList(1, searchObj)
      }, 1500);
   }, 1500)

   const handlePage = (page) => {
      if (activePage !== page) {
         getList(page, { ...variables, isActive })
         setPage(page)
      }
   }

   const handleRadio = (item) => {
      setActive(item)
      getList(1, { ...variables, isActive: item })
      setPage(1)
   }

   const checkPermission = (operation) => {
      return userPermissions.some(item => item.module === MODULES.CONTACT && item.operation === operation)
   }

   const rowEvents = {
      onClick: (e, row, rowIndex) => {
         //if (checkPermission(OPERATIONS.READ)) {
            goToResident(row.id)
         //}
      }
   }

   return (
      <div className="page-content">
         {alert}
         <div className="container-fluid">
            {/* start page title */}
            <div className="row mb-2">
               <div className="col-lg-4 col-sm-12 col-xs-12"><h4 className="mb-0 font-size-18">Residents</h4></div>
               <div className='col-lg-8 col-sm-12 col-xs-12 text-right'>
                  <div className='row'>
                     <div className='col-lg-10 text-right p-lg-0'>
                        <div className='btn-group mr-lg-2'>
                           <button className={'btn w-xs waves-effect ' + (isActive == 2 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(2)}>All</button>
                           <button className={'btn w-xs waves-effect ' + (isActive == 1 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(1)}>Active</button>
                           <button className={'btn w-xs waves-effect ' + (isActive == 0 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(0)}>In-Active</button>
                           {user?.roles != 'staff' &&
                           <button className={'btn w-xs waves-effect ' + (isActive == 3 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(3)}>Without Facility</button>
                           }
                           {user?.roles == 'staff' && adminUser &&
                           <button className={'btn w-xs waves-effect ' + (isActive == 3 ? 'btn-dark' : 'btn-outline-dark')} onClick={() => handleRadio(3)}>Without Facility</button>
                           }
                           
                        </div>
                     </div>
                     <div className='col-lg-2'>
                        <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.CREATE]}>
                           <button type="button" className="btn btn-primary waves-light mt-xs-3 btn-block" onClick={() => goToResident('')}><i className="bx bx-plus" /> &nbsp;Add Resident</button>
                        </RoleCheckHOC>
                     </div>
                  </div>
               </div>
            </div>
            {/* end page title */}

            <div className="row manage_res">
               <div className="col-12">
                  
                  {/*<div className="mb-3">
                     <div className='row'>
                        <div className='col-lg-1'>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="formRadios" id="formRadios1" onChange={() => handleRadio(1)} checked={isActive === 1} />
                              <label class="form-check-label" htmlFor="formRadios1">
                                 Active
                              </label>
                           </div>
                        </div>
                        <div className='col-lg-1'>
                           <div className="form-check">
                              <input className="form-check-input" type="radio" name="formRadios" id="formRadios2" onChange={() => handleRadio(0)} checked={isActive === 0} />
                              <label className="form-check-label" htmlFor="formRadios2">
                                 Inactive
                              </label>
                           </div>
                        </div>
                        <div className='col-lg-1'>
                           <div className="form-check">
                              <input className="form-check-input" type="radio" name="formRadios" id="formRadios3" onChange={() => handleRadio(2)} checked={isActive === 2} />
                              <label className="form-check-label" htmlFor="formRadios3">
                                 All
                              </label>
                           </div>
                        </div>
                        {user?.roles != 'staff' && 
                           <div className='col-lg-3'>
                              <div className="form-check">
                                 <input className="form-check-input" type="radio" name="formRadios" id="formRadios4" onChange={() => handleRadio(3)} checked={isActive === 3} />
                                 <label className="form-check-label" htmlFor="formRadios4">
                                 Without Facility Assigned
                                 </label>
                              </div>
                           </div>
                        }
                     </div>
                     </div>*/}
                  
                  <div className="card">
                     <div className="card-body p-0">
                        <div className="table-responsive data-table resident-table">
                           {cols.length ?
                              <BootstrapTable
                                 keyField='id'
                                 hover
                                 rowEvents={rowEvents}
                                 remote={{ filter: true, sort: true }}
                                 filter={filterFactory()}
                                 onTableChange={handleTableChange}
                                 data={users}
                                 noDataIndication={() => data && data.total === 0 ? <p>No results found</p> : <p>Loading...</p>}
                                 columns={cols}
                              /> : null}
                        </div>
                        <div className="row">
                           <div className="col-lg-6">
                              {data.total > 0 && <p>Total results: {data.total}</p>}
                           </div>
                           <div className="col-lg-6 d-flex justify-content-end">
                              {data.totalPage > 1 ?
                                 <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={PER_PAGE}
                                    totalItemsCount={data.total ? data.total : 1}
                                    pageRangeDisplayed={5}
                                    onChange={handlePage}
                                    innerClass='pagination'
                                    itemClass='paginate_button page-item'
                                    linkClass='page-link'
                                    activeClass='active'
                                    hideFirstLastPages={true}
                                    prevPageText='Previous'
                                    nextPageText='Next'
                                 /> : null}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div >
   )
}

export default ResidentList