import React from "react";
import { Input } from 'reactstrap';

export const TextInput = ({ inputref, value, error, ...rest }) => {
   return (
      <>
         <label htmlFor={rest.name} className="form-label">{rest.label} {rest.required && <span className="text-danger">*</span>}</label>
         <Input
            ref={inputref}
            maxLength={rest.maxLength}
            value={value ? value : ''}
            className="form-control"
            id={rest.name}
            placeholder={rest.placeholder}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
            readOnly={rest.readOnly}
            type={rest.type || 'text'}
         />
         {error?.message ?
            <div className="text-danger">
               <small>{error?.message}</small>
            </div>
            : null}
      </>
   )
}