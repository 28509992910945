import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  decryptId,
  formatPhone,
  formatSSN,
} from '../../components/form-elements/functions';
import {useResidentApi} from '../../hooks/api/residentApiHook';
import ReactToPrint from 'react-to-print';
import {NO_IMAGE} from '../../config/config';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
import 'bootstrap-daterangepicker/daterangepicker.css';

const Template = props => {
  const [resident, setResident] = useState({});
  const [medication, setMedication] = useState([]);
  const [discontd, setDiscontd] = useState([]);
  const [getAge, setAge] = useState();

  const {id} = useParams();
  const history = useHistory();
  const residentApi = useResidentApi();
  const [startDateAndEnddate, setStartAndEndDate] = useState(null);
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();

  useEffect(() => {
    const currentDate=moment(new Date()).utc().format('YYYY-MM-DD')
    var previousOneYearDate = moment(currentDate).utc().subtract(90, "days").format("YYYY-MM-DD");
    console.log('currentDate', currentDate,previousOneYearDate)
    getInfo(previousOneYearDate, currentDate);
  }, []);

  const calculateAge = dob => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    setAge(age);
  };

  const getAges = dateString => {
    const date = moment(dateString, 'YYYY-MM-DD');
    const years = moment().diff(date, 'years');
    return years;
  };

  const getInfo = (discontinue_start_date, discontinue_end_date) => {
    if (isNaN(decryptId(id))) {
      toast.error('Invalid Link');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    } else {
      residentApi.nineOneOne(
        {id: decryptId(id), discontinue_start_date:discontinue_start_date, discontinue_end_date: discontinue_end_date},
        (message, resp) => {
          calculateAge(resp.resident.birthDate);
          setResident(resp.resident);
          let actMed = resp.activeMedication.length
            ? resp.activeMedication.map(item => {
                return {
                  name: item.name,
                  strength: item.strength,
                  units: item.units,
                  disp: item.disp,
                  spDirections: item.spDirections,
                };
              })
            : [];

          let disMed = resp.discontinuedMedication.length
            ? resp.discontinuedMedication.map(item => {
                return {
                  name: item.name,
                  strength: item.strength,
                  units: item.units,
                  disp: item.disp,
                  deletedAt: item.deletedAt,
                  spDirections: item.spDirections,
                };
              })
            : [];
          console.log(resp.resident?.contact?.length,resp.resident.medical?.medicalCond?.trim().split(' ').length);
          setMedication(actMed);
          setDiscontd(disMed);
        },
        message => {
          toast.error(message);
        },
      );
    }
  };

  const onEventData = (event, picker) => {
    const startDate = (moment(picker.startDate._d).format('YYYY-MM-DD'))
    const endDate = (moment(picker.endDate._d).format('YYYY-MM-DD'))
    console.log(startDate);
    console.log(endDate)
    setStartAndEndDate(`${startDate} to ${endDate}`)
    setStartDates(startDate)
    setEndDates(endDate)
  
  }
  
  const handleCallback = (start, end, label) => {
    console.log(start, end, label);
  }
  
  const onCancelData = () => {
    setStartAndEndDate(null)
  }
  
  const onApplyData = () =>{
    getInfo(startDates, endDates);
  
  }

  const styles = {
    emSectionMt2:{
      marginTop: "2%"
    }
  };

  return (
    <div className="row f_1 emergency_report">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-body">
            <div className="em-sections">
              <h5>Resident Face Sheet</h5>
              <div className="pl-20 pr-20">
                <div className="row mb-3 print-row">
                  <div className="col-lg-3 col-md-4 mb-3">
                    <img
                      src={resident.photo ? resident.photo.fullUrl : NO_IMAGE}
                      alt=""
                      style={{maxHeight: '200px', maxWidth: '100%'}}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4">
                    <p style={{fontSize: '0.95rem'}}>
                      <strong>Resident Name:</strong>
                      {resident.firstName} {resident.lastName}
                    </p>
                    <p>
                      <strong>SSN:</strong>
                      {resident.ssn ? formatSSN(resident?.ssn?.replace(/[^\d]/g, '')) : 'N/A'}
                    </p>
                    <p>
                      <strong>DOB:</strong>
                      {moment(resident.birthDate).format('MMM DD, YYYY')}
                    </p>
                    <p>
                      <strong>Age:</strong>
                      {/* {getAge} Years */}
                      {resident?.birthDate
                        ? getAges(resident?.birthDate)
                        : ''}{' '}
                        years
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row print-row">
              <div className="col-lg-6 col-md-6">
                <div className="em-sections">
                  <h5>Facility</h5>
                  <div className="pl-20 pr-20">
                    <div className="row">
                      <div className="col-lg-12">
                        <p>
                          <strong>Name:</strong>
                          {resident.facility && resident.facility.name}
                        </p>
                        <p>
                          <strong>Address Line 1:</strong>
                          {resident.facility && resident.facility.addrLine1}
                        </p>
                        <p>
                          <strong>Address Line 2:</strong>
                          {resident.facility && resident.facility.addrLine2
                            ? resident.facility.addrLine2
                            : 'N/A'}
                        </p>
                        <p>
                          <strong>Phone:</strong>
                          {resident.facility && resident.facility.phone
                            ? formatPhone(resident.facility.phone)
                            : 'N/A'}
                        </p>
                        <p>
                          <strong>Administrator:</strong>
                          {resident.facility && resident.facility.administrator
                            ? resident.facility.administrator
                            : 'N/A'}
                        </p>
                        <p>
                          <strong>Admin Phone:</strong>
                          {resident.facility && resident.facility.adminPhone
                            ? formatPhone(resident.facility.adminPhone)
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="em-sections">
                  <h5>Insurance Information</h5>
                  <div className="pl-20 pr-20">
                    <div className="row">
                      <div className="col-lg-12">
                        <p>
                          <strong>Provider:</strong>
                          {resident.medical &&
                          resident.medical.insuranceProvider
                            ? resident.medical.insuranceProvider
                            : 'N/A'}
                        </p>
                        <p>
                          <strong>Account Number:</strong>
                          {resident.medical && resident.medical.accountNo
                            ? resident.medical.accountNo
                            : 'N/A'}
                        </p>
                        <p>
                          <strong>Group Number:</strong>
                          {resident.medical && resident.medical.groupNo
                            ? resident.medical.groupNo
                            : 'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="em-sections">
              <h5>Emergency Contacts</h5>
              <div className="pl-20 pr-20">
                {resident.contact && resident.contact.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{minWidth: '120px'}}>Name</th>
                          <th style={{minWidth: '140px'}}>Phone</th>
                          <th style={{minWidth: '100px'}}>Relation</th>
                        </tr>
                      </thead>
                      <tbody>
                        {resident.contact
                          .map(cont => ({
                            ...cont,
                            firstName: cont.reusableContact
                              ? cont.reusableContact.firstName
                              : cont.firstName,
                            lastName: cont.reusableContact
                              ? cont.reusableContact.lastName
                              : cont.lastName,
                            phone: cont.reusableContact
                              ? cont.reusableContact.phone
                              : cont.phone,
                            type: cont.reusableContact
                              ? cont.reusableContact.type
                              : cont.type,
                          }))
                          .map((item, i) => (
                            <tr key={i}>
                              <td>
                                {item.firstName} {item.lastName}
                              </td>
                              <td>{(/[,\-]/.test(item.phone) ? item.phone : formatPhone(item.phone))}</td>
                              <td>{item.relation}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h6>No results found</h6>
                )}
              </div>
            </div>
            
            <div class={resident?.contact?.length ? (resident?.contact?.length < 2 ? ((resident.medical?.medicalCond?.trim().split(' ').length > 1) ? (resident.medical?.medicalCond?.trim().split(' ').length > 91 ? 'page-break' : 'page-break') : '') : '') : 'page-break'}>&nbsp;</div>
            <div className='em-sections'>
              <h5>Medical Conditions</h5>
              <div className="pl-20 pr-20">
                <div className="row">
                  <div className="col-lg-12">
                    {resident.medical && resident.medical.medicalCond ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: resident.medical.medicalCond,
                        }}
                        style={{overflow: 'auto'}}
                      />
                    ) : (
                      <p className="detail-text">N/A</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="em-sections">
              <h5>Active Medications</h5>
              <div className="pl-20 pr-20">
                {medication && medication?.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Strength</th>
                          {/* <th>Units</th> */}
                          <th>Adverse Reaction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {medication.map((item, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="font-weight-bolder">
                                {item.name}
                              </td>
                              <td>{item.strength} {item.units}</td>
                              {/* <td>{item.units}</td> */}
                              <td>
                                {item.disp.length
                                  ? item.disp.map((obj, j) =>
                                      obj.adv_reaction ? (
                                        <p key={j} className="mb-1">
                                          {obj.adv_reaction}
                                        </p>
                                      ) : null,
                                    )
                                  : 'N/A'}
                              </td>
                            </tr>
                            {item.spDirections ? (
                              <tr style={{borderBottom: '2px solid #a2a2a2'}}>
                                <td colSpan="4">
                                  <span>
                                    <b>Special Directions:</b>
                                  </span>{' '}
                                  {item.spDirections}
                                </td>
                              </tr>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h6>No results found</h6>
                )}
              </div>
            </div>
            
            <div class={resident?.contact?.length ? (resident?.contact?.length < 2 ? (resident?.medical?.medicalCond && resident.medical?.medicalCond?.trim().split(' ').length > 91 && medication && medication?.length > 4 ? 'page-break' : '' ) : 'page-break') : (resident?.medical?.medicalCond && resident.medical?.medicalCond?.trim().split(' ').length > 91 ? 'page-break' : '')}>&nbsp;</div>
            <div className="em-sections">
              <h5>
                Discontinued Medications{' '}
                <span className="font-15 pre-line">
                  (within the past 90 days)
                </span>
                {/* <span className="font-15 pre-line move-left">
                <DateRangePicker onEvent={onEventData} onCallback={handleCallback}
             onCancel={onCancelData} onApply={onApplyData}
             >
  <input type="text" className="form-control" placeholder={(startDateAndEnddate || startDateAndEnddate!=null) ? startDateAndEnddate : "Select Date Range"} />
  
</DateRangePicker>
                </span> */}
              </h5>
              <div className="pl-20 pr-20">
                {discontd && discontd.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Strength</th>
                          <th>Units</th>
                          <th>Adverse Reaction</th>
                          <th>Discontinued Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discontd.map((item, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="font-weight-bolder">
                                {item.name}
                              </td>
                              <td>{item.strength}</td>
                              <td>{item.units}</td>
                              <td>
                                {item.disp.length
                                  ? item.disp.map((obj, j) =>
                                      obj.adv_reaction ? (
                                        <p key={j} className="mb-1">
                                          {obj.adv_reaction}
                                        </p>
                                      ) : null,
                                    )
                                  : 'N/A'}
                              </td>
                              <td>
                                {item.deletedAt ? (
                                  <>
                                    <p className="mb-2">
                                      {moment(item.deletedAt).format(
                                        'MMM DD, YYYY',
                                      )}
                                    </p>
                                    <p>
                                      {moment(item.deletedAt).format(
                                        '(hh:mm a)',
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                            {item.spDirections ? (
                              <tr style={{borderBottom: '2px solid #a2a2a2'}}>
                                <td colSpan="5">
                                  <span>
                                    <b>Special Directions:</b>
                                  </span>{' '}
                                  {item.spDirections}
                                </td>
                              </tr>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h6>No results found</h6>
                )}
              </div>
            </div>

            <div class={resident?.contact?.length < 2 ? (resident?.medical?.medicalCond && resident.medical?.medicalCond?.trim().split(' ').length > 91 ? (medication?.length > 4 && discontd?.length > 5  ? 'page-break' : '' ) : (medication?.length > 4 ? (discontd?.length > 5 ? 'page-break' : '') : (discontd?.length > 5 ? 'page-break' : (discontd?.length == 0 ? '' : 'page-break')))) : ''}>&nbsp;</div>
            <div className="em-sections">
              <h5>Covid Vaccination Information</h5>
              <div className="pl-20 pr-20">
                <div className="row">
                  <div className="col-lg-12">
                    {resident.isVaccinated && (
                      <div style={{overflow: 'auto'}}>
                        <p>
                          <strong>Is Covid Vaccinated:</strong>
                          {resident.isVaccinated &&
                          resident.isVaccinated === 'yes'
                            ? 'Yes'
                            : (resident.isVaccinated === 'unknown' ? 'Unknown' : 'No')}
                        </p>

                        <p>
                          <strong>Comment:</strong>
                          {resident.vaccineComment &&
                          resident.isVaccinated === 'yes'
                            ? resident.vaccineComment
                            : 'N/A'}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

class Temp extends React.Component {
  render() {
    return <Template />;
  }
}

class NineOneOne extends React.Component {
  render() {
    return (
      <div className="page-content">
        <style>
          {`@page {
    size: A4;
    margin: 0;
         }
  `}
        </style> {/* auto; */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">911 / Face Sheet</h4>
                {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
                  <button
                    className="btn btn-primary print-btn"
                    onClick={() => window.print()}>
                    Print
                  </button>
                ) : (
                  <ReactToPrint
                    documentTitle="face-sheet"
                    trigger={() => (
                      <button className="btn btn-primary">Print</button>
                    )}
                    content={() => this.componentRef}
                  />
                )}
              </div>
            </div>
          </div>
          <Temp ref={el => (this.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default NineOneOne;
