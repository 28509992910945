import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import UnAuthLayout from '../hoc/unAuthLayout'
import { useUserAuthSelector } from '../hooks/selectors/userSelector'

const PublicRoute = ({ component: Component, ...rest }) => {
    let isAuthenticated = useUserAuthSelector()

    return (
        <Route {...rest} render={props => {
            if (isAuthenticated) {
                return <Redirect to='/dashboard' />
            }

            return (
                <UnAuthLayout>
                    <Component {...props} />
                </UnAuthLayout>
            )
        }}
        />
    )
}

export default PublicRoute