import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyStaffList = React.lazy(() =>
   import('./staffList')
);

const StaffList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyStaffList {...props} />
   </React.Suspense>
);

export default StaffList