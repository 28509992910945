import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useCommonApi() {
   const callApi = useApiCall();

   const uploadFiles = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.UPLOAD_FILES, data, onSuccess, onError);
   }

   const uploadFile = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.UPLOAD_FILE, data, onSuccess, onError);
   }

   const deleteFiles = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.DELETE_FILES, data, onSuccess, onError);
   }

   const getRelations = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.GET_RELATIONS, data, onSuccess, onError);
   }

   const getStates = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.STATE_LIST, data, onSuccess, onError);
   }

   const getCities = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.CITY_LIST, data, onSuccess, onError);
   }

   const residentAlertsList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.RESIDENT_ALERTS_LIST, data, onSuccess, onError);
   }

   const staffAlertsList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.STAFF_ALERTS_LIST, data, onSuccess, onError);
   }

   const dashboard = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.COMMON.DASHBOARD, data, onSuccess, onError);
   }

   return {
      uploadFile,
      uploadFiles,
      deleteFiles,
      getRelations,
      getStates,
      getCities,
      residentAlertsList,
      staffAlertsList,
      dashboard
   }
}