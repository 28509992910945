import { call } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS } from '../config/config';

export function* addResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.ADD_RESIDENT, data, true);
      console.log("addResidentaddResidentaddResidentaddResident",resp);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentList(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentListJoinInventories(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST_JOIN_INVENTORY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentListByfacilityId(action) {
   try {
      console.log("SAGAGAGAGAGAGAGAGGAGAGAGAGA");
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST_BY_FACILITY, data, true);
    
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


export function* witnessListByIncidentIds(action) {
   try {
      console.log("SAGAGAGAGAGAGAGAGGAGAGAGAGA");
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.WITNESS_LIST_BY_INCIDENT, data, true);
    
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.GET_RESIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentDetailsForDOB(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.GET_RESIDENT_DOB, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.UPDATE_DELETE_RESIDENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* activateResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.ACTIVATE_RESIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteResident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.RESIDENT.UPDATE_DELETE_RESIDENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//emergency contact

export function* listEmContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.EM_CONTACT.LIST_EM_CONTACT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* addEmContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.EM_CONTACT.CREATE_EM_CONTACT + data.link, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateEMContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.EM_CONTACT.UPDATE_EM_CONTACT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteEmContact(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.RESIDENT.EM_CONTACT.DELETE_EM_CONTACT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//docs

export function* uploadResDoc(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.DOCS.UPLOAD_DOCS + data.link, data.data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* createResDoc(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.DOCS.CREATE_DOCS + data.link, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listResDoc(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.DOCS.LIST_DOCS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateResDoc(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.DOCS.UPDATE_DOCS + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteResDoc(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.RESIDENT.DOCS.DELETE_DOCS + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}


//Liscenses

export function* createResLiscense(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.LISCENCE.CREATE_LISCENSE + data.link, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listResLiscense(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.LISCENCE.LIST_LISCENSE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateResLiscense(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.LISCENCE.UPDATE_LISCENSE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteResLiscense(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.RESIDENT.LISCENCE.DELETE_LISCNSE + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//reports

export function* nineOneOne(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.NINE_ONE_ONE, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* sixTwoTwo(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.SIX_TWO_TWO, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* mAR(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.MAR, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* medicationHistory(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.MEDICATION_HISTORY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

//incidents
export function* listIncidents(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.INCIDENT.LIST_INCIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* addIncident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.INCIDENT.CREATE_INCIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateIncident(action) {
   try {
      const data = action.payload;
      // console.log('datasssssssssss', data)
      const resp = yield call(CallApi.PUT, APIS.RESIDENT.INCIDENT.UPDATE_INCIDENT + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getIncident(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.INCIDENT.GET_INCIDENT, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getIncidentTypes(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.INCIDENT.GET_INCIDENT_TYPES, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* thirdPartyPRNSubmits(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.RESIDENT.THIRD_PARTY_PRN_SUBMIT, data, true);
      console.log("addResidentaddResidentaddResidentaddResident",resp);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* residentListWithoutFacilities(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.RESIDENT.RESIDENT_LIST_WITHOUT_FACILITY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}