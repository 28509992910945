import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useFacilityApi() {
   const callApi = useApiCall();

   const addFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.ADD_FACILITY, data, onSuccess, onError);
   }

   const facilityList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.FACILITY_LIST, data, onSuccess, onError);
   }

   const updateFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.UPDATE_FACILITY, data, onSuccess, onError);
   }

   const deleteFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.DELETE_FACILITY, data, onSuccess, onError);
   }

   const getFacility = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.GET_FACILITY, data, onSuccess, onError);
   }

   const adminFacilityList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.FACILITY.ADMIN_FACILITY_LIST, data, onSuccess, onError);
   }

   return {
      facilityList,
      addFacility,
      updateFacility,
      deleteFacility,
      getFacility,
      adminFacilityList
   }
}