import React, { Component } from "react";
import AsyncSelect from "react-select/async";

export const AsyncSearch = ({
  inputRef,
  options,
  value,
  isClearable,
  onChange,
  onBlur,
  name,
  placeholder,
  error,
  label,
  required,
  defaultOptions
}) => {
  return (
    <>
    <label htmlFor={name} className="form-label">{label} {required && <span className="text-danger">*</span>}</label>
    {console.log("valuevaluevaluevalue",value)}
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        loadOptions={options}
        value={value}
        onChange={onChange}
        isClearable={isClearable == undefined ? false : isClearable}
        onBlur={onBlur}
        name={name}
        ref={inputRef}
        placeholder={placeholder}
        noOptionsMessage={() => "No Data"}
        className="witness"
      />
      {error?.message ? (
        <div className="text-danger danger-valid-txt">
          <small>{error?.message}</small>
        </div>
      ) : null}
    </>
  );
};
