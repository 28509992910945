import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyAncmentList = React.lazy(() =>
   import('./ancmentList')
);

const AncmentList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyAncmentList {...props} />
   </React.Suspense>
);

export default AncmentList