import { ACTIONS } from '../../config/config';

const initialState = {
  isLoading: false,
};

const loaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.LOADER.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case ACTIONS.LOADER.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default loaderReducer;
