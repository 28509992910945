import { call, put } from 'redux-saga/effects';
import { CallApi } from './call_api';
import { APIS, ACTIONS, STORAGE } from '../config/config';

export function* addMedicine(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.MEDICATION.ADD_MEDICATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listMedication(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.LIST_MEDICATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* typeListMedication(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.TYPE_MEDICATION_LIST, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updateMedication(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MEDICATION.UPDATE_DELETE_MEDICATION + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* deleteMedication(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.DELETE, APIS.MEDICATION.UPDATE_DELETE_MEDICATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* updatesMedications(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MEDICATION.UPDATE_DELETE_MEDICATION, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listMedicationForAlls(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.LIST_MEDICATION_FOR_THIRD_PARTY_ALL, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* listMedicationForAllsInventory(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.LIST_MEDICATION_FOR_ALL_INVENTORY, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }


   
}

export function* updateRxStatus(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.PUT, APIS.MEDICATION.UPDATE_RX_STATUS + data.id, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* getRxDetails(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.GET, APIS.MEDICATION.GET_RX_DETAILS, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}

export function* approveMedicationRx(action) {
   try {
      const data = action.payload;
      const resp = yield call(CallApi.POST, APIS.MEDICATION.APPROVE_MEDICATION_RX, data, true);
      if (resp.status >= 200 && resp.status < 300) {
         action && action.callbackSuccess && action.callbackSuccess(resp);
      } else {
         action && action.callbackError && action.callbackError(resp);
      }
   } catch (e) {
      action && action.callbackError && action.callbackError(e);
   }
}