import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { TextInput } from '../form-elements/textInput'
import { DateInput } from '../form-elements/datepicker';
import { MaskInput } from '../form-elements/maskInput';
import { useUserSelector } from '../../hooks/selectors/userSelector';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useUserApi } from '../../hooks/api/userApiHook';
import ImageModal from '../form-elements/imageModal';
import { useCommonApi } from '../../hooks/api/commonApiHook';
import { USER_TYPES } from '../../config/config';
import { changeDate } from '../form-elements/functions';

const schema = yup.object().shape({
   firstName: yup.string().required('First name is required').matches(/^[a-zA-Z]+$/, 'Invalid first name').max(20, 'Max 20 characters'),
   lastName: yup.string().required('Last name is required').matches(/^[a-zA-Z ]+$/, 'Invalid last name').max(40, 'Max 40 characters'),
   ssn: yup.string().nullable().max(20, 'Max 20 characters').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid SSN', val => {
      if (!val) return true;
      return val.length === 9;
   }),
   phone: yup.string().required('Phone is required').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val.length === 10),
   description: yup.string().max(250, 'Max 250 characters'),
   dssConfirmation: yup.string().when('role', {
      is: role => role === USER_TYPES.STAFF,
      then: yup.string().required('DSS confirmation required').max(20, 'Max 20 characters'),
   }),
   //username: yup.string().required('Username required').max(20, 'Max 20 characters'),
   username: yup.string().required('Username required'),
   birthDate: yup.string()
      .nullable()
      .test('Length', 'Invalid date of birth', function (value) {
         if (!value) return true;
         value = value && value.replace(/_/g, '')
         return value && value.length === 10 && moment(value, 'MM-DD-YYYY').isValid() && moment(value, 'MM-DD-YYYY').isSameOrBefore();
      }),
});

const ProfileForm = () => {

   const [isOpen, setOpen] = useState(false)
   const [photo, setPhoto] = useState({})

   const { handleSubmit, errors, control, reset, register } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });

   const user = useUserSelector()
   const commonApi = useCommonApi()
   const userApi = useUserApi()
   const history = useHistory()

   useEffect(() => {
      let temp = { ...user }
      temp.birthDate = changeDate(temp.birthDate)
      setPhoto(temp.photo ? temp.photo : {})
      reset(temp)
   }, [user])

   const onSubmit = data => {
      data.birthDate = data.birthDate ? moment(data.birthDate, 'MM-DD-YYYY').format('YYYY-MM-DD') : null
      data['id'] = user.id
      if (photo.id) {
         data['photoId'] = photo.id
      }
      delete data.email

      userApi.updateUser(data,
         (message, resp) => {
            userApi.getProfile(
               (message, resp) => {
                  toast.success('User updated successfully')
                  setTimeout(() => {
                     history.push('/dashboard')
                  }, 500);
               },
               (message) => {
                  //toast.error(message)
               })
         }, (message) => {
            toast.error(message)
         })
   }

   const uploadImg = () => {
      let formData = new FormData()
      formData.append('file', photo.file)
      formData.append('fileType', 'profile')

      commonApi.uploadFile({ data: formData, format: 'image' },
         (message, resp) => {
            setPhoto(resp)
            setOpen(false)
            toast.success('Please save to confirm changes')
         }, (message) => {
            toast.error(message)
         })
   }

   const handleImg = (e) => {
      let file = e.target.files[0]
      if (file) {
         let url = URL.createObjectURL(file)
         setPhoto({ fullUrl: url, file })
         setOpen(true)
      }
   }

   const closeModal = () => {
      setOpen(false)
      setPhoto({})
      document.getElementById('fileInput').value = ''
   }

   return (
      <>
         <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row f_1">
               <div className="col-lg-8">
                  <div className="card">
                     <div className="card-body">
                        <h6 className="mb-4">Company: <strong>{user.company && user.company.name}</strong></h6>
                        {console.log('user details', user)}
                        <input type='hidden' ref={register} value={user && user.roles} name="role" />
                        <div className="row">
                           <div className="col-lg-6" data-select2-id={1}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="firstName"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.firstName}
                                          label='First Name'
                                          placeholder='First name'
                                          type='text'
                                          required
                                          maxLength={20}
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6" data-select2-id={2}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="lastName"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          type='text'
                                          required
                                          maxLength={40}
                                          value={value}
                                          error={errors.lastName}
                                          label='Last Name'
                                          placeholder='Last name'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-6" data-select2-id={4}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="username"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          value={value}
                                          required
                                          error={errors.username}
                                          label='Username'
                                          placeholder="Username"
                                          type='text'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6" data-select2-id={9}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="email"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          type='text'
                                          value={value}
                                          required
                                          error={errors.email}
                                          label='Email'
                                          readOnly
                                          placeholder="Email"
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-6" data-select2-id={6}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="phone"
                                    render={({ ref, value, ...props }) => (
                                       <MaskInput
                                          mask="(999) 999-9999"
                                          maskPlaceholder="Phone"
                                          inputref={ref}
                                          required
                                          value={value}
                                          error={errors.phone}
                                          label='Phone'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6" data-select2-id={8}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="ssn"
                                    render={({ ref, value, ...props }) => (
                                       <MaskInput
                                          mask="999-99-9999"
                                          maskPlaceholder="SSN"
                                          inputref={ref}
                                          // required
                                          value={value}
                                          error={errors.ssn}
                                          label='SSN'
                                          type={'number'}
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                        </div>
                        <div className="row">
                           <div className="col-lg-6" data-select2-id={5}>
                              <div className="form-group" data-select2-id={15}>
                                 <label className="control-label">Date of Birth 
                                    {/* <span className="text-danger">*</span> */}
                                 </label>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="birthDate"
                                    render={({ ref, value, ...props }) => (
                                       <DateInput
                                          inputref={ref}
                                          value={value}
                                          error={errors.birthDate}
                                          placeholder='mm-dd-yyyy'
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>
                           {user.roles === 'staff' && <div className="col-lg-6" data-select2-id={8}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="dssConfirmation"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          type='text'
                                          value={value}
                                          error={errors.dssConfirmation}
                                          label='DSS Confirmation'
                                          required
                                          maxLength={20}
                                          placeholder=''
                                          {...props}
                                       />
                                    )}
                                 />
                              </div>
                           </div>}
                        </div>

                        {user.roles === USER_TYPES.STAFF && <div className="row">
                           <div className="col-lg-12" data-select2-id={9}>
                              <div className="form-group" data-select2-id={15}>
                                 <Controller
                                    defaultValue=''
                                    control={control}
                                    name="description"
                                    render={({ ref, value, ...props }) => (
                                       <TextInput
                                          inputref={ref}
                                          type='textarea'
                                          value={value}
                                          error={errors.description}
                                          label='Description'
                                          maxLength={250}
                                          {...props}
                                       />
                                    )}
                                 />
                                 <small className="text-muted float-right">(Maximum 250 characters)</small>
                              </div>
                           </div>
                        </div>}

                        <div className="row">
                           {/* <div className="col-lg-6">
                              <div className="form-group">
                                 <label className="form-label">Photo</label>
                                 <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile02"></input>
                                    <label className="custom-file-label" for="inputGroupFile02">Choose file</label>
                                 </div>
                              </div>
                           </div> */}
                           <div className="col-lg-6">
                              <div className="form-group photo-display">
                                 <label className="form-label">Photo</label>
                                 {photo && photo.id ?
                                    <div>
                                       <img src={photo.fullUrl} className="d-block mb-3" />
                                       <button className="btn btn-danger" onClick={() => setPhoto({})} type="button">Replace Photo</button>
                                    </div>
                                    :
                                    <div className="custom-file">
                                       <input type="file" className="custom-file-input" onChange={handleImg} accept=".jpg,.jpeg,.png" id="photo" id="fileInput" />
                                       <label className="custom-file-label" htmlFor="fileInput">Choose file</label>
                                    </div>
                                 }
                              </div>
                           </div>
                        </div>

                        <button type="submit" className="btn btn-success waves-effect waves-light">Save</button>
                     </div>
                  </div>
                  {/* end select2 */}
               </div>
            </div>
         </form>
         <ImageModal
            isOpen={isOpen}
            url={photo && photo.fullUrl ? photo.fullUrl : ''}
            close={closeModal}
            onSave={uploadImg}
         />
      </>
   )
}

export default ProfileForm