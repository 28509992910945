import React, { useEffect, useState, useCallback, forwardRef, useRef, useImperativeHandle } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { TextInput } from '../form-elements/textInput'
import { DateInput } from '../form-elements/datepicker';
import { MaskInput } from '../form-elements/maskInput';
import { RadioGroup } from '../form-elements/radioGroup';
import { toast } from 'react-toastify'
import { useStaffApi } from '../../hooks/api/staffApiHook';
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useUserApi } from '../../hooks/api/userApiHook';
import { useRoleApi } from '../../hooks/api/roleApiHook';
import { SelectInput } from '../form-elements/dropdown';
import { useUserPermissionsSelector } from '../../hooks/selectors/userSelector';
import { MODULES, OPERATIONS } from '../../config/config';
import SweetAlert from 'react-bootstrap-sweetalert';
import ReadCheckHOC from '../../hoc/readCheckHoc';
import ImageModal from '../form-elements/imageModal';
import { useCommonApi } from '../../hooks/api/commonApiHook';
import { changeDate } from '../form-elements/functions';
import { useFacilityApi } from '../../hooks/api/facilityApiHook';
import LazyImage from '../form-elements/lazyImage';
import CreatableSelect from "react-select/creatable";
import debounce from "lodash.debounce";
import { useDispatch } from 'react-redux';
import { ACTIONS, APIS } from '../../config/config';
import { useUserSelector } from '../../hooks/selectors/userSelector';
//import { useCompanyApi } from "../../hooks/api/companyApiHook";

const blankRole = { label: 'Select Role', value: '' }

const blank_staff = {
   id: null,
   phone: "",
   description: "",
   firstName: "",
   lastName: "",
   username: "",
   birthday: "",
   dssConfirmation: "",
   phone: "",
   email: "",
   ssn: "",
   photo: {},
   // facility: "",
};

const schema = yup.object().shape({
   firstName: yup.string().required('First name is required').matches(/^[a-zA-Z]+$/, 'Invalid first name').max(20, 'Max 20 characters'),
   lastName: yup.string().required('Last name is required').matches(/^[a-zA-Z ]+$/, 'Invalid last name').max(40, 'Max 40 characters'),
   // username: yup.string().required('Username required').max(20, 'Max 20 characters'),
   username: yup.string().required('Username required'),
   phone: yup.string().required('Phone is required').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid phone number', val => val.length === 10),
   ssn: yup.string().required('SSN is required').max(20, 'Max 20 characters').transform(val => val.replace(/[^\d]/g, '')).test('len', 'Invalid SSN', val => val.length === 9),
   description: yup.string().max(250, 'Max 250 characters'),
   email: yup.string().email('Invalid email').required('Email is required'),
   dssConfirmation: yup.string().required('DSS confirmation required').max(20, 'Max 20 characters'),
   birthday: yup.string()
      .nullable()
      .test('Length', 'Invalid date of birth', function (value) {
         let temp = value.replace(/_/g, '')
         return value && temp.length === 10 && moment(value, 'MM-DD-YYYY').isValid() && moment(value, 'MM-DD-YYYY').isSameOrBefore();
      }),
   // facility: yup.object().shape({
   //    label: yup.string(),
   //    value: yup.string()
   // }).nullable().required('Facility is required'),
});



const StaffForm = forwardRef((props, ref) => {
   console.log(props)
   const refe = React.useRef()

   const [users, setUsers] = useState([]);
   const [roleOptions, setRoles] = useState([])
   const [assigned, setAssigned] = useState('')
   const [alert, setAlert] = useState(null)
   const [defaultRole, setDefaultRole] = useState('')
   const [isOpen, setOpen] = useState(false)
   const [photo, setPhoto] = useState({})
   const [facilityList, setFacilities] = useState([])

   const [isWizard, setWizard] = useState(props.isWizard)

   const [emailSearch, setEmailSearch] = useState("");
   const [emailLoading, setEmailLoading] = useState(false);
   const [emailMenuIsOpen, setEmailMenuOpen] = useState(false);
   const [selectedEmail, setSelectedEmail] = useState("");
   const [formInitialised, setFormInitialised] = useState(false);
   const [isDisplay, setDisplay] = useState(true)
   const [disableIndex, setDisableIndex] = useState("");
   const [manageStaff, setManageStaff] = useState();
   const [detailsValue, setDetailsValue] = useState();
   

   const staffApi = useStaffApi()
   const history = useHistory()
   const roleApi = useRoleApi()
   const commonApi = useCommonApi()
   const userApi = useUserApi()
   const facilityApi = useFacilityApi()
   const userPermissions = useUserPermissionsSelector()
   const dispatch = useDispatch()
   const user = useUserSelector()

   const { handleSubmit, errors, control, reset, setValue, watch, getValues } = useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema)
   });


   const watchRole = watch("roleId");

   useEffect(() => {
      if (props.userId) {
         // getMultipleFacility()
         getUserDetails()
      }
      else {
         getRolesList()
      }
      getDefault()
      getFacilityList()

   }, [])

   const skiptonextstep = () => {
      props.onChangeStep()
   }

   const handleEmailSearch = (inputValue = "") => {
      setEmailLoading(true);
      setEmailSearch(inputValue);
      setEmailLoading(false);
      setEmailMenuOpen(true);
      setDisableIndex(0);
   };

   const searchFunc = useCallback(
      debounce((val, type) => {
         handleEmailSearch(val);
      }, 1500),
      []
   );

   const typeAndSearch = (val, type) => {
      setDisableIndex(0);
      if (val.length >= 3) {
         searchFunc(val, type);
      }
      setEmailSearch(val);
   };

   const getAllFormValue = () => {
      var data = getValues();
      if (!data.roleId.value) {
         toast.error('Please assign a role to staff member')
         return
      }
      data.roleId = data.roleId.value ? data.roleId.value : null
      data['userType'] = 3
      console.log('data.facility', data.facility)
      data['facilityId'] = parseInt(data.facility.value)
      data['isActive'] = data.isActive == '1' ? true : false

      if (photo.id) {
         data['photoId'] = photo.id
      }

      if (props.userId) {
         data['id'] = props.userId
         data.birthDate = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         data.phone = data.phone.replace(/[^\d]/g, '')
         delete data.email

         userApi.updateUserMultipleFacility(data,
            (message, resp) => {
               // toast.success('Staff user updated successfully')
               // reset()
            }, (message) => {
               toast.error(message)
            })

         userApi.updateUser(data,
            (message, resp) => {
               toast.success('Staff user updated successfully')
               setSelectedEmail({
                  label: "",
                  value: ""
               })
               reset()
            }, (message) => {
               toast.error(message)
            })
      }
      else {
         data.birthday = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         data.phone = data.phone.replace(/[^\d]/g, '')
         console.log("datadatadatadatadatadatadatadatadatadatadata", data);
         staffApi.createStaff(data,
            (message, resp) => {
               toast.success('Staff user created successfully')
               //history.push('/staff-list')
               //props.onChangeStep()
               setSelectedEmail({
                  label: "",
                  value: ""
               })
               //reset()
               if(Object.keys(photo).length >0){
                  var photoObj = {
                     createdAt: "",
                     fileType: "",
                     fullUrl: "",
                     id: "",
                     mime: "",
                     name: "",
                     originalName: "",
                     path: ""
                  }
                  setPhoto(photoObj)
               }
               setTimeout(() => {
                  dispatch({type:ACTIONS.LOADER.HIDE_LOADER})
                  setDisplay(true)

               }, 3000);
               setDisplay(false)
               dispatch({type:ACTIONS.LOADER.SHOW_LOADER})
            }, (message) => {
               toast.error(message)
            })
      }
   }

   const getMultipleFacility = () =>{
      let params={id:props.userId}
      userApi.getUserMultipleFacility(params,
         (message, resp) => {
            console.log('multiple facility', resp)
            setManageStaff(resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item.facilityName, value: item.facilityId })))
            // reset({facility: resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item?.facilityName, value: item?.facilityId }))})
            setValue('facility', resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item?.facilityName, value: item?.facilityId })))
         }, (message) => {
            toast.error(message)
         })
   }

   const getUserDetails = () => {
      userApi.getUser({ id: props.userId },
         (message, resp) => {
            console.log(resp,user)
            setDetailsValue(resp)
            setPhoto(resp.photo ? resp.photo : {})
            resp.isActive = resp.isActive == true ? '1' : false
            resp.facility = resp?.multipleFacility?.map((item, index) => ({ label: item.facilityName, value: item.facilityId }))
            // ({ label: resp?.multipleFacility?.name, value: resp?.multipleFacility?.id })
            // resp.facility = ({ label: resp?.facility?.name, value: resp?.facility?.id })

            setSelectedEmail({
               label: resp.email,
               value: resp.email
            })

            reset(resp)
            let params={id:props.userId}
      // userApi.getUserMultipleFacility(params,
      //    (message, resp) => {
      //       console.log('multiple facility', resp)
      //       setManageStaff(resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item.facilityName, value: item.facilityId })))
      //       // reset({facility: resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item?.facilityName, value: item?.facilityId }))})
      //       setValue('facility', resp?.data && resp?.data?.length && resp?.data?.map((item, index) => ({ label: item?.facilityName, value: item?.facilityId })))
      //    }, (message) => {
      //       toast.error(message)
      //    })
            if (resp.birthDate) {
               setValue('birthday', changeDate(resp.birthDate))
            }
            getRolesList(resp.role)
         }, (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such staff found')
               setTimeout(() => {
                  history.replace('/staff-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const getFacilityList = () => {

      facilityApi.facilityList({ search: '', page: 1, limit: 99999, companyId: user?.company?.id },
         (message, resp) => {
            if (resp.data.length) {
               let temp = resp.data.map(item => { return { label: item.name, value: item.id } })
               console.log(temp)
               setFacilities(temp)
            }
         }, (message) => {
            //toast.error(message)
         })
   }

   const getRolesList = (role = '') => {
      let id = role ? role.id : '';
      let params = { isList: 1 }
      roleApi.roleList(params, (message, resp) => {
         let arr = resp.data.length ? resp.data.map(item => { return { value: item.id, label: item.name } }) : []
         arr.unshift(blankRole)
         setRoles(arr)
         if (id) {
            setValue('roleId', { label: role.name, value: role.id })
            setAssigned({ label: role.name, value: role.id })
         }
         else {
            if(id != 0){
               setValue('roleId', blankRole)
            }else{
               setValue('roleId', { label: role.name, value: role.id }) 
            }
            if (checkRoleList()) {
               setAssigned('')
            }
            else {
               setAssigned('default')
            }
         }
      }, (message) => {

      })
   }

   const updateUserFacility = (data) => {
      userApi.updateUserMultipleFacility(data,
         (message, resp) => {
            // toast.success('Staff user updated successfully')
            // reset()
         }, (message) => {
            toast.error(message)
         })
   }

   const getDefault = () => {
      roleApi.getDefaultRole(
         (message, resp) => {
            if (resp.data) {
               setDefaultRole({ label: resp.data.name, value: resp.data.id })
            }
            else {
               setDefaultRole({ label: 'No default role', value: '' })
            }
         },
         (message) => {

         })
   }

   const onSubmit = data => {
      console.log("datadatadatadatadata", getValues());
      const getValueData=getValues()
      if (!data.roleId.value) {
         toast.error('Please assign a role to staff member')
         return
      }
      if(!data.facility || !data.facility.length) {
         toast.error('Please select single or multiple facility')
         return
      }
      data.roleId = data.roleId.value ? data.roleId.value : null
      data['userType'] = 3
      console.log('data.facility.value', data.facility.value,data.facility)
      
      // data['facility']= data.facility
      // data['facilityId'] = Array.isArray(data.facility) ? parseInt(data.facility[0].value) : parseInt(data.facility.value)
      data['isActive'] = data.isActive == '1' ? true : false
      // if(getValueData && getValueData?.facility){
      // data['facility']=getValueData?.facility
      // }

      if (photo.id) {
         data['photoId'] = photo.id
      }
      if (props.userId) {
         data['id'] = props.userId
         data.birthDate = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         if(getValueData && getValueData?.facility){
            // let ufacilities = [];
            // ufacilities.push(getValueData?.facility);    
            data['facility']= null;
            }
         console.log("facility",data['facility']);
         delete data.email
         // delete data.facility 
         /*userApi.updateUserMultipleFacility(data,
            (message, resp) => {
               toast.success('Staff user updated successfully')
               // reset()
               // history.push('/staff-list')
            }, (message) => {
               toast.error(message)
            }) */

         // delete data.facility
          userApi.updateUser(data,
            (message, resp) => {
               toast.success('Staff user updated successfully')
               data['facility']= getValueData?.facility;
               updateUserFacility(data)
               if (isWizard === true) {
                  skiptonextstep()
               }
               else {
                  history.push('/staff-list')
               }
               // if(resp){
               //    setTimeout(() => {  
                  // updateUserFacility(data)
               // }, 2000);

               //    }
            }, (message) => {
               toast.error(message)
            }) 
      }
      else {
         data.birthday = moment(data.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
         // let ufacilities = [];
         // ufacilities.push(data.facility);
         console.log("datadatadatadatadatadatadatadata", data);

         staffApi.createStaff(data,
            (message, resp) => {
               toast.success('Staff user created successfully')
               if (isWizard === true) {
                  props.onChangeStep()
               }
               else {
                  history.push('/staff-list')
               }

            }, (message) => {
               toast.error(message)
            })
      }
   }

   const checkRoleList = () => {
      return userPermissions.some(item => item.module === MODULES.ROLES && item.operation === OPERATIONS.LIST)
   }

   useEffect(() => {
      if (!checkRoleList()) {
         if (props.userId) {
            if (assigned) {
               setValue('roleId', assigned)
            }
            else if (defaultRole.value) {
               setValue('roleId', defaultRole)
            }
            else {
               setValue('roleId', blankRole)
            }
         }
         else {
            if (defaultRole.value) {
               setValue('roleId', defaultRole)
               setAssigned(defaultRole.value)
            }
            else {
               setValue('roleId', blankRole)
            }
         }
      }
   }, [assigned, defaultRole])

   const showConfirm = () => {
      setAlert(
         <SweetAlert
            warning
            showCancel
            confirmBtnText="Continue!"
            confirmBtnBsStyle="danger"
            title="Unassign Role?"
            onConfirm={() => unAssignUserRole()}
            onCancel={() => setAlert(null)}
            focusCancelBtn
         >
            Are you sure?
         </SweetAlert>
      )
   }

   const unAssignUserRole = () => {
      setAlert(null)
      roleApi.unassignRole(
         { userId: props.userId },
         (message, resp) => {
            setAssigned('')
            setValue('roleId', blankRole)
            toast.success('Role unassigned successfully')
         }, (message) => {
            toast.error(message)
         })
   }

   const uploadImg = () => {
      let formData = new FormData()
      formData.append('file', photo.file)
      formData.append('fileType', 'profile')

      commonApi.uploadFile({ data: formData, format: 'image' },
         (message, resp) => {
            setPhoto(resp)
            setOpen(false)
            toast.success('Please save to confirm changes')
         }, (message) => {
            toast.error(message)
         })
   }

   const handleImg = (e) => {
      let file = e.target.files[0]
      if (file) {
         let url = URL.createObjectURL(file)
         setPhoto({ fullUrl: url, file })
         setOpen(true)
      }
   }

   const closeModal = () => {
      setOpen(false)
      setPhoto({})
      document.getElementById('fileInput').value = ''
   }

   const handleEmailSelect = (val, isExist) => {
      setTimeout(() => {
         setSelectedEmail({ label: val, value: val });
         setValue("username", val)
         setValue("email", val)
         setDisableIndex("");
      }, 100);
   };

   useImperativeHandle(ref, () => ({
      getAlert() {
         return getValues()
         //return refe.current.getValues()
      }
   }));

   return (
      <>
         {alert}
         {isDisplay && <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset disabled={props.isView}>
               <div className="row f_1">
                  <div className="col-lg-8">
                     <div className="card">
                        <div className="card-body">
                           <div className="row">
                              <div className="col-lg-6" data-select2-id={1}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="firstName"
                                       render={({ ref, value, ...props }) => (
                                          <TextInput
                                             inputref={ref}
                                             value={value}
                                             error={errors.firstName}
                                             label='First Name'
                                             required
                                             maxLength={20}
                                             placeholder='First name'
                                             type='text'
                                             {...props}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6" data-select2-id={2}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="lastName"
                                       render={({ ref, value, ...props }) => (
                                          <TextInput
                                             inputref={ref}
                                             type='text'
                                             value={value}
                                             error={errors.lastName}
                                             label='Last Name'
                                             required
                                             maxLength={40}
                                             placeholder='Last name'
                                             {...props}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6" data-select2-id={4}>
                                 <div className="form-group emailErrMsg" data-select2-id={15}>
                                 <label>Email <span className="text-danger">*</span></label>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="createEmail"
                                       render={({ ref, value, ...rest }) => (
                                          <CreatableSelect
                                             onBlur={() => setEmailMenuOpen(false)}
                                             menuIsOpen={emailMenuIsOpen}
                                             inputValue={emailSearch}
                                             onInputChange={(val) => typeAndSearch(val, "email")}
                                             // onChange={onChange}
                                             options={users}
                                             onCreateOption={(val) =>
                                                //console.log("onCreateOptiononCreateOptiononCreateOption", val)
                                                handleEmailSelect(val, "false")
                                             }
                                             createOptionPosition="first"
                                             cacheOptions
                                             value={selectedEmail}
                                             blurInputOnSelect
                                             isLoading={emailLoading}
                                             allowCreateWhileLoading={false}
                                             placeholder={"Email"}
                                          // error={errors?.users?.[index]?.email}
                                          // isDisabled={
                                          //    companyDetails?.users?.[index]?.id ||
                                          //    formValues?.users?.[index].isExists === "true"
                                          // }
                                          />
                                       )}
                                    />

                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="email"
                                       render={({ ref, value, ...rest }) => (
                                          <TextInput
                                             inputref={ref}
                                             type='hidden'
                                             value={value}
                                             error={errors.email}
                                             readOnly={props.userId ? true : false}
                                             placeholder="Email"
                                             {...rest}
                                          />
                                       )}
                                    />

                                 </div>
                              </div>
                              <div className="col-lg-6" data-select2-id={5}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="username"
                                       render={({ ref, value, ...rest }) => (
                                          <TextInput
                                             inputref={ref}
                                             value={value}
                                             error={errors.username}
                                             label='Username'
                                             required
                                             placeholder="Username"
                                             type='text'
                                             {...rest}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6" data-select2-id={6}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="phone"
                                       render={({ ref, value, ...props }) => (
                                          <MaskInput
                                             mask="(999) 999-9999"
                                             maskPlaceholder="Phone"
                                             inputref={ref}
                                             value={value}
                                             error={errors.phone}
                                             label='Phone'
                                             required
                                             {...props}
                                             type={'number'}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6" data-select2-id={7}>
                                 <div className="form-group" data-select2-id={15}>
                                    <label className="form-label">Date of birth <span className="text-danger">*</span></label>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="birthday"
                                       render={({ ref, value, ...rest }) => (
                                          <DateInput
                                             inputref={ref}
                                             value={value}
                                             error={errors.birthday}
                                             placeholder='mm-dd-yyyy'
                                             {...rest}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6" data-select2-id={8}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="dssConfirmation"
                                       render={({ ref, value, ...props }) => (
                                          <TextInput
                                             inputref={ref}
                                             type='text'
                                             value={value}
                                             error={errors.dssConfirmation}
                                             label='DSS Confirmation'
                                             required
                                             maxLength={20}
                                             placeholder=''
                                             {...props}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                              <div className="col-lg-6" data-select2-id={8}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="ssn"
                                       render={({ ref, value, ...props }) => (
                                          <MaskInput
                                             mask="999-99-9999"
                                             maskPlaceholder="SSN"
                                             inputref={ref}
                                             value={value}
                                             error={errors.ssn}
                                             label='SSN'
                                             type={'number'}
                                             required
                                             {...props}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-6" data-select2-id={8}>
                                 <div className="form-group" data-select2-id={15}>
                                    {/* <div className="d-flex justify-content-between align-items-baseline">
                                    {assigned && checkRoleList() && props.userId && <small className="text-danger cursor-pointer" onClick={showConfirm}>Unassign Role</small>}
                                 </div> */}
                                    <label>Assign Role <span className="text-danger">*</span></label>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="roleId"
                                       render={({ ref, value, ...rest }) => (
                                          <SelectInput
                                             inputref={ref}
                                             value={value}
                                             placeholder='Assign Role'
                                             disabled={!checkRoleList()}
                                             options={roleOptions}
                                             {...rest}
                                          />
                                       )}
                                    />
                                    {watchRole && watchRole.value === '' && (defaultRole === '' || defaultRole.value === '') && !checkRoleList() && !props.userId && <p className="text-danger mt-2">No default role given</p>}
                                 </div>
                              </div>
                              <div className="col-lg-6">
                                 <div className="form-group" data-select2-id={15}>
                                    <label>Facility <span className="text-danger">*</span></label>
                                    <Controller
                                       defaultValue={''}
                                       control={control}
                                       name="facility"
                                       render={({ ref, value, ...rest }) => (
                                          <SelectInput
                                             inputref={ref}
                                             value={value}
                                             disabled={props.isView}
                                             placeholder='Select Facility'
                                             options={facilityList}
                                             isMulti={true}
                                             {...rest}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className="row">
                              <div className="col-lg-12" data-select2-id={8}>
                                 <div className="form-group" data-select2-id={15}>
                                    <Controller
                                       defaultValue=''
                                       control={control}
                                       name="description"
                                       render={({ ref, value, ...props }) => (
                                          <TextInput
                                             inputref={ref}
                                             type='textarea'
                                             value={value}
                                             label='Description'
                                             maxLength={250}
                                             {...props}
                                          />
                                       )}
                                    />
                                    <small className="text-muted float-right">(Maximum 250 characters)</small>
                                 </div>
                              </div>
                           </div>
                           <div className="row mt-2">
                              <div className="col-lg-12">
                                 <div className="form-group">
                                    <Controller
                                       defaultValue={'1'}
                                       control={control}
                                       name="isActive"
                                       render={({ ref, value, ...rest }) => (
                                          <RadioGroup
                                             name="isActive"
                                             value={value}
                                             inputref={ref}
                                             options={[
                                                { label: 'Active', value: '1' },
                                                { label: 'Inactive', value: '0' }
                                             ]}
                                             {...rest}
                                          />
                                       )}
                                    />
                                 </div>
                              </div>
                           </div>

                           <div className="row">
                              <div className="col-lg-6">
                                 <div className="form-group photo-display">
                                    <label className="form-label">Photo</label>
                                    {photo && photo.id ?
                                       <div>
                                          <LazyImage src={photo.fullUrl} className="d-block mb-3" />
                                          {!props.isView && <button className="btn btn-danger" onClick={() => setPhoto({})} type="button">Replace Photo</button>}
                                       </div>
                                       :
                                       <div className="custom-file">
                                          <input type="file" className="custom-file-input" onChange={handleImg} accept=".jpg,.jpeg,.png" id="photo fileInput" />
                                          <label className="custom-file-label" htmlFor="fileInput">Choose file</label>
                                       </div>
                                    }
                                 </div>
                              </div>
                           </div>

                           <ReadCheckHOC module={MODULES.STAFF} operation={props.userId ? 'update' : 'create'} isView={props.isView}>
                              <button type="submit" value="saveDetails" className="btn btn-success waves-effect waves-light mt-4">Save Details</button>
                           </ReadCheckHOC>
                           {isWizard === true && (
                              <button type="button" onClick={() => { getAllFormValue() }} value="saveAnotherStaff" className="btn btn-success waves-effect waves-light mt-4 mr-2">Add Another Staff</button>
                           )}
                           {isWizard === true && (
                              <button type="button" className="btn btn-success waves-effect waves-light mt-4 mr-2" onClick={() => {
                                 skiptonextstep()
                                 props.setforceExit(true)
                              }}>Skip</button>
                           )}

                        </div>
                     </div>
                  </div>
               </div>
            </fieldset>
         </form>}
         <ImageModal
            isOpen={isOpen}
            url={photo && photo.fullUrl ? photo.fullUrl : ''}
            close={closeModal}
            onSave={uploadImg}
         />
      </>
   )

}
)

export default StaffForm