import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyApprovalList = React.lazy(() =>
   import('./approvalList')
);

const ApprovalList = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyApprovalList {...props} />
   </React.Suspense>
);

export default ApprovalList