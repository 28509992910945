import React, { useEffect, useState } from 'react'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { encryptId } from '../../components/form-elements/functions'
import moment from 'moment'
import { useUserSelector } from '../../hooks/selectors/userSelector'
import { PER_PAGE, ACTIONS } from '../../config/config'
import { useDispatch, useSelector } from 'react-redux'

const CustomerWizardModal = (props) => {

   const [data, setData] = useState([])
   const [search, setSearch] = useState('')

   const residentApi = useResidentApi()
   const user = useUserSelector()
   const history = useHistory()
   const dispatch = useDispatch()

   useEffect(() => {
      if (props.isOpen) {
         //getList()
      }
   }, [props.isOpen])



   const close = () => {
      props.close()

   }

  

   const goToLink = () => {
      //history.push(`/customer-wizard`)
      history.push(
         {
            pathname: `/customer-wizard`,
            state: {
              step: '1'
            } 
          }
      )
   }


   return (
      <Modal isOpen={props.isOpen} centered size="lg">
         <ModalHeader toggle={close} style={{ textTransform: 'capitalize' }}><h2>Welcome To The New Customer Wizard</h2></ModalHeader>
         <ModalBody style={{ maxHeight: '80vh', overflowY: 'auto' }}>

            <div className="table-responsive mt-4">
               <p>This wizard will help you learn more about how to use RCFE Admin.</p>
               <p>For basic understanding of RCFE Admin, the wizard will take you through 4 steps:</p>
               <ul>
                  <li>Create facility</li>
                  <li>Create staff</li>
                  <li>Create resident</li>
                  <li>Edit resident</li>
               </ul>

               <div>
                  <button className="btn btn-outline-danger ml-2" onClick={close}>Cancel</button>
                  <button className="btn btn-outline-success ml-2" onClick={goToLink}>Start</button>
               </div>
            </div>
         </ModalBody>
      </Modal>
   )
}

export default CustomerWizardModal