import axios from 'axios';
import { STORAGE } from '../config/config';

export class CallApi {
  static GET(url, params, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'GET',
        url,
        headers,
        params,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          //console.log(error.response);
          if(error.response && error.response.status===401)
          window.location.href="/login";
          return reject(error.response);
        });
    });
  }

  static POST(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'POST',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          //console.log(error);
          if(error.response && error.response.status===401)
          window.location.href="/login";
          return reject(error.response);
        });
    });
  }

  static PUT(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'PUT',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
         // console.log(error);
          if(error.response && error.response.status===401)
          window.location.href="/login";
          return reject(error.response);
        });
    });
  }

  static DELETE(url, data, isAuth) {
    return new Promise((resolve, reject) => {
      let headers = {};
      if (isAuth) {
        const token = localStorage.getItem(STORAGE);
        headers = {
          Authorization: `Bearer ${token}`,
        };
      }
      return axios({
        method: 'DELETE',
        url,
        headers,
        data,
      })
        .then(success => {
          return resolve(success);
        })
        .catch(error => {
          if(error.response && error.response.status===401)
          window.location.href="/login";
          return reject(error.response);
        });
    });
  }

  static POST_FORM_DATA(url, data, isAuth) {
   return new Promise((resolve, reject) => {
     let headers = {
       'Content-Type': 'application/x-www-form-urlencoded'
     };

     if (isAuth) {
       const token = localStorage.getItem(STORAGE);
       headers = {
         'Content-Type': 'multipart/form-data',
         Authorization: `Bearer ${token}`,
       };
     }
     return axios({
       method: 'POST',
       url,
       headers,
       data,
     })
       .then(success => {
         return resolve(success);
       })
       .catch(error => {
         //console.log(error);
         if(error.response && error.response.status===401) {
           localStorage.removeItem(STORAGE)
           window.location.href="/login";
         }
         return reject(error.response);
       });
   });
 }
}
