import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import 'moment-timezone';
import { TextInput } from '../../form-elements/textInput';
import { CheckboxInput } from '../../form-elements/checkboxInput';
import { DateInput } from '../../form-elements/datepicker';
import { MaskInput } from '../../form-elements/maskInput';
import { useResidentApi } from '../../../hooks/api/residentApiHook';
import { changeDate } from '../../form-elements/functions';
import { AsyncSearch } from '../../form-elements/asyncSearch';
import debounce from 'lodash.debounce';
import { PER_PAGE, ROLE_VALUE } from '../../../config/config';
import { RadioGroup } from '../../form-elements/radioGroup';
import { SelectInput } from '../../form-elements/dropdown';
import { useStaffApi } from '../../../hooks/api/staffApiHook';
import { formatPhone } from '../../../components/form-elements/functions';
import { useUserSelector } from '../../../hooks/selectors/userSelector';
import { useRoleApi } from '../../../hooks/api/roleApiHook';
import { useUserApi } from '../../../hooks/api/userApiHook';
import { string } from 'prop-types';

const DEFAULT_SELECT_OPTION = '';

const DUMMY_RESIDENT = {
  resident: DEFAULT_SELECT_OPTION,
};

const DUMMY_WITNESS = {
  firstName: "",
  lastName: "",
  email: "",
  selected: { label: "", value: "" },
};

const schema = yup.object().shape({
  incidentDate: yup
    .string()
    .nullable()
    .test('Length', 'Invalid incident date', function (value) {
      value = value && value.replace(/_/g, '');
      return (
        value &&
        value.length === 10 &&
        moment(value, 'MM-DD-YYYY').isValid() &&
        moment(value, 'MM-DD-YYYY').isSameOrBefore()
      );
    }),
  incidentTime: yup
    .string()
    .nullable()
    .required('Time is required')
    .test('time check', 'Invalid Time', function (value) {
      return value && value.split(':')[0] <= 23 && value.split(':')[1] <= 59;
    }),
  title: yup
    .string()
    .required('Title is required'),
  // .max(250, 'Max 250 characters'),
  description: yup.string().nullable(),
  observed: yup.string().nullable(),
  immediateAction: yup.string().nullable(),
  treatmentRequired: yup.boolean(),
  treatmentNature: yup.string().nullable(),
  treatmentAdminLocation: yup
    .string()
    .nullable(),
  treatmentAdminBy: yup.string().nullable(),
  treatmentFollowUp: yup.string().nullable(),
  actionTaken: yup.string().nullable(),
  comments: yup.string().nullable(),
  licensingPhone: yup.string(),
  licensingName: yup.string().nullable(),
  protectiveName: yup.string().nullable(),
  protectivePhone: yup.string(),
  ombudsmanName: yup.string().nullable(),
  ombudsmanPhone: yup.string(),
  PGCName: yup.string().nullable(),
  PGCPhone: yup.string(),
  lawEnforcementName: yup.string().nullable(),
  lawEnforcementPhone: yup.string(),
  placementAgencyName: yup.string().nullable(),
  placementAgencyPhone: yup.string(),
  reasonToReject: yup.string(),
  // Incident Types Checkboxes
  incidentTypes: yup.array().of(
    yup.object().shape({
      label: yup.string(),
      value: yup.string(),
      checked: yup.boolean(),
    }),
  ),
  users: yup.array().of(
    yup.object().shape({
      selected: yup.object().shape({
        label: yup.string(),
        // value: yup.string().required("Witness is required"),
        value: yup.string(),
      }),
    })
  ),
  // Resident Form Array with select
  // involvedResidents: yup.array().of(
  //   yup.object().shape({
  //     resident: yup.object().shape({
  //       label: yup.string(),
  //       value: yup.string(),
  //     }),
  //   }),
  // ),
  // Facility seelct from resident
});

const ResIncidentForm = props => {
  const residentApi = useResidentApi();
  const staffApi = useStaffApi();
  const history = useHistory();
  const [residentDetails, setResidentDetails] = useState({});
  const [staffUsers, setStaffUsers] = useState([]);
  const [typeSettingDone, setTypeSettingDone] = useState(false);
  const [existingTypeIds, setExistingTypeIds] = useState([]);
  // Use Hooks
  const roleApi = useRoleApi();
  const userApi = useUserApi();
  const [getRole, setRole] = useState([])
  const [witnessLists, setWitnessList] = useState([])
  const [adminFacility, setAdminFacility] = useState(null);
  const role_value = localStorage.getItem(ROLE_VALUE);


  const incidentId = props?.id

  const { handleSubmit, errors, control, reset, setValue, watch, register } =
    useForm({
      mode: 'onBlur',
      resolver: yupResolver(schema),
      defaultValues: {
        incidentTypes: [],
        involvedResidents: [],
        users: [DUMMY_WITNESS],
      },
    });
  const formValues = watch();
  const isTreatmentRequired = watch('treatmentRequired');
  const user = useUserSelector()
  const { fields, append, remove } = useFieldArray({
    control,
    name: "users",
  });

  const {
    fields: InvolvedResidents,
    append: appendInvolvedResidents,
    remove: removeInvolvedResidents,
  } = useFieldArray({
    control,
    name: 'involvedResidents',
  });
  

  const {
    fields: IncidentTypes,
    append: appendIncidentTypes,
    remove: removeIncidentTypes,
  } = useFieldArray({
    control,
    name: 'incidentTypes',
  });

  useEffect(() => {
    console.log('props.data.id', props.data.id)
    fetchIncidentTypes();
    if (props.data && props.data.id) {
      setData();
    } else {
      setValue('incidentDate', moment(new Date()).format('MM-DD-YYYY'));
      setValue('incidentTime', moment(new Date()).format('HH:mm'));
      setValue('involvedResidents', [DUMMY_RESIDENT]);
    }
  }, []);

  // useEffect(() => {
  //   getList(1, {})
  // }, []);

  useEffect(() => {
    console.log("staff-list-api-calling");
    handleSearchForEditWitness()
    // setTimeout(() => {
    if (props?.data?.id) {
      getWitnessListByIncidentId()
    }
    // }, 2000);

  }, []);

  const getWitnessListByIncidentId = () => {
    let paramss = { id: props?.data?.id }
    residentApi.witnessListByIncidentId(
      paramss,
      (message, resp) => {
        console.log('krishna', resp);
        setValue('users', resp && resp?.length && resp?.map((item, index) => ({ selected: { label: item?.witnessName, value: item?.witnessId } })))
      },
      message => { },
      false,
    );
  }

  useEffect(() => {
    console.log("useruseruseruseruseruseruser", user)
    let userObj = {
      id: user.id
    }
    if (userObj) {
      userApi.getUser(
        userObj,
        (message, resp) => {
          console.log("getUsergetUsergetUsergetUsergetUser", resp?.role?.slug);
          setRole(resp?.role?.slug)

        }, (message) => {
          console.log("messagemessagemessagemessage", message);
          //toast.error(message)
        }, 5000)
    }
    getwitnessList()
  }, []);

  
  useEffect(() => {
    if (props.uid) {
      fetchResident(props.uid);
      console.log('rererererrerereeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
    }
  }, [props.uid]);

  const getwitnessList = () => {
    let params = { page: 1, limit: 99999, fullName: '', facilityId: props.facilityId };
    staffApi.witnessList(
      params,
      (message, resp) => {
        console.log("witnessList", resp)
        let items = resp?.data?.filter((staff) => staff?.id != user?.id).map((staff) => ({
          ...staff,
          label: staff.email,
          value: staff.id,
        }));
        console.log(items)
        setWitnessList(items);
        const adminFaci = resp?.data.filter((user) => user?.multipleFacility[0]?.facility?.adminUserId != null);
        // console.log(adminFaci[0]?.multipleFacility[0]?.facility?.adminUserId, user.id);
        if (user.id === adminFaci[0]?.multipleFacility[0]?.facility?.adminUserId) {
          console.log("if-part-witness-user")
          setAdminFacility(user.id)
        }
      },
      (message) => { },
      false
    );
  }

  const handleSearchForEditWitness = debounce((search = "", callback) => {
    // console.log("staffList","usersuser",user)

    // staffApi.staffList({ page: 1, limit: PER_PAGE, userId: user.id, fullName: search },
    staffApi.witnessList({ page: 1, limit: PER_PAGE, facilityId: props.facilityId, fullName: search },
      (message, resp) => {
        let temp = resp.data.filter((staff) => staff?.id != user.id).map(item => { return { ...item, label: `${item.email}`, value: item.id } })
        setWitnessList(temp)
      }, (message) => {
        //toast.error(message)
      })
  }, 1500)

  const promiseOptions = debounce((search, callback) => {
    console.log("promiseOptions")
    // debugger
    // let params = { page: 1, limit: 99999, fullName: search, userId: user.id };
    // staffApi.staffList(
    let params = { page: 1, limit: 99999, fullName: search, facilityId: props.facilityId };
    /*staffApi.witnessList(
      params,
      (message, resp) => {
        let items = resp.data.map((staff) => ({
          ...staff,
          label: staff.email,
          value: staff.id,
        })).filter(
          (i) =>
            formValues.users.map((u) => u.selected.id).indexOf(i.id) === -1
        )
        callback(
          items
          // .filter(
          //   (i) =>
          //     formValues.users.map((u) => u.selected.id).indexOf(i.id) === -1
          // )
        );
      },
      (message) => { },
      false
    );*/
  }, 1000);

  //   const getList = (page, additional = {}) => {
  //     console.log('additional', additional)
  //     let params = { page, limit: 999999, ...additional }

  //     // if(additional?.isActive!=3){
  //     residentApi.residentList(params,
  //        (message, resp) => {
  //           console.log("resprespresprespresp",resp.data);
  //           // setUsers(resp.data)
  //           // setData({ total: resp.total, totalPage: resp.totalPage })
  //        }, (message) => { })
  //     // }
  //     // else if(additional?.isActive==3){
  //     //    residentApi.residentListWithoutFacility(params,
  //     //       (message, resp) => {
  //     //          console.log("resprespresprespresp",resp.data);
  //     //          setUsers(resp.data)
  //     //          setData({ total: resp.total, totalPage: resp.totalPage })
  //     //       }, (message) => { }) 
  //     // }
  //  }

  // useEffect(() => {
  //    if ( residentDetails && residentDetails.id ) {
  //       fetchStaffUsers()
  //    }
  // }, [residentDetails])

  useEffect(() => {
    if (typeSettingDone && props.data && props.data.incidentTypes) {
      setValue(
        'incidentTypes',
        IncidentTypes.map(i => ({
          ...i,
          checked: !!(
            props.data.incidentTypes.map(i => i.id).indexOf(i.id) > -1
          ),
        })),
      );
    }
  }, [typeSettingDone, props.data.incidentTypes]);

  useEffect(() => {
    if (props.data && props.data.residents) {
      setValue(
        'involvedResidents',
        props.data.residents
          .filter(i => i.id !== props.uid)
          .map(i => ({ resident: castToSelectItem(i) })),
      );
    }
  }, [props.data.residents]);

  const castToSelectItem = (item, castToCheckbox = false, labelField = '') =>
    item && item.id
      ? {
        ...item,
        label: labelField.length
          ? `${item[labelField]}`
          : `${item.firstName} ${item.lastName}`,
        value: item.id,
        checked: !!castToCheckbox,
      }
      : DEFAULT_SELECT_OPTION;

  const setData = () => {
    let resp = { ...props.data };
    resp.incidentDate = changeDate(resp.incidentDate);
    resp.incidentTime = moment(resp.incidentTime, 'HH:mm:ss').format('HH:mm');
    resp.treatmentRequired = !!resp.treatmentRequired ? '1' : '0';
    // resp.involvedResidents = resp.residents ? resp.residents.filter(i => i.id !== props.uid).map(i => ({ resident: castToSelectItem(i) })) : []

    // resp.treatmentAdminBy = castToSelectItem(resp.treatmentAdminBy)
    // resp.attendedBy = castToSelectItem(resp.attendedBy)
    reset(resp);
  };

  const fetchResident = id => {
    residentApi.residentDetails(
      { id },
      (message, resp) => {
        setResidentDetails(resp);
      },
      (message, resp) => {
        if (resp.status === 404) {
          toast.error('No such resident found');
          setTimeout(() => {
            history.replace('/resident-list');
          }, 500);
        } else {
          toast.error(message);
        }
      },
    );
  };

  const fetchStaffUsers = () => {
    staffApi.staffList(
      { page: 1, limit: 99999, facility: residentDetails.facility.name },
      (message, resp) => {
        setStaffUsers(
          resp.data.map(u => ({
            ...u,
            label: `${u.firstName} ${u.lastName}`,
            value: u.id,
          })),
        );
      },
      (message, resp) => {
        // toast.error(message)
      },
    );
  };

  const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  };

  const formatGroupLabel = data => (
    <div style={groupStyles}>
      ttttttttt
      <span>
        Teeeeeee<hr></hr>
      </span>
    </div>
  );

  const handleSearch = debounce((search = '', callback) => {
    // if ((search.length) || (role_value && role_value=='Default-Caregiver')) {
    console.log('handleSearchhandleSearchhandleSearch');
    let params = { page: 1, limit: PER_PAGE, fullName: search };
    residentApi.residentList(
      params,
      (message, resp) => {
        let items = resp.data.map(res => ({
          ...res,
          label: `${res.firstName} ${res.lastName}`,
          value: res.id,
        }));
        callback(
          items.filter(
            i =>
              InvolvedResidents.map(u => parseInt(u.resident.value)).indexOf(
                i.id,
              ) === -1 && i.id !== props?.uid,
          ),
        );
      },
      message => { },
      false,
    );
    // } 
    // else {
    //   console.log('uiduiduiduiduiduid', props.facilityId);

    //   let params = {
    //     page: 1,
    //     limit: PER_PAGE,
    //     facilityId: props.facilityId,
    //     companyId: props.companyId,
    //     residentId: props.uid,
    //   };
    //   residentApi.residentListByfacilityId(
    //     params,
    //     (message, resp) => {
    //       console.log('resprespresprespresprespresp2222222222', resp);

    //       /*
    //        * for data1
    //        */
    //       let items = resp.data.map(res => ({
    //         ...res,
    //         label: `${res.firstName} ${res.lastName}`,
    //         value: res.id,
    //       }));
    //       let filterdata1 = items.filter(
    //         i =>
    //           InvolvedResidents.map(u => parseInt(u.resident.value)).indexOf(
    //             i.id,
    //           ) === -1 && i.id !== residentDetails.id,
    //       );

    //       /*
    //        * for data2
    //        */
    //       let items2 = resp.data2.map(res => ({
    //         ...res,
    //         label: `${res.firstName} ${res.lastName}`,
    //         value: res.id,
    //       }));
    //       let filterdata2 = items2.filter(
    //         i =>
    //           InvolvedResidents.map(u => parseInt(u.resident.value)).indexOf(
    //             i.id,
    //           ) === -1 && i.id !== residentDetails.id,
    //       );

    //       let optionVal =
    //         filterdata1.length > 0
    //           ? filterdata1
    //           : [{label: 'Same facility resident not found!', value: 0}];

    //       var labelOption = '';

    //       if (filterdata1.length > 0) {
    //         var labelOption =
    //           '_______________________________________________________________________________________';
    //       }

    //       let genDropDown = [
    //         {
    //           options: filterdata1,
    //         },
    //         {
    //           label: labelOption,
    //           options: filterdata2,
    //         },
    //       ];
    //       callback(genDropDown);
    //     },
    //     message => {},
    //     false,
    //   );
    // }
  }, 1500);



  const fetchIncidentTypes = id => {
    residentApi.getIncidentTypes(
      { page: 1, limit: 99999 },
      (message, resp) => {
        if (resp.data) {
          setValue(
            'incidentTypes',
            resp.data.map(item => castToSelectItem(item, false, 'label')),
          );
          setTypeSettingDone(true);
        }
      },
      (message, resp) => { },
    );
  };

  const onSubmit = (data) => {

    console.log('data', data, witnessLists)
    let incidentTypeIds = data.incidentTypes ? data.incidentTypes
      .map(i => (i.checked ? parseInt(i.value) : null))
      .filter(i => i) : [];
    console.log("incidentTypeIds", incidentTypeIds)
    if (!data?.licensingName && data?.licensingPhone) {
      toast.error('Licensing name must be required');
    }
    else if (data?.licensingName && !data?.licensingPhone) {
      toast.error('Licensing telephone number must be required');
    }
    else if (!data?.protectiveName && data?.protectivePhone) {
      toast.error('Adult/Child protective service name must be required');
    }
    else if (data?.protectiveName && !data?.protectivePhone) {
      toast.error('Adult/Child protective telephone number must be required');
    }
    else if (!data?.ombudsmanName && data?.ombudsmanPhone) {
      toast.error('Long term care ombudsman name must be required');
    }
    else if (data?.ombudsmanName && !data?.ombudsmanPhone) {
      toast.error('Long term care ombudsman telephone number must be required');
    }
    else if (!data?.PGCName && data?.PGCPhone) {
      toast.error('Parent/Guardian Conservator name must be required');
    }
    else if (data?.PGCName && !data?.PGCPhone) {
      toast.error('Parent/Guardian Conservator telephone number must be required');
    }
    else if (!data?.lawEnforcementName && data?.lawEnforcementPhone) {
      toast.error('Law enforcement name must be required');
    }
    else if (data?.lawEnforcementName && !data?.lawEnforcementPhone) {
      toast.error('Law enforcement telephone number must be required');
    }
    else if (!data?.placementAgencyName && data?.placementAgencyPhone) {
      toast.error('Placement agency name must be required');
    }
    else if (data?.placementAgencyName && !data?.placementAgencyPhone) {
      toast.error('Placement agency telephone number must be required');
    }
    else if (!residentDetails?.facility?.id) {
      toast.error('Resident Without a Facility, Atleast one facility is required')
    }
    else if (incidentTypeIds.length < 1) {
      toast.error('You must choose at least one type of incident')
    }
    //  else if ((getRole != 'Default-Caregiver' && (!data?.users || !data?.users?.length))){
    //   toast.error('Please select witness');
    //  }
    else {
      // var userArr = [];
      // if(adminFacility || getRole == 'default'){
      //   console.log("dataObj",adminFacility)
      // }else{

      // }
      var userList = [];
      console.log("getRole", getRole, witnessLists, data);
      //phase-6-witnessLists
      const faciadmin = witnessLists.filter((user) => user.multipleFacility[0].facility.adminUserId != null);
      // console.log(faciadmin[0].multipleFacility[0].facility.adminUserId);
      if (faciadmin.length > 0) {
        if (!adminFacility && getRole != 'default') {
          userList.push(faciadmin[0].multipleFacility[0].facility.adminUserId)
          var witnessIds = userList ? userList : [];
          var users = [{"selected": { value: faciadmin[0].multipleFacility[0].facility.adminUser.id.toString(), 
            label: faciadmin[0].multipleFacility[0].facility.adminUser.email }}];
        } else {
          // console.log("adminFacility--->",data.users)
          var witnessIds = data.users ? data.users.map(i => (i.selected ? parseInt(i.selected.value) : null))
            .filter(i => i) : [];

          var users = data.users;
        }
        console.log("witnessIds--->",witnessIds)
        let params = {
          ...data,
          residentId: props.uid,
          facilityId: residentDetails?.facility?.id,
          incidentDate: moment(moment(data?.incidentDate).format('YYYY-MM-DD') + ' ' + data?.incidentTime).utc().format("YYYY-MM-DD"),
          incidentTime: moment(moment(data?.incidentDate).format('YYYY-MM-DD') + ' ' + data?.incidentTime).utc().format("HH:mm"),
          // incidentDate: data?.incidentDate ? moment(data?.incidentDate, 'MM-DD-YYYY').format(
          //   'YYYY-MM-DD',
          // ) : '',
          incidentTypeIds: incidentTypeIds,
          witnessId: witnessIds,
          users: users,
          involvedResidents: data.involvedResidents
            ? data.involvedResidents
              .map(i =>
                i.resident && i.resident.value
                  ? parseInt(i.resident.value)
                  : null,
              )
              .filter(i => i)
            : [],
        };
        params['licensingPhone'] = data?.licensingPhone?.replace(/[^\d]/g, '')
        params['protectivePhone'] = data?.protectivePhone?.replace(/[^\d]/g, '')
        params['ombudsmanPhone'] = data?.ombudsmanPhone?.replace(/[^\d]/g, '')
        params['PGCPhone'] = data?.PGCPhone?.replace(/[^\d]/g, '')
        params['lawEnforcementPhone'] = data?.lawEnforcementPhone?.replace(/[^\d]/g, '')
        params['placementAgencyPhone'] = data?.placementAgencyPhone?.replace(/[^\d]/g, '')
        if (data?.reasonToReject || data?.reasonToReject != '') {
          params['status'] = 'Pending'
        }
        if (((getRole != 'Default-Caregiver') && (!params?.witnessId || params?.witnessId?.length == 0))) {
          toast.error('Please select witness');
          return true;
        }
        if (getRole && getRole != 'Default-Caregiver') {
          const uniqueWitness = params?.users.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.selected.value === current.selected.value)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);

          params['users'] = uniqueWitness
        }
        console.log("paramsparamsparams", params)

        delete params['incidentTypes'];

        if (props.data && props.data.id) {
          params['id'] = props.data.id;
          residentApi.updateIncident(
            params,
            (message, resp) => {
              toast.success('Incident updated successfully');
              
              if(getRole && getRole != 'Default-Caregiver') {
                
                data['id'] = props.data.id
                // const uniqueWitness = data?.users.reduce((accumulator, current) => {
                const uniqueWitness = params['users'].reduce((accumulator, current) => {
                  if (!accumulator.find((item) => item.selected.value === current.selected.value)) {
                    accumulator.push(current);
                  }
                  return accumulator;
                }, []);
                data['users'] = uniqueWitness
                userApi.updateIncidentWitness(data,
                  (message, resp) => {
                    console.log("resp")
                  }, (message) => {
                    toast.error(message)
                  })
              }
              props.close(true);
            },
            message => {
              toast.error(message)
            },
          );
        } else {
          residentApi.addIncident(
            params,
            (message, resp) => {
              toast.success('Incident created successfully');
              props.close(false);
            },
            message => {
              toast.error(message)
              // toast.error('Resident Without a Facility, Atleast one facility is required')
            },
          ); 
        } 
         
      } else {
        toast.error("Please add facility admin");
      } 

      //phase-6-witnessLists

      //phase-5-witnessLists
      // for (var u = 0; u < witnessLists.length; u++) {
      //   if (getRole == 'Default-Admin') {
      //     if (getRole != witnessLists[u].role.slug && witnessLists[u].role.slug != 'Default-Manager' && witnessLists[u].role.slug != 'Default-Caregiver') {
      //       userList.push({ "selected": witnessLists[u] })
      //     }
      //   }
      //   if (getRole == 'Default-Manager') {
      //     if (getRole != witnessLists[u].role.slug && witnessLists[u].role.slug != 'Default-Caregiver') {
      //       userList.push({ "selected": witnessLists[u] })
      //     }
      //   }
      //   if (getRole == 'Default-Caregiver') {
      //     if (getRole != witnessLists[u].role.slug) {
      //       userList.push({ "selected": witnessLists[u] })
      //     }
      //   }
      // }
      // // console.log(userList)
      // if (getRole != 'default') {
      //   var witnessIds = userList ? userList.map(i => (i.selected ? parseInt(i.selected.value) : null))
      //     .filter(i => i) : [];
      //   var users = userList;
      // } else {
      //   var witnessIds = data.users ? data.users.map(i => (i.selected ? parseInt(i.selected.value) : null))
      //     .filter(i => i) : [];

      //   var users = data.users;
      // }
      // let params = {
      //   ...data,
      //   residentId: props.uid,
      //   facilityId: residentDetails?.facility?.id,
      //   incidentDate: data?.incidentDate ? moment(data?.incidentDate, 'MM-DD-YYYY').format(
      //     'YYYY-MM-DD',
      //   ) : '',
      //   // incidentTypeIds: data.incidentTypes
      //   //   ? data.incidentTypes
      //   //       .map(i => (i.checked ? parseInt(i.value) : null))
      //   //       .filter(i => i)
      //   //   : [],
      //   incidentTypeIds: incidentTypeIds,
      //   witnessId: witnessIds,
      //   users: users,
      //   // witnessId: data.users
      //   // ? data.users
      //   //     .map(i => (i.selected ? parseInt(i.selected.value) : null))
      //   //     .filter(i => i)
      //   // : [],

      //   // users: data.users,
      //   involvedResidents: data.involvedResidents
      //     ? data.involvedResidents
      //       .map(i =>
      //         i.resident && i.resident.value
      //           ? parseInt(i.resident.value)
      //           : null,
      //       )
      //       .filter(i => i)
      //     : [],
      //   // treatmentAdminById: data.treatmentAdminBy && data.treatmentAdminBy.value ? parseInt(data.treatmentAdminBy.value) : null,
      //   // attendedById: data.attendedBy && data.attendedBy.value ? parseInt(data.attendedBy.value) : null,
      // };
      // params['licensingPhone'] = data?.licensingPhone?.replace(/[^\d]/g, '')
      // params['protectivePhone'] = data?.protectivePhone?.replace(/[^\d]/g, '')
      // params['ombudsmanPhone'] = data?.ombudsmanPhone?.replace(/[^\d]/g, '')
      // params['PGCPhone'] = data?.PGCPhone?.replace(/[^\d]/g, '')
      // params['lawEnforcementPhone'] = data?.lawEnforcementPhone?.replace(/[^\d]/g, '')
      // params['placementAgencyPhone'] = data?.placementAgencyPhone?.replace(/[^\d]/g, '')
      // if (data?.reasonToReject || data?.reasonToReject != '') {
      //   params['status'] = 'Pending'
      // }
      // if (((getRole != 'Default-Caregiver') && (!params?.witnessId || params?.witnessId?.length == 0))) {
      //   toast.error('Please select witness');
      //   return true;
      // }
      // if (getRole && getRole != 'Default-Caregiver') {
      //   const uniqueWitness = params?.users.reduce((accumulator, current) => {
      //     if (!accumulator.find((item) => item.selected.value === current.selected.value)) {
      //       accumulator.push(current);
      //     }
      //     return accumulator;
      //   }, []);

      //   params['users'] = uniqueWitness
      // }
      // console.log("paramsparamsparams", params)

      // delete params['incidentTypes'];
      // // delete params['attendedBy']
      // // delete params['treatmentAdminBy']

      //phase-5-witnessLists
      // if (props.data && props.data.id) {
      //   params['id'] = props.data.id;
      //   residentApi.updateIncident(
      //     params,
      //     (message, resp) => {
      //       toast.success('Incident updated successfully');
      //       // let incidentId={id: props.data.id}
      //       if (getRole && getRole != 'Default-Caregiver') {
      //         data['id'] = props.data.id
      //         const uniqueWitness = data?.users.reduce((accumulator, current) => {
      //           if (!accumulator.find((item) => item.selected.value === current.selected.value)) {
      //             accumulator.push(current);
      //           }
      //           return accumulator;
      //         }, []);

      //         data['users'] = uniqueWitness
      //         userApi.updateIncidentWitness(data,
      //           (message, resp) => {
      //           }, (message) => {
      //             toast.error(message)
      //           })
      //       }
      //       props.close(true);
      //     },
      //     message => {
      //       // toast.error('Resident Without a Facility, Atleast one facility is required')
      //       toast.error(message)
      //       // toast.error('Resident Without a Facility, Atleast one facility is required')
      //     },
      //   ); 
      // } else {
      //   // if (((getRole != 'Default-Caregiver') && (!params?.witnessId || params?.witnessId?.length==0))){
      //   //   toast.error('Please select witness');
      //   //   return;
      //   //  }
      //   residentApi.addIncident(
      //     params,
      //     (message, resp) => {
      //       toast.success('Incident created successfully');
      //       props.close(false);
      //     },
      //     message => {
      //       toast.error(message)
      //       // toast.error('Resident Without a Facility, Atleast one facility is required')
      //     },
      //   );
      // }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* {console.log({formValues, errors})} */}
      {/* <div className="row">
            <div className="container d-flex flex-column">
               <h5><b>Facility:</b>&nbsp;{ residentDetails?.facility?.name || 'N/A' }</h5>
               <span><b>Liscence:</b>&nbsp;{ residentDetails?.facility?.liscenseNo || 'N/A' }</span>
               <span><b>Phone:</b>&nbsp;{ residentDetails?.facility?.adminPhone ? formatPhone(residentDetails.facility.adminPhone) : 'N/A' }</span>
               <span><b>Address:</b>&nbsp;{ residentDetails?.facility?.addrLine1 ? `${residentDetails?.facility?.addrLine1}, ${residentDetails?.facility?.addrLine2}` : 'N/A' }</span>
            </div>
         </div> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="form-group">
            <label className="form-label">
              Incident Summary<span className="text-danger">*</span>
            </label>
            <Controller
              defaultValue={''}
              control={control}
              name="title"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.title}
                  placeholder="Summary"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label className="form-label">
              Incident Date <span className="text-danger">*</span>
            </label>
            <Controller
              defaultValue={''}
              control={control}
              name="incidentDate"
              render={({ ref, value, ...props }) => (
                <DateInput
                  inputref={ref}
                  value={value}
                  error={errors.incidentDate}
                  placeholder="mm-dd-yyyy"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="incidentTime"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="99:99"
                  maskPlaceholder="12:00"
                  inputref={ref}
                  value={value}
                  error={errors.incidentTime}
                  label="Incident Time"
                  required
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-12">
          <label>Additional Residents Involved?</label>
        </div>
        {InvolvedResidents.map((item, index) => (
          <div className="col-lg-12 d-flex justify-content-between align-items-end">
            <div className="form-group flex-grow-1 mt-2 mb-0">
              <Controller
                defaultValue={item.resident}
                control={control}
                name={`involvedResidents[${index}].resident`}
                render={({ ref, value, ...props }) => (
                  <AsyncSearch
                    inputRef={ref}
                    options={handleSearch}
                    formatGroupLabel={() => (
                      <span>
                        <hr />
                      </span>
                    )}
                    value={value}
                    defaultOptions={true}
                    onChange={e => props.onChange(e)}
                    isClearable={false}
                    label="Search Additional Residents involved"
                    placeholder="Please type here to search"
                    error={errors?.involvedResidents?.[index]?.value}
                    {...props}
                  />
                )}
              />
            </div>
            <div className="actions ml-2">
              <span className="mb-2">&nbsp;</span>
              <a
                href="#"
                className="btn btn-danger mt-10p"
                onClick={() => removeInvolvedResidents(index)}>
                <i className="fa fa-trash"></i>
              </a>
            </div>
          </div>
        ))}
        <div className="col-lg-12">
          <a
            href="#"
            className="btn btn-outline-info mt-2"
            onClick={() => appendInvolvedResidents(DUMMY_RESIDENT)}>
            <i className="fa fa-plus mr-2"></i> Add Additional Resident
          </a>
        </div>
      </div>

      {/* widness form */}

      {/*getRole != 'Default-Caregiver' && default*/}
      {((adminFacility) || (getRole == 'default')) && (
        <>
          <div className="row mt-4">
            {/* <div className="col-lg-12">
                  <label className="sub-title">Search Witness <span className="text-danger">*</span></label>
                </div> */}

            {/* {console.log('feield user', fields)} */}
            {fields.map((item, index) => (
              <div
                className="col-lg-12 d-flex justify-content-between align-items-center"
                data-select2-id={1}
              >
                <div
                  className="form-group flex-grow-1"
                  data-select2-id={15}
                >
                  <Controller
                    defaultValue={item.selected}
                    control={control}
                    name={`users[${index}].selected`}
                    render={({ ref, value, name, ...props }) => (
                      <AsyncSearch
                        inputRef={ref}
                        // options={promiseOptions}
                        defaultOptions={witnessLists.filter(
                          (i) =>
                            formValues?.users?.map((u) => u.selected.id).indexOf(i.id) === -1
                        )}
                        // options={promiseOptions}
                        value={value}
                        // defaultOptions={true}
                        onChange={(e) => props.onChange(e)}
                        isClearable={false}
                        label="Select Witness"
                        placeholder="Type here to search Witness"
                        error={errors?.users?.[index]?.selected?.value}
                        {...props}
                      />
                    )}
                  />
                </div>
                {fields.length > 1 && (
                  <div className="actions ml-2 mt-2">
                    <span className="mb-2">&nbsp;</span>
                    <a
                      href="#"
                      className="btn btn-danger"
                      onClick={() => remove(index)}
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="mr-4">
            <a
              href="#"
              className="btn btn-outline-info"
              onClick={() => append(DUMMY_WITNESS)}
            >
              <i className="fa fa-plus mr-2"></i> Add Witness </a>
          </div>
        </>
      )}





      <div className="row mt-4">
        <div className="col-lg-12">
          <label>
            Type of Incident <span className="text-danger">*</span>
          </label>
        </div>
        {IncidentTypes.map((item, index) => (
          <div className="col-lg-4">
            <div className="form-group mb-0">
              <Controller
                defaultValue={IncidentTypes[index]?.checked}
                control={control}
                name={`incidentTypes[${index}].checked`}
                render={({ ref, value, ...props }) => (
                  <CheckboxInput
                    inputref={ref}
                    value={value}
                    error={errors.incidentType}
                    label={IncidentTypes[index].label}
                    {...props}
                  />
                )}
              />
              <input
                type="hidden"
                name={`incidentTypes[${index}].value`}
                ref={register()}
                value={IncidentTypes[index].value}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="form-group">
            <Controller
              defaultValue=""
              control={control}
              name="description"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.description}
                  label="Describe the incident"
                  type="textarea"
                  // maxLength={250}
                  {...props}
                />
              )}
            />
            {/* <small className="text-muted float-right mt-1">
              (Maximum 250 characters)
            </small> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <Controller
              defaultValue=""
              control={control}
              name="observed"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.observed}
                  label="Person(s) who observed the incident"
                  type="textarea"
                  // maxLength={250}
                  {...props}
                />
              )}
            />
            {/* <small className="text-muted float-right mt-1">
              (Maximum 250 characters)
            </small> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <Controller
              defaultValue=""
              control={control}
              name="immediateAction"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.immediateAction}
                  label="Explain what immediate action was taken"
                  type="textarea"
                  // maxLength={250}
                  {...props}
                />
              )}
            />
            {/* <small className="text-muted float-right mt-1">
              (Maximum 250 characters)
            </small> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label class="mr-2">Medical Treatment Necessary?</label>
            <Controller
              defaultValue={'0'}
              control={control}
              name="treatmentRequired"
              render={({ ref, value, ...props }) => (
                <div>
                  <RadioGroup
                    inputref={ref}
                    value={value}
                    options={[
                      { label: 'Yes', value: 1 },
                      { label: 'No', value: 0 },
                    ]}
                    error={errors.treatmentRequired}
                    {...props}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>

      {isTreatmentRequired === '1' && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <Controller
                  defaultValue=""
                  control={control}
                  name="treatmentNature"
                  render={({ ref, value, ...props }) => (
                    <TextInput
                      inputref={ref}
                      value={value}
                      error={errors.treatmentNature}
                      label="Nature of treatment"
                      type="textarea"
                      // maxLength={250}
                      {...props}
                    />
                  )}
                />
                {/* <small className="text-muted float-right mt-1">
                  (Maximum 250 characters)
                </small> */}
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                <Controller
                  defaultValue=""
                  control={control}
                  name="treatmentAdminLocation"
                  render={({ ref, value, ...props }) => (
                    <TextInput
                      inputref={ref}
                      value={value}
                      error={errors.treatmentAdminLocation}
                      label="Where Administered"
                      type="input"
                      {...props}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-6">
              <div className="form-group">
                {/*<label>Administered By</label>
                            <Controller
                              defaultValue=''
                              control={control}
                              name="treatmentAdminBy"
                              render={({ ref, value, ...props }) => (
                                 <SelectInput
                                    inputref={ref}
                                    value={value}
                                    options={staffUsers}
                                    error={errors.treatmentAdminBy}
                                    {...props}
                                 />
                              )}
                           /> */}
                <Controller
                  defaultValue=""
                  control={control}
                  name="treatmentAdminBy"
                  render={({ ref, value, ...props }) => (
                    <TextInput
                      inputref={ref}
                      value={value}
                      error={errors.treatmentAdminBy}
                      label="Administered By"
                      type="input"
                      {...props}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-12">
              <div className="form-group">
                <Controller
                  defaultValue=""
                  control={control}
                  name="treatmentFollowUp"
                  render={({ ref, value, ...props }) => (
                    <TextInput
                      inputref={ref}
                      value={value}
                      error={errors.treatmentNature}
                      label="Follow Up Treatment, if any"
                      type="textarea"
                      // maxLength={250}
                      {...props}
                    />
                  )}
                />
                {/* <small className="text-muted float-right mt-1">
                  (Maximum 250 characters)
                </small> */}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <Controller
              defaultValue={''}
              control={control}
              name="actionTaken"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.actionTaken}
                  label="Action taken or planned"
                  type="textarea"
                  // maxLength={250}
                  {...props}
                />
              )}
            />
            {/* <small className="text-muted float-right mt-1">
              (Maximum 250 characters)
            </small> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <Controller
              defaultValue={''}
              control={control}
              name="comments"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.comments}
                  label="Licensee/Supervisor Comments"
                  type="textarea"
                  // maxLength={250}
                  {...props}
                />
              )}
            />
            {/* <small className="text-muted float-right mt-1">
              (Maximum 250 characters)
            </small> */}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-group">
            {/*<label>Attending Physician</label>
                   <Controller
                     defaultValue={''}
                     control={control}
                     name="attendedBy"
                     render={({ ref, value, ...props }) => (
                        <SelectInput
                           inputref={ref}
                           value={value}
                           options={staffUsers}
                           error={errors.attendedBy}
                           {...props}
                        />
                     )}
                  /> */}
            <Controller
              defaultValue=""
              control={control}
              name="attendedBy"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.attendedBy}
                  label="Attending Physician"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>
      {props?.data && props.data.id && props.data.status == 'Rejected' &&
        <div className="row">
          <div className="col-12">
            <div className="form-group">

              <Controller
                defaultValue=""
                control={control}
                name="reasonToReject"
                render={({ ref, value, ...props }) => (
                  <TextInput
                    inputref={ref}
                    value={value}
                    error={errors.reasonToReject}
                    label="Rejected Reason"
                    type="input"
                    {...props}
                  />
                )}
              />
            </div>
          </div>
        </div>
      }

      {/* Licensing Part  */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Licensing</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="licensingName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.treatmentAdminBy}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="licensingPhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.licensingPhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>
      {/* Adult/Child Protective Services */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Adult/Child Protective Services</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="protectiveName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.treatmentAdminBy}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="protectivePhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.protectivePhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* Long Term Care Ombudsman */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Long Term Care Ombudsman</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="ombudsmanName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.ombudsmanName}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="ombudsmanPhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.ombudsmanPhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* Parent/Guardian/Conservator */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Parent/Guardian/Conservator</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="PGCName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.PGCName}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="PGCPhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.PGCPhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* Parent/Guardian/Conservator */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Law Enforcement</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="lawEnforcementName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.lawEnforcementName}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="lawEnforcementPhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.lawEnforcementPhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      {/* Parent/Guardian/Conservator */}
      <div className="row">
        <div className="col-lg-12">
          <label className="labelTextBld">Placement Agency</label>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            {/* <label className="form-label">Name<span className="text-danger">*</span></label> */}
            <Controller
              defaultValue=""
              control={control}
              name="placementAgencyName"
              render={({ ref, value, ...props }) => (
                <TextInput
                  inputref={ref}
                  value={value}
                  error={errors.placementAgencyName}
                  label="Name"
                  type="input"
                  {...props}
                />
              )}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group" data-select2-id={15}>
            <Controller
              defaultValue=""
              control={control}
              name="placementAgencyPhone"
              render={({ ref, value, ...props }) => (
                <MaskInput
                  mask="(999) 999-9999"
                  maskPlaceholder="Telephone Number"
                  inputref={ref}
                  value={value}
                  // error={errors.placementAgencyPhone}
                  type={'number'}
                  label="Telephone Number"
                  {...props}
                />
              )}
            />
          </div>
        </div>
      </div>

      <button
        className="float-right btn btn-primary waves-light mt-4"
        type="submit">
        Submit
      </button>
    </form>
  );
};

export default ResIncidentForm;
