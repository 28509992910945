import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import { useHistory } from 'react-router-dom'
import { MODULES, PER_PAGE } from '../../config/config'
import { useResidentApi } from '../../hooks/api/residentApiHook'
import moment from 'moment';
import 'moment-timezone';
import ResIncidentForm from '../../components/resident/manage-resident/resIncidentForm'
import Pagination from 'react-js-pagination'

const ResIncidentList = (props) => {
   const [modal, showModal] = useState(false)
   const [details, setDetails] = useState({})
   const [data, setData] = useState([])
   const [activePage, setPage] = useState(1)
   const [search, setSearch] = useState('')
   const [isWizard, setWizard] = useState(props.isWizard)

   const residentApi = useResidentApi()
   const history = useHistory()

   const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
   console.log(timeZone);

   useEffect(() => {
      if (props.resId) {
         getList(1)
      }
   }, [props.resId])

   const getList = (page = 1, search = '') => {
      let params = { page, limit: PER_PAGE, search, resident: props.resId }

      residentApi.listIncident(params,
         (message, resp) => {
            let resInc = resp.data.length ? resp?.data?.map((inc) => {
               var localDate = moment.utc(inc?.incidentDate + ' ' + inc?.incidentTime, "YYYY-MM-DD HH:mm").local().tz(timeZone).format("YYYY-MM-DD");
               var localTime = moment.utc(inc?.incidentTime, "HH:mm").local().tz(timeZone).format("HH:mm");
               inc.incidentDate = localDate
               inc.incidentTime = localTime
               console.log(inc.incidentDate, inc.incidentTime)
               return inc;
             }) : [];
            setData(resp)
         }, (message) => { })
   }

   const toggle = () => {
      if (modal) {
         showModal(false)
         setDetails({})
      }
      else {
         showModal(true)
      }
   }

   const editData = (id) => {
      let obj = data.data.find(item => item.id === id)
      if (obj) {
         setDetails(obj)
         toggle()
      }
   }

   const handlePage = (page) => {
      if (activePage !== page) {
         getList(page, search)
         setPage(page)
      }
   }

   const cancelSearch = () => {
      getList(1)
      setSearch('')
      setPage(1)
   }

   const handleSearch = () => {
      if (search === '') {
         toast.warn('No search value given')
      }
      else {
         getList(1, search)
         setPage(1)
      }
   }

   const handleClose = (isUpdate) => {
      if (isUpdate) {
         toggle();
         getList(activePage, search)
      }
      else {
         toggle()
         getList(1)
      }
   }

   const skiptonextstep = () => {
      history.push('/dashboard');
   }

   return (
      <>
         <div className="mt-3 p-3">
            <div className="row">
               <div className="col-12">
                  <button type="button" className="btn btn-outline-dark accordian-add-btn" onClick={() => showModal(true)}> <i className="fas fa-plus" />&nbsp;Add New</button>
               </div>
               <div className="col-12">
                  <div className="d-flex">
                     <input className="form-control mr-2" value={search} onChange={e => setSearch(e.target.value)} placeholder='Search by type' />
                     <button className="btn btn-outline-success mr-2" onClick={handleSearch}><i className="bx bx-search-alt" /></button>
                     <button className="btn btn-outline-danger" onClick={cancelSearch}><i className="bx bx-x-circle" /></button>
                  </div>
               </div>
            </div>

            <div className="table-responsive mt-3">
               <table className="table table-bordered">
                  <thead>
                     <tr>
                        <th style={{ minWidth: '120px' }}>Incident</th>
                        <th style={{ minWidth: '120px' }}>Date</th>
                        <th style={{ minWidth: '100px' }}>Time</th>
                        <th style={{ minWidth: '100px' }}>Status</th>
                        <th style={{ maxWidth: '150px' }}>Type</th>
                        <th>Actions</th>
                     </tr>
                  </thead>
                  <tbody>
                     {data && data.data && data.data.length ? data.data.map((item, i) =>
                        <tr key={i}>
                           <td>{item.title || 'N/A'}</td>
                           <td>{moment(item.incidentDate).format('MM-DD-YYYY')}</td>
                           <td>{moment(item.incidentTime, 'HH:mm').format('hh:mm a')}</td>
                           <td>{item?.status || 'N/A'}</td>
                           <td>{item?.incidentTypes?.map((t, ind) => (
                              ind < 2 && <span className="badge badge-info mr-2" style={{ fontSize: '12px' }}>{`${t.label.substr(0, 15)}..`}</span>
                           )) || ''} {item.incidentTypes.length > 2 ? `+${item.incidentTypes.length - 2} more` : ''}</td>
                           <td>
                              {(item?.status == 'Pending' || item?.status == 'Rejected')&&
                              <div>
                                 <i className="fa fa-edit mr-3 cursor-pointer" onClick={() => editData(item.id)} data-toggle="tooltip" title="Edit Incident" />
                              </div>
                              }
                           </td>
                        </tr>) : <tr><td className="no-td" colSpan="3">No results found</td></tr>}
                  </tbody>
               </table>
            </div>

            <div className="row">
               <div className="col-lg-6">
                  {data.total > 0 && <p>Total results: {data.total}</p>}
               </div>
               <div className="col-lg-6 d-flex justify-content-end">
                  {data.totalPage > 1 ?
                     <Pagination
                        activePage={activePage}
                        itemsCountPerPage={PER_PAGE}
                        totalItemsCount={data.total ? data.total : 1}
                        pageRangeDisplayed={5}
                        onChange={handlePage}
                        innerClass='pagination'
                        itemClass='paginate_button page-item'
                        linkClass='page-link'
                        activeClass='active'
                        hideFirstLastPages={true}
                        prevPageText='Previous'
                        nextPageText='Next'
                     /> : null}
               </div>
            </div>

            <div className="d-flex justify-content-between mt-3">

               {isWizard != true && (
                  <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               )}
               {isWizard === true && (
                  <button className="btn btn-primary w-lg btn-lg mt-0" onClick={()=>{skiptonextstep()
                     props.setforceExit(true)
                     }}>Exit Wizard </button>
               )}
            </div>

            <Modal centered isOpen={modal} size='lg'>
               <ModalHeader toggle={toggle}>Incident</ModalHeader>
               <ModalBody>
                  <ResIncidentForm
                     data={details}
                     uid={props.resId}
                     facilityId={props.facilityId}
                     companyId={props.companyId}
                     close={handleClose}
                  />
               </ModalBody>
            </Modal>
         </div>
      </>
   )
}

export default ResIncidentList