import React, {useEffect, useState} from 'react';
import Pagination from 'react-js-pagination';
import {
  encryptId,
  makePhotoLink,
  timeName,
} from '../../components/form-elements/functions';
import {
  ACTIONS,
  MODULES,
  NO_IMAGE,
  OPERATIONS,
  PER_PAGE,
  SITE_URL,
} from '../../config/config';
import moment from 'moment';
import 'moment-timezone';
import {useMedPassApi} from '../../hooks/api/medpassApiHook';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import PassMedForm from '../../components/pass-meds/passMedForm';
import {useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import RoleCheckHoc from '../../hoc/roleCheckHoc';
import LazyImage from '../../components/form-elements/lazyImage';
import {useResidentApi} from '../../hooks/api/residentApiHook';

const MedsListView = props => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [modal, showModal] = useState(false);
  const [details, setDetails] = useState({});
  const [datas, setDatas] = useState([]);

  const medPassApi = useMedPassApi();
  const history = useHistory();
  const dispatch = useDispatch();
  const activePage = useSelector(state => state.extras && state.extras.tabPage);
  const residentApi = useResidentApi();

  useEffect(() => {
    console.log('props.time', props.time, activePage)
    if (props?.time && props?.time == 'Pnr') {
      getList(activePage);
      //    setTimeout(() => {
      //    getList1(activePage)
      // }, 2000);
    } else {
      getList(activePage);
    }
  }, []);

  const getList = (page = 1, search = '') => {
    let from = moment(moment(new Date()).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
    let to = moment(moment(new Date()).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    console.log(from, to)
    let params = {
      resName: search,
      passtype: props.time,
      from: from, 
      to: to,
      limit: 9999999,//PER_PAGE,
      page,
    };

    medPassApi.getPatientList(
      params,
      (message, resp) => {
        if (props?.time && props?.time == 'Pnr') {
          getList2(resp);
        } else {
          // console.log('resp', resp);
          let withoutDouplicateItem = resp?.data?.filter(
            (test, index, array) =>
              index ===
              array.findIndex(
                findTest =>
                  findTest?.resident_firstName === test?.resident_firstName &&
                  findTest?.resident_lastName === test?.resident_lastName&&
                  moment(findTest?.resident_birthDate).format('YYYY-MM-DD') === moment(test?.resident_birthDate).format('YYYY-MM-DD'),
              ),
          );
          console.log(withoutDouplicateItem)
          setData({data: withoutDouplicateItem});
        }
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getList2 = data => {
    // console.log('shree krishna', data)
    let params = {
      firstName: search,
      passtype: props.time,
      limit: 9999999,
      page: 1,
    };

    residentApi?.residentList(
      params,
      (message, resp) => {
        // console.log(resp)
        let temp = resp?.data?.filter(item => item?.gender === 'other');
        let temp1 = temp?.filter(item => item?.frequency_time)?.map(item => {
          return {
            resident_firstName: item?.firstName,
            resident_lastName: item?.lastName,
            ...item,
          };
        });
        let newArray = [];
        newArray = [...newArray, ...data?.data, ...temp1];
        // console.log('newArray', newArray);

        let withoutDouplicateItem = newArray?.filter(
          (test, index, array) =>
            index ===
            array.findIndex(
              findTest =>
                findTest?.resident_firstName === test?.resident_firstName &&
                findTest?.resident_lastName === test?.resident_lastName &&
                moment(findTest?.resident_birthDate).format('YYYY-MM-DD') === moment(test?.resident_birthDate).format('YYYY-MM-DD'),
            ),
        );

          // console.log('withoutDouplicateItem', withoutDouplicateItem)
        const newparams = {data: withoutDouplicateItem};
        if (newparams) {
          setData(newparams);
        }
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getList1 = (page = 1, search = '') => {
    let params = {
      firstName: search,
      passtype: props.time,
      limit: PER_PAGE,
      page,
    };

    residentApi?.residentList(
      params,
      (message, resp) => {
        // console.log(resp)
        // let resident=resp && resp?.data?.length && resp?.data?.map((item)=>{
        //    item?.gender==gender
        // })
        // console.log('resident', resident)
        // if(datas && datas?.length){
        // console.log('datas', datas)
        // }
        setData(resp);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const handlePage = page => {
    if (activePage !== page) {
      if (props?.time && props?.time == 'Pnr') {
        getList1(page, search);
      } else {
        getList(page, search);
        dispatch({type: ACTIONS.MEDPASS.SET_PAGE, payload: page});
      }
    }
  };

  const toggle = () => {
    if (modal) {
      showModal(false);
      setDetails({});
    } else {
      showModal(true);
    }
  };

  const cancelSearch = () => {
    if (props?.time && props?.time == 'Pnr') {
      getList(1);
      setSearch('');
    } else {
      getList(1);
      setSearch('');
      dispatch({type: ACTIONS.MEDPASS.SET_PAGE, payload: 1});
    }
  };

  const handleSearch = () => {
    if (search === '') {
      toast.warn('No search value given');
    } else {
      if (props?.time && props?.time == 'Pnr') {
        getList1(1, search);
      } else {
        getList(1, search);
        dispatch({type: ACTIONS.MEDPASS.SET_PAGE, payload: 1});
      }
    }
  };

  return (
    <div className="mt-3 p-3">
      <div className="float-right">
        <div className="d-flex mb-3">
          <input
            className="form-control mr-3"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by resident"
          />
          <button
            className="btn btn-outline-success mr-2"
            onClick={handleSearch}>
            <i className="bx bx-search-alt" />
          </button>
          <button className="btn btn-outline-danger" onClick={cancelSearch}>
            <i className="bx bx-x-circle" />
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th style={{ width: '5%' }}></th>
              <th>Name</th>
              <th style={{minWidth: '150px'}}>
                Status for ({timeName(props.time)})
              </th>
              <th style={{minWidth: '150px'}}>Status for the day</th>
              <th style={{ textAlign: 'right', width: '8%' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data && data.data && data.data.length ? (
              data.data.map((item, i) => {
                return (
                  <tr key={i}>
                    {item.photo_path && (
                      <td className='p-1 text-center align-middle'>
                        <LazyImage
                          src={
                            item.photo_path
                              ? makePhotoLink(item.photo_path)
                              : ''
                          }
                          className="rounded avatar-sm"
                        />
                      </td>
                    )}
                    {item?.photo?.fullUrl && (
                      <td className='p-1 text-center align-middle'>
                        <img
                          loading="lazy"
                          src={item?.photo?.fullUrl || NO_IMAGE}
                          className="rounded avatar-sm"
                        />
                      </td>
                    )}

                    {!item?.photo_path && !item?.photo?.fullUrl && (
                      <td className='p-1 text-center align-middle'>
                        <img loading="lazy" src={NO_IMAGE} className="rounded avatar-sm"/>{' '}
                      </td>
                    )}
                    {/* {props.time != 'Pnr' && */}
                    <td className='align-middle'>
                      {item?.resident_firstName || item?.firstName}{' '}
                      {item?.resident_lastName || item?.lastName}
                    </td>

                    <td className='align-middle'>
                      {props.time === 'Pnr' || props.time === 'CmfKit' ? (
                        'N/A'
                      ) : (
                        <>
                          <div className="progress" id="tooltip-container">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.freqDispTaken / item.freqDispTotal) *
                                    100,
                                )}%`,
                              }}
                            />
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.freqDispRefused / item.freqDispTotal) *
                                    100,
                                )}%`,
                              }}
                            />
                          </div>
                          <div className="mt-2 d-flex flex-column">
                            {item.freqDispTotal -
                              item.freqDispRefused -
                              item.freqDispTaken >
                              0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#d2d1d1',
                                  }}
                                />
                                <span>
                                  {item.freqDispTotal -
                                    item.freqDispRefused -
                                    item.freqDispTaken}{' '}
                                  pending
                                </span>
                              </div>
                            )}
                            {item.freqDispTaken > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#34c38f',
                                  }}
                                />
                                <span>
                                  {item.freqDispTaken} already dispensed
                                </span>
                              </div>
                            )}
                            {item.freqDispRefused > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f46a6a',
                                  }}
                                />
                                <span>{item.freqDispRefused} refused</span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </td>
                    
                    <td className='align-middle'>
                      {props.time === 'Pnr' || props.time === 'CmfKit' ? (
                        'N/A'
                      ) : (
                        <>
                          <div className="progress" id="tooltip-container">
                            <div
                              className="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.dayTaken / item.dayTotal) * 100,
                                )}%`,
                              }}
                            />
                            <div
                              className="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: `${Math.ceil(
                                  (item.dayRefused / item.dayTotal) * 100,
                                )}%`,
                              }}
                            />
                          </div>
                          <div className="mt-2 d-flex flex-column">
                            {item.dayTotal - item.dayRefused - item.dayTaken >
                              0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#d2d1d1',
                                  }}
                                />
                                <span>
                                  {item.dayTotal -
                                    item.dayRefused -
                                    item.dayTaken}{' '}
                                  pending
                                </span>
                              </div>
                            )}
                            {item.dayTaken > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#34c38f',
                                  }}
                                />
                                <span>{item.dayTaken} already dispensed</span>
                              </div>
                            )}
                            {item.dayRefused > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f46a6a',
                                  }}
                                />
                                <span>{item.dayRefused} refused</span>
                              </div>
                            )}
                            {item.prnGiven > 0 && (
                              <div className="d-flex align-items-baseline">
                                <span
                                  className="dot"
                                  style={{
                                    backgroundColor: '#f1b44c',
                                  }}
                                />
                                <span>{item.prnGiven} PRN</span>
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </td>
                    <td className='align-middle text-right'>
                      <RoleCheckHoc
                        module={MODULES.PASS_MEDS}
                        operation={[
                          OPERATIONS.READ,
                          OPERATIONS.UPDATE,
                          OPERATIONS.CREATE,
                        ]}>
                        <button
                          className="btn btn-dark btn-sm"
                          onClick={() =>
                            props.time === 'Pnr'
                              ? history.push(
                                  `/administer-prn/${encryptId(
                                    item?.id || item?.resident_id || item?.medication_residentId,
                                  )}/${props.time}`,
                                )
                              : history.push(
                                  `/dispense-meds/${encryptId(
                                    item.frequency_residentId,
                                  )}/${props.time}`,
                                )
                          }>
                          View Meds
                        </button>
                      </RoleCheckHoc>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="no-td" colSpan="3">
                  No results found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="row">
        {/* <div className="col-lg-6">
               {data.total > 0 && <p>Total results: {data.total}</p>}
            </div> */}
        <div className="col-lg-6 d-flex justify-content-end">
          {data.totalPage > 1 ? (
            <Pagination
              activePage={activePage}
              itemsCountPerPage={PER_PAGE}
              totalItemsCount={data.total ? data.total : 1}
              pageRangeDisplayed={5}
              onChange={handlePage}
              innerClass="pagination"
              itemClass="paginate_button page-item"
              linkClass="page-link"
              activeClass="active"
              hideFirstLastPages={true}
              prevPageText="Previous"
              nextPageText="Next"
            />
          ) : null}
        </div>
      </div>
      <Modal centered isOpen={modal} size="lg">
        <ModalHeader toggle={toggle}>Dispense</ModalHeader>
        <ModalBody>
          <PassMedForm
            resId={details.frequency_residentId}
            type={details.frequency_time}
            photo={
              details.photo_path ? makePhotoLink(details.photo_path) : null
            }
            name={`${details.resident_firstName} ${details.resident_lastName}`}
            close={toggle}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MedsListView;
