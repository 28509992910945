import React, { useEffect, useState } from "react";
import { useMedPassApi } from "../../hooks/api/medpassApiHook";
import DatePicker from "react-datepicker";
import { Input, Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import 'moment-timezone';
import { toast } from "react-toastify";
import { Collapse, CardBody, Card, CardHeader } from "reactstrap";
import { MODULES, NO_IMAGE, OPERATIONS, PER_PAGE, STORE_ID } from "../../config/config";
import Pagination from "react-js-pagination";
import InputMask from "react-input-mask";
import RoleCheckHOC from "../../hoc/roleCheckHoc";
import { useHistory } from "react-router-dom";
import { useUserSelector } from '../../hooks/selectors/userSelector';
import { useMedicationApi } from "../../hooks/api/medcationApiHook";
import { useResidentApi } from '../../hooks/api/residentApiHook';

const PassMedForm = (props) => {
  const [medList, setMedList] = useState([]);
  // const [medRx, setMedRx] = useState([]);
  const [resident, setResData] = useState({});
  const [dispList, setDispList] = useState([]);
  const [activePage, setPage] = useState(1);
  const [searchDate, setSearchDate] = useState("");
  const [advModal, setAdvModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [toggleQuestion, setToggequestion] = useState(-1);

  const medPassApi = useMedPassApi();
  const medicationApi = useMedicationApi();
  const residentApi = useResidentApi()
  const history = useHistory();
  const user = useUserSelector()
  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
  // console.log(timeZone);

  useEffect(() => {
    if (props.resId) {
      getMedLists();
    }
  }, [props.resId]);

  const getMedLists = () => {
    let from = moment(moment(new Date()).format('YYYY-MM-DD 00:00:00')).utc().format('YYYY-MM-DD HH:mm')
    let to = moment(moment(new Date()).format('YYYY-MM-DD 23:59:59')).utc().format('YYYY-MM-DD HH:mm')
    console.log(from, to)
    medPassApi.getMedPassDetails(
      { residentId: props.resId, type: props.type, from: from, to: to  },
      (message, resp) => {
        let temp =
          resp.data.length &&
          resp.data.map((item) => {
            return {
              ...item,
              // giveTime: item.dispense.length
              //   ? moment(item.dispense[0].time, "HH:mm:ss").format("HH:mm")
              //   : moment(new Date()).format("HH:mm"),
              giveTime: item.dispense.length
              ? moment.utc(item.dispense[0].date + ' ' + item.dispense[0].time).local().format('HH:mm')
              : moment.utc(new Date()).local().format("HH:mm"),
              giveDate: new Date(),
              giveType:
                item.status && item.status.role === "Taken"
                  ? "given"
                  : item.status.role === "Refused"
                  ? "refused"
                  : "",
              note: item.dispense.length ? item.dispense[0].note : "",
            };
          });
        setMedList(temp);
        setResData(resp.resident);
      },
      (message, resp) => {
        if (resp.status === 404) {
          toast.error("Resident not found");
          setTimeout(() => {
            history.replace("/pass-meds");
          }, 500);
        } else {
          toast.error(message);
        }
      }
    );
  };
 
  const handleGiven = (index, type, value) => {
    let temp = [...medList];
    temp[index][type] = value;

    setMedList(temp);
  };

  const onSubmit = () => {
    let tempArr = [];
    let status = true;
      
    for (let index = 0; index < medList.length; index++) {
      console.log("index",medList[index].medication.prn_qty,(medList[index].medication.prn_qty - 8));
    
      let notes = medList[index].note ? medList[index].note : "";

      if (!medList[index].giveType) {
        toast.error(
          `Please select given or refused for medication: ${
            medList[index].medication && medList[index].medication.name
          }`
        );
        status = false;
        break;
      }
      if ((medList[index].medication.prn_qty) <= 0) {
        //&& props.type == 'Treatment'
        toast.error("Medication Quantity must be more than 0");
        status = false;
        break;
      }
      if (
        !medList[index].giveTime ||
        parseInt(medList[index].giveTime.split(":")[0]) > 23 ||
        parseInt(medList[index].giveTime.split(":")[1]) > 59
      ) {
        toast.error(
          `Invalid "At" time for medication: ${
            medList[index].medication && medList[index].medication.name
          }`
        );
        status = false;
        break;
      }
      if (notes.length > 250) {
        toast.error(
          `Max length for note is 250 characters for medication: ${
            medList[index].medication && medList[index].medication.name
          }`
        );
        status = false;
        break;
      }
      console.log(medList[index].giveDate,moment(moment(medList[index].giveDate).format('YYYY-MM-DD') + ' ' + medList[index].giveTime).utc().format("HH:mm"))
      let temp = {
        note: notes,
        time: moment(moment(medList[index].giveDate).format('YYYY-MM-DD') + ' ' + medList[index].giveTime).utc().format("HH:mm"), //medList[index].giveTime,
        roles: medList[index].giveType === "given" ? "Taken" : "Refused",
        date: moment(moment(medList[index].giveDate).format('YYYY-MM-DD') + ' ' + medList[index].giveTime).utc().format("YYYY-MM-DD"), //moment(medList[index].giveDate).format("YYYY-MM-DD"),
        frequencyId: medList[index].id,
        residentId: resident.id,
      };
      if (medList[index].dispense.length === 0) {
        tempArr.push(temp);
      }else if(medList[index].dispense[0].date == moment(new Date()).format('YYYY-MM-DD')){
        tempArr.push(temp);
      }
      // let thirdPartyprn_qty = medList[index].medication.prn_qty -1;
      // console.log("thirdPartyprn_qty",thirdPartyprn_qty,user,medList[index].time,medList[index]?.medication?.thirdPartyMedical.filter((r) => r.RxStatus === 0).length)
      /* if(medList[index].time == 'Treatment' && thirdPartyprn_qty <= 8 && user?.company?.thirdPartyApiFacility && medList[index]?.medication?.pharmacy == 'Senior Wellness Solutions' && medList[index]?.medication?.thirdPartyMedical?.filter((r) => r.RxStatus === 0).length < 1){
      // if(thirdPartyprn_qty <= 5 && user?.company?.thirdPartyApiFacility && medList[index]?.medication?.thirdPartyMedical?.filter((r) => r.RxStatus === 0).length < 1){
        thirdPApiCalling(medList[index].medication, resident)
        console.log("thirdPartyprn_qty")
      } */
      console.log(tempArr, status)
    }
    if (status) {
      // setTimeout(() => {
        medPassApi.submitMedPass(
          tempArr,
          (message, resp) => {
            if (toggleQuestion !== -1) {
              getDispenseList(1, "", toggleQuestion);
            }
            medList?.map((item, index) => {
              if ((medList[index].medication.prn_qty - 1) <= 0) {
                //&& props.type == 'Treatment'
                toast.error(`Medication Quantity must be more than 0 for medication: ${
                  medList[index].medication && medList[index].medication.name
                }`);
              }
            })
            setPage(1);
            getMedLists();
            toast.success("Changes saved successfully");
          
          },
          (message) => {
            toast.error(message);
          }
        ); 
      // },3000)
    }
  };

  //calling when prn_qty <=5
  const thirdPApiCalling = (obj, companyId) => {
    // console.log('companyId',companyId, obj,user)
    let params = { StoreID: STORE_ID, DocSignature: null, MessageHashCode: null, PDFFile: "", Patient: { FirstName: companyId?.firstName, LastName: companyId?.lastName, Sex: companyId?.gender, Patientstreet: companyId?.facility?.addrLine1, MI: null, DOB: companyId?.birthDate, Patientcity: null, Patientstate: null, Patientzip: null, PatientPhone: companyId?.phone || null, SSN: companyId?.ssn || null }, Doctor: { DoctorLastName: obj?.physician, DoctorFirstName: obj?.physician, Doctorstreet: null, Doctorcity: null, Doctorstate: null, Doctorzip: null, DoctorDea: null, DoctorNpi: null, DoctorPhone: null }, RxClaim: { DrugName: obj?.name, Qty: obj?.prn_qty.toString(), Refills: obj?.refillis.toString(), Instructions: obj?.spDirections, DrugNDC: null, DateWritten: null, Daw: null, DaysSupply: null, Notes: obj?.spDirections, RequestedBy: null }, Insurance: { Bin: null, PCN: null, CardNumber: null, GroupNumber: null } }
    residentApi.thirdPartyPRNSubmit(params,
      (message, resp) => {
        // toast.success('Medication submitted successfully')
        if (resp?.data?.ID) {
          updateRxStatus(obj, resp?.data?.ID)
        }
      }, (message) => {
        toast.error(message)
      })
  }

  const updateRxStatus = (data, rxId) => { 
    let params = {}
    params['RxNumber'] = rxId
    params['RxStatus'] = 0
    params['id'] = data?.id
    params['residentId'] = props.resId
    medicationApi.updateRxStatus(
      params,
      (message, resp) => {
        // getList1();
      },
      message => {
        toast.error(message);
      },
    );

  };

  const changeDropdown = (index) => {
    if (index == toggleQuestion) {
      setToggequestion(-1);
    } else {
      getDispenseList(1, "", index);
      setToggequestion(index);
    }
  };

  const getDispenseList = (page = 1, search = "", index) => {
    let params = {
      search,
      limit: PER_PAGE,
      page,
      frequencyId: medList[index].id,
    };
    medPassApi.dispenseList(
      params,
      (message, resp) => {
        console.log(resp)
        let tempDisp = resp.data.length ? resp?.data?.map((disp) => {
          // disp.time = moment.utc(disp.date + ' ' + disp.time).local().format('HH:mm')
          // const date = moment.utc(disp.date).local().format('YYYY-MM-DD')
          var localTime = moment.utc(disp.time, "HH:mm").local().tz(timeZone).format("HH:mm");
          var localDate = moment.utc(disp.date + ' ' + disp.time, "YYYY-MM-DD HH:mm").local().tz(timeZone).format("YYYY-MM-DD");
          // console.log(localTime, localDate);
          disp.date = localDate
          disp.time = localTime
          console.log(disp.date, disp.time)
          return disp
        }) : []
        console.log(tempDisp)
        setDispList(resp);
      },
      (message) => {
        toast.error(message);
      }
    );
  };

  const handlePage = (page, index) => {
    if (activePage !== page) {
      let time = searchDate ? moment(searchDate).format("YYYY-MM-DD") : "";
      getDispenseList(page, time, index);
      setPage(page);
    }
  };

  const cancelSearch = (index) => {
    getDispenseList(1, "", index);
    setSearchDate("");
    setPage(1);
  };

  const handleSearch = (index) => {
    if (searchDate === "") {
      toast.warn("No search value given");
    } else {
      getDispenseList(1, moment(searchDate).format("YYYY-MM-DD"), index);
      setPage(1);
    }
  };

  const toggleAdv = () => {
    if (advModal) {
      setAdvModal(false);
      setModalData({});
    } else {
      setAdvModal(true);
    }
  };

  const updateDisp = (params) => {
    params["id"] = modalData.id;
    medPassApi.updateDispense(
      params,
      (message, resp) => {
        let time = searchDate ? moment(searchDate).format("YYYY-MM-DD") : "";
        toggleAdv();
        getDispenseList(activePage, time, modalData.index);
        toast.success("Changes updated successfully");
      },
      (message) => {
        toast.error(message);
      }
    );
  };

  const setEditData = (data, index) => {
    setModalData({ ...data, index });
    toggleAdv();
  };

  return (
    <div className="row">
      <div className="col-lg-2 bg-light">
        <div className="card bg-light shadow-none">
          <div className="card-body text-center">
            <img
              src={resident.photo ? resident.photo.fullUrl : NO_IMAGE}
              className="rounded-circle avatar-xl"
            />
            <h4 className="text-dark mb-3 mt-3">
              {resident.firstName} {resident.lastName}
            </h4>
            <p className="card-text">
              Birth Date: {moment(resident.birthDate).format("MM-DD-YYYY")}
            </p>
            <p className="card-text">
              Language: {resident.language || "N/A"}
            </p>
            <p className="card-text">
              Religion: {resident.religion || "N/A"}
            </p>

            <button className="btn btn-primary btn-block waves-light mt-3" onClick={() => history.push('/pass-meds')}>Back to list</button>
          </div>
        </div>
      </div>
      <div className="col-lg-10 px-xs-0 mt-xs-3">
        {medList.length ? (
          medList.map((item, i) => (
            <Card key={i}>
              <CardHeader>
                <div
                  className="d-flex justify-content-between cursor-pointer"
                  onClick={() => changeDropdown(i)}
                >
                  <div>
                    <i className="fa fa-caret-down mr-2" />
                    <span
                      className="font-weight-bold color-blue"
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.medication ? item.medication.name : ""} (
                      {item.medication ? item.medication.strength : ""}{" "}
                      {item.medication ? item.medication.units : ""})
                    </span>
                  </div>
                </div>
                <div className="pilltime_list mt-2">
                  <div className="pillcheck_ctrl">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"group" + item.id}
                        id={"optGive" + item.id}
                        checked={item.giveType === "given" ? true : false}
                        onChange={(e) => handleGiven(i, "giveType", "given")}
                        disabled={item.status.role !== ""}
                      />
                      <label
                        className={`form-check-label`}
                        htmlFor={"optGive" + item.id}
                      >
                        Med Given
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"group" + item.id}
                        id={"optRefused" + item.id}
                        checked={item.giveType === "refused" ? true : false}
                        onChange={(e) =>
                          handleGiven(i, "giveType", "refused", e.target.id)
                        }
                        disabled={item.status.role !== ""}
                      />
                      <label
                        className={`form-check-label`}
                        htmlFor={"optRefused" + item.id}
                      >
                        Med Refused
                      </label>
                    </div>
                  </div>
                  <div className="pill_date">
                    <label>On</label>
                    <div className="ne-datepicker-container">
                      <DatePicker
                        selected={item.giveDate}
                        onChange={(date) => handleGiven(i, "giveDate", date)}
                        utcOffset={0}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        disabled={item.status.role !== ""}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="pill_time">
                    <label>At</label>
                    <div className="ne-datepicker-container">
                      <InputMask
                        mask="99:99"
                        value={item.giveTime}
                        name="from"
                        className="form-control"
                        onChange={(e) =>
                          handleGiven(i, "giveTime", e.target.value)
                        }
                        placeholder="12:00"
                        readOnly={item.status.role !== ""}
                      />
                    </div>
                  </div>
                  <div className="pill_note">
                    <label>Note</label>
                    <Input
                      type="textarea"
                      className="form-control"
                      onChange={(e) => handleGiven(i, "note", e.target.value)}
                      value={item.note}
                      maxLength={250}
                      disabled={item.status.role !== ""}
                    />
                  </div>
                </div>
              </CardHeader>
              <Collapse isOpen={toggleQuestion == i ? true : false}>
                <CardBody className="px-0">
                  <div className="card-body pill_box px-0">
                    <div className="pill_heading">
                      <h3>
                        {item.medication ? item.medication.name : ""}
                        <span>
                          {item.medication ? item.medication.strength : ""}{" "}
                          {item.medication ? item.medication.units : ""}
                        </span>
                      </h3>
                    </div>
                    {item.medication &&
                    item.medication.treatmentDuration &&
                    props.type === "Treatment" ? (
                      <div className="pilltime_list">
                        <div className="row">
                          <div className="col-12">
                            <p>
                              <strong>Treatment Duration: </strong>
                              {item.medication.treatmentDuration}{" "}
                              {item.medication.treatmentDurationType + "s"}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row mt-2">
                      <div className="col-12">
                        {dispList.status?.role === "Taken" && (
                          <div className="alert alert-success">
                            Medicine given
                          </div>
                        )}

                        {dispList.status?.role === "Refused" && (
                          <div className="alert alert-danger">
                            Medicine refused
                          </div>
                        )}

                        <div className="pilltake_declist">
                          <div className="float-right d-flex my-3">
                            <div className="ne-datepicker-container">
                              <DatePicker
                                selected={searchDate}
                                onChange={(date) => setSearchDate(date)}
                                utcOffset={0}
                                maxDate={new Date()}
                                dateFormat="MM-dd-yyyy"
                                className="form-control"
                              />
                            </div>
                            <button
                              className="btn btn-outline-success ml-2"
                              onClick={() => handleSearch(i)}
                            >
                              <i className="fa fa-search" />
                            </button>
                            <button
                              className="btn btn-outline-danger ml-2"
                              onClick={() => cancelSearch(i)}
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                          <div className="table-responsive">
                            <table className="table table-condensed table-hover">
                              <thead>
                                <tr>
                                  <th>For Date</th>
                                  <th>Time</th>
                                  <th>Status</th>
                                  <th>Note</th>
                                  <th>Adverse Reaction</th>
                                  <th style={{ textAlign: 'right', width: '5%' }}>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dispList.data && dispList.data.length ? (
                                  dispList.data.map((disp, key) => (
                                    <tr key={key}>
                                      <td>
                                        <span>
                                          {moment(disp.date).format("MM-DD-YYYY")}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {moment(disp.time, "HH:mm:ss").format(
                                            "HH:mm"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span>{disp.roles}</span>
                                      </td>
                                      <td>
                                        <span>
                                          {!disp.note.length
                                            ? "-"
                                            : disp.note.length > 10
                                            ? `${disp.note.substr(0, 10)}...`
                                            : disp.note}
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {disp.roles !== "Taken"
                                            ? "-"
                                            : !disp.adv_reaction.length
                                            ? "-"
                                            : disp.adv_reaction.length > 10
                                            ? `${disp.adv_reaction.substr(
                                                0,
                                                10
                                              )}...`
                                            : disp.adv_reaction}
                                        </span>
                                      </td>
                                      <td className="text-right align-middle">
                                        <RoleCheckHOC
                                          module={MODULES.PASS_MEDS}
                                          operation={[
                                            OPERATIONS.READ,
                                            OPERATIONS.UPDATE,
                                          ]}
                                        >
                                          <i
                                            className="fa fa-edit cursor-pointer"
                                            onClick={() => setEditData(disp, i)}
                                          />
                                        </RoleCheckHOC>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="3" className="no-td">
                                      No results found
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>

                          <div className="row mt-1">
                            <div className="col-lg-6">
                              {dispList.total > 0 && (
                                <p>Total results: {dispList.total}</p>
                              )}
                            </div>
                            <div className="col-lg-6 d-flex justify-content-end">
                              {dispList.totalPage > 1 ? (
                                <Pagination
                                  activePage={activePage}
                                  itemsCountPerPage={PER_PAGE}
                                  totalItemsCount={
                                    dispList.total ? dispList.total : 1
                                  }
                                  pageRangeDisplayed={5}
                                  onChange={(pg) => handlePage(pg, i)}
                                  innerClass="pagination"
                                  itemClass="paginate_button page-item"
                                  linkClass="page-link"
                                  activeClass="active"
                                  hideFirstLastPages={true}
                                  prevPageText="Previous"
                                  nextPageText="Next"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Collapse>
            </Card>
          ))
        ) : (
          <p>No results found</p>
        )}

        {medList.length ? (
          <RoleCheckHOC
            module={MODULES.PASS_MEDS}
            operation={[OPERATIONS.CREATE]}
          >
            <div>
              <label>&nbsp;</label>
              <button
                type="button"
                className="btn btn-primary waves-light float-right"
                onClick={() => onSubmit()}
              >
                Submit
              </button>
            </div>
          </RoleCheckHOC>
        ) : null}
      </div>
      <AdverseModal
        isOpen={advModal}
        close={toggleAdv}
        updateDisp={updateDisp}
        data={modalData}
      />
    </div>
  );
};

export default PassMedForm;

const AdverseModal = (props) => {
  const [data, setData] = useState({ adv_reaction: "", note: "" });

  useEffect(() => {
    if (props.data.id) {
      setData({ adv_reaction: props.data.adv_reaction, note: props.data.note });
    }
  }, [props.data]);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    if (data.adv_reaction.length > 250) {
      toast.error("Max length for adverse reaction is 250 characters");
      return;
    }
    if (data.note.length > 250) {
      toast.error("Max length for note is 250 characters");
      return;
    } else {
      props.updateDisp(data);
    }
  };

  return (
    <Modal centered isOpen={props.isOpen} size="md">
      <ModalHeader toggle={props.close}>Update Dispense</ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <p>
            <strong>For Date: </strong>{" "}
            {moment(props.data.date).format("MM-DD-YYYY")}
          </p>
          <p>
            <strong>Time: </strong>{" "}
            {moment(props.data.time, "HH:mm:ss").format("HH:mm")}
          </p>
        </div>
        <div className="form-group">
          <label className="form-label">Adverse Reaction</label>
          <Input
            type="textarea"
            onChange={handleChange}
            value={data.adv_reaction}
            name="adv_reaction"
            maxLength={250}
            disabled={props.data.roles === "Refused"}
          />
          <small className="text-muted float-right">
            (Maximum 250 characters)
          </small>
        </div>
        <div className="form-group">
          <label className="form-label">Note</label>
          <Input
            type="textarea"
            onChange={handleChange}
            value={data.note}
            name="note"
            maxLength={250}
          />
          <small className="text-muted float-right">
            (Maximum 250 characters)
          </small>
        </div>
        <RoleCheckHOC
          module={MODULES.PASS_MEDS}
          operation={[OPERATIONS.UPDATE]}
        >
          <div className="float-right w-100"><button
            className="btn btn-primary waves-light float-right mt-4"
            onClick={onSubmit}
          >
            Submit
          </button>
          </div>
        </RoleCheckHOC>
      </ModalBody>
    </Modal>
  );
};
