import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useReusableContactApi() {
   const callApi = useApiCall();

   const addReusableContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.CREATE_REUSABLE_CONTACT, data, onSuccess, onError);
   }

   const updateReusableContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.UPDATE_REUSABLE_CONTACT, data, onSuccess, onError);
   }

   const reusableContactList = (data, onSuccess, onError, showLoader = true) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.LIST_REUSABLE_CONTACT, data, onSuccess, onError, showLoader);
   }

   const reusableContactDetails = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.GET_REUSABLE_CONTACT, data, onSuccess, onError);
   }

   const deleteReusableContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.DELETE_REUSABLE_CONTACT, data, onSuccess, onError);
   }

   const getTypeList = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.REUSABLE_CONTACT.LIST_CONTACT_TYPES, data, onSuccess, onError);
   }

   return {
      addReusableContact,
      updateReusableContact,
      reusableContactDetails,
      reusableContactList,
      getTypeList,
      deleteReusableContact
   }
}