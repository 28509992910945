import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

export const CheckboxInput = ({ inputref, value, error, ...props }) => {
   return (
      <>
         <FormGroup check>
            <Label check>
               <Input
                  type="checkbox"
                  id={props.name}
                  ref={inputref}
                  checked={value}
                  onChange={(evt) => { props.onChange(evt.target.checked) }}
                  className={`text-input ${error?.message ? 'text-input-error' : ''}`}
                  className="mr-2"
                  disabled={!!props.disabled}
               />{' '}
               <p style={{marginTop: '2px'}}>{props.label}</p>
            </Label>
         </FormGroup>
         <p className="invalid-feedback">{error?.message}</p>
      </>
   )
}