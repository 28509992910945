import moment from "moment";
import 'moment-timezone';
import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {toast} from 'react-toastify';
import {
  decryptId,
  formatPhone,
  formatSSN,
} from '../../components/form-elements/functions';
import {useResidentApi} from '../../hooks/api/residentApiHook';
import ReactToPrint from 'react-to-print';
import {NO_IMAGE} from '../../config/config';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// you will need the css that comes with bootstrap@3. if you are using
// a tool like webpack, you can do the following:
// import 'bootstrap-daterangepicker/daterangepicker.css';
import DatePicker from 'react-datepicker';
// import 'rsuite/dist/styles/rsuite-default.css';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';

const Template = props => {
  const [resident, setResident] = useState({});
  const [medication, setMedication] = useState([]);
  const [discontd, setDiscontd] = useState([]);
  const [getAge, setAge] = useState();

  const {id} = useParams();
  const history = useHistory();
  const residentApi = useResidentApi();
  const [startDateAndEnddate, setStartAndEndDate] = useState(null);
  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [endDates1, setEndDates1] = useState();

  const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
  console.log(timeZone);

  useEffect(() => {
    const currentDate = moment(new Date()).utc().format('YYYY-MM-DD');
    const previousOneYearDate = moment(currentDate).utc()
      .subtract(364, 'days')
      .format('YYYY-MM-DD');
      const currentDatesNewFormat = moment(new Date()).format('MM/DD/YYYY');
    const previousOneYearDateNewFormat = moment(currentDatesNewFormat)
      .subtract(364, 'days')
      .format('MM/DD/YYYY');
      setStartDates(previousOneYearDateNewFormat);
      setEndDates1(currentDatesNewFormat)
    console.log('currentDate', currentDate, previousOneYearDate);
    getInfo(previousOneYearDate, currentDate);
  }, []);

  const calculateAge = dob => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    setAge(age);
  };

  const getInfo = (discontinue_start_date, discontinue_end_date) => {
    if (isNaN(decryptId(id))) {
      toast.error('Invalid Link');
      setTimeout(() => {
        history.goBack();
      }, 1000);
    } else {
      residentApi.nineOneOne(
        {
          id: decryptId(id),
          discontinue_start_date: discontinue_start_date,
          discontinue_end_date: discontinue_end_date,
        },
        (message, resp) => {
          calculateAge(resp.resident.birthDate);
          setResident(resp.resident);
          let actMed = resp.activeMedication.length
            ? resp.activeMedication.map(item => {
                console.log('gettt items', item);
                
                return {
                  name: item.name,
                  strength: item.strength,
                  units: item.units,
                  spDirections: item.spDirections,
                  pharmacy: item.pharmacy,
                  physician: item.physician,
                  dateStarted: item.dateStarted,
                  disp: item?.disp?.length ? item?.disp.map((dispense) => {
                    // dispense.time = moment.utc(dispense.date + ' ' + dispense.time).local().format('HH:mm')
                    // const date = moment.utc(dispense.date).local().format('YYYY-MM-DD')
                    var localTime = moment.utc(dispense.time, "HH:mm").local().tz(timeZone).format("HH:mm");
                    var localDate = moment.utc(dispense.date + ' ' + dispense.time, "YYYY-MM-DD HH:mm").local().tz(timeZone).format("YYYY-MM-DD");
          
                    dispense.date = localDate
                    dispense.time = localTime
                    console.log(dispense.date, dispense.time)
                    return dispense
                  }) : [],
                };
              })
            : [];

          let disMed = resp.discontinuedMedication.length
            ? resp.discontinuedMedication.map(item => {
                return {
                  name: item.name,
                  strength: item.strength,
                  units: item.units,
                  disp: item.disp,
                  deletedAt: item.deletedAt,
                  spDirections: item.spDirections,
                };
              })
            : [];

          setMedication(actMed);
          setDiscontd(disMed);
        },
        message => {
          toast.error(message);
        },
      );
    }
  };

  const getAges = dateString => {
    const date = moment(dateString, 'YYYY-MM-DD');
    const years = moment().diff(date, 'years');
    return years;
  };

  const onEventData = (event, picker) => {
    console.log('event', event);
    if (event) {
      const startDate = moment(event[0]).format('YYYY-MM-DD');
      const endDate = moment(event[1]).format('YYYY-MM-DD');
      setStartDates(moment(startDate).format('MM/DD/YYYY'))
      setEndDates1(moment(endDate).format('MM/DD/YYYY'))
      getInfo(startDate, endDate);      
    }
  };

  const onOkayData = () => {
    // console.log('startDates', startDates)
    // console.log('endDates', endDates)
    // getInfo(startDates, endDates);
  };

  const onCleanData = () => {
    const currentDate = moment(new Date()).format('YYYY-MM-DD');
    var previousOneYearDate = moment(currentDate)
      .subtract(364, 'days')
      .format('YYYY-MM-DD');
    console.log('currentDate', currentDate, previousOneYearDate);
    const currentDatesNewFormat = moment(new Date()).format('MM/DD/YYYY');
    const previousOneYearDateNewFormat = moment(currentDatesNewFormat)
      .subtract(364, 'days')
      .format('MM/DD/YYYY');
      setStartDates(previousOneYearDateNewFormat);
      setEndDates1(currentDatesNewFormat)
    getInfo(previousOneYearDate, currentDate);
  };

  return (
    <div className="row f_1 emergency_report">
      <style>
        {`@page {
    size: auto;
    margin: 0;
         }
  `}
      </style>
      <div className="col-lg-2 bg-light">
         <div className="card shadow-none bg-light">
          <div className="card-body text-center">
            <img
              src={resident.photo ? resident.photo.fullUrl : NO_IMAGE}
              alt=""
              className="rounded-circle avatar-xl"
            />

            <h4 className="text-dark my-3">{resident.firstName} {resident.lastName}</h4>
            <p>
              <strong>SSN: </strong>
              {resident.ssn ? formatSSN(resident?.ssn?.replace(/[^\d]/g, '')) : 'N/A'}
            </p>
            <p>
              <strong>Date of Birth: </strong>
              {moment(resident.birthDate).format('MMM DD, YYYY')}
            </p>
            <p>
              <strong>Age: </strong>
              {resident?.birthDate
                ? getAges(resident?.birthDate)
                : ''}{' '}
              Years
            </p>
          </div>
         </div>
      </div>
      <div className="col-lg-10 px-xs-0 mt-xs-3">
        <div className="card">
          <div className="card-body p-0">
           
            <div className="em-sections m-0">
              <h5>Active Medications</h5>
              <div className="">
                {medication && medication.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Start Date</th>
                          <th>Strength</th>
                          {/* <th>Units</th> */}
                          <th>Directions</th>
                          <th>Prescribing Physician</th>
                          <th>Pharmacy</th>
                        </tr>
                      </thead>
                      <tbody>
                        {medication.map((item, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="font-weight-bolder">
                                {item.name}
                              </td>
                              {console.log(item?.dateStarted)}
                              <td>
                                {item?.dateStarted ? moment(item?.dateStarted).utc().format('MM/DD/YYYY') : 'N/A'}
                              </td>
                              {console.log('medi item', item)}
                              <td>{item.strength} {item.units}</td>
                              {/* <td>{item.units}</td> */}
                              <td>
                                {item?.spDirections}
                              </td>
                              <td>{item?.physician}</td>
                              <td>{item?.pharmacy}</td>
                            </tr>

                            {item?.disp?.length != 0 &&
                              item?.disp?.map((obj, j) =>
                                obj?.roles === 'Refused' ? (
                                  <tr>
                                    <td colSpan="7"> {/* colSpan="6" */}
                                      <span>
                                        <b>Refuse Date and Time :</b>
                                      </span>{' '}
                                      {obj?.date
                                        ? moment(obj?.date).format('MM-DD-YYYY')
                                        : 'N/A'}{' '}
                                      {obj?.date ? ',' : ''}{' '}
                                      {obj?.time
                                        ? obj?.time
                                        : 'N/A'}
                                      {' , '}
                                      
                                      {/* obj?.time?.replace(':00', '') */}
                                      <span>&nbsp; &nbsp;</span>
                                      <span>
                                        <b>Refuse Notes :</b>
                                      </span>{' '}
                                      {obj?.note || 'N/A'}
                                    </td>
                                  </tr>
                                ) : null,
                              )}

                            {item.spDirections ? (
                              <tr style={{borderBottom: '2px solid #a2a2a2'}}>
                                <td colSpan="7">
                                  
                                </td>
                              </tr>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h6 className="ml-3">No results found</h6>
                )}
              </div>
            </div>
              
            <div class="page-break">&nbsp;</div>
            <div className="em-sections m-0">
              <h5>
                Discontinued Medications{' '}
                <span className="font-15 pre-line">
                  ({startDates} to {endDates1})
                </span>
                <span className="font-15 pre-line move-left">
                  
                  <DateRangePicker
                    onChange={onEventData}
                    onClean={onCleanData}
                    onOk={onOkayData}
                    placeholder={'Select Date Range'}
                    placement='topEnd'
                    preventOverflow
                    ranges={[
                  //     {
                  //   label: 'Last 7 days',
                  //   value: [dateFns.subDays(new Date(), 6), new Date()]
                  // }
                ]
                  }
                  />
                </span>
              </h5>
              <div className="">
                {discontd && discontd.length ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Strength</th>
                          {/* <th>Units</th> */}
                          <th>Adverse Reaction</th>
                          <th>Discontinued Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {discontd.map((item, i) => (
                          <React.Fragment key={i}>
                            <tr>
                              <td className="font-weight-bolder">
                                {item.name}
                              </td>
                              <td>{item.strength} {item.units}</td>
                              {/* <td>{item.units}</td> */}
                              <td>
                                {item.disp.length
                                  ? item.disp.map((obj, j) =>
                                      obj.adv_reaction ? (
                                        <p key={j} className="mb-1">
                                          {obj.adv_reaction}
                                        </p>
                                      ) : null,
                                    )
                                  : 'N/A'}
                              </td>
                              <td>
                                {item.deletedAt ? (
                                  <>
                                    <p className="mb-2">
                                      {moment(item.deletedAt).format(
                                        'MMM DD, YYYY',
                                      )}
                                    </p>
                                    <p>
                                      {moment(item.deletedAt).format(
                                        '(hh:mm a)',
                                      )}
                                    </p>
                                  </>
                                ) : (
                                  ''
                                )}
                              </td>
                            </tr>
                            {item.spDirections ? (
                              <tr style={{borderBottom: '2px solid #a2a2a2'}}>
                                <td colSpan="5">
                                  <span>
                                    <b>Directions:</b>
                                  </span>{' '}
                                  {item.spDirections}
                                </td>
                              </tr>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <h6 className="ml-3">No results found</h6>
                )}
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

class Temp extends React.Component {
  render() {
    return <Template />;
  }
}

class Medication extends React.Component {
  render() {
    return (
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18">Medication Report</h4>
                {navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ? (
                  <button
                    className="btn btn-primary print-btn"
                    onClick={() => window.print()}>
                    Print
                  </button>
                ) : (
                  <ReactToPrint
                    documentTitle="medication"
                    trigger={() => (
                      <button className="btn btn-primary">Print</button>
                    )}
                    content={() => this.componentRef}
                  />
                )}
              </div>
            </div>
          </div>
          <Temp ref={el => (this.componentRef = el)} />
        </div>
      </div>
    );
  }
}

export default Medication;
