import { SAGA_ACTIONS } from "../../config/config";
import { useApiCall } from "./callApiHook";

export function useContactApi() {
   const callApi = useApiCall();

   const addContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.CONTACT.ADD_CONTACT, data, onSuccess, onError);
   }

   const updateContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.CONTACT.UPDATE_CONTACT, data, onSuccess, onError);
   }

   const deleteContact = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.CONTACT.DELETE_CONTACT, data, onSuccess, onError);
   }

   const addLiscence = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.LISCENCE.ADD_LISCENCE, data, onSuccess, onError);
   }

   const updateLiscence = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.LISCENCE.UPDATE_LISCENCE, data, onSuccess, onError);
   }

   const deleteLiscence = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.LISCENCE.DELETE_LISCENCE, data, onSuccess, onError);
   }

   const addDocument = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.DOCUMENT.ADD_DOCUMENT, data, onSuccess, onError);
   }

   const uploadDocument = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.DOCUMENT.UPLOAD_DOCUMENT, data, onSuccess, onError);
   }

   const updateDocument = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.DOCUMENT.UPDATE_DOCUMENT, data, onSuccess, onError);
   }

   const deleteDocument = (data, onSuccess, onError) => {
      callApi(SAGA_ACTIONS.DOCUMENT.DELETE_DOCUMENT, data, onSuccess, onError);
   }

   return {
      addContact,
      updateContact,
      deleteContact,
      addLiscence,
      updateLiscence,
      deleteLiscence,
      addDocument,
      updateDocument,
      deleteDocument,
      uploadDocument
   }
}
