import React, { useState } from 'react'
import moment from 'moment'
import { formatPhone, formatSSN } from '../../form-elements/functions'
import axios from 'axios'
import { APIS } from '../../../config/config'
import { useHistory } from 'react-router-dom'
import SweetAlert from 'react-bootstrap-sweetalert'
import { toast } from 'react-toastify'
import LazyImage from "../../form-elements/lazyImage";
import { NO_IMAGE } from '../../../config/config';

const PersonalTab = (props) => {
   const [alert, setAlert] = useState(null)

   const history = useHistory()

   const downloadFile = (fileDoc) => {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      setAlert(
         <SweetAlert
            success
            title="Downloading!"
            onConfirm={() => { }}
            onCancel={() => { }}
            customButtons={<React.Fragment />}
         >
            <div class="progress mt-2" style={{ height: "15px" }}>
               <div class="progress-bar" style={{ width: "0%", height: "15px" }} id="progress"></div>
            </div>
         </SweetAlert>
      )
      axios({
         url: `${APIS.COMMON.DONWLOAD_FILE}?id=${fileDoc.id}`,//your url
         method: 'GET',
         responseType: 'blob', // important
         onDownloadProgress: progressEvent => {
            let progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            let ele = document.getElementById('progress')
            if (ele) {
               ele.style.width = progress + '%'
               ele.innerHTML = progress + '%'
            }
         }
      }).then(blob => {
         let blobs = new Blob([blob.data]),
            url = window.URL.createObjectURL(blobs);
         a.href = url;
         a.download = fileDoc.originalName;
         a.click();
         window.URL.revokeObjectURL(url);
         setAlert(null)
      }).catch(e => {
         toast.error('Could not download file')
         setAlert(null)
      })
   }

   const { details } = props
   return (
      <>
         {alert}
         <div className="mt-3 p-0 pb-3 details-wrap">
            <div className="row">
               {/*<div className="col-lg-3 col-md-6 bg-light">
                  <div className='card shadow-none bg-light'>
                     <div className='card-body text-center'>
                        {details.photo ? (
                           <LazyImage src={details.photo.fullUrl} alt="" className="rounded-circle avatar-xl" />
                        ) : (
                           <img src={NO_IMAGE} className='rounded-circle avatar-xl'/>
                        )}
                        
                        <h2 className='font-size-24'>{details?.firstName || "N/A"} {details?.lastName || "N/A"}</h2>
                        {details.email ? (
                           <div>
                              <i className="far fa-envelope"></i>&nbsp;
                              {details.email}
                           </div>
                        ) : (
                           <div></div>
                        )}

                        {details?.phone ? (
                           <div>
                              <i className="fas fa-mobile-alt"></i>&nbsp;
                              {(/[,\-]/.test(details?.phone) ? details?.phone : formatPhone(details?.phone))}</div>
                        ) : (
                           <div></div>
                        )}
                        
                     </div>
                  </div>
                  
               </div>*/}
               <div className='col-lg-12'> 
                  <div className="row">
                     <div className='col-lg-12 mb-3 mt-xs-3'><h5>Resident Details</h5></div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Nickname</label>
                           <p className="detail-text">{details?.nickname || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Gender</label>
                           <p className="detail-text" style={{ textTransform: 'capitalize' }}>{details?.gender || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Date of Birth</label>
                           <p className="detail-text">{moment(details.birthDate).format('MM-DD-YYYY')}</p>
                        </div>
                     </div>

                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Language</label>
                           <p className="detail-text">{details?.language || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Religion</label>
                           <p className="detail-text">{details?.religion || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Race</label>
                           <p className="detail-text">{details?.race || "N/A"}</p>
                        </div>
                     </div>

                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Deposit Accepted</label>
                           <p className="detail-text">{details.depositAccepted ? moment(details.birthDate).format('MM-DD-YYYY') : 'N/A'}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Death Date</label>
                           <p className="detail-text">{details.deathDate ? moment(details.deathDate).format('MM-DD-YYYY') : 'N/A'}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Moved Out Date</label>
                           <p className="detail-text">{details.movedOut ? moment(details.movedOut).format('MM-DD-YYYY') : 'N/A'}</p>
                        </div>
                     </div>

                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Facility</label>
                           <p className="detail-text">{details.facility && details.facility.name}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">SSN</label>
                           <p className="detail-text">{formatSSN(details?.ssn?.replace(/[^\d]/g, ''))}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Is vaccinated</label>
                           <p className="detail-text">{details.isVaccinated ==='yes'?'Yes':(details.isVaccinated=='unknown' ? 'Unknown' : (details.isVaccinated=='no' ? 'No' : ''))}</p>
                        </div>
                     </div>

                     <div className="col-lg-4 col-md-6">
                        <div className="form-group photo-display">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Moved In Date</label>
                           <p className="detail-text">{moment(details.movedInDate).format('MM-DD-YYYY')}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-md-5">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase btn-block">DNR/POLST</label>
                           {details && details.dnrPolst ? <button className="btn btn-primary btn-block" onClick={() => downloadFile(details.dnrPolst)}>{details.dnrPolst.originalName}</button> : <p className="detail-text">N/A</p>}
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Vaccination Comment</label>
                           {details.isVaccinated === 'yes' && 
                           <p className="detail-text">{details.vaccineComment}</p>
                           }
                           {details.isVaccinated === 'no' && 
                           <p className="detail-text">N/A</p>
                           }
                        </div>
                     </div>
                  </div>

                  <div className='row'>
                     <div className='col-lg-12'><h5>Care Type ({details?.medical ? (details.medical.residing ? (details.medical.residing == "homehealth" ? 'Home Health' : 'Hospice') : 'None') :  "None"})</h5></div>
                  </div>

                  <div className="row">
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Name</label>
                           <p className="detail-text">{details?.medical?.hospice || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Contact Name</label>
                           <p className="detail-text">{details?.medical?.hospiceContName || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Contact Phone</label>
                           <p className="detail-text">{details?.medical?.hospicePhoneNo || "N/A"}</p>
                        </div>
                     </div>
                  </div>

                  <div className='row'>
                     <div className='col-lg-12'><h5>Medical Details</h5></div>
                  </div>

                  <div className="row">
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Insurance Provider</label>
                           <p className="detail-text">{details?.medical?.insuranceProvider || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Account Number</label>
                           <p className="detail-text">{details?.medical?.accountNo || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Group Number</label>
                           <p className="detail-text">{details?.medical?.groupNo || "N/A"}</p>
                        </div>
                     </div>

                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Medicare</label>
                           <p className="detail-text">{details?.medical?.medicare || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Medical</label>
                           <p className="detail-text">{details?.medical?.medical || "N/A"}</p>
                        </div>
                     </div>
                     <div className="col-lg-4 col-6">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Attending Physician</label>
                           <p className="detail-text">{details?.medical?.physician || "N/A"}</p>
                        </div>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-lg-12">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Medical Condition</label>
                           <p className="detail-text" dangerouslySetInnerHTML={{ __html: (details?.medical?.medicalCond || "N/A") }}></p>
                        </div>
                     </div>
                  </div>

                  <div className="row">
                     <div className="col-lg-12">
                        <div className="form-group">
                           <label className="form-label mb-0 font-size-12 text-muted text-uppercase">Care Plan</label>
                           <p className="detail-text" dangerouslySetInnerHTML={{ __html: (details?.medical?.carePlan || "N/A") }}></p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            
            <div className="d-flex justify-content-between mt-3">
               <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
               <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
            </div>
         </div>
      </>
   )
}

export default PersonalTab