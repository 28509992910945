import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { PER_PAGE, ROLE_VALUE, USER_TYPES, MODULES, NO_IMAGE, OPERATIONS } from '../../config/config';
import RoleCheckHOC from "../../hoc/roleCheckHoc";
import { useAncmentApi } from '../../hooks/api/ancmentApiHook';
import { useCommonApi } from '../../hooks/api/commonApiHook';
import { useUserSelector } from '../../hooks/selectors/userSelector';
import { Tabs, Tab } from 'react-bootstrap';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import { useDispatch } from 'react-redux';
import { useStaffApi } from "../../hooks/api/staffApiHook";
import { useUserPermissionsSelector } from '../../hooks/selectors/userSelector';

const Dashboard = () => {
  const [data, setData] = useState({});
  const [currentPage, setPage] = useState(1);
  const [modal, showModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [alerts, setAlerts] = useState({});
  const [alertPage, setAlertPage] = useState(1);
  const [staffAlertPage, setStaffPage] = useState(1);
  const [staffAlerts, setStaffAlerts] = useState({});
  const [approvalCount, setApprovalCount] = useState();
  const [incidentapprovalCount, setIncidentApprovalCount] = useState();
  const [medicationapprovalCount, setMedicationApprovalCount] = useState();
  const [facilityTotal, setWithoutFacilityTotal] = useState();


  const user = useUserSelector();
  const commonApi = useCommonApi();
  const ancmentApi = useAncmentApi();
  const history = useHistory();
  const role_value = localStorage.getItem(ROLE_VALUE);
  const dispatch = useDispatch()
  const residentApi = useResidentApi()
  const staffApi = useStaffApi();
  const userPermissions = useUserPermissionsSelector();

  useEffect(() => {
    // console.log(userPermissions)
    getResAlerts();
    getStaffAlerts();
    getList(1);
    getApprovalCount();
    incidentApprovalCount();
    medicationApprovalCount();

    if (user.roles == 'user') {
      getCountWithoutList();
    }
  }, []);
  const getApprovalCount = (page = 1) => {
    ancmentApi.getApprovalsRequest(
      { page, limit: PER_PAGE, isDashboard: 1 },
      (message, resp) => {
        console.log('approval resp', resp);
        setApprovalCount(resp?.total_pending);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const incidentApprovalCount = (page = 1) => {
    ancmentApi.getIncidentApprovalsRequest(
      { page, limit: PER_PAGE, isDashboard: 1 },
      (message, resp) => {
        console.log('approval resp', resp);
        setIncidentApprovalCount(resp?.total_pending);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const medicationApprovalCount = (page = 1) => {
    staffApi.medApprovalList(
      { page, limit: PER_PAGE },
      (message, resp) => {
        // console.log(userPermissions)
        let hasPermissions = userPermissions.some(item => item.module === MODULES.APPROVE_MEDS && OPERATIONS.LIST === item.operation)
        // console.log(hasPermissions,resp.total_med_approval)
        if (hasPermissions) {
          setMedicationApprovalCount(resp.total_med_approval)
        }
        else {
          setMedicationApprovalCount(0)
        }
      },
      (message) => { }
    );
  };

  const getList = (page = 1) => {
    ancmentApi.listAncment(
      { page, limit: PER_PAGE, isDashboard: 1 },
      (message, resp) => {
        if (page === 1) {
          setData(resp);
        } else {
          let temp = { ...data };
          temp.data = [...temp.data, ...resp.data];
          setData(temp);
        }
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getResAlerts = (page = 1) => {
    commonApi.residentAlertsList(
      { page, limit: PER_PAGE },
      (message, resp) => {
        setAlerts(resp);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const getStaffAlerts = (page = 1) => {
    commonApi.staffAlertsList(
      { page, limit: PER_PAGE },
      (message, resp) => {
        setStaffAlerts(resp);
      },
      message => {
        //toast.error(message)
      },
    );
  };

  const handlePage = () => {
    getList(currentPage + 1);
    setPage(currentPage + 1);
  };

  const handleStaffPage = useCallback(
    (increase = true) => {
      getStaffAlerts(increase ? staffAlertPage + 1 : staffAlertPage - 1);
      setStaffPage(increase ? staffAlertPage + 1 : staffAlertPage - 1);
    },
    [staffAlertPage],
  );

  const handleResPage = useCallback(
    (increase = true) => {
      getResAlerts(increase ? alertPage + 1 : alertPage - 1);
      setAlertPage(increase ? alertPage + 1 : alertPage - 1);
    },
    [alertPage],
  );

  const toggle = () => {
    if (modal) {
      setModalData({});
      showModal(false);
    } else {
      showModal(true);
    }
  };

  const openModal = item => {
    setModalData(item);
    showModal(true);
  };

  const goToApprovalList = (type) => {
    // history.push('/approval-list')
    if (type && type != 'without-facility') {
      history.push({ pathname: '/approval-list', state: type });
    }
    else {
      history.push({ pathname: '/resident-list', state: type });
    }
  }

  const getCountWithoutList = () => {
    let page = 1
    let params = { page, limit: PER_PAGE }
    residentApi.residentListWithoutFacility(params,
      (message, resp) => {
        //  console.log("resprespresprespresp",resp.data);
        setWithoutFacilityTotal(resp?.total)
      }, (message) => { })
  }

  return (
    <div className="page-content">
      <div className="container-fluid p-0">
        <div className="row f_1 emergency_report">
          <>
          <div className="col-lg-12">
            <div className="table-no-border">
              <div className="em-sections">
                <h2>News & Announcements</h2>
                <div>
                  <div className="d-flex flex-wrap row">
                    {data && data.data && data.data.length ? (
                      data.data.map((item, key) => (
                        <div
                          className="box col-lg-12 col-md-12"
                          key={'ancment' + key}>
                          <div className="em-sections news_announce">
                            <h5 className="d-flex justify-content-between">
                              <span>{item.title}</span>
                              <span className="date_txt">
                                {moment(item.publishDate).format('MM-DD-YYYY')}
                              </span>
                            </h5>
                            <div
                              className="in_html"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                            <a
                              className="read_more"
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                openModal(item);
                              }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-lg-12">
                        <h4>No results found</h4>
                      </div>
                    )}
                  </div>

                  {data && data.data && data.data.length !== data.total ? (
                    <a
                      className="news_more"
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        handlePage();
                      }}>
                      View More
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          
            {alerts && alerts.data && alerts.data.length > 0 && (
              <div className="col-lg-6">
                <div className="table-wraper">
                  <div className="em-sections">
                    <h2>Resident Alerts</h2>
                    <div className="table-responsive">
                      {alerts && alerts.data && alerts.data.length ? (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Resident Name</th>
                              <th>Event Name</th>
                              <th>Event Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {alerts.data.map((item, key) => (
                              <tr key={'res' + key}>
                                <td>
                                  {item.resident && item.resident.firstName}{' '}
                                  {item.resident && item.resident.lastName}
                                </td>
                                <td>{item.type}</td>
                                <td>
                                  {moment(item.dueDate).format('MM-DD-YYYY')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h4>No results found</h4>
                      )}
                    </div>
                  </div>
                </div>
                <ul className="pagination float-right">
                  <li
                    className={`paginate_button page-item ${alertPage <= 1 ? 'disabled' : ''
                      }`}>
                    <a
                      className="page-link"
                      href="#"
                      style={{ background: '#f8f8fb' }}
                      onClick={e => {
                        e.preventDefault();
                        handleResPage(false);
                      }}>
                      Previous
                    </a>
                  </li>
                  <li
                    className={`paginate_button page-item ${alerts.totalPage <= 1 || alertPage === alerts.totalPage
                      ? 'disabled'
                      : ''
                      }`}>
                    <a
                      className="page-link"
                      href="#"
                      style={{ background: '#f8f8fb' }}
                      onClick={e => {
                        e.preventDefault();
                        handleResPage(true);
                      }}>
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            )}
            {staffAlerts.data && staffAlerts.data.length > 0 && (
              <div className="col-lg-6">
                <div className="table-wraper">
                  <div className="em-sections">
                    <h2>{user.roles === 'staff' ? 'My' : 'Staff'} Alerts</h2>
                    <div className="table-responsive">
                      {staffAlerts.data && staffAlerts.data.length ? (
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              {user.roles !== USER_TYPES.STAFF && (
                                <th>Staff Name</th>
                              )}
                              <th>Event Name</th>
                              <th>Event Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {staffAlerts.data.map((item, key) => (
                              <tr key={'staff' + key}>
                                {user.roles !== USER_TYPES.STAFF && (
                                  <td>
                                    {item.staff
                                      ? item.staff.firstName +
                                      ' ' +
                                      item.staff.lastName
                                      : ''}
                                  </td>
                                )}
                                <td>{item.type}</td>
                                <td>
                                  {moment(item.dueDate).format('MM-DD-YYYY')}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <h4>No results found</h4>
                      )}
                    </div>
                  </div>
                  <ul className="pagination float-right">
                    <li
                      className={`paginate_button page-item ${staffAlertPage <= 1 ? 'disabled' : ''
                        }`}>
                      <a
                        className="page-link"
                        href="#"
                        style={{ background: '#f8f8fb' }}
                        onClick={e => {
                          e.preventDefault();
                          handleStaffPage(false);
                        }}>
                        Previous
                      </a>
                    </li>

                    <li
                      className={`paginate_button page-item ${staffAlerts.totalPage <= 1 ||
                        staffAlertPage === staffAlerts.totalPage
                        ? 'disabled'
                        : ''
                        }`}>
                      <a
                        className="page-link"
                        href="#"
                        style={{ background: '#f8f8fb' }}
                        onClick={e => {
                          e.preventDefault();
                          handleStaffPage(true);
                        }}>
                        Next
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </>
        </div>

        <div className="row f_1 emergency_report">
          <div className="col-lg-12 col-sm-12 pr-lg-1">
            <div className="table-wraper">
              <div className="em-sections new_app">
                <h2>Approval Request</h2>
                <div className="row">
                  <div
                    className="col-lg-3"
                    onClick={() => goToApprovalList('approval_list')}
                    style={{ cursor: 'pointer' }}>
                    {
                      <div class="mini-stats-wid card bg-soft-danger border border-danger">
                        <div class="card-body">
                          <div class="d-flex">
                            <div class="flex-grow-1">
                              <p class="text-muted text-uppercase font-size-12 fw-medium">Med. Discontinue Approval</p>
                              <h4
                                className="mb-0 pl-0"
                                style={{
                                  background: 'none',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  goToApprovalList('approval_list')
                                }>
                                {approvalCount || 0}
                              </h4>
                            </div>
                            <div class="avatar-sm rounded-circle align-self-center mini-stat-icon">
                              <span class="avatar-title rounded-circle bg-danger"><i class="bx bx-check-shield font-size-24"></i></span>
                            </div>
                          </div>
                        </div>
                      </div>   
                    }
                  </div>
                  {role_value && (
                    <div
                      className="col-lg-3"
                      onClick={() => goToApprovalList('incident_approval_list')}
                      style={{ cursor: 'pointer' }}>
                      {
                        <div class="mini-stats-wid card bg-soft-primary border border-primary">
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <p class="text-muted text-uppercase font-size-12 fw-medium">Resident Incident Approval</p>
                                <h4
                                  className="mb-0 pl-0"
                                  style={{
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    goToApprovalList('incident_approval_list')
                                  }>
                                  {incidentapprovalCount || 0}
                                </h4>
                              </div>
                              <div class="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-primary"><i class="bx bx-run font-size-24"></i></span>
                              </div>
                            </div>
                          </div>
                        </div> 
                      }
                    </div>
                  )}

                  {role_value && (
                    <div
                      className="col-lg-3"
                      onClick={() => goToApprovalList('medication_approval_list')}
                      style={{ cursor: 'pointer' }}>
                      {
                        <div class="mini-stats-wid card bg-soft-success border border-success">
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <p class="text-muted text-uppercase font-size-12 fw-medium">Resident Medicine Approval</p>
                                <h4
                                  className="mb-0 pl-0"
                                  style={{
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    goToApprovalList('medication_approval_list')
                                  }>
                                  {user?.company?.thirdPartyApiFacility == 1 ? medicationapprovalCount : 0}
                                </h4>
                              </div>
                              <div class="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-success"><i class="bx bx-task font-size-24"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}

                  {user?.roles == 'user' && (
                    <div
                      className="col-lg-3"
                      onClick={() => goToApprovalList('without-facility')}
                      style={{ cursor: 'pointer' }}>
                      {
                        <div class="mini-stats-wid card bg-soft-info border border-info">
                          <div class="card-body">
                            <div class="d-flex">
                              <div class="flex-grow-1">
                                <p class="text-muted text-uppercase font-size-12 fw-medium">Resident Without Facilities</p>
                                <h4
                                  className="mb-0 pl-0"
                                  style={{
                                    background: 'none',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    goToApprovalList('without-facility')
                                  }>
                                  {facilityTotal || 0}
                                </h4>
                              </div>
                              <div class="avatar-sm rounded-circle align-self-center mini-stat-icon">
                                <span class="avatar-title rounded-circle bg-info"><i class="bx bx-user-x font-size-24"></i></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          

          {/* <div className="col-lg-12">
            <div className="table-no-border">
              <div className="em-sections">
                <h2>News & Announcements</h2>
                <div>
                  <div className="d-flex flex-wrap row">
                    {data && data.data && data.data.length ? (
                      data.data.map((item, key) => (
                        <div
                          className="box col-lg-12 col-md-12"
                          key={'ancment' + key}>
                          <div className="em-sections news_announce">
                            <h5 className="d-flex justify-content-between">
                              <span>{item.title}</span>
                              <span className="date_txt">
                                {moment(item.publishDate).format('MM-DD-YYYY')}
                              </span>
                            </h5>
                            <div
                              className="in_html"
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            />
                            <a
                              className="read_more"
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                openModal(item);
                              }}>
                              Read More
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-lg-12">
                        <h4>No results found</h4>
                      </div>
                    )}
                  </div>

                  {data && data.data && data.data.length !== data.total ? (
                    <a
                      className="news_more"
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        handlePage();
                      }}>
                      View More
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ViewAncment isOpen={modal} close={toggle} data={modalData} />
    </div>
  );
};

export default Dashboard;

const ViewAncment = props => {
  return (
    <Modal centered isOpen={props.isOpen}>
      <ModalHeader toggle={props.close}>
        {props.data.title || ''}
        <p style={{ fontSize: '10px' }} className="mt-2 mb-0">
          Date: {moment(props.data.onDate).format('MM-DD-YYYY')}
        </p>
      </ModalHeader>
      <ModalBody>
        <div dangerouslySetInnerHTML={{ __html: props.data.description || '' }} />
      </ModalBody>
    </Modal>
  );
};
