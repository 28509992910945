import React from 'react'
import { useHistory } from 'react-router'
import { formatPhone } from '../../form-elements/functions'

const HomeHealthTab = (props) => {
   const history = useHistory()

   const { phone, hospice, name } = props

   return (
      <div className="mt-3 p-0 pb-3 details-wrap">
         <div className="row">
            <div className="col-lg-4 col-12">
               <div className="form-group">
                  <label className="form-label">Home Health/Hospice</label>
                  <p className="detail-text">{hospice ? hospice : 'N/A'}</p>
               </div>
            </div>
            <div className="col-lg-4 col-12">
               <div className="form-group">
                  <label className="form-label">Contact Name</label>
                  <p className="detail-text">{name ? name : 'N/A'}</p>
               </div>
            </div>
            <div className="col-lg-4 col-12">
               <div className="form-group">
                  <label className="form-label">Contact Phone</label>
                  <p className="detail-text">{phone ? phone : 'N/A'}</p>
               </div>
            </div>
         </div>
         <div className="d-flex justify-content-between mt-3">
            <button className="btn btn-warning w-lg btn-lg" onClick={() => history.push('/resident-list')}>Back to List</button>
            <button className="btn btn-primary w-lg btn-lg mt-0" onClick={props.changeStep}>Go to Next</button>
         </div>
      </div>
   )
}

export default HomeHealthTab