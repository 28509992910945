import React from 'react';
import SuspenseLoader from '../../common/suspenseLoader';

const LazyAdministerprn = React.lazy(() =>
   import('./administerPrn')
);

const Administerprn = (props) => (
   <React.Suspense fallback={<SuspenseLoader />}>
      <LazyAdministerprn {...props} />
   </React.Suspense>
);

export default Administerprn