import { useDispatch } from 'react-redux'
import { ACTIONS } from '../../config/config'

export function useApiCall() {
   const dispatch = useDispatch()
   const callApi = (sagaAction, dataOrParams, callbackSuccess, callbackError, useLoader = true) => {
      console.log("sagaActionsagaActionsagaActionsagaAction",sagaAction);
      if (useLoader) dispatch({ type: ACTIONS.LOADER.SHOW_LOADER })
      dispatch({
         type: sagaAction,
         payload: dataOrParams,
         callbackSuccess: (data) => {
          
            let message = data && data.data ? data.data.message : 'Request processed successfully'
            let resp = data ? data.data : null
            if (useLoader) dispatch({ type: ACTIONS.LOADER.HIDE_LOADER })
            callbackSuccess && callbackSuccess(message, resp)
         },
         callbackError: (error) => {
            let message = error && error.data ? error.data.message : 'Unable to process request, please try again'
            let resp = error && error.data ? error.data : null
            if (useLoader) dispatch({ type: ACTIONS.LOADER.HIDE_LOADER })
            callbackError && callbackError(message, {data: resp, status: resp.status ? resp.status : error.status})
         }
      })
   }

   return callApi

}