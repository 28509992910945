import React, { useEffect, useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory, useParams, Link } from 'react-router-dom';
import { decryptId, encryptId, formatPhone, formatSSN } from '../../components/form-elements/functions';
import { toast } from 'react-toastify';
import { useResidentApi } from '../../hooks/api/residentApiHook';
import ContactTab from '../../components/resident/view-resident/contactTab';
import HomeHealthTab from '../../components/resident/view-resident/homeHealthTab';
import MedicalTab from '../../components/resident/view-resident/medicalTab';
import LiscenseTab from '../../components/resident/view-resident/liscenseTab';
import PersonalTab from '../../components/resident/view-resident/personalTab';
import DocumentTab from '../../components/resident/view-resident/documentTab';
import { useDispatch } from 'react-redux';
import { ACTIONS, MODULES, OPERATIONS } from '../../config/config';
import RoleCheckHOC from '../../hoc/roleCheckHoc';
import ResIncidentTab from '../../components/resident/view-resident/incidentTab';
import ResMedicationTab from '../../components/resident/view-resident/medicationTab';
import moment from "moment";
import { NO_IMAGE } from '../../config/config';
import LazyImage from '../../components/form-elements/lazyImage';


const ViewResident = (props) => {
   const history = useHistory()
   const residentApi = useResidentApi()
   const dispatch = useDispatch()

   let id = decryptId(useParams().id)
   if (id && isNaN(id)) {
      toast.error('Invalid link')
      history.replace('/resident-list')
   }

   const [activeTab, setActiveTab] = useState('1');
   const [manageTab, setMangeTab] = useState('')
   const [resident, setResident] = useState({})
   const [getAge, setAge] = useState("");


   useEffect(() => {
      getResident()
      dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: '1' })
   }, [])

   useEffect(() => {
      window.scrollTo(0, 0)
   }, [activeTab])

   const getResident = () => {
      residentApi.residentDetails({ id },
         (message, resp) => {
            console.log("respresprespresprespresprespresprespresp",resp);
            if (resp.document.length) {
               let doc = resp.document.find(item => item.file.fileType === 'dnr')
               if(doc){
                  resp['dnrPolst'] = doc.file
               }
            }
            var today = new Date();
            var birthDate = new Date(resp.birthDate);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
            // {
            setAge(age)
            //}
            setResident(resp)
         },
         (message, resp) => {
            if (resp.status === 404) {
               toast.error('No such resident found')
               setTimeout(() => {
                  history.replace('/resident-list')
               }, 500);
            }
            else {
               toast.error(message)
            }
         })
   }

   const toggle = (tab) => {
      if (activeTab !== tab) {
         setActiveTab(tab);
      }
      let temp = (tab === '1' || tab === '4' || tab === '5') ? '1' : tab === '2' ? '2' : tab === '7' ? '5' : tab === '6' ? '4' : tab === '3' ? '3' : '6'
      dispatch({ type: ACTIONS.RESIDENT.SET_RESIDENT_TAB, payload: temp })
   }

   const getAges = (dateString) => {
      const date = moment(dateString, 'YYYY-MM-DD')
      const years = moment().diff(date, 'years')
      return years
   }

   return (
      <div className="page-content" >
         <div className="container-fluid">
            <div className="row f_1">
               <div className="col-lg-2 col-md-6 bg-light">
                  <div className='card shadow-none bg-light'>
                     <div className='card-body text-center'>
                        {resident.photo ? (
                           <LazyImage src={resident.photo.fullUrl} alt="" className="rounded-circle avatar-xl" />
                        ) : (
                           <img src={NO_IMAGE} className='rounded-circle avatar-xl'/>
                        )}
                        
                        <h2 className='font-size-24'>{resident?.firstName || "N/A"} {resident?.lastName || "N/A"}</h2>
                        {resident.email ? (
                           <div>
                              <i className="far fa-envelope"></i>&nbsp;
                              {resident.email}
                           </div>
                        ) : (
                           <div></div>
                        )}

                        {resident?.phone ? (
                           <div>
                              <i className="fas fa-mobile-alt"></i>&nbsp;
                              {(/[,\-]/.test(resident?.phone) ? resident?.phone : formatPhone(resident?.phone))}</div>
                        ) : (
                           <div></div>
                        )}
                        <p>({getAges(resident.birthDate)} years)</p>

                        <RoleCheckHOC module={MODULES.RESIDENT} operation={[OPERATIONS.UPDATE]}>
                           <Link className="btn btn-primary waves-light mt-3 btn-block" to={`/manage-resident/${encryptId(resident.id)}/${activeTab === '4' ? '#home-health' : activeTab === '5' ? '#medical' : ''}`}>Edit Resident</Link>
                        </RoleCheckHOC>
                     </div>
                  </div>
                  
               </div>
               <div className="col-lg-10 p-0">
                  <div className="card">
                     <div className="card-body py-0 mt-xs-3">
                        <Nav tabs>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '1' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('1'); }}
                              >
                                 Personal Details
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '2' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('2'); }}
                              >
                                 Medication Details
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '3' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('3'); }}
                              >
                                 Contacts
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '4' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('4'); }}
                              >
                                 Home Health/Hospice
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '5' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('5'); }}
                              >
                                 Medical Details
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '6' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('6'); }}
                              >
                                 Documents
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '7' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('7'); }}
                              >
                                 Events
                              </NavLink>
                           </NavItem>
                           <NavItem>
                              <NavLink
                                 className={`${activeTab === '8' ? 'active' : ''} cursor-pointer`}
                                 onClick={() => { toggle('8'); }}
                              >
                                 Incidents
                              </NavLink>
                           </NavItem>
                        </Nav>
                        <TabContent activeTab={activeTab}>
                           <TabPane tabId="1">
                              <PersonalTab
                                 details={resident}
                                 changeStep={() => { toggle('2'); }}
                              />
                           </TabPane>
                           <TabPane tabId="2">
                              {activeTab === "2" && <ResMedicationTab resId={resident.id} changeStep={() => { toggle('3'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="3">
                              {activeTab === "3" && <ContactTab
                                 // data={resident.contact}
                                 data={(resident.contact || []).map(cont => ({
                                    ...cont,
                                    firstName: cont.reusableContact ? cont.reusableContact.firstName : cont.firstName,
                                    lastName: cont.reusableContact ? cont.reusableContact.lastName : cont.lastName,
                                    phone: cont.reusableContact ? cont.reusableContact.phone : cont.phone,
                                    type: cont.reusableContact ? cont.reusableContact.type : cont.type,
                                 }))}
                                 changeStep={() => { toggle('4'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="4">
                              {activeTab === "4" && <HomeHealthTab
                                 hospice={resident.medical && resident.medical.hospice || ''}
                                 name={resident.medical && resident.medical.hospiceContName || ''}
                                 phone={resident.medical && resident.medical.hospicePhoneNo || ''}
                                 changeStep={() => { toggle('5'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="5">
                              {activeTab === "5" && <MedicalTab
                                 details={resident.medical}
                                 changeStep={() => { toggle('6'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="6">
                              {activeTab === "6" && <DocumentTab
                                 data={resident.document}
                                 changeStep={() => { toggle('7'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="7">
                              {activeTab === "7" && <LiscenseTab
                                 data={resident.liscense}
                                 changeStep={() => { toggle('8'); }}
                              />}
                           </TabPane>
                           <TabPane tabId="8">
                              {activeTab === "8" && <ResIncidentTab resId={resident.id} />}
                           </TabPane>
                        </TabContent>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default ViewResident;